import React from "react";

interface IProps {
  errors: string[];
  className?: string;
}

export default function Errors({ errors, className }: IProps) {
  const errorsList = errors.map((error: string, index: number) => (
    <li key={index} className="ml-2 text-red-400">
      {error}
    </li>
  ));
  return (
    <div className={`rounded-md border border-red-400 p-2 ${className}`}>
      <ul className="list-none p-0">{errorsList}</ul>
    </div>
  );
}

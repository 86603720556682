import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import findCancellationResponseJsonFile from "src/examples/responses/find_cancellation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function FindCancellation({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("find-cancellation");
      if (hash.includes("find-cancellation")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/cancellations#find-cancellation">
        <h3 className="mb-8 text-xl font-medium">Find a cancellation</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the cancellation.</div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The cancellation object, if found.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(testApiKey!, findCancellationExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              findCancellationResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const findCancellationExampleCurlBody = `$'{
  "query": "query FindCancellation($id:ID!){cancellation:FindCancellation(id:$id){id providerId customerId state createdAt updatedAt cancellationInfo}}",
  "variables": {
    "id": "cancellation_d8fb9e40650351069573001a8184845c"
  }
`;

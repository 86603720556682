import { Space } from "@cohubinc/blueprint";
import React, { ReactNode } from "react";
import CodeSnippet from "../CodeSnippet";
import Typography from "../Typography";

interface IProps {
  anchor: string;
  title: string;
  code?: string;
  language?: string;
  children?: ReactNode;
  className?: string;
}

const Section: React.FC<IProps> = ({
  anchor,
  title,
  children,
  code,
  language,
  className
}) => {
  return (
    <div className={className}>
      <button id={anchor} style={{ visibility: "hidden" }} />
      {/* <Space height={2} /> */}
      <Typography.HeadingSmall>{title}</Typography.HeadingSmall>
      <div>{children}</div>
      <Space height={1} />
      {!!code && <CodeSnippet code={code} language={language} />}
    </div>
  );
};

export default React.memo(Section);

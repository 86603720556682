import queryString from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { host, protocol } from "src/config";
import useSession from "src/hooks/useSession";
import NewPasswordForm from "./NewPasswordForm";

const NewPassword: React.FC = () => {
  const { logIn } = useSession();
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);

  const setPassword = React.useCallback(
    async (values) => {
      setErrors([]);
      const url = `${protocol}://${host}/api/partner_users/new_password`;
      const reset_password_token = queryString.parse(
        window.location.search
      ).reset_password_token;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ reset_password_token, ...values })
      });

      const result = await response.json();

      if (result.partner_user) {
        // logIn(result.partner_user);
        history.push("/");
      } else if (result.errors) {
        setErrors(result.errors);
      }
    },
    [history]
  );

  return <NewPasswordForm onSubmit={setPassword} errors={errors} />;
};

export default NewPassword;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import sandboxTriggerProviderUpdatedJsonFile from "src/examples/responses/sandbox_trigger_provider_updated.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SandboxTriggerProviderUpdated({
  className,
  id
}: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "sandbox-trigger-provider-updated"
      );
      if (hash.includes("sandbox-trigger-provider-updated")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "sandbox-trigger-provider-updated"}>
      <a href="/api-docs/sandbox-resources#sandbox-trigger-provider-updated">
        <h3 className="mb-8 text-xl font-medium">
          Sandbox trigger provider updated
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"providerId"}
              type={"ID"}
              description={
                <div className="space-y-4">
                  <div>The ID of the provider that will send the webhook.</div>
                  <div>
                    <span className="text-xs text-red-500">NOTE:</span> This
                    mutation fakes a provider.updated webhook in the sandbox.
                    The provider ID passed to this mutation will NOT make any
                    real changes to the provider itself to avoid conflicting
                    with the syncing mechanism used to keep production providers
                    in sync with sandbox providers. This means that if
                    FindProvider or ListProviders is called, their contents may
                    not align with the webhook delivered by this mutation. This
                    is expected behavior.
                  </div>
                  <div>
                    <span className="text-xs text-red-500">NOTE:</span> Given
                    that this mutation is only used as a method to trigger a
                    webhook, it does not return any data other than a success
                    field and an errors field.
                  </div>
                  <div>
                    Passing a provider that is negotiable will cause a webhook
                    to fire where the negotiable flag is set to false.
                  </div>
                  <div>
                    Passing a provider that is cancellable will cause a webhook
                    to fire that contains a new{" "}
                    <CodeSnippet
                      bare
                      inline
                      code="provider_cancellation_field"
                    />
                    .
                  </div>
                </div>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>A boolean success field and an array of possible errors.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, calculateEstimateExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   calculateEstimateExampleJsBody
              // ),
              curl: curlExample(testApiKey!, sandboxResetBillExampleCurlBody)
              // python: pythonExample(
              //   testApiKey!,
              //   calculateEstimateExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              sandboxTriggerProviderUpdatedJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const sandboxResetBillExampleCurlBody = `
$'{
  "query": "mutation SandboxTriggerProviderUpdated($providerId:ID!){SandboxTriggerProviderUpdated(input:{providerId:$providerId}){success errors}}",
	"variables": {
		"providerId": "provider_0ec2d08078405f4ed554a582ca1c953f"
	}
`;

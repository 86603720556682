import React, { ReactNode } from "react";

interface IDetailProps {
  heading: string;
  body: string | ReactNode;
  className?: string;
}

const Detail: React.FC<IDetailProps> = ({ heading, body, className }) => {
  return (
    <div className={className}>
      <h6 className="block text-gray-500 text-xs leading-4">{heading}</h6>
      <span className="text-gray-700 font-medium text-sm">{body}</span>
    </div>
  );
};

export default Detail;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import listInfoReqsObjectJsonFile from "src/examples/responses/list_info_reqs.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListInfoRequests({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("list-info-reqs");
      if (hash.includes("list-info-reqs")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "list-info-reqs"}>
      <a href="/api-docs/info-reqs#list-info-reqs">
        <h3 className="mb-8 text-xl font-medium">List information requests</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"limit"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>
                    The number of info requests you'd like returned by this
                    request.
                  </div>
                </React.Fragment>
              }
              defaultValue={"25"}
            />
            <Parameter
              field={"offset"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>The number of info requests to skip.</div>
                </React.Fragment>
              }
              defaultValue={"0"}
            />
            <Parameter
              field={"customer_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include info requests
                    owned by the customer associated with the given ID.
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The total numbers of info requests that matched the given
              parameters and an array of those info requests.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, listInfoRequestsExampleRubyBody),
              js: javascriptExample(testApiKey!, listInfoRequestsExampleJsBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(listInfoReqsObjectJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const listInfoRequestsExampleRubyBody = `
info_requests = client.list_information_requests(
  limit: 25,
  offset: 0,
  customer_id: "customer_12fasfa3187asd"
)
`;

const listInfoRequestsExampleJsBody = `
const { totalCount, informationRequests } = await client.listInformationRequests(
  {
    limit: 25,
    offset: 0,
    customerId: "customer_12fasfa3187asd"
  }
);
`;

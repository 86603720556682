const pythonExample = (apiKey: string, body: string): string => {
  return `
# Install the Python Requests library:
# pip install requests

import requests


def send_request():
    # Calculate Savings Estimate
    # POST https://sandbox.billfixers.com/partner/graphql

    try:
        response = requests.post(
            url="https://sandbox.billfixers.com/partner/graphql",
            headers={
                "X-Partner-ApiKey": ${apiKey},
                "Content-Type": "application/json; charset=utf-8",
            },
            data=${body}
        )
        print('Response HTTP Status Code: {status_code}'.format(
            status_code=response.status_code))
        print('Response HTTP Response Body: {content}'.format(
            content=response.content))
    except requests.exceptions.RequestException:
        print('HTTP Request failed')`;
};

export default pythonExample;

import { Divider, Space, Stack } from "@cohubinc/blueprint";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import Typography from "src/components/Typography";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import { Reference, StyledApiDocs } from "./styles";

export default function ApiDocs() {
  usePageTitle("Getting Started");
  const { hash } = useLocation();

  const stdGQLPostString = `
    {
      "query": "...",
      "operationName": "...",
      "variables": {
        "myVariable": "someValue",
        ...
      }
    }
   `;

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("authentication");
      if (hash.includes("authentication")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <NewContainer className="mb-12">
      <Space height={3} />
      <StyledApiDocs>
        <Reference>
          <Stack>
            <Typography.HeadingLarge>GraphQL API</Typography.HeadingLarge>
          </Stack>
          <Stack>
            <Typography>
              Unlike REST APIs, there is only one request type used to fetch or
              alter data in a GraphQL API.
            </Typography>
            <Typography>
              Every request is a POST request and should be sent to one of the
              URLs below:
            </Typography>
            <div>
              <div className="mb-2">
                <span>For testing your integration:</span>
                <CodeSnippet
                  bare
                  code="https://sandbox.billfixers.com/partner/graphql"
                  className="text-sm"
                />
              </div>
              <div>
                <span>For live, production use:</span>
                <CodeSnippet
                  bare
                  code="https://app.billfixers.com/partner/graphql"
                  className="text-sm"
                />
              </div>
            </div>
          </Stack>
        </Reference>
        <Segment className="w-full">
          <div className="mb-6">Client Libraries</div>
          <div className="flex items-center">
            <div className="flex flex-col justify-center items-center mr-6">
              <svg
                className="SVGInline-svg SVG-svg LangIcon-svg mb-1"
                style={{ width: "28px", height: "28px" }}
                viewBox="4 4 28 28"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.476 5.076c2.78.481 3.569 2.382 3.51 4.373L27 9.42l-1.251 16.398-16.266 1.114h.014c-1.35-.057-4.36-.18-4.497-4.388l1.508-2.75 2.584 6.037.461 1.075 2.571-8.382-.027.006.014-.028 8.484 2.71-1.28-4.978-.907-3.575 8.085-.522-.564-.467-5.804-4.732 3.354-1.871-.003.01zM9.703 9.65c3.264-3.238 7.477-5.152 9.095-3.52 1.615 1.63-.097 5.597-3.367 8.833-3.266 3.238-7.428 5.256-9.042 3.628-1.62-1.63.041-5.7 3.31-8.938l.004-.003z"
                  fill="#D91505"
                />
              </svg>
              <Link to={paths.libraries.ruby} className="text-sm text-gray-600">
                Ruby
              </Link>
            </div>
            <div className="flex flex-col justify-center items-center">
              <svg
                className="SVGInline-svg SVG-svg LangIcon-svg mb-1"
                style={{ width: "28px", height: "28px" }}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.516 28.952a2.09 2.09 0 0 1-1.002-.263l-3.17-1.883c-.478-.262-.24-.358-.096-.405.644-.215.763-.263 1.43-.644.072-.048.167-.024.239.024l2.432 1.454c.095.048.214.048.286 0l9.513-5.507c.095-.048.143-.143.143-.263v-10.99c0-.12-.048-.215-.143-.263L15.635 4.73c-.096-.048-.215-.048-.286 0l-9.513 5.483c-.096.048-.143.167-.143.262v10.991c0 .096.047.215.143.263l2.599 1.502c1.406.715 2.288-.12 2.288-.954V11.428a.29.29 0 0 1 .286-.286h1.216a.29.29 0 0 1 .287.286v10.848c0 1.884-1.026 2.98-2.814 2.98-.548 0-.977 0-2.193-.596L5 23.23A2.012 2.012 0 0 1 4 21.49V10.497c0-.715.381-1.383 1.001-1.74l9.513-5.508a2.128 2.128 0 0 1 2.003 0l9.513 5.508a2.012 2.012 0 0 1 1.001 1.74V21.49c0 .716-.381 1.383-1.001 1.74l-9.513 5.508a2.44 2.44 0 0 1-1.001.215zm2.932-7.558c-4.172 0-5.03-1.907-5.03-3.529a.29.29 0 0 1 .286-.286h1.24c.142 0 .262.096.262.239.19 1.263.739 1.883 3.266 1.883 2.003 0 2.861-.453 2.861-1.526 0-.62-.238-1.073-3.362-1.383-2.598-.262-4.22-.834-4.22-2.908 0-1.931 1.622-3.076 4.34-3.076 3.051 0 4.553 1.05 4.744 3.338a.385.385 0 0 1-.072.215c-.047.047-.119.095-.19.095h-1.24a.28.28 0 0 1-.262-.215c-.286-1.31-1.025-1.74-2.98-1.74-2.194 0-2.456.763-2.456 1.335 0 .691.31.906 3.266 1.288 2.933.381 4.315.93 4.315 2.98-.023 2.098-1.74 3.29-4.768 3.29z"
                  fill="#89D42C"
                />
              </svg>
              <Link
                to={paths.libraries.javascript}
                className="text-sm text-gray-600"
              >
                Javascript
              </Link>
            </div>
          </div>
        </Segment>
      </StyledApiDocs>
      <Space height={3} />
      <StyledApiDocs>
        <Reference>
          <Typography>
            A standard GraphQL POST request should use the{" "}
            <CodeSnippet
              bare
              inline={true}
              code="application/json"
              className="text-sm"
            />{" "}
            content type, and include a JSON-encoded body like the one shown
            here {"-->"}
          </Typography>
        </Reference>
        <CodeSnippet code={stdGQLPostString} language="json" />
      </StyledApiDocs>
      <Divider marginSize={3} />
    </NewContainer>
  );
}

import React from "react";
import {
  IPartnerFragment,
  useUpdateWebhookUrlsMutation
} from "src/components/graphql";
import { useTestMode } from "src/contexts/TestModeContext";
import useSession from "src/hooks/useSession";
import WebhookHeaders from "./WebhookHeaders";
import Webhooks from "./Webhooks";

const updateWebhooksGql = `
mutation UpdateWebhookUrls($liveWebhookUrl: String, $testWebhookUrl: String) {
  UpdateWebhookUrls(
    input: { liveWebhookUrl: $liveWebhookUrl, testWebhookUrl: $testWebhookUrl }
  ) {
    success
    errors
    partner {
      id
      liveWebhookUrl
      testWebhookUrl
    }
  }
}
`;

interface IProps {
  partner: IPartnerFragment;
}

export default function WebhooksTab({ partner }: IProps) {
  const { testMode } = useTestMode();
  const { email, token } = useSession();
  const { liveWebhookUrl, testWebhookUrl } = partner;

  const [updateMutation] = useUpdateWebhookUrlsMutation();

  const updateWebhookUrls = React.useCallback(
    async ({ liveWebhookUrl, testWebhookUrl }) => {
      await updateMutation({
        variables: { liveWebhookUrl, testWebhookUrl }
      });

      if (process.env.REACT_APP_REPLICATE_WEBHOOK_URLS_TO_STAGE === "true") {
        await fetch(
          "https://billfixers-partner-sandbox.herokuapp.com/partner/graphql",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "X-PartnerUser-Email": email!,
              "X-PartnerUser-Token": token!
            },
            body: JSON.stringify({
              query: updateWebhooksGql,
              variables: { liveWebhookUrl, testWebhookUrl }
            })
          }
        );
      }
    },
    [email, token, updateMutation]
  );

  return (
    <div className="">
      <Webhooks partner={partner} />
      <WebhookHeaders partner={partner} />
    </div>
  );
}

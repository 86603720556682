import queryString from "query-string";

let { host: apiHost, protocol: apiProtocol } = queryString.parse(
  window.location.search
);

if (!apiHost) {
  apiHost = localStorage.getItem("host") as string;
}

if (!apiProtocol) {
  apiProtocol = localStorage.getItem("protocol") as string;
}

const defaultHost = process.env.REACT_APP_BACKEND_URL;
const defaultProtocol = process.env.REACT_APP_BACKEND_PROTOCOL;

const host = apiHost || defaultHost;
const protocol = apiProtocol || defaultProtocol;

if (host !== defaultHost) {
  console.log({ host });
  console.log({ defaultHost });

  localStorage.setItem("host", host as string);
}

if (protocol !== defaultProtocol) {
  localStorage.setItem("protocol", protocol as string);
}

export { host, protocol, defaultHost, defaultProtocol };

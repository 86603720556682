import debounce from "lodash/debounce";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import DateAtTime from "src/components/DateAtTime";
import NewContainer from "src/components/NewContainer";
import Paginator from "src/components/Paginator";
import { useListWebhookEventsQuery } from "src/components/graphql";
import usePageTitle from "src/hooks/usePageTitle";

const eventTypeOptions = [
  { value: "bill.customer_ineligible", label: "bill.customer_ineligible" },
  { value: "bill.negotiation_started", label: "bill.negotiation_started" },
  {
    value: "bill.negotiation_completed",
    label: "bill.negotiation_completed"
  },
  { value: "bill.negotiation_failed", label: "bill.negotiation_failed" },
  { value: "bill.savings_updated", label: "bill.savings_updated" },
  { value: "bill.autofix_upcoming", label: "bill.autofix_upcoming" },
  { value: "bill.outside_of_scope", label: "bill.outside_of_scope" },
  { value: "bill.bad_document", label: "bill.bad_document" },
  {
    value: "bill.negotiation_scheduled",
    label: "bill.negotiation_scheduled"
  },
  { value: "bill.savings_expiring", label: "bill.savings_expiring" },
  { value: "bill.savings_expired", label: "bill.savings_expired" },
  { value: "bill.duplicate_discarded", label: "bill.duplicate_discarded" },
  { value: "bill.stopped", label: "bill.stopped" },
  {
    value: "bill.provider_account_closed",
    label: "bill.provider_account_closed"
  },
  { value: "customer.updated", label: "customer.updated" },
  {
    value: "information_request.created",
    label: "information_request.created"
  },
  { value: "offer.created", label: "offer.created" },
  { value: "offer.accepted", label: "offer.accepted" },
  { value: "offer.rejected", label: "offer.rejected" },
  { value: "cancellation.started", label: "cancellation.started" },
  { value: "cancellation.succeeded", label: "cancellation.succeeded" },
  {
    value: "cancellation.already_cancelled",
    label: "cancellation.already_cancelled"
  },
  {
    value: "cancellation.unable_to_cancel",
    label: "cancellation.unable_to_cancel"
  },
  {
    value: "cancellation.no_subscription_found",
    label: "cancellation.no_subscription_found"
  },
  {
    value: "cancellation_info_request.created",
    label: "cancellation_info_request.created"
  },
  {
    value: "cancellation_offer.created",
    label: "cancellation_offer.created"
  },
  {
    value: "cancellation_offer.accepted",
    label: "cancellation_offer.accepted"
  },
  {
    value: "cancellation_offer.rejected",
    label: "cancellation_offer.rejected"
  },
  {
    value: "provider.updated",
    label: "provider.updated"
  }
];

const WebhookEvents: React.FC = () => {
  usePageTitle("Webhook Events");
  const { page } = queryString.parse(window.location.search);
  const { search } = useLocation();
  const perPage = 50;
  const [apiId, setApiId] = useState<string | undefined>("");
  const [eventType, setEventType] = useState<string | undefined>(undefined);
  const params: any = queryString.parse(search, {
    arrayFormat: "bracket"
  });

  const { loading, data } = useListWebhookEventsQuery({
    variables: {
      offset: perPage * (parseInt(page as string) - 1 || 0),
      limit: perPage,
      recordApiId: apiId,
      eventType: eventType
    }
  });

  const changeHandler = (event: any) => {
    setApiId(event.target.value);
  };

  const debouncedSetApiId = useMemo(() => debounce(changeHandler, 500), []);

  if (loading && !data) {
    return <AltLoader />;
  }

  if (!data) {
    <NewContainer>
      <div className="mt-8 flex justify-center">No events found.</div>
    </NewContainer>;
  }

  const events = data?.events.nodes.map((node) => node);
  const totalCount = data?.events.totalCount;

  const eventsList = events?.map((event, idx) => {
    if (!event) {
      return null;
    }

    return (
      <tr key={event.id} className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 py-4 text-sm text-gray-500">
          <div>{event.eventType}</div>
          <div className="text-xs text-slate-600">
            {JSON.stringify(event.additionalData)}
          </div>
          <div className="text-xxs text-slate-600">{event.apiId}</div>
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          <div>
            {event.subjectType} {event.subjectId}
          </div>
          <div>
            <span className="text-xs text-slate-600">{event.recordApiId}</span>
          </div>
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          <div>{event.delivered ? "Yes" : "No"}</div>
          {event.delivered && (
            <span className="text-xs text-slate-600">
              <DateAtTime input={event.deliveredAt} />
            </span>
          )}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          {event.retryCount}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
          {event.url}
          <div className="text-xs">
            {event.partnerWebhook?.webhookEnvironment}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <NewContainer>
      <h1 className="text-2xl font-medium text-gray-800">Webhook Events</h1>
      <h5 className="text-xs text-gray-500">
        <NumberFormat value={totalCount} thousandSeparator displayType="text" />{" "}
        total webhook events
      </h5>
      <div className="mt-4 flex items-center space-x-4">
        <div>
          <label
            htmlFor="Status"
            className="block text-sm font-medium text-gray-700"
          >
            Event Type
          </label>
          <div className="mt-1">
            <select
              id="status"
              name="status"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={eventType}
              onChange={(evt) => {
                if (evt.target.value === "Any") {
                  setEventType(undefined);
                } else {
                  setEventType(evt.target.value);
                }
              }}
            >
              <option value={"Any"}>Any</option>
              {eventTypeOptions.map((eventType) => (
                <option key={eventType.value} value={eventType.value}>
                  {eventType.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-80">
          <label
            htmlFor="apiId"
            className="block cursor-pointer text-sm font-medium text-gray-700"
          >
            API ID
          </label>
          <input
            type="text"
            name={"apiId"}
            id={"apiId"}
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={debouncedSetApiId}
            disabled={loading}
          />
        </div>
        {/* <div>
          <label
            htmlFor="missingDocumentInfo"
            className="block cursor-pointer text-sm font-medium text-gray-700"
          >
            Missing document info
          </label>
          <input
            type="checkbox"
            name={"missingDocumentInfo"}
            id={"missingDocumentInfo"}
            className="mr-2 mt-1 cursor-pointer accent-indigo-400"
            checked={missingDocumentInfo}
            onChange={(evt) => setMissingDocumentInfo(evt.target.checked)}
            disabled={loading}
          />
        </div> */}
      </div>
      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full table-auto divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Event Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Delivered
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Retries
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      URL
                    </th>
                  </tr>
                </thead>
                <tbody>{eventsList}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {totalCount && totalCount > perPage && (
        <div className="flex justify-center">
          <div className="mt-4 w-1/4 rounded-lg bg-white p-3 shadow-md">
            <Paginator totalCount={totalCount || 0} perPage={perPage} />
          </div>
        </div>
      )}
    </NewContainer>
  );
};

export default WebhookEvents;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import listNegotiationsObjectJsonFile from "src/examples/responses/list_negotiations.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListNegotiations({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const listNegotiationsElement =
        window.document.getElementById("list-negotiations");
      if (hash.includes("list-negotiations")) {
        listNegotiationsElement?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/negotiations#list-negotiations">
        <h3 className="mb-8 text-xl font-medium">List negotiations</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <Parameter
            field={"bill_id"}
            required
            type={"ID"}
            description={
              <React.Fragment>
                <div>
                  The ID of the bill you'd like to retrieve negotiations for.
                </div>
              </React.Fragment>
            }
          />
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An array of the negotiations associated with the bill, if any.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, listBillsExampleRubyBody),
              // js: javascriptExample(testApiKey!, listBillsExampleJsBody),
              curl: curlExample(testApiKey!, listNegotiationsExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              listNegotiationsObjectJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const listNegotiationsExampleCurlBody = `
$'{
  "query": "query ListNegotiations($billId:ID!){ListNegotiations(billId:$billId){totalCount nodes{id billId state totalSavings resultsNote typeOfSavings startedAt fixedAt failedAt items{prePrice postPrice}}}}",
	"variables": {
		"billId": "bill_274317a64108e26a72a1daee37b82c05"
	  }
  }
`;

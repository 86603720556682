import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import exampleInfoRequest from "src/examples/information_request.json";
import usePageTitle from "src/hooks/usePageTitle";
import { StyledApiDocs } from "../styles";
import FindInfoRequest from "./FindInfoRequest";
import ListInfoRequests from "./ListInfoRequests";
import RespondToInfoRequest from "./RespondToInfoRequest";

const InformationRequests: React.FC = () => {
  usePageTitle("Information Requests");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Typography.Large>
            During the course of a negotiation, we may need additional
            information from a customer about their bill. For example, many
            providers need us to provide them with a billing/service address
            during our negotations.
          </Typography.Large>
          <Typography.Large>
            An InformationRequest will contain sub-fields that are in the format
            of a question and an answer.
          </Typography.Large>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <AvailableFields objectName="informationRequest">
            <FieldAttribute
              field={"id"}
              type={"ID"}
              description={"Alpha-numeric unique identifier"}
            />
            <FieldAttribute
              field={"customer_id"}
              type={"ID"}
              description={
                "Alpha-numeric unique identifier for the customer related to an information request."
              }
            />
            <FieldAttribute
              field={"bill_id"}
              type={"ID"}
              description={
                "Alpha-numeric unique identifier for the bill related to an information request."
              }
            />
            <FieldAttribute
              field={"content"}
              type={"String"}
              description={
                "A plain-text string describing the question or reason the fixer/negotiator is requesting information"
              }
            />
            <FieldAttribute
              field={"content_html"}
              type={"String"}
              description={"HTML formatted version of the content"}
            />
            <FieldAttribute
              field={"header_text"}
              type={"String"}
              description={
                "Short 2-4 word description of the information that's missing or needed"
              }
            />
            <FieldAttribute
              field={"created_at"}
              type={"DateTime"}
              description={"Date and time when information was requested"}
            />
            <FieldAttribute
              field={"updated_at"}
              type={"DateTime"}
              description={"Date and time when information was requested"}
            />
            <FieldAttribute
              field={"responded_at"}
              type={"DateTime"}
              description={
                "Date and time when the customer responded to the information request"
              }
            />
            <FieldAttribute
              field={"fields"}
              type={"Array of fields"}
              description={
                "An array of fields. Each field has an id, label, placeholder, data type, and value."
              }
              childAttributes={[
                {
                  field: "id",
                  description:
                    "Alpha-numeric unique identifier for the information request field.",
                  type: "ID"
                },
                {
                  field: "label",
                  description: "The descriptive label for the form field.",
                  type: "string"
                },
                {
                  field: "placeholder",
                  description: "The placeholder for the form field.",
                  type: "string"
                },
                {
                  field: "data_type",
                  description:
                    "The type of data we're requesting from the customer.",
                  type: "string",
                  possibleValues: ["text"]
                },
                {
                  field: "value",
                  description:
                    "The value provided by the customer when they responded to the information request. If they haven't responded, value will be null.",
                  type: "string"
                }
              ]}
            />
          </AvailableFields>
        </Reference>
        <CodeSnippet
          code={JSON.stringify(exampleInfoRequest, null, 2)}
          language="json"
        />
      </StyledApiDocs>
      <Divider marginSize={6} />
      <RespondToInfoRequest />
      <Divider marginSize={6} />
      <ListInfoRequests />
      <Divider marginSize={6} />
      <FindInfoRequest />
    </Fragment>
  );
};

export default InformationRequests;

import { XIcon } from "@heroicons/react/solid";
import React, { CSSProperties, Fragment, ReactNode, useState } from "react";
import Color from "src/definitions/enums/Color";
import NewButton, { TButtonVersion } from "./NewButton";
import Segment from "./Segment";
import Typography from "./Typography";

interface ITheme {
  backgroundColor?: string | Color;
  color?: string | Color;
  borderHighlightColor?: string | Color;
  iconColor?: string | Color;
}

type TAction = {
  onAction: () => void;
  content: string;
  buttonVersion?: TButtonVersion;
};

interface IAlertProps {
  title?: string;
  children?: ReactNode;
  body?: string | ReactNode;
  theme?: ITheme;
  icon?: string | ReactNode;
  dismissable?: boolean;
  primaryAction?: TAction;
  onDismiss?: () => void;
  className?: string;
}

export default function Alert({
  title,
  children,
  body,
  theme,
  icon,
  primaryAction,
  dismissable = true,
  className,
  onDismiss = () => null
}: IAlertProps) {
  const [dismissed, setDismissed] = useState(false);

  theme = {
    backgroundColor: Color.trueWhite,
    color: Color.text,
    ...theme
  };

  let styledBorder: CSSProperties = {};

  if (theme.borderHighlightColor) {
    styledBorder = {
      borderLeft: `12px solid ${theme.borderHighlightColor}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px"
    };
  }

  return (
    <React.Fragment>
      {!dismissed && (
        <Segment className={className}>
          <div className="flex items-start justify-between">
            <div className="flex w-full items-start">
              <div className="w-full">
                {title && (
                  <Typography.Large weight={600} color={theme.color} block>
                    {title}
                  </Typography.Large>
                )}
                <div className="flex items-center">
                  {body && (
                    <Fragment>
                      <Typography className="mr-4 flex-1" color={theme.color}>
                        {body}
                      </Typography>
                    </Fragment>
                  )}
                  {primaryAction && (
                    <NewButton
                      version={primaryAction.buttonVersion || "plain"}
                      className="hidden sm:block"
                      size="sm"
                      onClick={primaryAction.onAction}
                    >
                      {primaryAction.content}
                    </NewButton>
                  )}
                </div>
                {children && children}
              </div>
            </div>
            {dismissable && (
              <XIcon
                className="h-6 w-6 cursor-pointer text-gray-700"
                onClick={() => {
                  setDismissed(true);
                  onDismiss();
                }}
              />
            )}
          </div>
          {primaryAction && (
            <div className="mt-4 flex items-center justify-center sm:hidden">
              <NewButton
                version={primaryAction.buttonVersion || "plain"}
                size="sm"
                onClick={primaryAction.onAction}
              >
                {primaryAction.content}
              </NewButton>
            </div>
          )}
        </Segment>
      )}
    </React.Fragment>
  );
}

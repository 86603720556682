import React from "react";
import Breadcrumbs from "src/components/Breadcrumbs";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import usePageTitle from "src/hooks/usePageTitle";

export default function CreatingCustomers() {
  usePageTitle("Creating Customers");

  return (
    <NewContainer className="mt-8">
      <Breadcrumbs
        crumbs={[
          {
            path: "/api-docs/implementation",
            text: "Implementation Guide",
            active: false
          },
          {
            path: "/api-docs/implementation/creating-customers",
            text: "Creating Customers",
            active: true
          }
        ]}
      />
      <div className="mt-8">
        <h3
          className="text-xl text-gray-bf-text-medium mb-2"
          id="the-customer-is-always-first"
        >
          Creating customers
        </h3>
        <p className="mb-4">
          Negotiations can't happen unless there's someone to negotiate for,
          which is why 99.9% of the time, the first thing your application
          should do when using our API is to create a customer.
        </p>
        <p className="mb-4">
          As soon as you suspect that a customer might be interested in bill
          negotiation, go ahead and call the API using the{" "}
          <CodeSnippet bare inline code="createCustomer" /> mutation. There are
          no required fields, but the more information you can provide, the more
          likely we are to have a successful negotiation. Fields like{" "}
          <CodeSnippet bare inline code="first_name" />,{" "}
          <CodeSnippet bare inline code="last_name" />,{" "}
          <CodeSnippet bare inline code="email" />, and{" "}
          <CodeSnippet bare inline code="phone_number" /> are useful to have
          when we begin our negotiations.
        </p>
      </div>
    </NewContainer>
  );
}

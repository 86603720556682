import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import rejectCancellationOfferResponseJsonFile from "src/examples/responses/reject_cancellation_offer.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function RejectCancellationOffer({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  const hashRoute = id || "reject-cancellation-offer";

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(hashRoute);
      if (hash.includes(hashRoute)) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash, hashRoute]);

  return (
    <div className={className} id={hashRoute}>
      <a href={`/api-docs/offers#${hashRoute}`}>
        <h3 className="mb-8 text-xl font-medium">
          Reject a cancellation offer
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <Divider marginSize={0.5} />
          <Parameter
            required
            field={"cancellation_offer_id"}
            type={"ID"}
            description={
              <React.Fragment>
                <div>
                  The ID of the cancellation offer that's being rejected.
                </div>
              </React.Fragment>
            }
          />
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The cancellation offer object that was rejected if successful.
            </p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request route="/partners/graphql" examples={{}} />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              rejectCancellationOfferResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

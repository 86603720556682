import startCase from "lodash/startCase";
import React from "react";
import Detail from "src/components/Detail";
import { IBillFragment } from "src/components/graphql";

interface IProps {
  bill: IBillFragment;
}

export default function DocumentlessSection({ bill }: IProps) {
  return (
    <React.Fragment>
      <div className="flex items-start justify-between mt-4 mb-2">
        <div className="text-sm">Documentless Fields</div>
      </div>
      {bill.documentlessInfo.map((res: any) => {
        return (
          <div key={res.id} className="my-1">
            <Detail
              heading={startCase(res.name)}
              body={res.recorded_response || "No response"}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
}

import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import exampleSavingsEvent from "src/examples/savings_event.json";
import usePageTitle from "src/hooks/usePageTitle";
import { StyledApiDocs } from "../styles";
import FindRandomRecentSavings from "./FindRandomRecentSavings";
import ListRandomRecentSavings from "./ListRandomRecentSavings";

const SavingsEstimate: React.FC = () => {
  usePageTitle("Savings Event");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs className="mb-16">
        <Stack>
          <Typography.Large>
            Savings events can be used for social proof in marketing materials.
          </Typography.Large>
          <Typography.Large>
            A savings event comes from either a negotiation or cancellation that
            saved a customer money.
          </Typography.Large>
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <AvailableFields objectName="savingsEvent">
            <FieldAttribute
              field={"savings"}
              type={"Money"}
              description={
                "Amount that was saved as a result of the negotiation or cancellation"
              }
            />
            <FieldAttribute
              field={"savingsSource"}
              type={"String"}
              description={
                "The method/source of the savings event, either 'negotiation' or 'cancellation'"
              }
            />
            <FieldAttribute
              field={"customerInitials"}
              type={"String"}
              description={
                "First name and last initial of the customer who saved money"
              }
            />
            <FieldAttribute
              field={"customerFirstName"}
              type={"String"}
              description={"First name of the customer who saved money"}
            />
            <FieldAttribute
              field={"providerName"}
              type={"String"}
              description={
                "Name of the provider associated with the savings event"
              }
            />
            <FieldAttribute
              field={"providerLogo"}
              type={"Image"}
              possibleValues={[
                "tinyUrl",
                "iconUrl",
                "thumbnailUrl",
                "smallUrl",
                "mediumUrl",
                "largeUrl",
                "grandeUrl"
              ]}
              description={
                <div className="space-y-2">
                  <p>
                    An object containing keys that correspond to different URLs
                    representing the provider's logo in various sizes. This logo
                    is the square version of the provider's logo.
                  </p>
                  <p>
                    If you request the logo field, you should pass at least one
                    of the following into the logo field in your query:
                  </p>
                </div>
              }
            />
            <FieldAttribute
              field={"providerName"}
              type={"String"}
              description={
                "The date and time that the savings occurred. Useful for displaying a relative time if desired, like '3 days ago'"
              }
            />
            <FieldAttribute
              field={"provider"}
              type={"Provider"}
              description={"The provider associated with the savings event."}
            />
            <FieldAttribute
              field={"customer"}
              type={"Customer"}
              description={"The customer associated with the savings event."}
            />
          </AvailableFields>
        </Reference>
        <CodeSnippet
          code={JSON.stringify(exampleSavingsEvent, null, 2)}
          language="json"
        />
      </StyledApiDocs>
      <Divider marginSize={6} />
      <FindRandomRecentSavings />
      <Divider marginSize={6} />
      <ListRandomRecentSavings />
    </Fragment>
  );
};

export default SavingsEstimate;

import React from "react";
import { Field, Form } from "react-final-form";
import Button from "src/components/Button";
import Feedback from "src/components/Feedback";

interface IFormValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IFormValues) => void;
  errors?: string[];
  email?: string;
}

const BareResetPasswordForm: React.FC<IProps> = ({
  onSubmit: submit,
  errors,
  email
}) => {
  return (
    <div>
      {!!errors?.length && <Feedback message={errors[0]} type="error" />}
      <Form
        onSubmit={submit}
        initialValues={{ email: email || "", password: "" }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <Field
                    name="email"
                    type="email"
                    render={({ input }) => (
                      <input
                        {...input}
                        autoComplete="email"
                        required
                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-bf-green-500 focus:outline-none focus:ring-bf-green-500 sm:text-sm"
                        placeholder="Email address"
                      />
                    )}
                    required
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={submitting}
                  version="danger"
                  fullWidth
                  size="sm"
                >
                  {submitting ? "Sending" : "Change Password"}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default BareResetPasswordForm;

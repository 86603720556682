import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import findRandomRecentSavingsResponseJsonFile from "src/examples/responses/find_random_recent_savings.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function FindRandomRecentSavings({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "find-random-recent-savings"
      );
      if (hash.includes("find-random-recent-savings")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "find-random-recent-savings"}>
      <a href="/api-docs/savings-estimate#find-random-recent-savings">
        <h3 className="mb-8 text-xl font-medium">
          Find a random recent savings event
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              field={"savingsSource"}
              type={"String"}
              defaultValue="null"
              description={
                <React.Fragment>
                  <div>
                    The method/source that resulted in savings. Passing{" "}
                    <CodeSnippet bare inline code="null" /> to this argument
                    will choose randomly between a negotiation and a
                    cancellation.
                  </div>
                </React.Fragment>
              }
              possibleValues={["negotiation", "cancellation"]}
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>A savings event object.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, findRandomRecentSavingsExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   findRandomRecentSavingsExampleJsBody
              // ),
              curl: curlExample(
                testApiKey!,
                findRandomRecentSavingsExampleCurlBody
              )
              // python: pythonExample(
              //   testApiKey!,
              //   findRandomRecentSavingsExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              findRandomRecentSavingsResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const findRandomRecentSavingsExampleCurlBody = `
$'{
  "query": "query FindRandomRecentSavings($savingsSource:String){FindRandomRecentSavings(savingsSource:$savingsSource){savings savingsSource customerInitials customerFirstName providerName providerLogo{tinyUrl smallUrl mediumUrl}savingsDate}}",
	"variables": {
    "savingsSource": "Negotiation"
	}
`;

import { Space } from "@cohubinc/blueprint";
import React from "react";
import { Link } from "react-router-dom";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import NewContainer from "src/components/NewContainer";
import usePageTitle from "src/hooks/usePageTitle";
import useSession from "src/hooks/useSession";
import paths from "src/paths";
import { LinkToSection } from "../styles";
import {
  acceptOffer,
  acceptOfferResponse,
  configureClient,
  createBill,
  createBillResponse,
  createCustomer,
  createCustomerResponse,
  findBill,
  findBillResponse,
  findCustomer,
  findCustomerResponse,
  findInfoReq,
  findInfoReqResponse,
  findOffer,
  findOfferResponse,
  listBills,
  listBillsResponse,
  listCustomers,
  listCustomersResponse,
  listInfoReqs,
  listInfoReqsResponse,
  listOffers,
  listOffersResponse,
  listProviders,
  listProvidersResponse,
  rejectOffer,
  rejectOfferResponse,
  renegotiateBill,
  renegotiateBillResponse,
  respondToInfoReq,
  respondToInfoReqResponse
} from "./snippets";

const JavascriptLibrary: React.FC = () => {
  const { email, token } = useSession();
  usePageTitle("Javascript Library Reference");

  return (
    <NewContainer>
      <Space height={1} />
      <ul>
        <li>
          <LinkToSection href="#install">Installation</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#config">Configuration</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#customers">Customers</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#providers">Providers</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#bills">Bills</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#offers">Offers</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#info-reqs">Information Requests</LinkToSection>
        </li>
      </ul>
      <Space height={2} />
      <Divider />

      {/* <Section
        className="mt-4"
        anchor="install"
        code={installation}
        title="Installation"
        language="shell"
      /> */}

      <div className="mt-12">
        <h4 className="mb-4 text-xl font-medium" id="config">
          Installation
        </h4>
        <CodeSnippet
          className="mt-6"
          language="shell"
          code={"yarn add @billfixers/partner-client"}
          showInsetCopy
          insetCopy={{ showBackground: false, translate: 50 }}
        />
        <p className="mt-4">Or:</p>
        <CodeSnippet
          className="mt-4"
          language="shell"
          code={"npm --save @billfixers/partner-client"}
          showInsetCopy
          insetCopy={{ showBackground: false, translate: 50 }}
        />
      </div>

      <div className="mt-12">
        <h4 className="mb-4 text-xl font-medium" id="config">
          Configuration
        </h4>
        <p>
          To communicate with the Billfixers API, you'll need to provide your
          email and token. Both have been populated for you in the examples
          below.
        </p>
        <p className="mt-4">
          <span className="text-red-700">NOTE:</span> the testing client passes
          a third boolean parameter which indicates that the client should use
          testing data instead of real production data.
        </p>
        <CodeSnippet
          className="mt-6"
          language="javascript"
          code={configureClient(email!, token!)}
        />
      </div>

      {/* CUSTOMERS */}
      <div className="mt-12">
        <div className="mb-4">
          <h4 className="text-xl font-medium">Customers</h4>
          <Link to={paths.apiDocs.customers} className="text-blue-500">
            Learn more about customers
          </Link>
        </div>
        <p>
          A customer is the person or entity that is being billed by a provider.
          When creating a new bill, you must specify the customer that the bill
          belongs to by passing that customer's ID.
        </p>
        <h5 className="mt-8 mb-4 font-medium">Create a customer</h5>
        <p>
          Use the{" "}
          <CodeSnippet
            bare
            inline
            code="createCustomer()"
            className="text-sm"
          />{" "}
          method to create a customer. Provide an object of valid customer
          fields, one of which must be an email address. For more customer
          fields,{" "}
          <Link
            to={paths.apiDocs.customers + "#create-customer"}
            className="text-blue-500"
          >
            click here
          </Link>
        </p>
        <p className="mt-4">
          The response will contain the newly created customer object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={createCustomer}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={createCustomerResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">List customers</h5>
        <div>
          <p>
            Use the{" "}
            <CodeSnippet
              bare
              inline
              code="listCustomers()"
              className="text-sm"
            />{" "}
            method to fetch an array of all the providers that we negotiate
            with. This method accepts two optional arguments,{" "}
            <CodeSnippet bare inline code="limit" className="text-sm" /> and{" "}
            <CodeSnippet bare inline code="offset" className="text-sm" />.
          </p>
          <p className="mt-4">
            By default, limit is set to 25 and offset is set to 0. The "offset"
            parameter can be used to paginate through your list of customers. If
            you have 50 customers total and want to fetch the second half of
            your customers, change the offset parameter to 25.
          </p>
          <p className="mt-4">
            Example:{" "}
            <CodeSnippet
              bare
              inline
              className="text-sm"
              code="await client.listCustomers({ limit: 25, offset: 25 });"
            />
          </p>
        </div>
        <p className="mt-6">
          The response will contain two fields: an integer called{" "}
          <CodeSnippet bare inline code="totalCount" className="text-sm" /> and
          an array of your{" "}
          <CodeSnippet bare inline code="customers" className="text-sm" />.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={listCustomers}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={listCustomersResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Find a customer</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="findCustomer()" className="text-sm" />{" "}
          method to fetch a single customer. It takes a customer ID as its only
          argument.
        </p>
        <p className="mt-4">
          The response will contain the requested customer object, if it was
          found.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={findCustomer}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={findCustomerResponse} />
          </div>
        </div>
      </div>

      {/* PROVIDERS */}
      <div className="mt-12">
        <div className="mb-4">
          <h4 className="text-xl font-medium" id="providers">
            Providers
          </h4>
          <Link to={paths.apiDocs.providers} className="text-blue-500">
            Learn more about providers
          </Link>
        </div>
        <p>
          A provider is the business or entity that is billing a customer for
          their services. Before submitting a bill to BillFixers, you must
          identify the provider who is doing the billing and pass that
          provider's ID as a parameter for the bill.
        </p>
        <h5 className="mt-8 font-medium">List providers</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="listProviders()" className="text-sm" />{" "}
          method to fetch an array of all the providers that we negotiate with.
        </p>
        <p className="mt-4">
          The response will be an array of all of our current providers.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={listProviders}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response
              className="mt-4"
              // language="javascript"
              // code={listProviders}
              responseJson={listProvidersResponse}
            />
          </div>
        </div>
      </div>

      {/* BILLS */}
      <div className="mt-12">
        <div className="mb-4">
          <h4 className="text-xl font-medium" id="bills">
            Bills
          </h4>
          <Link to={paths.apiDocs.bills} className="text-blue-500">
            Learn more about bills
          </Link>
        </div>
        <p>
          A bill is the main resource that our negotiators use to find better
          rates for a customer. When creating a bill, you must provide a
          customerId and an object of bill attributes, one of which must be a
          providerId.
        </p>
        <h5 className="mt-8 mb-4 font-medium">Create a bill</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="createBill()" className="text-sm" />{" "}
          method to create a bill. You must pass a customerId and an object of
          bill attributes, one of which must be a providerId. For more bill
          fields,{" "}
          <Link
            to={paths.apiDocs.bills + "#create-bill"}
            className="text-blue-500"
          >
            click here
          </Link>
        </p>
        <p className="mt-4">
          The response will contain the newly created bill object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={createBill}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={createBillResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">List bills</h5>
        <div>
          <p>
            Use the{" "}
            <CodeSnippet bare inline code="listBills()" className="text-sm" />{" "}
            method to fetch an array of all of your bills. This method accepts
            three optional arguments,{" "}
            <CodeSnippet bare inline code="limit" className="text-sm" />,{" "}
            <CodeSnippet bare inline code="offset" className="text-sm" />, and{" "}
            <CodeSnippet bare inline code="customerId" className="text-sm" />.
          </p>
          <p className="mt-4">
            By default, limit is set to 25 and offset is set to 0. The "offset"
            parameter can be used to paginate through your list of customers. If
            you have 50 bills total and want to fetch the second half of your
            bills, change the offset parameter to 25.
          </p>
          <p className="mt-4">
            Example:{" "}
            <CodeSnippet
              bare
              inline
              className="text-sm"
              code="await client.listBills({ limit: 25, offset: 25 });"
            />
          </p>
        </div>
        <p className="mt-6">
          The response will contain two fields: an integer called{" "}
          <CodeSnippet bare inline code="totalCount" className="text-sm" /> and
          an array of your{" "}
          <CodeSnippet bare inline code="bills" className="text-sm" />.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={listBills}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={listBillsResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Find a bill</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="findBill()" className="text-sm" />{" "}
          method to fetch a single bill. It takes a bill ID as its only
          argument.
        </p>
        <p className="mt-4">
          The response will contain the requested bill object, if it was found.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={findBill}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={findBillResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Renegotiate a bill</h5>
        <p>
          Use the{" "}
          <CodeSnippet
            bare
            inline
            code="renegotiateBill()"
            className="text-sm"
          />{" "}
          method to request a renegotiation for a bill. It takes a bill ID as
          its only argument.
        </p>
        <p className="mt-4">
          The response will contain the renegotiated bill object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={renegotiateBill}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={renegotiateBillResponse} />
          </div>
        </div>
      </div>

      {/* OFFERS */}
      <div className="mt-12">
        <div className="mb-4">
          <h4 className="text-xl font-medium" id="offers">
            Offers
          </h4>
          <Link to={paths.apiDocs.offers} className="text-blue-500">
            Learn more about offers
          </Link>
        </div>
        <p>
          An offer represents a savings opportunity for a customer that we need
          their consent/approval to lock in. When a negotiator finds a better
          deal or money saving opportunity, the negotiator will prompt the
          customer to accept or reject the offer.
        </p>
        <h5 className="mt-8 mb-4 font-medium">List offers</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="listOffers()" className="text-sm" />{" "}
          method to fetch a list of offers. For more on offer fields,{" "}
          <Link
            to={paths.apiDocs.offers + "#list-offers"}
            className="text-blue-500"
          >
            click here
          </Link>
        </p>
        <p className="mt-4">
          The response will contain an array of offer objects.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={listOffers}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={listOffersResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Accept an offer</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="acceptOffer()" className="text-sm" />{" "}
          method to accept an offer. It takes an offer ID as its only argument.
        </p>
        <p className="mt-4">
          The response will contain the accepted offer object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={acceptOffer}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={acceptOfferResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Reject an offer</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="rejectOffer()" className="text-sm" />{" "}
          method to reject an offer. It takes an offer ID as its only argument.
        </p>
        <p className="mt-4">
          The response will contain the rejected offer object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={rejectOffer}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={rejectOfferResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Find an offer</h5>
        <p>
          Use the{" "}
          <CodeSnippet bare inline code="findOffer()" className="text-sm" />{" "}
          method to find an offer. It takes an offer ID as its only argument.
        </p>
        <p className="mt-4">
          The response will contain the offer object, if it was found.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={findOffer}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={findOfferResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
      </div>

      {/* INFO REQUESTS */}
      <div className="mt-12">
        <div className="mb-4">
          <h4 className="text-xl font-medium" id="info-reqs">
            Information requests
          </h4>
          <Link to={paths.apiDocs.infoReqs} className="text-blue-500">
            Learn more about information requests
          </Link>
        </div>
        <p className="mb-4">
          During the course of a negotiation, we may need additional information
          from a customer about their bill. For example, many providers need us
          to provide them with a billing/service address during our negotations.
        </p>
        <p>
          An InformationRequest will contain sub-fields that are in the format
          of a question and an answer.
        </p>
        <h5 className="mt-8 mb-4 font-medium">List information requests</h5>
        <p>
          Use the{" "}
          <CodeSnippet
            bare
            inline
            code="listInformationRequests()"
            className="text-sm"
          />{" "}
          method to fetch a list of information requests. For more on
          information request fields,{" "}
          <Link
            to={paths.apiDocs.infoReqs + "#list-info-reqs"}
            className="text-blue-500"
          >
            click here
          </Link>
          .
        </p>
        <p className="mt-4">
          The response will contain an array of information request objects.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={listInfoReqs}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={listInfoReqsResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">
          Respond to an information request
        </h5>
        <p>
          Use the{" "}
          <CodeSnippet
            bare
            inline
            code="respondToInformationRequest()"
            className="text-sm"
          />{" "}
          method to provide us with the customer's response to the information
          we need. It takes an information request ID and an object of field
          responses as its arguments.
        </p>
        <p className="mt-4">
          The response will contain the information request object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={respondToInfoReq}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response
              className="mt-4"
              responseJson={respondToInfoReqResponse}
            />
          </div>
        </div>
        <Divider marginSize={4} />
        <h5 className="mt-8 mb-4 font-medium">Find an information request</h5>
        <p>
          Use the{" "}
          <CodeSnippet
            bare
            inline
            code="findInformationRequest()"
            className="text-sm"
          />{" "}
          method to find an information request. It takes an information request
          ID as its only argument.
        </p>
        <p className="mt-4">
          The response will contain the information request object, if it was
          successful.
        </p>
        <div className="grid grid-cols-2 grid-rows-auto place-items-start gap-4">
          <div className="mt-2 w-full">
            <CodeSnippet
              className="mt-4"
              language="javascript"
              code={findInfoReq}
              copy
            />
          </div>
          <div className="mt-2 w-full">
            <Response className="mt-4" responseJson={findInfoReqResponse} />
          </div>
        </div>
        <Divider marginSize={4} />
      </div>
    </NewContainer>
  );
};

export default JavascriptLibrary;

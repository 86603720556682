import { ExclamationIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import Alert from "./Alert";
import IdleCountdownLogout from "./IdleCountdownLogout";
import NewContainer from "./NewContainer";

export default function IdleTimer() {
  const [isIdle, setIsIdle] = useState(false);

  const handleOnIdle = () => {
    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
    setIsIdle(true);
  };

  const handleOnActive = () => {
    // console.log("user is active", event);
    // console.log("time remaining", getRemainingTime());
    setIsIdle(false);
  };

  const handleOnAction = () => {
    // console.log("user did something", event);
    setIsIdle(false);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    // The timeout for being idle is 6 hours.
    timeout: 1000 * 60 * 720,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: true
  });
  return (
    <React.Fragment>
      {isIdle && (
        <NewContainer>
          <Alert className="mt-4">
            <div className="flex items-center space-x-3">
              <ExclamationIcon className="h-5 w-5 text-red-500" />
              <p>
                You've been inactive for too long. You'll be logged out in{" "}
                <IdleCountdownLogout /> seconds.
              </p>
            </div>
          </Alert>
        </NewContainer>
      )}
    </React.Fragment>
  );
}

import React, { useState } from "react";
import NewButton from "src/components/NewButton";
import SideSheet from "src/components/SideSheet";
import {
  IPartnerWebhookFragment,
  useUpdatePartnerWebhookMutation
} from "src/components/graphql";
import { useTestMode } from "src/contexts/TestModeContext";
import WebhookForm from "./WebhookForm";

interface IProps {
  webhook: IPartnerWebhookFragment;
}

export default function EditWebhook({ webhook }: IProps) {
  const { testMode } = useTestMode();
  const [showWebhookForm, setShowWebhookForm] = useState(false);
  const [webhookFormErrors, setWebhookFormErrors] = useState<
    string[] | undefined
  >();

  const [updateWebhookMutation] = useUpdatePartnerWebhookMutation();

  const updateWebhook = async (values: any) => {
    let events = values.events;
    if (values.events.includes("all_events")) {
      events = [
        "bill.customer_ineligible",
        "bill.negotiation_started",
        "bill.negotiation_completed",
        "bill.negotiation_failed",
        "bill.negotiation_scheduled",
        "bill.savings_updated",
        "bill.autofix_upcoming",
        "bill.outside_of_scope",
        "bill.bad_document",
        "bill.duplicate_discarded",
        "bill.savings_expiring",
        "bill.savings_expired",
        "bill.provider_account_closed",
        "bill.stopped",
        "customer.updated",
        "information_request.created",
        "offer.created",
        "offer.accepted",
        "offer.rejected",
        "cancellation.started",
        "cancellation.succeeded",
        "cancellation.already_cancelled",
        "cancellation.unable_to_cancel",
        "cancellation.no_subscription_found",
        "cancellation_info_request.created",
        "cancellation_offer.created",
        "cancellation_offer.accepted",
        "cancellation_offer.rejected",
        "provider.updated"
      ];
    }

    const result = await updateWebhookMutation({
      variables: {
        webhookId: webhook.id,
        webhook: {
          url: values.url,
          webhookType: values.webhookType,
          events: events,
          oauthUrl: values.oauthUrl,
          webhookEnvironment: values.webhookEnvironment,
          clientId: values.clientId,
          clientSecret: values.clientSecret
        }
      },
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.UpdatePartnerWebhook?.success) {
      setShowWebhookForm(false);
      setWebhookFormErrors(undefined);
    } else if (result.data?.UpdatePartnerWebhook?.errors) {
      setWebhookFormErrors(result.data?.UpdatePartnerWebhook?.errors);
    }
  };

  return (
    <React.Fragment>
      <NewButton
        version="plain"
        size="xs"
        onClick={() => setShowWebhookForm(true)}
        disabled={testMode}
      >
        Edit
      </NewButton>
      <SideSheet
        open={showWebhookForm}
        onClose={() => setShowWebhookForm(false)}
      >
        <WebhookForm
          initialValues={webhook}
          errors={webhookFormErrors}
          onSubmit={updateWebhook}
        />
      </SideSheet>
    </React.Fragment>
  );
}

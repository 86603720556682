/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, ReactNode } from "react";

type TTitle = {
  title: string;
  alignment?: "text-left" | "text-center" | "text-right";
};

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: TTitle;
  showCloseIcon?: boolean;
  className?: string;
}

export default function Modal({
  open,
  onClose,
  children,
  title,
  showCloseIcon = true,
  className
}: IProps) {
  // const [open, setOpen] = useState(true);

  const titleMarkup = () => {
    if (title) {
      return (
        <Dialog.Title
          as="h3"
          className={`text-xl font-semibold leading-6 text-gray-700 ${
            title?.alignment ? title.alignment : "text-left"
          }`}
        >
          {title.title}
        </Dialog.Title>
      );
    }
  };

  const titleAndCloseIconPosition = () => {
    if (title && showCloseIcon) {
      return "justify-between";
    } else if (title) {
      return "justify-between";
    } else if (showCloseIcon) {
      return "justify-end";
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6 sm:align-middle">
              <div className={`mb-4 flex ${titleAndCloseIconPosition()}`}>
                {titleMarkup()}
                {showCloseIcon && (
                  <div className="flex justify-end">
                    <XIcon
                      className="h-6 w-6 cursor-pointer text-gray-400"
                      onClick={onClose}
                      tabIndex={1}
                    />
                  </div>
                )}
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

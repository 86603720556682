import { useEffect } from "react";
import useMeta from "./useMeta";

export default function usePageTitle(newTitle: string) {
  const { pageTitle, setPageTitle } = useMeta();

  useEffect(() => {
    setPageTitle(newTitle);
  }, [setPageTitle, newTitle]);

  return { pageTitle, setPageTitle };
}

import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import React, { ReactNode } from "react";

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

export default function SideSheet({ open, title, children, onClose }: IProps) {
  return (
    // The absolute positioning here when the sidesheet is closed keeps the flexbox on the compact
    // bill form from including the sidesheet as a child if there's a plaid item logo being used
    // on the bill. If absolute isn't used, then cancel bill appears in the middle of the card
    <div
      className={`${
        open ? "fixed inset-0 overflow-hidden" : "absolute"
      } z-[1001]`}
    >
      <Transition
        show={open}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50"
          onClick={onClose}
        />
      </Transition>

      <section className="fixed inset-y-0 right-0 max-w-full flex z-50">
        <Transition
          show={open}
          enter="transform transition ease-in-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="w-screen h-screen max-w-md">
            <div className="h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll">
              <header className="px-4 sm:px-6">
                <div className="flex items-start justify-between space-x-3">
                  <h2 className="text-lg leading-7 font-medium text-gray-bf-text-medium">
                    {title}
                  </h2>
                  <div
                    className="h-7 flex items-center cursor-pointer"
                    onClick={onClose}
                  >
                    <button
                      aria-label="Close panel"
                      className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 cursor-pointer"
                    >
                      <XIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              </header>
              <div className="relative flex-1 px-4 sm:px-6">{children}</div>
            </div>
          </div>
        </Transition>
      </section>
    </div>
  );
}

import { Stack } from "@cohubinc/blueprint";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import SideSheet from "src/components/SideSheet";
import Typography from "src/components/Typography";
import usePageTitle from "src/hooks/usePageTitle";
import useSession from "src/hooks/useSession";
import paths from "src/paths";

export default function ApiAuthentication() {
  usePageTitle("Authentication");
  const { apiKey, testApiKey } = useSession();

  const [showLiveKey, setShowLiveKey] = useState(false);

  return (
    <React.Fragment>
      <NewContainer className="mt-8">
        <Stack>
          <Typography>
            Our APIs use an API key to authenticate requests.
          </Typography>
          <Typography>
            As a partner, you have two keys: one for live data, and one for test
            data. Your test key is used throughout the documentation.
          </Typography>
          <Typography>
            Your test key is <CodeSnippet bare inline code={testApiKey || ""} />
            .
          </Typography>
          <Typography>
            To view your live key,{" "}
            <span
              className="cursor-pointer text-blue-500"
              onClick={() => setShowLiveKey(true)}
            >
              click here
            </span>
            .
          </Typography>
          <Typography>
            Include the following request header when making API calls:
          </Typography>
          <div>
            <CodeSnippet
              language="bash"
              code={`X-Partner-ApiKey: ${testApiKey}`}
              copy
            />
          </div>
        </Stack>
        <div>
          <Typography>
            If you need to rotate your API keys, head to your{" "}
            <Link
              to={paths.settings + "/apikeys"}
              className="cursor-pointer text-blue-500"
            >
              account settings
            </Link>
            .
          </Typography>
        </div>
      </NewContainer>
      <SideSheet
        open={showLiveKey}
        onClose={() => setShowLiveKey(false)}
        title="Live API Key"
      >
        <div>
          <p className="mb-4 text-sm">
            You must keep your live API key confidential and only store it on
            your own servers. You must not share your live API key with any
            third parties. Your account’s live API key can perform any API
            request to BillFixers without restriction.
          </p>
          <p className="mb-4 text-sm">
            If we believe that your live API key has been compromised, we may
            cancel and reissue it, potentially resulting in an interruption to
            any services that rely on your BillFixers integration.
          </p>
          <p className="mb-4 text-sm">
            If you believe that your live API key has been compromised, you
            should rotate your API key{" "}
            <Link to="/settings/apikeys" className="text-blue-500">
              here.
            </Link>
          </p>
          <CodeSnippet
            className="text-sm"
            language="bash"
            code={apiKey!}
            copy
          />
        </div>
      </SideSheet>
    </React.Fragment>
  );
}

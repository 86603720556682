import React, { useState } from "react";
import Button from "src/components/Button";
import { IPartnerFragment } from "src/components/graphql";
import SideSheet from "src/components/SideSheet";
import { host, protocol } from "src/config";
import { useTestMode } from "src/contexts/TestModeContext";
import useSession from "src/hooks/useSession";
import BareResetPasswordForm from "src/screens/ResetPassword/BareResetPasswordForm";
import { haltAndCatchFire } from "src/utils/haltAndCatchFire";

interface IProps {
  partner: IPartnerFragment;
}

export default function Profile({ partner }: IProps) {
  const { testMode } = useTestMode();
  const { email } = useSession();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const resetPassword = React.useCallback(async (values) => {
    setErrors([]);
    const url = `${protocol}://${host}/api/partner_users/reset_password`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    });

    const result = await response.json();

    if (result.partner_user) {
      setFormSubmitted(true);
      haltAndCatchFire(true);
    } else if (result.errors) {
      setFormSubmitted(false);
      setErrors(result.errors);
    }
  }, []);

  return (
    <div className="mt-10 divide-y divide-gray-200">
      <div className="mb-6 space-y-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
        <p className="text-sm text-gray-600">
          Below is the information associated with your account.
        </p>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {email}
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Password</dt>
            <dd className="flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <div>
                <p>
                  Of course we're not going to show your password here! But if
                  you'd like to change it, click the button below.
                </p>
                <Button
                  size="xs"
                  onClick={() => setShowChangePasswordForm(true)}
                  version="secondary"
                  className="mt-2"
                  disabled={testMode}
                >
                  Change Password
                </Button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <SideSheet
        title="Change Password"
        open={showChangePasswordForm}
        onClose={() => setShowChangePasswordForm(false)}
      >
        <div className="space-y-4">
          <p className="text-sm text-gray-900">
            Clicking the change password button will send an email to the
            address entered below. Check that address for further instructions
            on how to change your password.{" "}
          </p>
          <p className="text-sm text-gray-900">
            Once you click the button, you'll be logged out.
          </p>
          <BareResetPasswordForm onSubmit={resetPassword} email={email || ""} />
        </div>
      </SideSheet>
    </div>
  );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import pythonExample from "src/examples/graphql/queries/python/pythonExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import calculateEstimateResponseJsonFile from "src/examples/responses/calculate_savings_estimate.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function CalculateSavingsEstimate({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "calculate-savings-estimate"
      );
      if (hash.includes("calculate-savings-estimate")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "calculate-savings-estimate"}>
      <a href="/api-docs/savings-estimate#calculate-savings-estimate">
        <h3 className="mb-8 text-xl font-medium">
          Calculate a savings estimate
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"provider_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    The ID of the provider that is billing the customer.
                  </div>
                </React.Fragment>
              }
            />
          </div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"current_monthly_amount"}
              type={"Float"}
              description={
                <React.Fragment>
                  <div>
                    The amount that the customer is currently paying the
                    provider each month.
                  </div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>A savings estimate object.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, calculateEstimateExampleRubyBody),
              js: javascriptExample(
                testApiKey!,
                calculateEstimateExampleJsBody
              ),
              curl: curlExample(testApiKey!, calculateEstimateExampleCurlBody),
              python: pythonExample(
                testApiKey!,
                calculateEstimateExamplePythonBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              calculateEstimateResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const calculateEstimateExampleRubyBody = `
estimate = client.calculate_savings_estimate(
  'provider_12fasfa3187asd',
  100.00
)
`;

const calculateEstimateExampleJsBody = `
const estimate = await client.calculateSavingsEstimate(
  'provider_12fasfa3187asd',
  100.00
)
`;

const calculateEstimateExampleCurlBody = `
$'{
  "query": "query CalculateSavingsEstimate($providerId:ID! $currentMonthlyAmount:Float!){estimate:CalculateSavingsEstimate{estimatedAnnualSavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)estimatedMonthlySavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)percentageSavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)}}",
  "variables": {
  "providerId": "provider_6b925382fa592f07d5ab1720586b038e",
  "currentMonthlyAmount": 150
}
`;

const calculateEstimateExamplePythonBody = `
"{
  "query": "query CalculateSavingsEstimate($providerId:ID! $currentMonthlyAmount:Float!){estimate:CalculateSavingsEstimate{estimatedAnnualSavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)estimatedMonthlySavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)percentageSavings(providerId:$providerId currentMonthlyAmount:$currentMonthlyAmount)}}",
  "variables": {
    "providerId": "provider_6b925382fa592f07d5ab1720586b038e",
    "currentMonthlyAmount": 150
  }
}"
`;

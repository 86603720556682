import AltLoader from "src/components/AltLoader";
import { useListPartnerUsersQuery } from "src/components/graphql";
import NewContainer from "src/components/NewContainer";
import useCurrentUser from "src/hooks/useCurrentUser";
import PartnerUserRow from "../PartnerUserRow";

export default function PartnerUsers() {
  const { currentUser } = useCurrentUser();
  const { loading, data } = useListPartnerUsersQuery({});

  if (loading || !data) {
    return <AltLoader />;
  }

  if (currentUser?.role !== "manager") {
    return <NewContainer className="mt-6">Access denied</NewContainer>;
  }

  const users = data.ListPartnerUsers.nodes.map((user) => user);

  return (
    <NewContainer className="mt-6">
      <div>
        <h1 className="text-2xl font-medium text-gray-800">Users</h1>
      </div>
      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Last Signed In At
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    />
                  </tr>
                </thead>
                <tbody>
                  {loading && !data && (
                    <tr className="my-2">
                      <td colSpan={5}>
                        <AltLoader />
                      </td>
                    </tr>
                  )}
                  {users?.map((user, idx) => (
                    <PartnerUserRow key={user.id} user={user} idx={idx} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </NewContainer>
  );
}

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, RouteComponentProps } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import DateAtTime from "src/components/DateAtTime";
import Detail from "src/components/Detail";
import Divider from "src/components/Divider";
import DocLink from "src/components/DocLink";
import {
  useFindBillQuery,
  useSandboxResetBillMutation
} from "src/components/graphql";
import Modal from "src/components/Modal";
import Money from "src/components/Money";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import SideSheet from "src/components/SideSheet";
import Typography from "src/components/Typography";
import { useTestMode } from "src/contexts/TestModeContext";
import useDevAwareShow from "src/hooks/useDevAwareShow";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import BillItems from "./BillItems";
import DocumentlessSection from "./DocumentlessSection";
import DocumentSection from "./DocumentSection";
import GenerateNegotiation from "./GenerateNegotiation";
import InfoReqs from "./InfoReqs";
import Negotiations from "./Negotiations";
import Offers from "./Offers";

const Bill: React.FC<RouteComponentProps> = ({ match }) => {
  usePageTitle(``);
  const { billId } = match.params as any;
  const { testMode } = useTestMode();
  const devAwareShow = useDevAwareShow();
  const { loading, data } = useFindBillQuery({
    variables: { billId }
    // fetchPolicy: "cache-and-network"
  });
  const [generateNegotiationOpen, setGenerateNegotiationOpen] = useState(false);
  const [resetBillPrompt, setResetBillPrompt] = useState(false);

  const [resetBillMutation] = useSandboxResetBillMutation();

  if (loading && !data) {
    return <AltLoader />;
  }

  if (!data?.bill) {
    return (
      <NewContainer>
        <div className="mt-8 flex justify-center">
          Bill not found.{" "}
          {testMode
            ? "Turn off test mode to see if the bill exists in production data."
            : "Turn on test mode to see if the bill exists in test data."}
        </div>
      </NewContainer>
    );
  }

  const { bill } = data;

  const resetBill = async () => {
    const result = await resetBillMutation({
      variables: { billId },
      refetchQueries: ["FindBill", "SandboxListNegotiationsForBill"]
    });

    if (result.data?.SandboxResetBill?.success) {
      setResetBillPrompt(false);
    }
  };

  return (
    <NewContainer>
      <Helmet>
        <title>Bill - {billId}</title>
      </Helmet>
      <div className="grid grid-cols-8 gap-8">
        <div className="col-span-3">
          <Segment>
            <div className="mb-6">
              <Typography.Large block>{bill.title}</Typography.Large>
              <Typography.Small muted>{bill.id}</Typography.Small>
            </div>
            <div className="mb-6 grid grid-cols-3 grid-rows-1 gap-4">
              <Detail heading="Status" body={bill.status} />
              <Detail
                heading="Active savings"
                body={<Money amount={bill.totalSavings} />}
              />
              <Detail
                heading="All time savings"
                body={<Money amount={bill.grossSavings} />}
              />
            </div>
            <Detail
              className="mb-2"
              heading="Customer"
              body={
                <Link
                  to={paths.customer(bill.customer.id)}
                  className="cursor-pointer"
                >
                  <div className="text-blue-500">{bill.customer.name}</div>
                  <div className="break-words text-sm">
                    {bill.customer.email}
                  </div>
                </Link>
              }
            />
            <Detail
              className="mb-2"
              heading="Created on"
              body={<DateAtTime input={bill.createdAt} />}
            />
            <Detail
              className="mb-2"
              heading="Allows contracts"
              body={bill.allowsContract ? "On" : "Off"}
            />
            <Detail
              className="mb-2"
              heading="Autofix"
              body={bill.autoRenegotiate ? "On" : "Off"}
            />
          </Segment>
          <Segment className="mt-6">
            Document Info{" "}
            {bill.documentless && (
              <Badge size="xs" version="secondary" text="Documentless" />
            )}
            {bill.missingDocumentInfo && (
              <React.Fragment>
                <br />
                <Badge
                  size="sm"
                  version="danger"
                  text="Missing document info"
                  className="block"
                />
              </React.Fragment>
            )}
            {bill.documentCount > 0 && <DocumentSection bill={bill} />}
            {bill.documentless && (
              <React.Fragment>
                <Divider />
                <DocumentlessSection bill={bill} />
              </React.Fragment>
            )}
          </Segment>
        </div>
        <div className="col-span-5">
          <div className="flex justify-end">
            {/* Hide or disable this button unless viewing test data */}
            {devAwareShow && (
              <Button
                size="sm"
                version="plain"
                className="mr-2"
                onClick={() => setResetBillPrompt(true)}
              >
                Reset Bill
              </Button>
            )}
            {/* <Button
              size="sm"
              // disabled={!testMode}
              onClick={() => setGenerateNegotiationOpen(true)}
            >
              Generate Negotiation
            </Button> */}
          </div>
          {devAwareShow && (
            // <div className="mt-12 mb-4">
            <div className="my-4">
              <div className="flex items-center">
                <h4 className="mb-1 text-gray-600">Negotiations</h4>
                <Badge
                  size="xs"
                  version="purple"
                  text="Test mode only"
                  className="ml-2"
                />
              </div>
              <p className="mb-3 text-xs text-gray-700">
                Because we don't negotiate test bills, you'll want to generate
                your own negotiations while in test mode. Doing so will ensure
                that your integration is covering all the bases and will allow
                you to better understand the API and how our negotiation process
                works.
              </p>
              <p className="mb-3 text-xs text-gray-700">
                In production, you'll never interact with negotiations.
              </p>
              <Negotiations billId={billId} />
            </div>
          )}
          {/* <div className="my-4"> */}
          <div className="mt-12 mb-4">
            <div className="flex items-center">
              <h4 className="mb-1 text-gray-600">Items</h4>
              <DocLink to={paths.apiDocs.items} />
            </div>
            <p className="mb-3 text-xs text-gray-700">
              Items generally correspond to particular line items on a bill that
              we have been able to save money on.
            </p>
            <BillItems items={bill.items} />
          </div>
          <div className="mt-12 mb-4">
            <div className="flex items-center">
              <h4 className="mb-1 text-gray-600">Offers</h4>
              <DocLink to={paths.apiDocs.offers} />
            </div>
            <p className="mb-3 text-xs text-gray-700">
              Offers are savings opportunities that require a customer's
              consent.
            </p>
            <Offers offers={bill.offers} />
          </div>
          <div className="mt-12 mb-4">
            <div className="flex items-center">
              <h4 className="mb-1 text-gray-600">Information Requests</h4>
              <DocLink to={paths.apiDocs.infoReqs} />
            </div>
            <p className="mb-3 text-xs text-gray-700">
              Info requests need a response from the customer before we can
              continue our negotiations.
            </p>
            <InfoReqs infoReqs={bill.informationRequests} />
          </div>
        </div>
      </div>
      <SideSheet
        open={generateNegotiationOpen}
        onClose={() => setGenerateNegotiationOpen(false)}
        title="Generating test negotiations"
      >
        <GenerateNegotiation
          billId={bill.id}
          onSuccess={() => setGenerateNegotiationOpen(false)}
        />
      </SideSheet>
      <Modal
        open={resetBillPrompt}
        onClose={() => setResetBillPrompt(false)}
        showCloseIcon
        className="w-11/12 sm:w-1/2"
        title={{
          title: "Reset bill"
        }}
      >
        <div>
          <p className="mb-4">
            Resetting this bill will destroy all of its negotiations, items,
            offers, and information requests.
          </p>
          <p className="mb-4">
            This is useful as a quick way to get a bill into its original state
            as you build and test your integration.
          </p>
          <Button version="danger" onClick={resetBill} fullWidth>
            Reset
          </Button>
        </div>
      </Modal>
    </NewContainer>
  );
};

export default Bill;

import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import exampleOffer from "src/examples/offer.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import AcceptOffer from "./AcceptOffer";
import FindOffer from "./FindOffer";
import ListOffers from "./ListOffers";
import RejectOffer from "./RejectOffer";

export default function Offers() {
  usePageTitle("Offers");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Typography.Large>
            An offer represents a savings opportunity for a customer. When a
            negotiator finds a better deal or money saving opportunity, the
            negotiator will prompt the customer to accept or reject the offer.
            NOTE: Offers are valid for 30 days once they're created. After 30
            days, an offer that hasn't been accepted or rejected will be
            rejected automatically and we'll continue negotiating the customer's
            bill.
          </Typography.Large>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference className="w-full">
          <AvailableFields objectName="offer">
            <FieldAttribute
              field={"id"}
              type={"ID"}
              description={"Alpha-numeric unique identifier for an offer."}
            />
            <FieldAttribute
              field={"customer_id"}
              type={"ID"}
              description={
                "Alpha-numeric unique identifier for the customer related to an offer."
              }
            />
            <FieldAttribute
              field={"bill_id"}
              type={"ID"}
              description={
                "Alpha-numeric unique identifier for the bill related to an offer."
              }
            />
            <FieldAttribute
              field={"id"}
              type={"ID"}
              description={"Alpha-numeric unique identifier for an offer."}
            />
            <FieldAttribute
              field={"status"}
              type={"String"}
              description={
                "Current status of the offer. May be one of the following values:"
              }
              possibleValues={["pending", "accepted", "rejected"]}
            />
            <FieldAttribute
              field={"content"}
              type={"String"}
              description={
                "Plain text used to describe the offer to the customer."
              }
            />
            <FieldAttribute
              field={"contentHtml"}
              type={"String"}
              description={
                "HTML formatted text used to describe the offer to the customer."
              }
            />
            <FieldAttribute
              field={"acceptedAt"}
              type={"DateTime"}
              description={
                "Date and time when the offer was accepted by the customer."
              }
            />
            <FieldAttribute
              field={"rejectedAt"}
              type={"DateTime"}
              description={
                "Date and time when the offer was rejected by the customer."
              }
            />
            <FieldAttribute
              field={"createdAt"}
              type={"DateTime"}
              description={"Date and time when the offer was created."}
            />
            <FieldAttribute
              field={"createdAt"}
              type={"DateTime"}
              description={"Date and time when the offer was last updated."}
            />
          </AvailableFields>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(exampleOffer, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>

      <Divider marginSize={6} className="bg-gray-300" />
      <ListOffers id="list-offers" />
      <Divider marginSize={6} />
      <FindOffer />
      <Divider marginSize={6} />
      <AcceptOffer />
      <Divider marginSize={6} />
      <RejectOffer />
    </Fragment>
  );
}

import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment, useState } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import SideSheet from "src/components/SideSheet";
import Typography from "src/components/Typography";
import billObjectJsonFile from "src/examples/bill.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import CreateBill from "./CreateBill";
import CreateDocument from "./CreateDocument";
import FindBill from "./FindBill";
import ListBills from "./ListBills";
import ProvideDocumentlessInfo from "./ProvideDocumentlessInfo";
import RenegotiateBill from "./RenegotiateBill";
import SetBillReadyToNegotiate from "./SetBillReadyToNegotiate";
import StopNegotiating from "./StopNegotiating";
import UpdateBill from "./UpdateBill";

export default function ApiDocsBills() {
  usePageTitle("Bills");
  const [showAutoFixDetails, setShowAutoFixDetails] = useState(false);

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              A bill is what a customer gets from a provider, usually monthly,
              that contains a payable amount for the provider's service(s).
            </Typography.Large>
            <Typography.Large>
              BillFixers uses the bill(s) provided by a customer to conduct all
              negotiations on a customer's behalf.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="bill">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={"Alpha-numeric unique identifier for a bill."}
              />
              <FieldAttribute
                field={"status"}
                type={"String"}
                description={
                  <React.Fragment>
                    <div>
                      Current status of the bill in the BillFixers system. May
                      be one of the following values:
                    </div>
                    <ul className="mt-2 space-y-3">
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Pending"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Default state for all bills
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"In Progress"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">Negotiation is underway</div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Bad Document"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The document uploaded for the bill contains
                          insufficient information to begin negotiating. A new
                          document should be uploaded.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Scheduled For Negotiation"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation is scheduled for a future to maximize
                          savings.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Completed"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation has finished and money was saved
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Failed"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation has finished but no money was saved
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Needs Additional Information"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          More information must be provided before negotiation
                          can begin. An information request will be present when
                          the bill is in this state.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Needs Your Consent"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The customer needs to accept or reject a savings
                          opportunity. An offer will be present when the bill is
                          in this state.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Savings Expiring"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          If the bill has auto_renegotiate set to false, a bill
                          can be in this state when savings are set to expire
                          within a month of the current date.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Savings Expired"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          If the bill has auto_renegotiate set to false, a bill
                          can be in this state when savings have expired.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Outside of Scope"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The bill is unnegotiable because it is from a provider
                          that we cannot negotiate with.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Cancelled"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The customer has requested that we stop negotiations.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Stopped"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The BillFixers team has stopped negotiations, usually
                          because the customer has expressed dissatisfaction
                          with our negotiation service.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"Provider Account Closed"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The customer's account with the provider is closed or
                          no longer active, meaning we cannot negotiate.
                        </div>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"title"}
                type={"String"}
                description={
                  "Title of the bill, which is usually just the provider's name."
                }
              />
              <FieldAttribute
                field={"total_savings"}
                type={"Money"}
                description={
                  "The current amount of money that BillFixers has successfully negotiated for the bill."
                }
              />
              <FieldAttribute
                field={"type_of_savings"}
                type={"String"}
                possibleValues={[
                  "monthly",
                  "one_time",
                  "monthly_and_one_time",
                  "null"
                ]}
                description={
                  "The type of savings that BillFixers has negotiated for the bill. May be one of the following values:"
                }
              />
              <FieldAttribute
                defaultValue="null"
                field={"negotiation_results_note"}
                type={"String"}
                description={
                  "Additional information related to the results of our negotiation e.g. an internet speed increase that came as a result of the negotiation."
                }
              />
              <FieldAttribute
                field={"gross_savings"}
                type={"Money"}
                description={
                  "The all time total amount of money that BillFixers has successfully negotiated for the bill."
                }
              />
              <FieldAttribute
                field={"auto_renegotiate"}
                type={"Boolean"}
                defaultValue={"true"}
                description={
                  <React.Fragment>
                    <div>
                      <p>
                        Whether or not the customer would like this bill to be
                        automatically negotiated whenever its savings expire in
                        the future.
                      </p>
                      <p className="mt-4">
                        For more about how auto_renegotiate works,{" "}
                        <span
                          className="cursor-pointer text-blue-500"
                          onClick={() => setShowAutoFixDetails(true)}
                        >
                          click here.
                        </span>
                      </p>
                    </div>
                    <SideSheet
                      open={showAutoFixDetails}
                      onClose={() => setShowAutoFixDetails(false)}
                      title={"Auto Renegotiate (aka AutoFix)"}
                    >
                      <div className="space-y-4">
                        <p>
                          Whenever we perform a negotiation, we take note of
                          when our negotiated savings is expected to end and
                          record that date on the bill.
                        </p>
                        <p>
                          That date becomes the next_renengotiate_on date, which
                          is what we use to determine when to automatically
                          renegotiate a bill.
                        </p>
                        <p>
                          The next_renegotiate_on date may be updated if we
                          determine that negotiated savings are expiring sooner
                          or later than we initially recorded. The date will
                          also update when a new negotiation occurs.
                        </p>
                        <p>
                          We have a daily task that determines which bills are
                          expiring soon and it proactively creates new
                          negotiations so that the customer does not experience
                          a sudden increase in their bills price.
                        </p>
                        <p>
                          When a new negotiation is created, a webhook is
                          delivered called{" "}
                          <CodeSnippet
                            inline
                            bare
                            code="bill.autofix_upcoming"
                          />{" "}
                          that alerts the partner about the upcoming
                          negotiation.
                        </p>
                      </div>
                    </SideSheet>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"allows_contract"}
                type={"Boolean"}
                defaultValue={"false"}
                description={
                  "When true, BillFixers will accept any contractual offers on behalf of the customer that would result in savings. The default is false."
                }
              />
              <FieldAttribute
                field={"scheduled_for_negotiation_at"}
                type={"Date"}
                description={
                  "The date a bill's negotiation will start/continue. This is only present if a bill has been scheduled for negotiation at a later date."
                }
              />
              <FieldAttribute
                field={"completed_at"}
                type={"Date"}
                description={
                  "The date that the bill was fixed or failed. If the bill is still pending, in progress, awaiting information, or has a pending offer, this value will be null."
                }
              />
              {/* <FieldAttribute
                field={"savings_start_on"}
                type={"Date"}
                description={
                  "The date any savings are expected to be applicable."
                }
              />
              <FieldAttribute
                field={"savings_end_on"}
                type={"Date"}
                description={"The date any savings are expected to expire."}
              /> */}
              <FieldAttribute
                field={"savings_expire_on"}
                type={"Date"}
                description={
                  <div>
                    The date any savings are expected to expire. This is an
                    alias method for{" "}
                    <CodeSnippet bare inline code="savings_end_on" />.
                  </div>
                }
              />
              <FieldAttribute
                field={"next_renegotiate_on"}
                type={"Date"}
                description={
                  "If auto_renegotiate is true, this is the date that the next negotiation will occur."
                }
              />
              <FieldAttribute
                field={"customer_eligible_for_negotiation"}
                type={"Boolean"}
                description={
                  <React.Fragment>
                    <p>
                      Reflects the eligibilty of the bill's customer for
                      negotiation. This is the same as the customer object's{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="eligible_for_negotiation"
                      />{" "}
                      field.
                    </p>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"items"}
                type={"Array of item objects"}
                description={"List of the line items that exist on the bill."}
              />
              <FieldAttribute
                field={"customer"}
                type={"Customer"}
                description={"The customer that owns the bill."}
              />
              <FieldAttribute
                field={"provider"}
                type={"Provider"}
                description={
                  "The provider that originally issued the bill to the customer."
                }
              />
              <FieldAttribute
                field={"offers"}
                type={"Array of offer objects"}
                description={"List of the offers that exist on the bill."}
              />
              <FieldAttribute
                field={"information_requests"}
                type={"Array of informationRequest objects"}
                description={
                  "List of the information requests that exist on the bill."
                }
              />
              <FieldAttribute
                field={"negotiations"}
                type={"Array of negotiation objects"}
                description={"List of the negotiations that exist on the bill."}
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(billObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <CreateBill id="create-bill" />
      <Divider marginSize={6} className="bg-gray-300" />
      <CreateDocument id="create-document" />
      <Divider marginSize={6} className="bg-gray-300" />
      <SetBillReadyToNegotiate id="set-bill-ready-to-negotiate" />
      <Divider marginSize={6} className="bg-gray-300" />
      <ProvideDocumentlessInfo id="provide-documentless-info" />
      <Divider marginSize={6} className="bg-gray-300" />
      <UpdateBill id="update-bill" />
      <Divider marginSize={6} className="bg-gray-300" />
      <ListBills id="list-bills" />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindBill id="find-bill" />
      <Divider marginSize={6} className="bg-gray-300" />
      <RenegotiateBill id="renegotiate-bill" />
      <Divider marginSize={6} className="bg-gray-300" />
      <StopNegotiating id="stop-negotiating" />
    </Fragment>
  );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import findCustomerResponseJsonFile from "src/examples/responses/find_customer.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function FindCustomer({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("find-customer");
      if (hash.includes("find-customer")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/customers#find-customer">
        <h3 className="mb-8 text-xl font-medium">Find a customer</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the customer.</div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The customer object, if found.</p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be false.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, findCustomerExampleRubyBody),
              js: javascriptExample(testApiKey!, findCustomerExampleJsBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(findCustomerResponseJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const findCustomerExampleRubyBody = `
customer = client.find_customer('customer_12fasfa3187asd')
`;

const findCustomerExampleJsBody = `
const customer = await client.findCustomer(
  'customer_12fasfa3187asd'
)
`;

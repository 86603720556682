import apollo from "src/apollo";
import { protocol, host } from "src/config";
import { rotateToken } from "./rotateAuthToken";

// USE AS A LAST RESORT TO NUKE LOCALSTORAGE AND LOGOUT
export function haltAndCatchFire(redirectToRoot?: boolean) {
  const storedEmail = localStorage.getItem("email");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  localStorage.removeItem("apiKey");
  localStorage.removeItem("testApiKey");
  apollo.clearStore();
  // We have to do this because we have PWA features enabled with our serviceWorkerRegistration.ts
  // If we don't manually clear the caches, the cached version of the app will be served to the user
  // until they close all browser tabs running billfixers.
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }

  if (storedEmail) {
    rotateToken(storedEmail);
  }

  if (redirectToRoot) {
    window.location.href = "/";
  }
}

import { Space } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import Section from "src/components/CodeDocs/Section";
import CodeSnippet from "src/components/CodeSnippet";
import Segment from "src/components/Segment";
import usePageTitle from "src/hooks/usePageTitle";
import useSession from "src/hooks/useSession";
import { LinkToSection } from "../Libraries/styles";

const RateLimits: React.FC = () => {
  usePageTitle("Rate Limits");
  const { testApiKey } = useSession();

  const usageCurl = `curl 'https://app.billfixers.com/partner/graphql/usage' \
                          -H 'X-Partner-ApiKey: ${testApiKey}' \
                          -H 'Content-Type: application/json; charset=utf-8' \
`;

  return (
    <Fragment>
      <Space height={1} />
      <ul>
        <li>
          <LinkToSection href="#the-leaky-bucket">
            The Leaky Bucket
          </LinkToSection>
        </li>
        <li>
          <LinkToSection href="#usage-limits">Usage Limits</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#throttling">Throttling</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#determining-usage">
            Determining Usage
          </LinkToSection>
        </li>
        <li>
          <LinkToSection href="#single-query-complexity">
            Single Query Complexity
          </LinkToSection>
        </li>
      </ul>
      <Space height={2} />

      <Section
        title="The Leaky Bucket"
        anchor="the-leaky-bucket"
        className="mt-8"
      >
        <div className="grid grid-cols-2 gap-8">
          <div className="">
            <p className="mt-2 text-gray-600 line-clamp-2">
              BillFixers rate limits GraphQL requests using a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Leaky_bucket#As_a_meter"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                leaky bucket
              </a>{" "}
              algorithm. GraphQL requests are analyzed as they come in and their{" "}
              <a
                href="https://www.howtographql.com/advanced/4-security/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                complexity score
              </a>{" "}
              is added to your organization's total API usage. If your
              organization exceeds that usage in a given period of time, your
              requests will be throttled until your usage returns to acceptable
              limits.
            </p>
            <p className="mt-2 text-gray-600 line-clamp-2">
              The leaky bucket algorithm lets you make an unlimited amount of
              requests in infrequent bursts over time and ensures that partners
              who manage API calls responsibly will always have room in their
              buckets to make a burst of requests if needed.
            </p>
          </div>
        </div>
      </Section>

      <Section title="Usage Limits" anchor="usage-limits" className="mt-8">
        <div className="grid grid-cols-2 gap-8">
          <div className="">
            <p className="mt-2 text-gray-600 line-clamp-2">
              Your organization is allowed to make requests until the combined
              complexity of your requests/queries reaches or exceeds 1000 points
              in a given period of time.
            </p>
            <p className="mt-2 text-gray-600 line-clamp-2">
              Each field requested in a GraphQL query costs at least 1 point.
              Mutations cost 10 points by default.
            </p>
            <p className="mt-2 text-gray-600 line-clamp-2">
              Points are replenished at a rate of 50 per second.
            </p>
            {/* <p className="mt-4 text-gray-600 line-clamp-2">
              <span className="text-red-500 font-bold">*</span> Keep in mind
              that rate limits are not user dependent, so if you have multiple
              users accessing the API with their own keys, their requests all
              count towards the same 1000 points.
            </p> */}
          </div>
          <div>
            <Segment>
              <div className="flex items-center">
                <h4>Maximum Points Available:</h4>
                <h4 className="ml-2">1000</h4>
              </div>
              <div className="flex items-center mt-4">
                <h4>Replenish Rate:</h4>
                <h4 className="ml-2">50 points / second</h4>
              </div>
              <div className="flex items-center mt-4">
                <h4>Query Cost:</h4>
                <h4 className="ml-2">Determined at time of request</h4>
              </div>
              <p className="mt-4 text-gray-500 text-sm line-clamp-2">
                <span className="text-red-500 font-light">*</span> The limit and
                replenish rate are subject to change at any time.
              </p>
            </Segment>
          </div>
        </div>
      </Section>

      <Section title="Throttling" anchor="throttling" className="mt-8">
        <div className="grid grid-cols-2 gap-8">
          <div className="">
            <p className="mt-2 text-gray-600 line-clamp-2">
              If you make an excessive number of costly queries or too many
              queries too quickly, your requests will be throttled and you'll be
              given a 429 response code with an error message like {"-->"}
            </p>
          </div>
          <div>
            <p className="p-4 bg-gray-200 text-gray-700 text-sm rounded-md mx-2 my-4">
              Your request's complexity is 64 points, but you have reached the
              max number of points allowed (1000). Points replenish at a rate of
              50 per second. Please allow your points to replenish and try the
              request again.
            </p>
          </div>
        </div>
      </Section>

      <Section
        title="Determining Usage"
        anchor="determining-usage"
        className="mt-8"
      >
        <div className="grid grid-cols-2 gap-8">
          <div className="">
            <p className="mt-2 text-gray-600 line-clamp-2">
              Each GraphQL request you make will contain a response header
              called{" "}
              <CodeSnippet
                language="text"
                bare
                inline
                code={"X-Billfixers-Call-Limit-Usage"}
              />{" "}
              that shows the current number of points you have used. The closer
              that number is to the max of 1000, the more careful you should be
              if you want to avoid being throttled.
            </p>
            <p className="mt-2 text-gray-600 line-clamp-2">
              Of course, there's nothing wrong with being throttled as long as
              you have proper mitigation strategies in place. You could
            </p>
            <ul className="list-disc ml-8 mt-3 text-gray-600">
              <li className="mt-2">
                Wait for a number of seconds and then retry your request(s).
              </li>
              <li className="mt-2">
                Monitor your usage with a poll interval and the current usage
                endpoint described below.
              </li>
              <li className="mt-2">
                Use a background process to make and automatically retry
                requests that aren't needed immediately.
              </li>
            </ul>
          </div>
          <div>
            <CodeSnippet
              className="text-sm"
              language="json"
              code={"X-Billfixers-Call-Limit-Usage: 782"}
            />
          </div>
          <div className="">
            <p className="text-gray-600 line-clamp-2">
              You may also make a GET request to the usage endpoint of our API
              to get your current usage.
            </p>
          </div>
          <div>
            <CodeSnippet language="bash" code={usageCurl} copy />
          </div>
        </div>
      </Section>

      <Section
        title="Single Query Complexity"
        anchor="single-query-complexity"
        className="mt-8"
      >
        <div className="grid grid-cols-2 gap-8">
          <div className="">
            <p className="mt-2 text-gray-600 line-clamp-2">
              The maximum complexity of any single query is 500 points. Queries
              above that limit will fail, regardless of how many points are
              available for use.
            </p>
          </div>
        </div>
      </Section>
    </Fragment>
  );
};

export default RateLimits;

import React from "react";
import { Field, Form } from "react-final-form";
import Divider from "src/components/Divider";
import Errors from "src/components/Errors";
import NewButton from "src/components/NewButton";

interface IProps {
  initialValues?: any;
  errors: string[] | undefined;
  onSubmit: (values: any) => void;
}

export default function WebhookHeaderForm({
  initialValues,
  errors,
  onSubmit
}: IProps) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ form, handleSubmit, submitting, pristine, invalid }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            {errors && <Errors errors={errors} className="mb-8" />}
            <div className="space-y-6">
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="headerName"
                >
                  Header Name
                </label>
                <Field
                  name="headerName"
                  type="text"
                  render={({ input }) => (
                    <input
                      {...input}
                      placeholder="ex. Authorization"
                      autoComplete="off"
                      className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="headerValue"
                >
                  Header Value
                </label>
                <Field
                  name="headerValue"
                  type="text"
                  render={({ input }) => (
                    <input
                      {...input}
                      placeholder="ex. Bearer 123456AbcDEf"
                      autoComplete="off"
                      className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="headerType"
                >
                  Header Type
                </label>
                <Field
                  name="headerType"
                  render={({ input }) => (
                    <select
                      id={"headerType"}
                      {...input}
                      autoComplete="off"
                      className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="test_header">Test</option>
                      <option value="live_header">Live</option>
                    </select>
                  )}
                />
                <p className="mt-2 text-sm text-gray-500">
                  The type of the header determines which webhook it will be
                  sent with. Test headers are sent with the test webhook URL,
                  and live headers are sent with the live webhook URL.
                </p>
              </div>
            </div>
            <Divider marginSize={1} />
            <NewButton
              fullWidth
              size="sm"
              type="submit"
              loading={submitting}
              disabled={submitting || pristine || invalid}
            >
              Save
            </NewButton>
          </form>
        );
      }}
    />
  );
}

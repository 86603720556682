export const installation = `gem install billfixers-partner`;

export const configureClient = (apiKey: string) => `require 'billfixers/partner'

# Configure the client
client = Billfixers::Partner::Client.new(
  api_key: "${apiKey}",
)
`;

export const listProviders = `providers = client.list_providers`;

export const listProvidersResponse = `[
  {
    "id": "provider_12fasfa3187asd",
    "name": "Comcast Xfinity",
    "services": [
      "TV",
      "Internet",
      "Over priced landline phone service",
      "Hidden fees"
    ]
  },
  {
    "id": "provider_12fasfa3187asd",
    "name": "AT&T",
    "services": [
      "TV",
      "Internet"
    ]
  }
]`;

export const findCustomer = `customer = client.find_customer("customer_12fasfa3187asd")`;

export const findCustomerResponse = `{
  "id": "customer_12fasfa3187asd",
  "email": "william@billfixers.com",
  "name": "William Fixer",
  "firstName": "William",
  "lastName": "Fixer",
  "phoneNumber": "(123) 456-7890",
  "createdAt": "2020-06-10T16:16:24-05:00",
  "updatedAt": "2020-06-10T16:16:24-05:00"
}`;

export const createCustomer = `customer = client.create_customer({
  email: "william.fixer@example.com",
  first_name: "William",
  last_name: "Fixer"
})
`;

export const createCustomerResponse = `{
  "id": "customer_12fasfa3187asd",
  "email": "william.fixer@example.com",
  "name": "William Fixer",
  "firstName": "William",
  "lastName": "Fixer",
  "phoneNumber": nil,
  "createdAt": "2020-06-10T16:16:24-05:00",
  "updatedAt": "2020-06-10T16:16:24-05:00"
}`;

export const listCustomers = `customers = client.list_customers(limit: 25, offset: 0)`;

export const listCustomersResponse = `"totalCount": 25,
"customers": [
  {
    "id": "customer_12fasfa3187asd",
    "email": "william@billfixers.com",
    "name": "William Fixer",
    "firstName": "William",
    "lastName": "Fixer",
    "phoneNumber": "(123) 456-7890",
    "createdAt": "2020-06-10T16:16:24-05:00",
    "updatedAt": "2020-06-10T16:16:24-05:00"
  },
  {
    "id": "customer_8cca2d0a2731fbb661e42a7ab98f92f8",
    "email": "philb@example.com",
    "name": "Phil Bicksurz",
    "firstName": "Phil",
    "lastName": "Bicksurz",
    "phoneNumber": nil,
    "createdAt": "2020-08-25T09:38:59-05:00",
    "updatedAt": "2020-08-25T09:38:59-05:00"
  }
]`;

export const createBill = `bill = client.create_bill("customer_12fasfa3187asd",
{
  providerId: "provider_...",
  last4ssn: "0000",
  passcode: "1234",
  allowsContract: false,
  autoRenegotiate: true,
  documentUrls: [
    "https://example.com/bill.pdf",
    "https://example.com/bill2.pdf"
  ]
})
`;

export const createBillResponse = `
{
  "id": "bill_12fasfa3187asd",
  "status": "In Progress",
  "title": "Comcast Bill",
  "totalSavings": "0.00",
  "grossSavings": "0.00",
  "autoRenegotiate": true,
  "allowsContract": false,
  "provider": {
    "id": "provider_12fasfa3187asd",
    "name": "Comcast Xfinity",
    "services": [
      "TV",
      "Internet",
      "Over priced landline phone service",
      "Hidden fees"
    ]
  },
  "customer": {
    "id": "customer_12fasfa3187asd",
    "email": "william@billfixers.com",
    "name": "William Fixer",
    "firstName": "William",
    "lastName": "Fixer",
    "phoneNumber": "(123) 456-7890",
    "createdAt": "2020-06-10T16:16:24-05:00",
    "updatedAt": "2020-06-10T16:16:24-05:00"
  },
  "items": [],
  "offers": [],
  "information_requests": []
}
`;

export const listBills = `total_count, bills = client.list_bills(limit: 25, offset: 0, customer_id: nil)
`;

export const listBillsResponse = `"totalCount": 25,
"bills": [
  {
    "id": "bill_12fasfa3187asd",
    "status": "In Progress",
    "title": "Comcast Bill",
    "totalSavings": "100.00",
    "grossSavings": "200.00",
    "provider": {
      "id": "provider_12fasfa3187asd",
      "name": "Comcast Xfinity",
      "services": [
        "TV",
        "Internet",
        "Over priced landline phone service",
        "Hidden fees"
      ]
    },
    "customer": {
      "id": "customer_12fasfa3187asd",
      "email": "william@billfixers.com",
      "name": "William Fixer",
      "firstName": "William",
      "lastName": "Fixer",
      "phoneNumber": "(123) 456-7890",
      "createdAt": "2020-06-10T16:16:24-05:00",
      "updatedAt": "2020-06-10T16:16:24-05:00"
    },
    "items": [
      {
        "name": "50 mbps internet",
        "prePrice": "$60.00",
        "postPrice": "$30.00",
        "duration": "12",
        "savings": "$360.00",
        "savingsStartOn": "2020-07-01",
        "savingsEndOn": "2021-07-01",
        "underContract": "false"
      },
      {
        "name": "Modem rental fee",
        "prePrice": "$15.00",
        "postPrice": "$5.00",
        "duration": "12",
        "savings": "$120.00",
        "savingsStartOn": "2020-07-01",
        "savingsEndOn": "2021-07-01",
        "underContract": "false"
      }
    ],
    "offers": [],
    "information_requests": []
  },
  ...
]
`;

export const findBill = `bill = client.find_bill("bill_12fasfa3187asd");
`;

export const findBillResponse = `{
  "id": "bill_12fasfa3187asd",
  "status": "In Progress",
  "title": "Comcast Bill",
  "totalSavings": "100.00",
  "grossSavings": "200.00",
  "allowsContract": false,
  "autoRenegotiate": true,
  "provider": {
    "id": "provider_12fasfa3187asd",
    "name": "Comcast Xfinity",
    "services": [
      "TV",
      "Internet",
      "Over priced landline phone service",
      "Hidden fees"
    ]
  },
  "customer": {
    "id": "customer_12fasfa3187asd",
    "email": "william@billfixers.com",
    "name": "William Fixer",
    "firstName": "William",
    "lastName": "Fixer",
    "phoneNumber": "(123) 456-7890",
    "createdAt": "2020-06-10T16:16:24-05:00",
    "updatedAt": "2020-06-10T16:16:24-05:00"
  },
  "items": [
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    },
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    }
  ],
  "offers": [],
  "information_requests": []
}`;

export const renegotiateBill = `bill = client.renegotiate_bill("bill_12fasfa3187asd");
`;

export const renegotiateBillResponse = `{
  "id": "bill_12fasfa3187asd",
  "status": "Pending",
  "title": "Comcast Bill",
  "totalSavings": "100.00",
  "grossSavings": "200.00",
  "allowsContract": false,
  "autoRenegotiate": false,
  "provider": {
    "id": "provider_12fasfa3187asd",
    "name": "Comcast Xfinity",
    "services": [
      "TV",
      "Internet",
      "Over priced landline phone service",
      "Hidden fees"
    ]
  },
  "customer": {
    "id": "customer_12fasfa3187asd",
    "email": "william@billfixers.com",
    "name": "William Fixer",
    "firstName": "William",
    "lastName": "Fixer",
    "phoneNumber": "(123) 456-7890",
    "createdAt": "2020-06-10T16:16:24-05:00",
    "updatedAt": "2020-06-10T16:16:24-05:00"
  },
  "items": [
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    },
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    }
  ],
  "offers": [],
  "information_requests": []
}`;

export const stopNegotiating = `bill = client.stop_negotiating("bill_12fasfa3187asd");
`;

export const stopNegotiatingResponse = `{
  "id": "bill_12fasfa3187asd",
  "status": "Cancelled",
  "title": "Comcast Bill",
  "totalSavings": "100.00",
  "grossSavings": "200.00",
  "allowsContract": false,
  "autoRenegotiate": true,
  "provider": {
    "id": "provider_12fasfa3187asd",
    "name": "Comcast Xfinity",
    "services": [
      "TV",
      "Internet",
      "Over priced landline phone service",
      "Hidden fees"
    ]
  },
  "customer": {
    "id": "customer_12fasfa3187asd",
    "email": "william@billfixers.com",
    "name": "William Fixer",
    "firstName": "William",
    "lastName": "Fixer",
    "phoneNumber": "(123) 456-7890",
    "createdAt": "2020-06-10T16:16:24-05:00",
    "updatedAt": "2020-06-10T16:16:24-05:00"
  },
  "items": [
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    },
    {
      "name": "50 mbps internet",
      "prePrice": "$60.00",
      "postPrice": "$30.00",
      "duration": "12",
      "savings": "$360.00",
      "savingsStartOn": "2020-07-01",
      "savingsEndOn": "2021-07-01",
      "underContract": "false"
    }
  ],
  "offers": [],
  "information_requests": []
}`;

export const listOffers = `totalCount, offers = client.list_offers(
  limit: 25,
  offset: 0,
  status: "pending",
  customer_id: "customer_12fasfa3187asd",
  bill_id: nil
)
`;

export const listOffersResponse = `{
  "totalCount": 1,
  "offers": [
    {
      "id": "offer_ad81b738bc03cf9cbf0935e935bc6657",
      "status": "accepted",
      "content": "We could reduce your bill by $100.00 a month with a new 12 month promotion.",
      "contentHtml": "<p>We could reduce your bill by $100.00 a month with a new 12 month promotion.</p>",
      "createdAt": "2020-08-25T09:24:20-05:00",
      "updatedAt": "2020-08-25T09:24:20-05:00",
      "acceptedAt": "2020-08-25T09:24:20-05:00",
      "rejectedAt": nil,
      "bill": {
        "id": "bill_b30fd3aa5e1c4c9a98f6e7b569d7db36",
        "title": "1&1"
      },
      "customer": {
        "id": "customer_d24a8aead6aa88bbe36c0897ba7b0c5f",
        "name": "William Fixer"
      }
    },
    ...
  ]
}
`;

export const acceptOffer = `offer = client.accept_offer("offer_ad81b738bc03cf9cbf0935e935bc6657")`;

export const acceptOfferResponse = `{
  "id": "offer_ad81b738bc03cf9cbf0935e935bc6657",
  "status": "accepted",
  "content": "We could reduce your bill by $100.00 a month with a new 12 month promotion.",
  "contentHtml": "<p>We could reduce your bill by $100.00 a month with a new 12 month promotion.</p>",
  "createdAt": "2020-08-25T09:24:20-05:00",
  "updatedAt": "2020-08-25T09:24:20-05:00",
  "acceptedAt": "2020-08-25T09:24:20-05:00",
  "rejectedAt": nil,
  "bill": {
    "id": "bill_b30fd3aa5e1c4c9a98f6e7b569d7db36",
    "title": "1&1"
  },
  "customer": {
    "id": "customer_d24a8aead6aa88bbe36c0897ba7b0c5f",
    "name": "William Fixer"
  }
}`;

export const rejectOffer = `offer = client.reject_offer("offer_ad81b738bc03cf9cbf0935e935bc6657")`;

export const rejectOfferResponse = `{
  "id": "offer_ad81b738bc03cf9cbf0935e935bc6657",
  "status": "rejected",
  "content": "We could reduce your bill by $100.00 a month with a new 12 month promotion.",
  "contentHtml": "<p>We could reduce your bill by $100.00 a month with a new 12 month promotion.</p>",
  "createdAt": "2020-08-25T09:24:20-05:00",
  "updatedAt": "2020-08-25T09:24:20-05:00",
  "acceptedAt": nil,
  "rejectedAt": "2020-08-25T09:24:20-05:00",
  "bill": {
    "id": "bill_b30fd3aa5e1c4c9a98f6e7b569d7db36",
    "title": "1&1"
  },
  "customer": {
    "id": "customer_d24a8aead6aa88bbe36c0897ba7b0c5f",
    "name": "William Fixer"
  }
}`;

export const findOffer = `offer = client.find_offer("offer_ad81b738bc03cf9cbf0935e935bc6657")`;

export const findOfferResponse = `{
  "id": "offer_ad81b738bc03cf9cbf0935e935bc6657",
  "status": "pending",
  "content": "We could reduce your bill by $100.00 a month with a new 12 month promotion.",
  "contentHtml": "<p>We could reduce your bill by $100.00 a month with a new 12 month promotion.</p>",
  "createdAt": "2020-08-25T09:24:20-05:00",
  "updatedAt": "2020-08-25T09:24:20-05:00",
  "acceptedAt": nil,
  "rejectedAt": nil,
  "bill": {
    "id": "bill_b30fd3aa5e1c4c9a98f6e7b569d7db36",
    "title": "1&1"
  },
  "customer": {
    "id": "customer_d24a8aead6aa88bbe36c0897ba7b0c5f",
    "name": "William Fixer"
  }
}`;

export const listInfoReqs = `totalCount, info_requests = client.list_information_requests(
  limit: 25,
  offset: 0,
  status: "pending",
  customer_id: "customer_12fasfa3187asd",
)
`;

export const listInfoReqsResponse = `{
  "totalCount": 1,
  "informationRequests": [
    {
      "id": "information_request_47638e080396f0f046",
      "content": "While we have the other bill information needed, we are missing the service address on the account. Would you mind sending over your service address?",
      "contentHtml": "<p>While we have the other bill information needed, we are missing the service address on the account. Would you mind sending over your service address?</p>",
      "createdAt": "2020-11-19T09:05:51-06:00",
      "respondedAt": null,
      "fields": [
        {
          "label": "Service Address",
          "placeholder": "1234 Main St, Anytown, USA 90210",
          "dataType": "text",
          "createdAt": "2020-11-19T09:05:51-06:00",
          "value": null
        }
      ]
    },
    ...
  ]
}
`;

export const respondToInfoReq = `info_request = client.respond_to_information_request(
  'information_request_47638e080396f0f046',
  {
    information_request: {
      fields: [
        {
          id: 'information_request_field_1a55dc6aa502e589dc5b3e19529d035b',
          value: '1234'
        }
      ]
    }
  }
)
`;

export const respondToInfoReqResponse = `{
  "id": "information_request_47638e080396f0f046",
  "content": "While we have the other bill information needed, we are missing the PIN on the account. Would you mind sending over your PIN?",
  "contentHtml": "<p>While we have the other bill information needed, we are missing the PIN on the account. Would you mind sending over your PIN?</p>",
  "createdAt": "2020-11-19T09:05:51-06:00",
  "respondedAt": "2020-11-19T09:05:51-06:00,
  "fields": [
    {
      "label": "Account PIN",
      "placeholder": "Account PIN",
      "dataType": "text",
      "createdAt": "2020-11-19T09:05:51-06:00",
      "value": 1234
    }
  ]
}
`;

export const findInfoReq = `info_request = client.find_to_information_request(
  'information_request_47638e080396f0f046'
)
`;

export const findInfoReqResponse = `{
  "id": "information_request_47638e080396f0f046",
  "content": "While we have the other bill information needed, we are missing the PIN on the account. Would you mind sending over your PIN?",
  "contentHtml": "<p>While we have the other bill information needed, we are missing the PIN on the account. Would you mind sending over your PIN?</p>",
  "createdAt": "2020-11-19T09:05:51-06:00",
  "respondedAt": nil,
  "fields": [
    {
      "label": "Account PIN",
      "placeholder": "Account PIN",
      "dataType": "text",
      "createdAt": "2020-11-19T09:05:51-06:00",
      "value": 1234
    }
  ]
}`;

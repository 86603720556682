import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import sandboxTransitionNegotiationJsonFile from "src/examples/responses/sandbox_transition_negotiation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SandboxTransitionNegotiation({
  className,
  id
}: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "sandbox-transition-negotiation"
      );
      if (hash.includes("sandbox-transition-negotiation")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "sandbox-transition-negotiation"}>
      <a href="/api-docs/sandbox-resources#sandbox-transition-negotiation">
        <h3 className="mb-8 text-xl font-medium">
          Sandbox transition negotiation
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    The ID of the bill that is transitioning its current
                    negotiation.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              required
              field={"transition"}
              type={"String"}
              description={
                <React.Fragment>
                  <div>
                    The verb representing the transition for the negotiation.
                    Must be one of:
                  </div>
                  <ul className="mt-2 space-y-3">
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"start"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        The start transition must happen before any other
                        transitions can take place.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"fix"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">Fixes the negotiation.</div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"fail"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">Fails the negotiation.</div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"schedule"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Schedules the negotiation for 30 days from now.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"request_information"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Generates an information request for the negotiation.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"request_consent"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Generates an offer for the negotiation.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"reset"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Resets the negotiation to its initial pending state.
                      </div>
                    </li>
                  </ul>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The bill that has transitioned its current negotiation, if the
              mutation was successful.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, calculateEstimateExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   calculateEstimateExampleJsBody
              // ),
              curl: curlExample(
                testApiKey!,
                sandboxTransitionNegotiationExampleCurlBody
              )
              // python: pythonExample(
              //   testApiKey!,
              //   calculateEstimateExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              sandboxTransitionNegotiationJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const sandboxTransitionNegotiationExampleCurlBody = `
$'{
  "query": "mutation SandboxTransitionNegotiation($billId:ID!,$transition:String!){SandboxTransitionNegotiation(input:{billId:$billId,transition:$transition}){success errors bill{id}}}",
	"variables": {
		"billId": "bill_5484b35341280f05b76e085a6e822e47",
		"transition": "fix"
	}
}`;

import { observable, action } from "mobx";

import { createContext } from "react";

class MetaStore {
  constructor() {
    this.pageTitle = "Partners";
  }

  @observable
  pageTitle: string;

  @action
  setPageTitle = (newTitle: string) => {
    document.title = newTitle;
    this.pageTitle = newTitle;
  };
}

export const metaStore = new MetaStore();
export const MetaStoreContext = createContext(metaStore);

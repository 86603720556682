import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import setBillReadyToNegotiateJsonFile from "src/examples/responses/set_bill_ready_to_negotiate.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SetBillReadyToNegotiate({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "set-bill-ready-to-negotiate"
      );
      if (hash.includes("set-bill-ready-to-negotiate")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#set-bill-ready-to-negotiate">
        <h3 className="mb-8 text-xl font-medium">
          Set bill ready to negotiate
        </h3>
      </a>
      <p className="mb-8">
        This mutation sets a bills{" "}
        <CodeSnippet inline bare code={"ready_to_negotiate"} /> column to true,
        and should be used whenever the bill has all the requisite parts to
        begin negotiation (a document has been provided and negotiation
        preferences have been set).
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    Alpha-numeric unique identifier for the bill that will be
                    ready to negotiate.
                  </div>
                </React.Fragment>
              }
            />
            <React.Fragment />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The bill object that is now ready to negotiate, if successful.
            </p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(
                testApiKey!,
                setBillReadyToNegotiateExampleCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              setBillReadyToNegotiateJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const setBillReadyToNegotiateExampleCurlBody = `
$'{
  "query": "mutation SetBillReadyToNegotiate($billId:ID!){SetBillReadyToNegotiate(input:{billId:$billId}){success errors bill{id readyToNegotiate}}}",
  "variables": {
		"billId": "bill_8e993f69bbcaaaea69f7cdc1a7eb8222"
	}
`;

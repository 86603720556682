import React, { CSSProperties } from "react";
import { Inline } from "@cohubinc/blueprint";
import CodeSnippet from "../CodeSnippet";

interface IPossibleFieldValuesProps {
  values: string[];
  style?: CSSProperties;
}

export default function PossibleFieldValues({
  values,
  style
}: IPossibleFieldValuesProps) {
  const valuesElements = values.map((v) => (
    <CodeSnippet
      bare
      code={v}
      style={{ padding: "0.25rem" }}
      key={v}
      className="text-xs mb-1"
    />
  ));

  return (
    <Inline style={style} itemAlignment="center">
      {valuesElements}
    </Inline>
  );
}

import React, { Fragment } from "react";
import DesktopSidebar from "./Desktop";
import MobileSidebar from "./Mobile";

const Sidebar: React.FC = () => {
  return (
    <Fragment>
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <MobileSidebar />

      {/* Static sidebar for desktop */}
      <DesktopSidebar />
    </Fragment>
  );
};

export default Sidebar;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import createCustomerResponseJsonFile from "src/examples/responses/create_customer.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function CreateCustomer({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("create-customer");
      if (hash.includes("create-customer")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/customers#create-customer">
        <h3 className="mb-8 text-xl font-medium">Create a customer</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"email"}
              type={"string"}
              description={
                <React.Fragment>
                  <div>A valid email address for the customer.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"external_id"}
              type={"string"}
              description={
                <div className="space-y-4">
                  <div>
                    An identifying string that you may use to associate your
                    data with a BillFixers customer.
                  </div>
                  <div>
                    If passed, the external ID must be unique across all of your
                    customers.
                  </div>
                </div>
              }
            />
            <Parameter
              field={"corvette_id"}
              type={"string"}
              description={
                <div className="space-y-4">
                  <div>The customer's corvette ID.</div>
                </div>
              }
            />
            <Parameter
              field={"membership_id"}
              type={"string"}
              description={
                <div className="space-y-4">
                  <div>The customer's membership ID.</div>
                </div>
              }
            />
            <Parameter
              field={"first_name"}
              type={"string"}
              description={
                <React.Fragment>
                  <div>The customer's first name.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"last_name"}
              type={"string"}
              description={
                <React.Fragment>
                  <div>The customer's last name.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"phone_number"}
              type={"string"}
              description={
                <React.Fragment>
                  <div>The customer's phone number.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"gender"}
              type={"string"}
              possibleValues={["male", "female"]}
              description={
                <React.Fragment>
                  <div>The customer's gender.</div>
                </React.Fragment>
              }
            />
            <Parameter
              overridePreferredLanguage
              field={"last4ssn"}
              type={"string"}
              description={
                <React.Fragment>
                  <div>
                    The last 4 digits of the customer's social security number.
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The customer object that was created if successful.</p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, createCustomerExampleRubyBody),
              js: javascriptExample(testApiKey!, createCustomerExampleJsBody),
              curl: curlExample(testApiKey!, createCustomerExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              createCustomerResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const createCustomerExampleRubyBody = `
customer = client.create_customer({
  email: "customer@test.com",
  first_name: "Phil",
  last_name: "Bicksurz"
})
`;

const createCustomerExampleJsBody = `
const customer = await client.createCustomer({
  email: "william.fixer@example.com",
  firstName: "William",
  lastName: "Fixer"
});
`;

const createCustomerExampleCurlBody = `
$'{
  "query": "mutation CreateCustomer($customer:CustomerAttributes!){CreateCustomer(input:{customer:$customer}){customer{id externalId email name firstName lastName eligibleForNegotiation eligibleForCancellation gender last4ssn phoneNumber}success errors}}",
	"variables": {
		"customer": {
			"email": "philb@example.com",
      "firstName": "Phil",
      "lastName": "Bicksurz",
			"externalId": "ext_g18hmnz689io1",
			"corvetteId": "28e14b02351547c9afe107b23dce988b",
			"membershipId": "1023714940581829033",
      "gender": "female",
      "last4ssn": "4321"
		}
	}
`;

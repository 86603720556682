import { useTestMode } from "src/contexts/TestModeContext";

// Used to show things in production test mode, but allow dev environment processes to also see
// those things since test mode is strictly tied to the sandbox heroku app
export default function useDevAwareShow(): boolean {
  const { testMode } = useTestMode();

  if (process.env.NODE_ENV === "development") {
    return true;
  } else if (testMode) {
    return true;
  } else {
    return false;
  }
}

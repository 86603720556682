import { Link, useLocation } from "react-router-dom";
import paths from "src/paths";
import { getLinkClassNames } from "./Desktop";

export default function Navigation() {
  const { pathname } = useLocation();

  return (
    <nav className="flex-1 space-y-1 bg-gray-800 px-2">
      <div className="space-y-1">
        <Link
          to={paths.dashboard}
          className={`${getLinkClassNames(paths.dashboard, pathname)} pl-4`}
        >
          <h6>Dashboard</h6>
        </Link>
        <Link
          to={paths.bills}
          className={`${getLinkClassNames(paths.bills, pathname)} pl-4`}
        >
          <h6>Bills</h6>
        </Link>
        <Link
          to={paths.customers}
          className={`${getLinkClassNames(paths.customers, pathname)} pl-4`}
        >
          <h6>Customers</h6>
        </Link>
        <Link
          to={paths.webhookEvents}
          className={`${getLinkClassNames(paths.webhookEvents, pathname)} pl-4`}
        >
          <h6>Webhook Events</h6>
        </Link>
        <Link
          to={paths.apiDocs.home}
          className={`${getLinkClassNames(paths.apiDocs.home, pathname)} pl-4`}
        >
          <h6>API Documentation</h6>
        </Link>
        <Link
          to={paths.settings}
          className={`${getLinkClassNames(paths.settings, pathname)} pl-4`}
        >
          <h6>Settings</h6>
        </Link>
      </div>
    </nav>
  );
}

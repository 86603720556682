import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import sandboxGenerateNegotiationJsonFile from "src/examples/responses/sandbox_generate_negotiation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SandboxResetBill({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("sandbox-reset-bill");
      if (hash.includes("sandbox-reset-bill")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "sandbox-reset-bill"}>
      <a href="/api-docs/sandbox-resources#sandbox-reset-bill">
        <h3 className="mb-8 text-xl font-medium">Sandbox reset bill</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"billId"}
              type={"ID"}
              description={
                <div className="space-y-4">
                  <div>The ID of the bill that needs to be reset.</div>
                  <div>
                    Resetting this bill will destroy all of its negotiations,
                    items, offers, and information requests. This is useful as a
                    quick way to get a bill into its original state as you build
                    and test your integration.
                  </div>
                </div>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The bill that has been reset.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, calculateEstimateExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   calculateEstimateExampleJsBody
              // ),
              curl: curlExample(testApiKey!, sandboxResetBillExampleCurlBody)
              // python: pythonExample(
              //   testApiKey!,
              //   calculateEstimateExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              sandboxGenerateNegotiationJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const sandboxResetBillExampleCurlBody = `
$'{
  "query": "mutation SandboxResetBill($billId:ID!){SandboxResetBill(input:{billId:$billId}){success errors bill{id}}}",
	"variables": {
		"billId": "bill_5484b35341280f05b76e085a6e822e47"
	}
`;

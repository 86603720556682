import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useSession from "src/hooks/useSession";
import { authenticate } from "src/utils/authentication";
import LoginForm2 from "./LoginForm2";

const LoginScreen: React.FC = () => {
  const history = useHistory();
  const { logIn } = useSession();
  const [errors, setErrors] = useState<string[]>([]);

  const checkCredentials = React.useCallback(
    async ({ email, password }) => {
      setErrors([]);
      const { partner_user, errors } = await authenticate(email, password);

      if (errors) {
        setErrors(errors);
        return;
      }

      logIn(partner_user!);

      history.push("/");
    },
    [history, logIn]
  );

  return <LoginForm2 onSubmit={checkCredentials} errors={errors} />;
};

export default LoginScreen;

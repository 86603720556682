const javascriptExample = (apiKey: string, body: string): string => {
  return `import Client from BillfixersPartnerClient;

client = new Client(
"${apiKey}"
);
${body}
`;
};

export default javascriptExample;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import setCustomerEligibleForNegotiationResponseJsonFile from "src/examples/responses/set_customer_eligible_for_negotiation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SetCustomerEligibleForNegotiation({
  className,
  id
}: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "set-eligible-for-negotiation"
      );
      if (hash.includes("set-eligible-for-negotiation")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/customers#set-eligible-for-negotiation">
        <h3 className="mb-8 text-xl font-medium">
          Set customer eligible for negotiation
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"customer_id"}
              type={"ID"}
              required
              description={
                <React.Fragment>
                  <div>The ID of the customer.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"eligible_for_negotiation"}
              type={"boolean"}
              required
              description={
                <React.Fragment>
                  <div className="space-y-4">
                    <p>
                      Whether or the customer is eligible to have their bills
                      negotiated.
                    </p>
                    <p>
                      Setting this to false will immediately delete any{" "}
                      <span className="font-semibold">pending</span>{" "}
                      negotiations. Any negotiations that have already been
                      started will not be deleted.
                    </p>
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The customer object, if found.</p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be false.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, findCustomerExampleRubyBody),
              // js: javascriptExample(testApiKey!, findCustomerExampleJsBody),
              curl: curlExample(
                testApiKey!,
                setCustomerEligibleForNegotiationCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              setCustomerEligibleForNegotiationResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const findCustomerExampleRubyBody = `
customer = client.find_customer('customer_12fasfa3187asd')
`;

const findCustomerExampleJsBody = `
const customer = await client.findCustomer(
  'customer_12fasfa3187asd'
)
`;

const setCustomerEligibleForNegotiationCurlBody = `
$'{
  "query": "mutation SetCustomerEligibleForNegotiation($customerId:ID! $eligibleForNegotiation:Boolean!){SetCustomerEligibleForNegotiation(input:{customerId:$customerId,eligibleForNegotiation:$eligibleForNegotiation}){success errors customer{id eligibleForNegotiation}}}",
	"variables": {
		"customerId": "customer_4fbe80f8d5a6f0d497b30fe57269479b",
		"eligibleForNegotiation": true
	}
`;

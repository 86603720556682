import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { ReactNode } from "react";
import { usePreferredLanguage } from "src/contexts/PreferredLanguageContext";
import Badge from "../Badge";
import ChildAttributes from "./ChildAttributes";
import PossibleFieldValues from "./PossibleFieldValues";

export type TFieldAttributes = {
  field: string;
  type: string;
  description: string | ReactNode | ReactNode[];
  possibleValues?: string[];
  defaultValue?: string;
  overridePreferredLanguage?: boolean; // use this to make the field not change case automatically and look exactly like what is provided to the field prop
};

interface IFieldAttributeProps extends TFieldAttributes {
  childAttributes?: TFieldAttributes[];
}

export default function FieldAttribute({
  field,
  type,
  description,
  childAttributes,
  possibleValues,
  defaultValue,
  overridePreferredLanguage = false
}: IFieldAttributeProps) {
  const { preferredLanguage } = usePreferredLanguage();

  let casedField = field;

  if (!overridePreferredLanguage) {
    switch (preferredLanguage) {
      case "ruby":
        casedField = snakeCase(casedField);
        break;
      case "javascript":
        casedField = camelCase(casedField);
        break;
      case "bash":
        casedField = camelCase(casedField);
        break;
      case "python":
        casedField = snakeCase(casedField);
        break;
      default:
        casedField = camelCase(casedField);
    }
  }

  return (
    <div className="my-4">
      <div className="flex flex-col">
        <div className="mb-2 flex items-center">
          <div className="mr-2 font-mono text-sm font-semibold">
            {casedField}
          </div>
          <Badge
            text={type}
            size="sm"
            className="mr-2 bg-gray-200 text-gray-800"
          />
          {defaultValue && (
            <div className="text-xs text-gray-800">
              default is {defaultValue}
            </div>
          )}
        </div>
        {/* {possibleValues && (
          <div className="flex items-center text-sm mt-2">
            <span className="mr-2">Accepted values:</span>
            <PossibleFieldValues values={possibleValues} />
          </div>
        )} */}
        <span className="mt-2 mb-2 text-sm">{description}</span>
        {possibleValues && <PossibleFieldValues values={possibleValues} />}
        {childAttributes && (
          <ChildAttributes attributes={childAttributes} canExpand={false} />
        )}
      </div>
    </div>
  );
}

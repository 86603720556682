import React, { useState } from "react";
import { host, protocol } from "src/config";
import CheckEmail from "./CheckEmail";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword: React.FC = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const resetPassword = React.useCallback(async values => {
    setErrors([]);
    const url = `${protocol}://${host}/api/partner_users/reset_password`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    });

    const result = await response.json();

    if (result.partner_user) {
      setFormSubmitted(true);
    } else if (result.errors) {
      setFormSubmitted(false);
      setErrors(result.errors);
    }
  }, []);

  if (formSubmitted) {
    return <CheckEmail />;
  }

  return <ResetPasswordForm onSubmit={resetPassword} errors={errors} />;
};

export default ResetPassword;

import { pick, startCase } from "lodash";
import React, { useState } from "react";
import DateAtTime from "src/components/DateAtTime";
import {
  IPartnerUserFragment,
  useUpdatePartnerUserMutation
} from "src/components/graphql";
import NewButton from "src/components/NewButton";
import SideSheet from "src/components/SideSheet";
import EditPartnerUserForm from "./EditPartnerUserForm";

interface IPartnerUserProps {
  user: IPartnerUserFragment;
  idx: number;
}

export default function PartnerUserRow({ user, idx }: IPartnerUserProps) {
  const [showEditPartnerUserForm, setShowEditPartnerUserForm] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [updatePartnerUserMutation] = useUpdatePartnerUserMutation();

  const updatePartnerUser = async (values: any) => {
    const result = await updatePartnerUserMutation({
      variables: {
        id: user.id,
        params: values
      },
      refetchQueries: ["FindPartner"]
    });

    if (result.data) {
      const errs = result.data.UpdatePartnerUser!.errors;
      if (errs.length) {
        setErrors(errs);
      } else {
        setShowEditPartnerUserForm(false);
      }
    }
  };

  return (
    <React.Fragment>
      <tr className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          {user.email}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          {startCase(user.role)}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
          {user.lastSignInAt ? (
            <DateAtTime input={user.lastSignInAt} />
          ) : (
            "Never signed in"
          )}
        </td>
        <td className="px-6 py-4 text-right text-sm text-gray-500">
          <NewButton
            size="xs"
            version="text"
            onClick={() => setShowEditPartnerUserForm(true)}
          >
            Edit
          </NewButton>
          <SideSheet
            open={showEditPartnerUserForm}
            onClose={() => setShowEditPartnerUserForm(false)}
          >
            <EditPartnerUserForm
              onSubmit={updatePartnerUser}
              initialValues={pick(user, "email", "role")}
              errors={errors}
            />
          </SideSheet>
        </td>
      </tr>
    </React.Fragment>
  );
}

import { Space, Stack } from "@cohubinc/blueprint";
import React from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import cancellationObjectJsonFile from "src/examples/cancellation.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import CreateCancellation from "./CreateCancellation";
import FindCancellation from "./FindCancellation";
import ListCancellations from "./ListCancellations";
import ProvideCancellationInfo from "./ProvideCancellationInfo";
import ProviderCancellationFields from "./ProviderCancellationFields";
import SetCancellationReadyToCancel from "./SetCancellationReadyToCancel";
import StopCancelling from "./StopCancelling";
import UpdateCancellation from "./UpdateCancellation";

export default function ApiDocsCancellations() {
  usePageTitle("Cancellations");

  return (
    <React.Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              A cancellation represents a request from a customer to cancel
              their service with a given provider. It also represents our
              attempt to cancel such service.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="cancellation">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a cancellation."
                }
              />
              <FieldAttribute
                field={"customer_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for the customer that a cancellation belongs to."
                }
              />
              <FieldAttribute
                field={"provider_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for the provider that a cancellation belongs to."
                }
              />
              <FieldAttribute
                field={"state"}
                type={"String"}
                description={
                  <React.Fragment>
                    <div>
                      Current state of the cancellation in the BillFixers
                      system. May be one of the following values:
                    </div>
                    <ul className="mt-2 space-y-3">
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"pending"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Default state for all cancellations
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"started"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">Cancellation is underway</div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"succeeded"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Cancellation was successful
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"awaiting_consent"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The customer needs to accept or reject a proposed
                          cancellation strategy. An cancellation offer will be
                          present when the cancellation is in this state.
                        </div>
                        <div className="text-xs">
                          For example, cancellations often come with early
                          termination fees or other strings attached. We won't
                          accept these requirements unless the customer has
                          given us explicit consent to do so.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"awaiting_information"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          The customer must provide more information before the
                          cancellation attempt can begin. A cancellation
                          information request will be present when the
                          cancellation is in this state.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"unable_to_cancel"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          For some reason, we were unable to cancel the service
                          with the provider.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"already_cancelled"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Service with the provider has already been cancelled.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"no_subscription_found"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          There is no subscription for the customer with the
                          given provider.
                        </div>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"payment_amount"}
                type={"Money"}
                defaultValue={"$0.00"}
                description={
                  <div className="space-y-2">
                    <p>
                      The amount of money that the customer typically pays for
                      their service.
                    </p>
                    <p>
                      <span className="text-red-500">*</span> This amount should
                      come from the customer's transaction/tradeline data for
                      any customer that has used PFM features as part of their
                      premium membership.
                    </p>
                    <p>
                      This amount is used to calculate the{" "}
                      <CodeSnippet bare inline code={"expected_savings"} />{" "}
                      field on the cancellation.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"expected_savings"}
                type={"Money"}
                defaultValue={"null"}
                description={
                  <div className="space-y-2">
                    <p>
                      The estimated amount of money a customer can expect to
                      save over the next 12 months as a result of cancelling
                      their service.
                    </p>
                    <p>
                      Note: This field will be null if the{" "}
                      <CodeSnippet bare inline code="payment_amount" /> field is
                      $0.00. It will also be null if the cancellation is not
                      successful.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"refund_amount"}
                type={"Money"}
                defaultValue={"$0.00"}
                description={
                  "The amount of money that the customer will receive as a refund for cancelling their service. We're not always able to get a refund, so this value can be $0.00"
                }
              />
              <FieldAttribute
                field={"last4_of_payment_method"}
                defaultValue="null"
                type={"String"}
                description={
                  "The last 4 digits of the payment method that the customer uses to pay for their service."
                }
              />
              <FieldAttribute
                defaultValue="null"
                field={"date_of_last_transaction"}
                type={"String"}
                description={
                  "The date of the last transaction that the customer made for their service."
                }
              />
              <FieldAttribute
                defaultValue="null"
                field={"raw_transaction_name"}
                type={"String"}
                description={
                  "The unprocessed/unrefined name of the transaction that this cancellation originated from, if available. For example, a charge from Netflix might appear in trasaction data as 'CHECKCARD 0219 NETFLIX 24692161050100485405864 RECURRING'."
                }
              />
              <FieldAttribute
                field={"cancellation_info"}
                type={"Money"}
                description={
                  <React.Fragment>
                    <p>An array of ProviderCancellationField objects.</p>
                    <p className="mt-4">
                      These fields are most often things like PIN, Service
                      Address, Account Holder Name, or Phone Number, but they
                      vary by provider.
                    </p>
                    <p className="mt-4">
                      In order to know what fields to send, you can either use
                      the <CodeSnippet inline bare code="findProvider" /> query
                      and check the{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="provider_cancellation_fields"
                      />
                      , or use the{" "}
                      <CodeSnippet bare inline code="findCancellation" /> query
                      and check its{" "}
                      <CodeSnippet bare inline code="cancellation_info" />.
                    </p>
                    <p className="mt-4">
                      The keys you send in your request should correspond with
                      the <CodeSnippet bare inline code="name" /> of a field in
                      the <CodeSnippet bare inline code="cancellation_info" />{" "}
                      JSON array.
                    </p>
                    <p className="mt-4">
                      Some keys are required, and if they're not sent in the
                      request, an error will be returned explaining which keys
                      must be sent in the request.
                    </p>
                    <p className="mt-4">
                      If a response has already been recorded for a particular
                      field, that field's{" "}
                      <CodeSnippet bare inline code="recorded_response" /> key
                      will contain the response. It will otherwise be null.
                    </p>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"succeeded_at"}
                type={"Date"}
                description={
                  "The date that the cancellation was successfully finished and the customer's service was cancelled."
                }
              />
              <FieldAttribute
                field={"awaited_consent_at"}
                type={"Date"}
                description={
                  "The date that we determined we needed to send a cancellation offer to the customer to get their consent."
                }
              />
              <FieldAttribute
                field={"awaited_information_at"}
                type={"Date"}
                description={
                  "The date that we determined we needed additional information from the customer in order to continue the cancellation process with their provider."
                }
              />
              <FieldAttribute
                field={"unable_to_cancel_at"}
                type={"Date"}
                description={
                  "The date that we determined we were unable to cancel the customer's service."
                }
              />
              <FieldAttribute
                field={"already_cancelled_at"}
                type={"Date"}
                description={
                  "The date that we determined the service was already cancelled with the customer's provider."
                }
              />
              <FieldAttribute
                field={"no_subscription_found_at"}
                type={"Date"}
                description={
                  "The date that we determined the service was already cancelled with the customer's provider."
                }
              />
              <FieldAttribute
                field={"customer"}
                type={"Customer"}
                description={
                  "The customer that owns/requested the cancellation."
                }
              />
              <FieldAttribute
                field={"provider"}
                type={"Provider"}
                description={
                  "The provider that the customer would like to cancel service with."
                }
              />
              <FieldAttribute
                field={"cancellationOffers"}
                type={"Array of CancellationOffer objects"}
                description={
                  "List of the line cancellation offers that exist on the cancellation."
                }
              />
              <FieldAttribute
                field={"cancellation_info_requests"}
                type={"Array of CancellationInfoRequest objects"}
                description={
                  "List of the cancellation info requests that exist on the cancellation."
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(cancellationObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <ProviderCancellationFields id="provider-cancellation-fields" />
      <Divider marginSize={6} className="bg-gray-300" />
      <CreateCancellation id="create-cancellation" />
      <Divider marginSize={6} className="bg-gray-300" />
      <ProvideCancellationInfo id="provide-cancellation-info" />
      <Divider marginSize={6} className="bg-gray-300" />
      <SetCancellationReadyToCancel id="set-cancellation-ready-to-cancel" />
      <Divider marginSize={6} className="bg-gray-300" />
      <UpdateCancellation id="update-cancellation" />
      <Divider marginSize={6} className="bg-gray-300" />
      <ListCancellations id="list-cancellations" />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindCancellation id="find-cancellation" />
      <Divider marginSize={6} className="bg-gray-300" />
      <StopCancelling id="stop-cancelling" />
    </React.Fragment>
  );
}

enum BaseColor {
  // Greens
  green500 = "hsla(164, 74%, 52%, 1)",
  green200 = "hsla(163, 75%, 91%, 1)",

  // Greys
  grey800 = "hsla(225, 13%, 18%, 1)",
  grey700 = "#7B7F89",
  grey600 = "hsla(225, 13%, 30%, 1)",
  grey400 = "hsla(223, 6%, 51%, 1)",
  grey300 = "hsla(222, 6%, 65%, 1)",
  grey200 = "hsla(225, 6%, 86%, 1)",

  // Blacks
  black200 = "hsl(0, 2%, 22%)",
  black500 = "hsl(0, 2%, 12%)",
  black800 = "hsl(0, 3%, 7%)",

  // Whites
  white700 = "rgba(244, 245, 247, 1)",
  white500 = "hsl(0, 0%, 100%)",

  // Reds
  red800 = "hsla(7, 95%, 33%, 1)",
  red500 = "hsla(7, 87%, 59%, 1)",
  red200 = "hsla(7, 86%, 92%, 1)",

  // Blues
  blue700 = "hsla(211, 100%, 50%, 1)",
  blue300 = "hsla(193, 100%, 79%, 1)",
  blue200 = "hsla(191, 100%, 96%, 1)",

  // Oranges
  orange500 = "hsla(35, 100%, 63%, 1)",
  orange200 = "hsla(36, 100%, 93%, 1)",

  // Transparent
  transparent = "hsla(0, 0%, 0%, 0%)"
}

enum Color {
  // Greens
  green500 = BaseColor.green500,
  green200 = BaseColor.green200,

  // Greys
  grey800 = BaseColor.grey800,
  grey600 = BaseColor.grey600,
  grey400 = BaseColor.grey400,
  grey300 = BaseColor.grey300,
  grey200 = BaseColor.grey200,

  // Blacks
  black200 = BaseColor.black200,
  black500 = BaseColor.black500,
  black800 = BaseColor.black800,

  // Whites
  white500 = BaseColor.white500,
  white700 = BaseColor.white700,

  // Reds
  red800 = BaseColor.red800,
  red500 = BaseColor.red500,
  red200 = BaseColor.red200,

  // Blues
  blue700 = BaseColor.blue700,
  blue300 = BaseColor.blue300,
  blue200 = BaseColor.blue200,

  // Oranges
  orange200 = BaseColor.orange200,
  orange500 = BaseColor.orange500,

  // Primaries
  primaryGreen = BaseColor.green500,
  primaryBlue = BaseColor.blue700,
  primaryRed = BaseColor.red500,
  primaryOrange = BaseColor.orange500,

  ///////// DEFAULT COLOR //////////
  primary = BaseColor.green500,
  trueWhite = BaseColor.white500,
  offWhite = BaseColor.white700,
  background = BaseColor.grey200,
  text = BaseColor.black800,
  lightText = BaseColor.grey400,
  link = BaseColor.blue700,
  lightBlack = BaseColor.black200,
  black = BaseColor.black500,
  darkBlack = BaseColor.black800,
  border = BaseColor.grey200,
  iconGrey = BaseColor.grey700,
  grey = BaseColor.grey600,
  transparent = BaseColor.transparent
}

export default Color;

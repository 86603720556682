import React, { useMemo } from "react";

interface IProps {
  message: string | React.ElementType;
  type: "success" | "error" | "alert" | "info";
  onDismiss?: () => void;
}

const Feedback: React.FC<IProps> = ({ type, message, onDismiss }) => {
  const icon = useMemo(() => iconForType(type), [type]);
  const clsNames = useMemo(() => classNames[type], [type]);

  return (
    <div className={`rounded-md p-4 ${clsNames.background}`}>
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${clsNames.message}`}>{message}</p>
        </div>
        {!!onDismiss && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${clsNames.dismiss}`}
              >
                <span className="sr-only">Dismiss</span>
                {dismissIcon}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;

function iconForType(type: string) {
  switch (type) {
    case "success": {
      // Heroicon name: check-circle
      return (
        <svg
          className="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      );
    }
    case "error": {
      // Heroicon name: check-circle
      return (
        <svg
          className="h-5 w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      );
    }
  }
}

const classNames = {
  success: {
    background: "bg-bf-green-100",
    message: "text-bf-green-700",
    dismiss:
      "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600"
  },
  error: {
    background: "bg-red-100",
    message: "text-red-700",
    dismiss: ""
  },
  alert: {
    background: "bg-bf-green-100",
    message: "text-bf-green-700",
    dismiss: ""
  },
  info: {
    background: "bg-bf-green-100",
    message: "text-bf-green-700",
    dismiss: ""
  }
};

// Heroicon name: x
const dismissIcon = (
  <svg
    className="h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

import { observable, action } from "mobx";

import { createContext } from "react";
import { IAuthenticatedPartnerUser } from "src/utils/authentication";
import { rotateToken } from "src/utils/rotateAuthToken";

class SessionStore {
  constructor() {
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const apiKey = localStorage.getItem("apiKey");
    const testApiKey = localStorage.getItem("testApiKey");
    this.loggedIn = !!(email && token);
    this.email = email;
    this.token = token;
    this.apiKey = apiKey;
    this.testApiKey = testApiKey;
  }

  @observable
  loggedIn: boolean;

  @observable
  email: string | null;

  @observable
  token: string | null;

  @observable
  apiKey: string | null;

  @observable
  testApiKey: string | null;

  @action
  logIn = (partnerUser: IAuthenticatedPartnerUser) => {
    localStorage.setItem("email", partnerUser.email);
    localStorage.setItem("token", partnerUser.authentication_token);
    localStorage.setItem("apiKey", partnerUser.api_key);
    localStorage.setItem("testApiKey", partnerUser.test_api_key);

    this.email = partnerUser!.email;
    this.token = partnerUser!.authentication_token;
    this.loggedIn = true;
    this.apiKey = partnerUser!.api_key;
    this.testApiKey = partnerUser!.test_api_key;
  };

  @action
  logOut = () => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      rotateToken(storedEmail);
    }
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("apiKey");
    localStorage.removeItem("testApiKey");
    this.loggedIn = false;
    this.token = null;
    this.email = null;
    this.apiKey = null;
    this.testApiKey = null;
  };
}

export const sessionStore = new SessionStore();
export const SessionStoreContext = createContext(sessionStore);

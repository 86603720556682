import { useState } from "react";
import Button from "src/components/Button";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import {
  IPartnerFragment,
  useRotateApiKeysMutation
} from "src/components/graphql";
import SideSheet from "src/components/SideSheet";
import { useTestMode } from "src/contexts/TestModeContext";
import useCurrentUser from "src/hooks/useCurrentUser";

interface IProps {
  partner: IPartnerFragment;
}

export default function ApiKeys({ partner }: IProps) {
  const { currentUser } = useCurrentUser();
  const { testMode } = useTestMode();
  const [showRotateKeysModal, setShowRotateKeysModal] = useState(false);
  const [confirmRotation, setConfirmRotation] = useState(false);

  const [rotateKeysMutation] = useRotateApiKeysMutation();

  const rotateKeys = async () => {
    const result = await rotateKeysMutation({
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.RotateApiKeys?.success) {
      setShowRotateKeysModal(false);
    }
  };

  return (
    <div className="mt-10 divide-y divide-gray-200">
      <div className="mb-6 space-y-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          API Keys
        </h3>
        <p className="text-sm text-gray-600">
          You must keep your live API key confidential and only store them on
          your own servers. You must not share your live API key with any third
          parties.{" "}
          <span className="font-semibold">
            Your account’s live API key can perform any API request to
            BillFixers without restriction.
          </span>
        </p>
        <p className="text-sm text-gray-600">
          If we believe that your live API key has been compromised, we may
          cancel and reissue it, potentially resulting in an interruption to any
          services that rely on your BillFixers integration.
        </p>
        <p className="text-sm text-gray-600">
          If you believe that your live API key has been compromised, you should
          rotate it using the button below. There is no limit or restriction on
          the number of times you can rotate your keys.
        </p>
        <div className="flex justify-between">
          <Button
            size="xs"
            version="danger"
            onClick={() => setShowRotateKeysModal(true)}
            disabled={testMode || currentUser?.role !== "manager"}
          >
            Rotate API Keys
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Test API Key</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <CodeSnippet code={partner.testApiKey} copy />
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Live API Key</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <CodeSnippet code={partner.apiKey} copy />
            </dd>
          </div>
        </dl>
      </div>
      <SideSheet
        title="Rotate API Keys"
        open={showRotateKeysModal}
        onClose={() => setShowRotateKeysModal(false)}
      >
        <div className="space-y-4">
          <p className="text-sm text-gray-600">
            Rotating your API keys may result in temporary downtime for your
            BillFixers integration. You will not be able to authenticate any API
            requests until your new keys are used in your requests.
          </p>
          <p className="text-sm font-semibold text-gray-600">
            Rotating your keys cannot be undone.
          </p>
          <p className="text-sm text-gray-600">
            Please confirm that you'd like to rotate your API keys by typing
            "ROTATE" in the input below.
          </p>
          <input
            onChange={(evt) => {
              if (evt.target.value === "ROTATE") {
                return setConfirmRotation(true);
              } else {
                setConfirmRotation(false);
              }
            }}
            className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            placeholder=""
          />
        </div>
        <Divider />
        <Button
          version="danger"
          disabled={!confirmRotation}
          onClick={rotateKeys}
          size="sm"
        >
          Rotate
        </Button>
      </SideSheet>
    </div>
  );
}

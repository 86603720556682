import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import listBillsObjectJsonFile from "src/examples/responses/list_bills.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListBills({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const listBillsElement = window.document.getElementById("list-bills");
      if (hash.includes("list-bills")) {
        listBillsElement?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#list-bills">
        <h3 className="mb-8 text-xl font-medium">List bills</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"limit"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>
                    The number of bills you'd like returned by this request.
                  </div>
                </React.Fragment>
              }
              defaultValue={"25"}
            />
            <Parameter
              field={"offset"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>The number of bills to skip.</div>
                </React.Fragment>
              }
              defaultValue={"0"}
            />
            <Parameter
              field={"customer_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include bills owned by
                    the customer associated with the given ID.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"missing_document_info"}
              type={"Boolean"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include bills that do
                    not have a valid document or are missing sufficient
                    documentless information.
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The total numbers of bills that matched the given parameters and
              an array of those bills.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, listBillsExampleRubyBody),
              js: javascriptExample(testApiKey!, listBillsExampleJsBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(listBillsObjectJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const listBillsExampleRubyBody = `
bills = client.list_bills(
  limit: 25,
  offset: 0,
  customer_id: nil
)
`;

const listBillsExampleJsBody = `
const { totalCount, bills } = await client.listBills(
  {
    limit: 25,
    offset: 0,
    customerId: null
  }
);
`;

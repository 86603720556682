import React from "react";
import { Field, Form } from "react-final-form";
import Select from "react-select";
import Divider from "src/components/Divider";
import Errors from "src/components/Errors";
import NewButton from "src/components/NewButton";

interface IProps {
  initialValues?: any;
  errors: string[] | undefined;
  onSubmit: (values: any) => void;
}

export default function WebhookForm({
  initialValues,
  errors,
  onSubmit
}: IProps) {
  const eventsOptions = [
    { label: "all_events", value: "all_events" },
    { label: "bill.customer_ineligible", value: "bill.customer_ineligible" },
    { label: "bill.negotiation_started", value: "bill.negotiation_started" },
    {
      label: "bill.negotiation_completed",
      value: "bill.negotiation_completed"
    },
    { label: "bill.negotiation_failed", value: "bill.negotiation_failed" },
    {
      label: "bill.negotiation_scheduled",
      value: "bill.negotiation_scheduled"
    },
    { label: "bill.savings_updated", value: "bill.savings_updated" },
    { label: "bill.autofix_upcoming", value: "bill.autofix_upcoming" },
    { label: "bill.outside_of_scope", value: "bill.outside_of_scope" },
    { label: "bill.bad_document", value: "bill.bad_document" },
    { label: "bill.duplicate_discarded", value: "bill.duplicate_discarded" },
    { label: "bill.savings_expiring", value: "bill.savings_expiring" },
    { label: "bill.savings_expired", value: "bill.savings_expired" },
    {
      label: "bill.provider_account_closed",
      value: "bill.provider_account_closed"
    },
    { label: "bill.stopped", value: "bill.stopped" },
    { label: "customer.updated", value: "customer.updated" },
    {
      label: "information_request.created",
      value: "information_request.created"
    },
    { label: "offer.created", value: "offer.created" },
    { label: "offer.accepted", value: "offer.accepted" },
    { label: "offer.rejected", value: "offer.rejected" },
    { label: "cancellation.started", value: "cancellation.started" },
    { label: "cancellation.succeeded", value: "cancellation.succeeded" },
    {
      label: "cancellation.already_cancelled",
      value: "cancellation.already_cancelled"
    },
    {
      label: "cancellation.unable_to_cancel",
      value: "cancellation.unable_to_cancel"
    },
    {
      label: "cancellation.no_subscription_found",
      value: "cancellation.no_subscription_found"
    },
    {
      label: "cancellation_info_request.created",
      value: "cancellation_info_request.created"
    },
    {
      label: "cancellation_offer.created",
      value: "cancellation_offer.created"
    },
    {
      label: "cancellation_offer.accepted",
      value: "cancellation_offer.accepted"
    },
    {
      label: "cancellation_offer.rejected",
      value: "cancellation_offer.rejected"
    },
    {
      value: "provider.updated",
      label: "provider.updated"
    }
  ];

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ form, handleSubmit, submitting, pristine, invalid }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            {errors && <Errors errors={errors} className="mb-8" />}
            <div className="space-y-6">
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="url"
                >
                  URL
                </label>
                <Field
                  name="url"
                  type="text"
                  render={({ input }) => (
                    <React.Fragment>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                          https://
                        </span>
                        <input
                          {...input}
                          required
                          placeholder="webhook.test.com/receive"
                          autoComplete="off"
                          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        This is the URL/endpoint that the webhook should post
                        event data to.
                      </p>
                    </React.Fragment>
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="oauthUrl"
                >
                  OAuth URL
                </label>
                <Field
                  name="oauthUrl"
                  type="text"
                  render={({ input }) => (
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                        https://
                      </span>
                      <input
                        {...input}
                        required
                        placeholder="webhook.test.com/oauth"
                        autoComplete="off"
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="webhookEnvironment"
                >
                  Environment
                </label>
                <Field
                  name="webhookEnvironment"
                  type="text"
                  render={({ input }) => (
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        {...input}
                        required
                        placeholder="Development"
                        autoComplete="off"
                        className="block w-full min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="clientId"
                >
                  Client ID
                </label>
                <Field
                  name="clientId"
                  type="text"
                  render={({ input }) => (
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        {...input}
                        required
                        placeholder=""
                        autoComplete="off"
                        className="block w-full min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="clientSecret"
                >
                  Client Secret
                </label>
                <Field
                  name="clientSecret"
                  type="text"
                  render={({ input }) => (
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        {...input}
                        required
                        placeholder=""
                        autoComplete="off"
                        className="block w-full min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  )}
                />
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="webhookType"
                >
                  Webhook Type
                </label>
                <Field
                  name="webhookType"
                  render={({ input }) => (
                    <select
                      id={"webhookType"}
                      {...input}
                      autoComplete="off"
                      className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="test_webhook">Test</option>
                      <option value="live_webhook">Live</option>
                    </select>
                  )}
                />
                <p className="mt-2 text-sm text-gray-500">
                  The type of the webhook determines which environment it will
                  be sent in. Test webhooks are sent when events occur in the
                  BillFixers test/sandbox environment, and live webhooks are
                  sent in the BillFixers production environment.
                </p>
              </div>
              <div>
                <label
                  className="mb-2 block text-sm text-gray-700"
                  htmlFor="events"
                >
                  Events
                </label>
                <Field
                  name="events"
                  render={({ input, meta }) => {
                    let value = eventsOptions.filter((o: any) =>
                      input.value.includes(o.value)
                    );
                    return (
                      <Select
                        isMulti
                        name="events"
                        options={eventsOptions}
                        value={value}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        onChange={(values: any) => {
                          const keys = values.map((v: any) => v.value);
                          if (keys.includes("all_events")) {
                            input.onChange(["all_events"]);
                          } else {
                            input.onChange(
                              (values || []).map((v: any) => v.value)
                            );
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <Divider marginSize={1} />
            <NewButton
              fullWidth
              size="sm"
              type="submit"
              loading={submitting}
              disabled={submitting || pristine || invalid}
            >
              Save
            </NewButton>
          </form>
        );
      }}
    />
  );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import listBillsObjectJsonFile from "src/examples/responses/list_bills.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListCancellations({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const listcancellationsElement =
        window.document.getElementById("list-cancellations");
      if (hash.includes("list-cancellations")) {
        listcancellationsElement?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/cancellations#list-cancellations">
        <h3 className="mb-8 text-xl font-medium">List cancellations</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"limit"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>
                    The number of cancellations you'd like returned by this
                    request.
                  </div>
                </React.Fragment>
              }
              defaultValue={"25"}
            />
            <Parameter
              field={"offset"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>The number of cancellations to skip.</div>
                </React.Fragment>
              }
              defaultValue={"0"}
            />
            <Parameter
              field={"customer_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include cancellations
                    owned by the customer associated with the given ID.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"state"}
              type={"String"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include cancellations in
                    the specified state.
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The total numbers of cancellations that matched the given
              parameters and an array of those cancellations.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(testApiKey!, listCancellationsExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(listBillsObjectJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const listCancellationsExampleCurlBody = `$'{
  "query": "query ListCancellations($customerId:ID, $state:String){ListCancellations(customerId:$customerId, state:$state){totalCount nodes{id customerId providerId}}}"
  "variables": {
    "customerId": "customer_d8fb9e40650351069573001a8184845c",
    "state": "pending"
  }
`;

import debounce from "lodash/debounce";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import Badge from "src/components/Badge";
import CodeSnippet from "src/components/CodeSnippet";
import DateAtTime from "src/components/DateAtTime";
import { useListBillsQuery } from "src/components/graphql";
import Money from "src/components/Money";
import NewContainer from "src/components/NewContainer";
import Paginator from "src/components/Paginator";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";

const Bills: React.FC = () => {
  usePageTitle("Bills");
  const [status, setStatus] = useState<string | undefined>("");
  const [apiId, setApiId] = useState<string | undefined>("");
  const [missingDocumentInfo, setMissingDocumentInfo] = useState(false);
  const { page } = queryString.parse(window.location.search);
  const perPage = 25;
  const { loading, data } = useListBillsQuery({
    variables: {
      offset: perPage * (parseInt(page as string) - 1 || 0),
      limit: perPage,
      status: status,
      missingDocumentInfo,
      apiId
    }
  });

  const changeHandler = (event: any) => {
    setApiId(event.target.value);
  };

  const debouncedSetApiId = useMemo(() => debounce(changeHandler, 500), []);

  if (!data) {
    <NewContainer>
      <div className="mt-8 flex justify-center">
        No bills found. Use the API's{" "}
        <CodeSnippet bare inline code="createBill" /> mutation to create some.
      </div>
    </NewContainer>;
  }

  const bills = data?.ListBills.nodes;
  const totalCount = data?.ListBills.totalCount;

  return (
    <NewContainer>
      <h1 className="text-2xl font-medium text-gray-800">Bills</h1>
      <h5 className="text-xs text-gray-500">
        <NumberFormat value={totalCount} thousandSeparator displayType="text" />{" "}
        total bills
      </h5>
      <div className="mt-4 flex items-center space-x-4">
        <div>
          <label
            htmlFor="Status"
            className="block text-sm font-medium text-gray-700"
          >
            Status
          </label>
          <div className="mt-1">
            <select
              id="status"
              name="status"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={status}
              onChange={(evt) => {
                if (evt.target.value === "Any") {
                  setStatus(undefined);
                } else {
                  setStatus(evt.target.value);
                }
              }}
            >
              <option>Any</option>
              <option>Pending</option>
              <option>In Progress</option>
              <option>Completed</option>
              <option>Failed</option>
              <option>Needs Additional Information</option>
              <option>Needs Your Consent</option>
              <option>Cancelled</option>
              <option>Outside of Scope</option>
              <option>Savings Expired</option>
              <option>Savings Expiring</option>
            </select>
          </div>
        </div>
        <div className="w-80">
          <label
            htmlFor="apiId"
            className="block cursor-pointer text-sm font-medium text-gray-700"
          >
            API ID
          </label>
          <input
            type="text"
            name={"apiId"}
            id={"apiId"}
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={debouncedSetApiId}
            disabled={loading}
          />
        </div>
        <div>
          <label
            htmlFor="missingDocumentInfo"
            className="block cursor-pointer text-sm font-medium text-gray-700"
          >
            Missing document info
          </label>
          <input
            type="checkbox"
            name={"missingDocumentInfo"}
            id={"missingDocumentInfo"}
            className="mr-2 mt-1 cursor-pointer accent-indigo-400"
            checked={missingDocumentInfo}
            onChange={(evt) => setMissingDocumentInfo(evt.target.checked)}
            disabled={loading}
          />
        </div>
      </div>
      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Provider and ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Created Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Savings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && !data && (
                    <tr className="my-2">
                      <td colSpan={5}>
                        <AltLoader />
                      </td>
                    </tr>
                  )}
                  {bills?.map((b, idx) => (
                    <tr
                      key={b.id}
                      className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        <Link to={paths.bill(b.id)} className="cursor-pointer">
                          {b.provider.name}
                          <br />
                          <span className="text-xs text-blue-500">{b.id}</span>
                        </Link>
                        {b.missingDocumentInfo && (
                          <React.Fragment>
                            <br />
                            <Badge
                              text="Missing document info"
                              version="danger"
                              size="xs"
                            />
                          </React.Fragment>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {b.customer.name}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        {b.status}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        <DateAtTime input={b.createdAt} />
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                        <Money amount={b.totalSavings} />
                      </td>
                    </tr>
                  ))}
                  {totalCount === 0 && (
                    <tr className="my-2 bg-white">
                      <td colSpan={5}>
                        <div className="my-2 flex justify-center text-center">
                          No bills found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {totalCount && totalCount > perPage ? (
        <div className="flex justify-center">
          <div className="mt-4 w-1/4 rounded-lg bg-white p-3 shadow-md">
            <Paginator totalCount={totalCount || 0} perPage={perPage} />
          </div>
        </div>
      ) : null}
    </NewContainer>
  );
};

export default Bills;

import React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import DateAtTime from "src/components/DateAtTime";
import Detail from "src/components/Detail";
import DocLink from "src/components/DocLink";
import { useFindCustomerQuery } from "src/components/graphql";
import Money from "src/components/Money";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import Typography from "src/components/Typography";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import CustomerBills from "./CustomerBills";

const Customer: React.FC<RouteComponentProps> = ({ match }) => {
  const { customerId } = match.params as any;
  const { loading, data } = useFindCustomerQuery({ variables: { customerId } });
  const customer = data?.customer;
  usePageTitle(customer?.name || "");

  if (loading || !customer) {
    return <AltLoader />;
  }

  return (
    <NewContainer>
      <Helmet>
        <title>Customer - {customerId}</title>
      </Helmet>
      <div className="grid grid-cols-8 gap-8">
        <div className="col-span-3">
          <Segment>
            <div className="mb-6">
              <Typography.Large block>{customer.name}</Typography.Large>
              <Typography.Small muted>{customer.id}</Typography.Small>
              <Typography.Small muted block>
                External ID: {customer.externalId || "None"}
              </Typography.Small>
            </div>
            <div className="mb-6 grid grid-cols-3 grid-rows-1 gap-4">
              <Detail heading="Bills" body={customer.billCount} />
              <Detail
                heading="All time savings"
                body={<Money amount={customer.allTimeGrossSavings} />}
              />
            </div>
            <Detail
              className="mb-2 break-words"
              heading="Email"
              body={customer.email || "None"}
            />
            <Detail
              className="mb-2"
              heading="Phone"
              body={customer.phoneNumber || "None"}
            />
            <Detail
              className="mb-2"
              heading="Created on"
              body={<DateAtTime input={customer.createdAt} />}
            />
            {/* <Detail
              className="mb-2"
              heading="Allows contracts"
              body={bill.allowsContract ? "On" : "Off"}
            />
            <Detail
              className="mb-2"
              heading="Autofix"
              body={bill.autoRenegotiate ? "On" : "Off"}
            /> */}
          </Segment>
        </div>
        <div className="col-span-5">
          <div className="flex justify-end" />
          {/* <div className="my-4"> */}
          <div className="mt-12 mb-4">
            <div className="flex items-center">
              <h4 className="mb-1 text-gray-600">Bills</h4>
              <DocLink to={paths.apiDocs.bills} />
            </div>
            <p className="mb-3 text-xs text-gray-700">
              Bills are what we negotiate for customers. They'll always belong
              to a customer and a provider.
            </p>
            <CustomerBills customerId={customerId} />
          </div>
        </div>
      </div>
    </NewContainer>
  );
};

export default Customer;

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  from
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { host, protocol } from "src/config";
import { sessionStore } from "src/stores/SessionStore";

const fetcher = (...args: any) => {
  return (window as any).fetch(...args);
};

const httpLink = () => {
  // If we're in testMode, point to the sandbox URL
  if (
    localStorage.getItem("testMode") === "true" &&
    process.env.NODE_ENV === "production"
  ) {
    return createHttpLink({
      uri: `https://sandbox.billfixers.com/partner/graphql`,
      fetch: fetcher
    });
  } else {
    return createHttpLink({
      uri: `${protocol}://${host}/partner/graphql`,
      fetch: fetcher
    });
  }
};

const authLink = setContext((_: any, { headers }: any) => {
  return {
    headers: {
      ...headers,
      "X-PartnerUser-Email": sessionStore.email,
      "X-PartnerUser-Token": sessionStore.token
    }
  };
});

const afterwareLink = onError(({ networkError }: any) => {
  if (networkError?.statusCode === 401) {
    sessionStore.logOut();
  } else {
    console.warn(networkError);
  }
});

const authFlowLink = authLink.concat(afterwareLink);

const link = ApolloLink.from([authFlowLink, httpLink()]);

const client = new ApolloClient({
  link: from([authLink, afterwareLink, link]),
  cache: new InMemoryCache()
});

export default client;

import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout2";
import useSession from "./hooks/useSession";
import ApiDocs from "./screens/ApiDocs";
import ApiAuthentication from "./screens/ApiDocs/ApiAuthentication";
import ApiDocsBills from "./screens/ApiDocs/Bills";
import ApiDocsCancellationInfoRequests from "./screens/ApiDocs/CancellationInfoRequests";
import ApiDocsCancellationOffers from "./screens/ApiDocs/CancellationOffers";
import ApiDocsCancellations from "./screens/ApiDocs/Cancellations";
import ApiDocsCustomers from "./screens/ApiDocs/Customers";
import ApiDocsInfoReqs from "./screens/ApiDocs/InformationRequests";
import ApiDocsItems from "./screens/ApiDocs/Items";
import ApiDocsNegotiations from "./screens/ApiDocs/Negotiations";
import ApiDocsOffers from "./screens/ApiDocs/Offers";
import ApiDocsProviders from "./screens/ApiDocs/Providers";
import SandboxResources from "./screens/ApiDocs/SandboxResources/SandboxResources";
import ApiDocsSavingsEstimate from "./screens/ApiDocs/SavingsEstimate";
import ApiDocsSavingsEvent from "./screens/ApiDocs/SavingsEvent";
import Bill from "./screens/Bill";
import Bills from "./screens/Bills";
import Customer from "./screens/Customer";
import Customers from "./screens/Customers";
import Dashboard from "./screens/Dashboard";
import CreatingBills from "./screens/Implementation/CreatingBills";
import CreatingCustomers from "./screens/Implementation/CreatingCustomers";
import ImplementationGuide from "./screens/Implementation/ImplementationGuide";
import InterpretingWebhooks from "./screens/Implementation/InterpretingWebhooks";
import RespondingToInfoRequests from "./screens/Implementation/RespondingToInfoRequests";
import RespondingToOffers from "./screens/Implementation/RespondingToOffers";
import JavascriptLibrary from "./screens/Libraries/Javascript";
import RubyLibrary from "./screens/Libraries/Ruby";
import Login from "./screens/Login";
import NewPassword from "./screens/NewPassword";
import RateLimits from "./screens/RateLimits";
import ResetPassword from "./screens/ResetPassword";
import Settings from "./screens/Settings";
import WebhookEvents from "./screens/WebhookEvents/WebhookEvents";
import Webhooks from "./screens/Webhooks";

const Routes: React.FC = () => {
  const { loggedIn } = useSession();

  if (!loggedIn) {
    return (
      <Switch>
        <Route path="/reset-password" render={() => <ResetPassword />} />
        <Route path="/new-password" render={() => <NewPassword />} />
        <Route path="*" render={() => <Login />} />
      </Switch>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route path="/reset-password" render={() => <ResetPassword />} />
        <Route
          path="/api-docs/libraries/javascript"
          render={() => <JavascriptLibrary />}
        />
        <Route path="/api-docs/libraries/ruby" render={() => <RubyLibrary />} />
        <Route path="/api-docs/bills" render={() => <ApiDocsBills />} />
        <Route
          path="/api-docs/negotiations"
          render={() => <ApiDocsNegotiations />}
        />
        <Route path="/api-docs/items" render={() => <ApiDocsItems />} />
        <Route path="/api-docs/customers" render={() => <ApiDocsCustomers />} />
        <Route path="/api-docs/providers" render={() => <ApiDocsProviders />} />
        <Route path="/api-docs/items" render={() => <ApiDocsItems />} />
        <Route path="/api-docs/offers" render={() => <ApiDocsOffers />} />
        <Route path="/api-docs/info-reqs" render={() => <ApiDocsInfoReqs />} />
        <Route
          path="/api-docs/cancellations"
          render={() => <ApiDocsCancellations />}
        />
        <Route
          path="/api-docs/cancellation-info-requests"
          render={() => <ApiDocsCancellationInfoRequests />}
        />
        <Route
          path="/api-docs/cancellation-offers"
          render={() => <ApiDocsCancellationOffers />}
        />
        <Route
          path="/api-docs/savings-estimate"
          render={() => <ApiDocsSavingsEstimate />}
        />
        <Route
          path="/api-docs/savings-event"
          render={() => <ApiDocsSavingsEvent />}
        />
        <Route path="/api-docs/rate-limits" render={() => <RateLimits />} />
        <Route path="/api-docs/webhooks" render={() => <Webhooks />} />
        <Route
          path="/api-docs/authentication"
          render={() => <ApiAuthentication />}
        />
        <Route
          path="/api-docs/sandbox-resources"
          render={() => <SandboxResources />}
        />
        {/* IMPLEMENTATION GUIDE */}
        <Route
          path="/api-docs/implementation/creating-customers"
          render={() => <CreatingCustomers />}
        />
        <Route
          path="/api-docs/implementation/creating-bills"
          render={() => <CreatingBills />}
        />
        <Route
          path="/api-docs/implementation/responding-to-info-requests"
          render={() => <RespondingToInfoRequests />}
        />
        <Route
          path="/api-docs/implementation/responding-to-offers"
          render={() => <RespondingToOffers />}
        />
        <Route
          path="/api-docs/implementation/interpreting-webhooks"
          render={() => <InterpretingWebhooks />}
        />
        <Route
          path={["/api-docs/implementation", "/implementation-guide"]}
          render={() => <ImplementationGuide />}
        />
        <Route path="/api-docs" render={() => <ApiDocs />} />
        <Route
          path="/bills/:billId(bill_.+)"
          render={(props) => <Bill {...props} />}
        />
        <Route path="/bills" render={() => <Bills />} />
        <Route path="/webhook-events" render={() => <WebhookEvents />} />
        <Route
          path="/customers/:customerId(customer_.+)"
          render={(props) => <Customer {...props} />}
        />
        <Route path="/customers" render={() => <Customers />} />
        <Route path="/settings" render={() => <Settings />} />
        <Route path="/">
          {/* <Introduction /> */}
          <Dashboard />
        </Route>
        {/* <Route path="/" render={() => <Dashboard />} /> */}
      </Switch>
    </Layout>
  );
};

export default observer(Routes);

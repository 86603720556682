import { TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import NewButton from "src/components/NewButton";
import SideSheet from "src/components/SideSheet";
import {
  IPartnerFragment,
  useCreatePartnerWebhookMutation,
  useDeletePartnerWebhookMutation
} from "src/components/graphql";
import { useTestMode } from "src/contexts/TestModeContext";
import useCurrentUser from "src/hooks/useCurrentUser";
import EditWebhook from "./EditWebhook";
import WebhookForm from "./WebhookForm";

interface IProps {
  partner: IPartnerFragment;
}

export default function Webhooks({ partner }: IProps) {
  const { currentUser } = useCurrentUser();
  const { testMode } = useTestMode();
  const [showWebhookForm, setShowWebhookForm] = useState(false);
  const [webhookFormErrors, setWebhookFormErrors] = useState<
    string[] | undefined
  >();

  const [createWebhookMutation] = useCreatePartnerWebhookMutation();

  const [deleteWebhookMutation] = useDeletePartnerWebhookMutation();

  const createWebhook = async (values: any) => {
    let events = values.events;
    if (values.events.includes("all_events")) {
      events = [
        "bill.customer_ineligible",
        "bill.negotiation_started",
        "bill.negotiation_completed",
        "bill.negotiation_failed",
        "bill.negotiation_scheduled",
        "bill.savings_updated",
        "bill.autofix_upcoming",
        "bill.outside_of_scope",
        "bill.bad_document",
        "bill.duplicate_discarded",
        "bill.savings_expiring",
        "bill.savings_expired",
        "bill.provider_account_closed",
        "bill.stopped",
        "customer.updated",
        "information_request.created",
        "offer.created",
        "offer.accepted",
        "offer.rejected",
        "cancellation.started",
        "cancellation.succeeded",
        "cancellation.already_cancelled",
        "cancellation.unable_to_cancel",
        "cancellation.no_subscription_found",
        "cancellation_info_request.created",
        "cancellation_offer.created",
        "cancellation_offer.accepted",
        "cancellation_offer.rejected",
        "provider.updated"
      ];
    }

    const result = await createWebhookMutation({
      variables: {
        webhook: {
          url: values.url,
          webhookType: values.webhookType,
          events: events,
          oauthUrl: values.oauthUrl,
          webhookEnvironment: values.webhookEnvironment,
          clientId: values.clientId,
          clientSecret: values.clientSecret
        }
      },
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.CreatePartnerWebhook?.success) {
      setShowWebhookForm(false);
      setWebhookFormErrors(undefined);
    } else if (result.data?.CreatePartnerWebhook?.errors) {
      setWebhookFormErrors(result.data?.CreatePartnerWebhook?.errors);
    }
  };

  const deleteWebhook = async (webhookId: string) => {
    const result = await deleteWebhookMutation({
      variables: {
        webhookId: webhookId
      },
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.DeletePartnerWebhook?.success) {
      console.log("something");
    }
  };

  return (
    <div className="mt-10">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Webhooks
          </h3>
          <p className="text-sm text-gray-500">
            A webhook must contain a URL, the type of webhook, and a list of
            events that should trigger the webhook.
          </p>
        </div>
        {currentUser?.role === "manager" && (
          <NewButton
            size="sm"
            onClick={() => setShowWebhookForm(true)}
            disabled={testMode}
          >
            Add Webhook
          </NewButton>
        )}
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      URL
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Events
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {partner.webhooks.map((hook) => (
                    <tr key={hook.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div>{hook.url}</div>
                        {hook.oauthUrl && (
                          <div className="text-xs text-gray-400">
                            OAuth URL: {hook.oauthUrl}
                          </div>
                        )}
                        {hook.webhookEnvironment && (
                          <div className="text-xs text-gray-400">
                            Environment: {hook.webhookEnvironment}
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {hook.webhookType}
                      </td>
                      <td className="px-3 py-4 text-xs text-gray-500">
                        {hook.events.join(", ")}
                      </td>
                      <td className="">
                        <div className="mr-2 flex items-center justify-end space-x-2">
                          {currentUser?.role === "manager" && (
                            <React.Fragment>
                              <EditWebhook webhook={hook} />
                              <TrashIcon
                                onClick={() => deleteWebhook(hook.id)}
                                className={`h-5 w-5 cursor-pointer text-red-500 hover:text-red-400 ${
                                  testMode
                                    ? "pointer-events-none text-red-200"
                                    : ""
                                }`}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SideSheet
        open={showWebhookForm}
        onClose={() => setShowWebhookForm(false)}
      >
        <WebhookForm
          initialValues={{ webhookType: "test_webhook" }}
          errors={webhookFormErrors}
          onSubmit={createWebhook}
        />
      </SideSheet>
    </div>
  );
}

import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import Button from "src/components/Button";
import Divider from "src/components/Divider";
import { useSandboxGenerateNegotiationMutation } from "src/components/graphql";

type TTestItemType = "Discount" | "Otc" | "PlanChange";

type TTestItem = {
  type: TTestItemType;
  quantity: number;
};

interface IProps {
  billId: string;
  onSuccess: () => void;
}

export default function GenerateNegotiation({ billId, onSuccess }: IProps) {
  const [testDiscounts, setTestDiscounts] = useState<TTestItem>({
    type: "Discount",
    quantity: 0
  });
  const [testOtcs, setTestOtcs] = useState<TTestItem>({
    type: "Otc",
    quantity: 0
  });
  const [testPlanChanges, setTestPlanChanges] = useState<TTestItem>({
    type: "PlanChange",
    quantity: 0
  });

  const [
    sandboxGenerateNegotiationMutation
  ] = useSandboxGenerateNegotiationMutation({
    variables: {
      billId: billId
      // discountQuantity: testDiscounts.quantity,
      // otcQuantity: testOtcs.quantity,
      // planChangeQuantity: testPlanChanges.quantity
    },
    refetchQueries: ["FindBill"]
  });

  const generateNeg = async () => {
    const result = await sandboxGenerateNegotiationMutation();

    if (result.data?.SandboxGenerateNegotiation?.success) {
      onSuccess();
    }
  };

  const humanReadableItem = (testItem: TTestItem) => {
    switch (testItem.type) {
      case "Discount":
        return { name: "Discount", description: "A discount is something" };
      case "Otc":
        return {
          name: "One time credit",
          description: "A one time credit (often called an 'OTC') is something"
        };
      case "PlanChange":
        return {
          name: "Plan change",
          description: "A plan change is something"
        };
      default:
        break;
    }
  };

  const resetAllItems = () => {
    setTestDiscounts({ type: "Discount", quantity: 0 });
    setTestOtcs({ type: "Otc", quantity: 0 });
    setTestPlanChanges({ type: "PlanChange", quantity: 0 });
  };

  const addTestItem = (type: TTestItemType) => {
    switch (type) {
      case "Discount":
        return setTestDiscounts({
          type: "Discount",
          quantity: (testDiscounts.quantity += 1)
        });
      case "Otc":
        return setTestOtcs({
          type: "Otc",
          quantity: (testOtcs.quantity += 1)
        });
      case "PlanChange":
        return setTestPlanChanges({
          type: "PlanChange",
          quantity: (testPlanChanges.quantity += 1)
        });
    }
  };

  const removeTestItem = (type: TTestItemType) => {
    switch (type) {
      case "Discount":
        if (testDiscounts.quantity > 0) {
          return setTestDiscounts({
            type: "Discount",
            quantity: (testDiscounts.quantity -= 1)
          });
        }
        break;
      case "Otc":
        if (testOtcs.quantity > 0) {
          return setTestOtcs({
            type: "Otc",
            quantity: (testOtcs.quantity -= 1)
          });
        }
        break;
      case "PlanChange":
        if (testPlanChanges.quantity > 0) {
          return setTestPlanChanges({
            type: "PlanChange",
            quantity: (testPlanChanges.quantity -= 1)
          });
        }
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <p className="text-sm">
          Because we don't negotiate any test bills, you'll want to generate
          your own negotiations while in test mode. Doing so will ensure that
          your integration is covering all the bases and will allow you to
          better understand the API and how our negotiation process works.
        </p>
        <Divider marginSize={1} />
        {/* <Well className="mb-6 bg-gray-100" sunken>
          <div className="flex justify-between">
            <div className="text-sm uppercase font-medium text-gray-700">
              Items
            </div>
            <Button size="xs" version="text" onClick={() => setTestItems([])}>
              Reset
            </Button>
          </div>
          {testItems.map((ti) => {
            return <div>{humanReadableItem(ti)}</div>;
          })}
        </Well> */}
        <div className="mb-6">
          <div className="flex justify-between">
            <div className="text-sm uppercase font-medium text-gray-700">
              Items
            </div>
            <Button
              size="xs"
              version="text"
              onClick={resetAllItems}
              className="py-0"
            >
              Reset
            </Button>
          </div>
          <ul className="divide-y divide-gray-200">
            <li>
              <div className="flex items-center py-4">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-6 md:gap-4">
                    <div className="col-span-5">
                      <p className="text-sm font-medium text-blue-600 truncate">
                        {humanReadableItem(testDiscounts)?.name} x{" "}
                        {testDiscounts.quantity}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="">
                          {humanReadableItem(testDiscounts)?.description}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <div>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <button
                            onClick={() => removeTestItem("Discount")}
                            className="-mr-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-l-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <MinusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => addTestItem("Discount")}
                            className="-ml-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-r-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <PlusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="flex items-center py-4">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-6 md:gap-4">
                    <div className="col-span-5">
                      <p className="text-sm font-medium text-blue-600 truncate">
                        {humanReadableItem(testOtcs)?.name} x{" "}
                        {testOtcs.quantity}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="">
                          {humanReadableItem(testOtcs)?.description}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <div>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <button
                            onClick={() => removeTestItem("Otc")}
                            className="-mr-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-l-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <MinusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => addTestItem("Otc")}
                            className="-ml-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-r-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <PlusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="flex items-center py-4">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 md:grid md:grid-cols-6 md:gap-4">
                    <div className="col-span-5">
                      <p className="text-sm font-medium text-blue-600 truncate">
                        {humanReadableItem(testPlanChanges)?.name} x{" "}
                        {testPlanChanges.quantity}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="">
                          {humanReadableItem(testPlanChanges)?.description}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <div>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <button
                            onClick={() => removeTestItem("PlanChange")}
                            className="-mr-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-l-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <MinusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => addTestItem("PlanChange")}
                            className="-ml-px relative inline-flex items-center space-x-1 px-2 py-2 border border-gray-100 text-sm font-medium rounded-r-md text-gray-700 hover:bg-gray-100 focus:outline-none"
                          >
                            <PlusSmIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <Divider marginSize={1} />
          <div className="flex justify-end">
            <Button size="sm" onClick={() => generateNeg()}>
              Generate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

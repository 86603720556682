import React from "react";
import NumberFormat from "react-number-format";

interface IProps {
  amount: number;
}

export default class Money extends React.Component<IProps> {
  render() {
    return (
      <NumberFormat
        value={this.props.amount}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        displayType="text"
        prefix="$"
      />
    );
  }
}

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";

interface IProps {
  responseJson: string;
  className?: string;
}

export default function Response({ responseJson, className }: IProps) {
  return (
    <div className={`${className}`}>
      <div className="rounded-t-md bg-gray-700 p-2">
        <div className="flex items-center justify-between">
          <div className="font-mono text-sm tracking-wide text-gray-50">
            RESPONSE
          </div>
        </div>
      </div>
      <SyntaxHighlighter
        language={"json"}
        style={xonokai}
        codeTagProps={{ style: { fontSize: "12px" } }}
        customStyle={{
          width: "inherit",
          border: "none",
          borderTopRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0.375rem",
          borderBottomRightRadius: "0.375rem",
          background: "hsla(212, 25%, 20%, 1)"
        }}
      >
        {responseJson}
      </SyntaxHighlighter>
    </div>
  );
}

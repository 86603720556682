import { useContext } from "react";
import { SessionStoreContext } from "src/stores/SessionStore";

export default function useSession() {
  const { loggedIn, logIn, logOut, email, token, apiKey, testApiKey } =
    useContext(SessionStoreContext);

  return {
    loggedIn,
    logIn,
    logOut,
    email,
    token,
    apiKey,
    testApiKey
  };
}

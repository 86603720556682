import { Stack } from "@cohubinc/blueprint";
import React from "react";
import Divider from "src/components/Divider";
import NewContainer from "src/components/NewContainer";
import Typography from "src/components/Typography";
import usePageTitle from "src/hooks/usePageTitle";
import SandboxGenerateNegotiation from "./SandboxGenerateNegotiation";
import SandboxResetBill from "./SandboxResetBill";
import SandboxTransitionBill from "./SandboxTransitionBill";
import SandboxTransitionCancellation from "./SandboxTransitionCancellation";
import SandboxTransitionNegotiation from "./SandboxTransitionNegotiation";
import SandboxTriggerProviderUpdated from "./SandboxTriggerProviderUpdated";

export default function ApiAuthentication() {
  usePageTitle("Sandbox Resources");

  return (
    <React.Fragment>
      <NewContainer className="mt-8">
        <Stack>
          <Typography>
            The resources and methods outlined below are only accessible using a
            test API key.
          </Typography>
          <Typography>
            They're intended to be used for exploring how our negotiation
            processes work and to simulate possible bill states as a bill is
            negotiated by our team.
          </Typography>
        </Stack>
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxGenerateNegotiation id="sandbox-generate-negotiation" />
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxTransitionNegotiation id="sandbox-transition-negotiation" />
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxTransitionBill id="sandbox-transition-bill" />
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxResetBill id="sandbox-reset-bill" />
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxTransitionCancellation id="sandbox-transition-cancellation" />
        <Divider marginSize={6} className="bg-gray-300" />
        <SandboxTriggerProviderUpdated id="sandbox-trigger-provider-updated" />
      </NewContainer>
    </React.Fragment>
  );
}

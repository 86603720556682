import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import sandboxTransitionBillJsonFile from "src/examples/responses/sandbox_transition_bill.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SandboxTransitionBill({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("sandbox-transition-bill");
      if (hash.includes("sandbox-transition-bill")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "sandbox-transition-bill"}>
      <a href="/api-docs/sandbox-resources#sandbox-transition-bill">
        <h3 className="mb-8 text-xl font-medium">Sandbox transition bill</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the bill that is transitioning.</div>
                </React.Fragment>
              }
            />
            <Parameter
              required
              field={"transition"}
              type={"String"}
              description={
                <React.Fragment>
                  <div>
                    The verb representing the transition for the bill. Must be
                    one of:
                  </div>
                  <ul className="mt-2 space-y-3">
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"savings_updated"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        The savings_updated transition simulates what would
                        happen if a bill got updated savings after a negotiation
                        has been completed, but we later determine the original
                        savings is incorrect and must be updated.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"autofix_upcoming"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Simulates the autofix process.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"outside_of_scope"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Sets the bill to outside_of_scope, meaning we cannot
                        negotiate the bill because it's from a provider or
                        service that we do not work with.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"bad_document"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Sets the bill's bad_document flag to true, indiciating
                        that we need a new document uploaded to continue our
                        negotiations.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"savings_expiring"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Sets the bill's items to expire today and updates the
                        bill's status to 'Savings Expiring'.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"savings_expired"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Sets the bill's items to have expired 7 days ago and
                        updates the bill's status to 'Savings Expired'.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"trigger_autofix"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Attempts to mimic an autofix for the bill. This will
                        create a new negotiation, and if set the bill's status
                        to{" "}
                        <CodeSnippet
                          bare
                          inline
                          code="In Progress"
                          className="text-xs"
                        />
                        .
                        <div className="mt-4">
                          <span className="text-xs text-red-500">NOTE:</span> In
                          production, a bill will not immediately have a started
                          negotiation after autofix has occurred. Autofix will
                          occur and the bill's status may remain Completed or
                          Failed until a negotiations agent starts the newly
                          created negotiation, which usually happens within 1-3
                          business days. Once the agent starts the newly created
                          negotiation, the bill's status will be updated to In
                          Progress and a{" "}
                          <CodeSnippet
                            bare
                            inline
                            code="bill.negotiation_started"
                            className="text-xs"
                          />{" "}
                          webhook will be delivered.
                        </div>
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"duplicate_discarded"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Attempts to mimic a duplicate_discarded mutation and the
                        subsequent webhook. If a customer has multiple bills
                        with the same provider, this transition will discard the
                        bill ID passed to the mutation.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"stop"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Changes the bill's status to 'Stopped'.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"set_provider_account_closed"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Changes the bill's status to 'Provider Account Closed'.
                      </div>
                    </li>
                  </ul>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The bill that has transitioned its current negotiation, if the
              mutation was successful.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, calculateEstimateExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   calculateEstimateExampleJsBody
              // ),
              curl: curlExample(
                testApiKey!,
                sandboxTransitionBillExampleCurlBody
              )
              // python: pythonExample(
              //   testApiKey!,
              //   calculateEstimateExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              sandboxTransitionBillJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const sandboxTransitionBillExampleCurlBody = `
$'{
  "query": "mutation SandboxTransitionBill($billId:ID!,$transition:String!){SandboxTransitionBill(input:{billId:$billId,transition:$transition}){success errors bill{id}}}",
	"variables": {
		"billId": "bill_5484b35341280f05b76e085a6e822e47",
		"transition": "bad_document"
	}
}`;

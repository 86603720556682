import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "src/components/Breadcrumbs";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import SuggestedApproach from "./SuggestedApproach";

export default function CreatingBills() {
  usePageTitle("Creating Bills");

  return (
    <NewContainer className="mt-8">
      <Breadcrumbs
        crumbs={[
          {
            path: "/apiDocs/implementation",
            text: "Implementation Guide",
            active: false
          },
          {
            path: "/apiDocs/implementation/creating-bills",
            text: "Creating Bills",
            active: true
          }
        ]}
      />
      <div className="mt-8">
        <h3
          className="text-xl text-gray-bf-text-medium mb-2"
          id="creating-bills"
        >
          Creating bills
        </h3>
      </div>
      <div className="mt-8">
        <p className="mb-8">
          There are two typical approaches to creating a bill: break it into
          discrete steps, or lump it into a single form. We find it's best to
          break it into steps so that it's clear to the customer what they need
          to do. These steps are outlined below.
        </p>
      </div>
      <div className="mt-8">
        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            1. Present a list of providers
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                The list of providers that we negotiate with can be fetched
                using the <CodeSnippet bare inline code="listProviders" />{" "}
                query. You can, and probably should, use this query to present
                your users/customers a list of service providers that they can
                pick from to begin the creation of a bill.
              </p>
              <p className="mb-4">
                If you only want to show a subset of all providers, you should
                still use the <CodeSnippet bare inline code="listProviders" />{" "}
                query and filter out any unwanted providers on the client side.
              </p>
            </div>
            <SuggestedApproach
              body={"Show the customer a list of all providers"}
            />
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            2. Determine the provider_id and customer_id
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                A <CodeSnippet bare inline code="provider_id" /> and a{" "}
                <CodeSnippet bare inline code="customer_id" /> are required when
                using the <CodeSnippet bare inline code="createBill" />{" "}
                mutation. Since you've shown the customer a list of negotiable
                providers as detailed above, when they choose one of those
                providers, you may create the bill at that point or persist
                their chosen <CodeSnippet bare inline code="provider_id" />{" "}
                somewhere else until you're ready to create the bill. Either
                way, you'll have to send that{" "}
                <CodeSnippet bare inline code="provider_id" /> and the{" "}
                <CodeSnippet bare inline code="customer_id" /> to create a bill.
              </p>
            </div>
            <SuggestedApproach
              body={
                "Create the bill as soon as the customer has chosen a provider"
              }
            />
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            3. Store the bill_id
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                If your call to <CodeSnippet bare inline code="createBill" />{" "}
                succeeded, the response will contain an ID for the bill that
                looks something like{" "}
                <CodeSnippet
                  bare
                  inline
                  code="bill_c00ed07a7d39ec06bcd38e6aaa0e73d0"
                />
                . You should persist that ID in your database so that you can
                use it to find the bill in the future, and so that you can
                update the bill with the rest of the necessary fields in the
                following steps.
              </p>
            </div>
            <SuggestedApproach body={"Store the bill_id in your database"} />
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            4. Add document_urls or go documentless
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                In order to negotiate for your customers, we need a copy of
                their statement, which you send to us by using the{" "}
                <CodeSnippet bare inline code="updateBill" /> mutation and
                passing the <CodeSnippet bare inline code="bill_id" /> that you
                just stored in your database and{" "}
                <CodeSnippet bare inline code="document_urls" /> as an array of
                URLs that we can access to download the bill.
              </p>
            </div>
            <SuggestedApproach
              body={
                "Use a service like AWS S3 to create publicly accessible URLs that expire after a short time period once accessed"
              }
            />
          </div>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                Many of the most common providers can be negotiated without a
                document as long as we're given certain information about the
                bill and the account holder. Use the{" "}
                <CodeSnippet bare inline code="provideDocumentlessInfo" />{" "}
                mutation and pass it the{" "}
                <CodeSnippet bare inline code="bill_id" /> that you just stored
                in your database and a JSON object containing the necessary
                fields for the bills provider. You can find out what those
                fields are by checking the{" "}
                <CodeSnippet bare inline code="documentless_info" /> field on
                the bill itself.
              </p>
            </div>
            <SuggestedApproach
              className="mt-4"
              body={
                "Use the documentless flow if you know you're customers will provide the required information. Documentless is more convenient, but if we don't get the required information to negotiate, your custoemr may be frustrated with their results."
              }
            />
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            5. Request and send additional bill fields
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                Most providers require some additional verification before we
                can begin negotiating, usually in the form of the last 4 digits
                of a customer's social security number or a PIN/passcode on the
                customer's account.
              </p>
              <p className="mb-4">
                To know if the chosen provider needs these fields, use the{" "}
                <CodeSnippet bare inline code="findProvider" /> query and check
                the <CodeSnippet bare inline code="bill_fields" /> array. That
                array may contain <CodeSnippet bare inline code="last4ssn" />,{" "}
                <CodeSnippet bare inline code="passcode" />, both, or nothing.
                If it contains nothing, you can skip this step.
              </p>
              <p className="mb-4">
                Give your customer a way to provide the necessary fields, and
                send them in another{" "}
                <CodeSnippet bare inline code="updateBill" /> mutation. See the{" "}
                <Link
                  to={paths.apiDocs.bills + "#update-bill"}
                  className={`text-blue-500`}
                >
                  Update a bill
                </Link>{" "}
                documentation for more details.
              </p>
            </div>
            {/* <SuggestedApproach body={"Update the bill with document_urls"} /> */}
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            6. Set negotiation preferences
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                The final step in bill creation is to let us know if the
                customer will allow us to enter them into a contract with their
                provider and to tell us if the customer would like us to monitor
                their bill and automatically renegotiate it (what we call
                'AutoFix').
              </p>
              <p className="mb-4">
                You'll use the <CodeSnippet bare inline code="updateBill" />{" "}
                mutation again to send boolean values for the{" "}
                <CodeSnippet bare inline code="allowsContract" /> and{" "}
                <CodeSnippet bare inline code="autoRenegotiate" /> fields.
              </p>
              <p className="mb-4">
                Neither of these fields are required, and if you don't request
                them from your customer and send them to us, we'll assume two
                things:
                <ul className="ml-8 mt-2 list-disc">
                  <li>
                    the customer <b>does not</b> want us to negotiate any
                    savings that would require a contract (i.e.{" "}
                    <CodeSnippet bare inline code="allowsContract" /> is{" "}
                    <CodeSnippet bare inline code="false" />)
                  </li>
                  <li className="mt-1">
                    the customer <b>does</b> want us to automatically
                    renegotiate their bill in the future when any of their
                    discounts/savings are set to expire (i.e.{" "}
                    <CodeSnippet bare inline code="autoRenegotiate" /> is{" "}
                    <CodeSnippet bare inline code="true" />)
                  </li>
                </ul>
              </p>
            </div>
            <SuggestedApproach
              body={
                "Leave autoRenegotiate set to true to keep customers discounts in place for the long term"
              }
            />
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            7. Let the customer know their work is done
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                At this point, the customer has given all the information we
                need to begin out negotiations. Typically, we turn a negotiation
                around in a few business days.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h4 className="text-lg text-gray-bf-text-medium mb-2">
            8. Listen for webhook events
          </h4>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4">
              <p className="mb-4">
                Though the customer's work is done, you'll need to set up{" "}
                <Link
                  className="text-blue-500 cursor-pointer"
                  to={paths.webhooks}
                >
                  webhook URLs
                </Link>{" "}
                and listen for events that may necessitate further action from
                the customer. We may need additional information about the bill
                to continue or negotiations (see{" "}
                <Link
                  className="text-blue-500 cursor-pointer"
                  to={paths.apiDocs.implementation.respondingToInfoRequests}
                >
                  responding to info requests
                </Link>
                ) or we may have a savings offer that requires the customer's
                consent (see{" "}
                <Link
                  className="text-blue-500 cursor-pointer"
                  to={paths.apiDocs.implementation.respondingToOffers}
                >
                  responding to offers
                </Link>
                ).
              </p>
            </div>
          </div>
        </div>
      </div>
    </NewContainer>
  );
}

import { startCase } from "lodash";
import React from "react";
import DateAtTime from "src/components/DateAtTime";
import Detail from "src/components/Detail";
import { IOfferFragment } from "src/components/graphql";

interface IOffersProps {
  offers: IOfferFragment[];
}

const Offers: React.FC<IOffersProps> = ({ offers }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {!!offers.length ? (
          <React.Fragment>
            {offers.map((off) => (
              <li key={`${off.id}`}>
                <div className="flex items-start px-4 py-4">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium truncate">
                          {startCase(off.status)}
                        </p>
                        <p className="mt-1 flex items-center text-xs text-gray-500">
                          <span className="truncate">{off.id}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <Detail
                            heading="Offered on"
                            body={
                              <React.Fragment>
                                <DateAtTime onlyDate input={off.createdAt} />
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Detail
                      heading="Days until expiration"
                      body={off.daysUntilExpiration}
                    />
                  </div>
                </div>
              </li>
            ))}
          </React.Fragment>
        ) : (
          <li>
            <div className="flex items-center justify-center px-4 py-4">
              <p className="text-sm text-gray-500">
                This bill doesn't have any offers.
              </p>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Offers;

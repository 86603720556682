import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import negotiationObjectJsonFile from "src/examples/negotiation.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import ListNegotiations from "./ListNegotiations";

export default function ApiDocsNegotiations() {
  usePageTitle("Negotiations");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              A negotiation represents an attempt to reduce the price of a bill.
            </Typography.Large>
            <Typography.Large>
              A bill may have many negotiations associated with it. Each time a
              bill is negotiated, a new negotiation is created.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="negotiation">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a negotiation."
                }
              />
              <FieldAttribute
                field={"bill_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a negotiation's parent bill."
                }
              />
              {/* <FieldAttribute
                field={"negotiation_number"}
                type={"integer"}
                description={
                  <React.Fragment>
                    <div className="mb-4">
                      Indicates how many times the parent bill has been
                      negotiated.
                    </div>
                    <div>
                      For example, if a bill has already been negotiated once
                      and a new negotiation is begun, the newest negotiation's{" "}
                      <CodeSnippet
                        bare
                        inline
                        code={"negotiationNumber"}
                        className="mb-1 text-xs"
                      />{" "}
                      would be{" "}
                      <CodeSnippet
                        bare
                        inline
                        code={"2"}
                        className="mb-1 text-xs"
                      />
                      .
                    </div>
                  </React.Fragment>
                }
              /> */}
              <FieldAttribute
                field={"state"}
                type={"String"}
                description={
                  <React.Fragment>
                    <div>
                      Current state of the negotiation in the BillFixers system.{" "}
                      <div className="mt-2">
                        <span className="text-red-500">NOTE:</span>&nbsp;This
                        field is not the same as a bill's status field and is
                        provided for informational purposes only.
                      </div>
                    </div>
                    <ul className="mt-2 space-y-3">
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"pending"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Default state for all negotiations
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"started"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          A BillFixers agent has begun the negotiation process
                          and is actively attempting to reduce the price of the
                          parent bill.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"scheduled"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation is scheduled for a future to maximize
                          savings.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"fixed"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation is complete and the BillFixers agent was
                          able to get savings and reduce the price of the parent
                          bill.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"failed"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation attempts did not result in savings or a
                          reduced price for the parent bill.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"awaiting_consent"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation is paused until the customer responds to
                          an offer.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"awaiting_information"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation is paused until the customer responds to
                          an information request.
                        </div>
                      </li>
                      <li>
                        <CodeSnippet
                          bare
                          inline
                          code={"cancelled"}
                          className="mb-1 text-xs"
                        />
                        <div className="text-xs">
                          Negotiation has been cancelled.
                        </div>
                      </li>
                    </ul>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"total_savings"}
                type={"Money"}
                description={
                  "The total amount of money that BillFixers has successfully saved for the parent bill."
                }
              />
              <FieldAttribute
                defaultValue="null"
                field={"results_note"}
                type={"String"}
                description={
                  "Additional information related to the results of our negotiation e.g. an internet speed increase that came as a result of the negotiation. This field may be null."
                }
              />
              <FieldAttribute
                field={"type_of_savings"}
                type={"String"}
                possibleValues={[
                  "monthly",
                  "one_time",
                  "monthly_and_one_time",
                  "null"
                ]}
                description={
                  "The type of savings that BillFixers has negotiated for the parent bill. May be one of the following values:"
                }
              />
              <FieldAttribute
                field={"started_at"}
                type={"Date"}
                description={
                  "The date a BillFixers agent began negotiating to reduce the price of the parent bill."
                }
              />
              <FieldAttribute
                field={"fixed_at"}
                type={"Date"}
                description={
                  "The date a BillFixers agent completed their negotiation and reduced the price of the parent bill."
                }
              />
              <FieldAttribute
                field={"failed_at"}
                type={"Date"}
                description={
                  "The date a BillFixers agent determined that they were unable to reduce the price of the parent bill."
                }
              />
              <FieldAttribute
                field={"auto_created"}
                type={"Boolean"}
                description={
                  "Indicates if the negotiation was created due to the autofix process or not. If true, the negotiation was created by the autofix process."
                }
              />
              <FieldAttribute
                field={"items"}
                type={"Array of item objects"}
                description={
                  "List of the line items that exist on the negotiation."
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(negotiationObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <ListNegotiations id="list-negotiations" />
    </Fragment>
  );
}

import { Space, Stack } from "@cohubinc/blueprint";
import React from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import cancellationObjectJsonFile from "src/examples/cancellation.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import FindCancellationInfoRequest from "./FindCancellationInfoRequest";
import RespondToCancellationInfoRequest from "./RespondToCancellationInfoRequest";

export default function ApiDocsCancellationInfoRequests() {
  usePageTitle("Cancellation Info Requests");

  return (
    <React.Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              During the course of a cancellation, we may need additional
              information from a customer about their account with a provider.
              For example, some providers need us to provide them with a
              billing/service address during a cancellation.
            </Typography.Large>
            <Typography.Large>
              An CancellationInfoRequest will contain sub-fields that are in the
              format of a question and an answer.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="cancellationInfoRequest">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a cancellation info request."
                }
              />
              <FieldAttribute
                field={"cancellation_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for the cancellation that a cancellation info request belongs to."
                }
              />
              <FieldAttribute
                field={"content"}
                type={"String"}
                description={
                  "A plain-text string describing the question or reason the fixer/negotiator is requesting information"
                }
              />
              <FieldAttribute
                field={"content_html"}
                type={"String"}
                description={"HTML formatted version of the content"}
              />
              <FieldAttribute
                field={"created_at"}
                type={"DateTime"}
                description={"Date and time when information was requested"}
              />
              <FieldAttribute
                field={"updated_at"}
                type={"DateTime"}
                description={"Date and time when information was requested"}
              />
              <FieldAttribute
                field={"responded_at"}
                type={"DateTime"}
                description={
                  "Date and time when the customer responded to the information request"
                }
              />
              <FieldAttribute
                field={"fields"}
                type={"Array of fields"}
                description={
                  "An array of fields. Each field has an id, label, placeholder, data type, and value."
                }
                childAttributes={[
                  {
                    field: "id",
                    description:
                      "Alpha-numeric unique identifier for the information request field.",
                    type: "ID"
                  },
                  {
                    field: "label",
                    description: "The descriptive label for the form field.",
                    type: "string"
                  },
                  {
                    field: "placeholder",
                    description: "The placeholder for the form field.",
                    type: "string"
                  },
                  {
                    field: "data_type",
                    description:
                      "The type of data we're requesting from the customer.",
                    type: "string",
                    possibleValues: ["text"]
                  },
                  {
                    field: "value",
                    description:
                      "The value provided by the customer when they responded to the information request. If they haven't responded, value will be null.",
                    type: "string"
                  }
                ]}
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(cancellationObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <RespondToCancellationInfoRequest />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindCancellationInfoRequest />
    </React.Fragment>
  );
}

import { Divider, Space } from "@cohubinc/blueprint";
import React from "react";
import { Link } from "react-router-dom";
import Section from "src/components/CodeDocs/Section";
import CodeSnippet from "src/components/CodeSnippet";
import Segment from "src/components/Segment";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import { LinkToSection } from "src/screens/Libraries/styles";

const examplePayload = `{
  "event_type": "offer.created",
  "id": "offer_5e12133055ada65444775e2f0",
  "customer_id": "customer_5e12133055ada65444775e2f0",
  "customer_external_id": "your_external_id",
  "data": {
    "days_until_expiration": 24
  },
  "webhook_event_id": "webhook_event_128fasb03tp42fmndg9282kdkk44"
}
`;

const Webhooks: React.FC = () => {
  usePageTitle("Webhooks");
  return (
    <React.Fragment>
      <Space height={1} />
      <ul>
        <li>
          <LinkToSection href="#intro">Introduction</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#payload">
            The Payload (What we send)
          </LinkToSection>
        </li>
        <li>
          <LinkToSection href="#events">Event Types</LinkToSection>
        </li>
        <li>
          <LinkToSection href="#setup">Setup and Testing</LinkToSection>
        </li>
      </ul>
      <Space height={2} />

      <Section title="Introduction" anchor="intro" className="mt-8">
        <p className="line-clamp-2 mt-1 text-sm text-gray-600">
          BillFixers uses webhooks to notify your application when an event
          happens in your partner account. Webhooks are particularly useful to
          remain well-informed about the current status of a customer's bill or
          receiving notification when a customer accepts or rejects an offer.
        </p>
        <Space height={1} />
        <p className="line-clamp-2 mt-1 text-sm text-gray-600">
          Not all integrations require webhooks. Keep reading to learn more
          about what webhooks are and when you should use them.
        </p>
      </Section>

      <Segment className="mb-12">
        <Section
          title="The Payload (What we send)"
          anchor="payload"
          // code={examplePayload}
          language="json"
        >
          <p className="line-clamp-2 mt-1 text-sm text-gray-600">
            The payload of the webhook (what we send your application) is a JSON
            object containing four or five keys:{" "}
            <CodeSnippet className="text-xs" bare inline code="event_type" />{" "}
            and <CodeSnippet className="text-xs" bare inline code="id" /> will
            always be present,{" "}
            <CodeSnippet className="text-xs" bare inline code="customer_id" />{" "}
            and{" "}
            <CodeSnippet
              className="text-xs"
              bare
              inline
              code="customer_external_id"
            />{" "}
            may be present, and a fifth key{" "}
            <CodeSnippet className="text-xs" bare inline code="data" /> may be
            present in some cases.
          </p>

          <Space height={1} />

          <div className="mt-6 grid grid-cols-2 place-items-start gap-8">
            <div className="flow-root">
              <ul className="-my-5 divide-y divide-gray-200">
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      event_type
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      The event that triggered the webhook. Refer to our{" "}
                      <LinkToSection href="#events">
                        list of event types
                      </LinkToSection>{" "}
                      to see the events for which we send webhooks.
                    </p>
                  </div>
                </li>
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">id</h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      The ID of the subject of the event. For example, if the
                      event is{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="bill.negotiation_started"
                      />
                      , the ID tells you which bill has been started.
                    </p>
                  </div>
                </li>
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      customer_id
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      If a customer is present on the object that triggered the
                      webhook, the customer_id will be included in the payload.
                    </p>
                  </div>
                </li>
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      customer_external_id
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      If a customer is present on the object that triggered the
                      webhook and that customer has an external_id set, the
                      customer_external_id will be included in the payload.
                    </p>
                  </div>
                </li>
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      data
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      Any additional data that may be useful or relevant to the
                      event that triggered the webhook.
                    </p>
                  </div>
                </li>
                <li className="py-5">
                  <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                    <h3 className="text-sm font-semibold text-gray-800">
                      webhook_event_id
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      The ID of the recorded webhook event, which will look
                      something like{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="webhook_event_128fasb03tp42fmndg9282kdkk44"
                      />
                      . This ID can be used like a nonce to prevent interpreting
                      the same webhook event more than once.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <CodeSnippet language="json" code={examplePayload} />
          </div>
        </Section>
      </Segment>

      <Divider />

      <Segment className="mb-12">
        <Section title="Event Types" anchor="events">
          <div className="mt-6 flow-root">
            <ul className="-my-5 divide-y divide-gray-200">
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <div>
                    <h3 className="text-sm font-semibold text-gray-800">
                      bill.negotiation_started
                    </h3>
                    <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                      Occurs whenever a negotiation has been started for a bill.
                    </p>
                  </div>
                  <div>
                    <CodeSnippet
                      language="json"
                      code={billNegotiationStartedWebhook}
                    />
                  </div>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.negotiation_completed
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a negotiation has been successfully
                    completed for a negotiation. (i.e. our negotiation resulted
                    in savings for the customer)
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.negotiation_failed
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a negotiation has failed. (i.e. our
                    negotiation did <span className="underline">not</span>{" "}
                    result in savings for the customer).
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.negotiation_scheduled
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a negotiation is scheuled to begin at a
                    later date. This happens when a negotiation has been
                    started, but the negotiator determines that a better savings
                    opportunity exists at a later date. Negotiations can only be
                    scheduled for a date within the next 30 days.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.savings_updated
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs when we've performed a negotiation for a bill, and
                    then the expected savings changes. This can happen if the
                    provider does not honor our negotiated price or we're able
                    to find additional savings after our initial negotiation.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.autofix_upcoming
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a bill's current discounts are approaching
                    expiration and we'll be renegotiating soon.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.outside_of_scope
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs if we get a bill that we cannot work on. Typically
                    this happens if we receive something like a medical bill or
                    a non-negotiable service like Netflix.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.bad_document
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs if the document associated with a bill is missing
                    important information that we need to have to begin our
                    negotiations. This often happens when a customer provides a
                    blurry photo of a bill or leaves out pages from a bill that
                    contain important info like the service address or account
                    holder's name.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.duplicate_discarded
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs if a customer has uploaded duplicate bills for the
                    same provider. A member of the BillFixer team has determined
                    that the bill is truly a duplicate, not a valid second
                    account with the same provider, and the bill has been
                    discarded.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.savings_expiring
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs when a bill's savings are expected to expire within
                    the next 30 days. NOTE: this webhook will not be triggered
                    for any bill that has{" "}
                    <CodeSnippet bare inline code="auto_renegotiate" /> set to
                    true.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.savings_expired
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs when a bill's savings have expired. NOTE: this
                    webhook will not be triggered for any bill that has{" "}
                    <CodeSnippet bare inline code="auto_renegotiate" /> set to
                    true.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.customer_ineligible
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a customer becomes ineligible for
                    negotiations. The bill ID sent with this webhook indicates
                    that bill will not negotiated since the customer is no
                    longer eligible for the service.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.provider_account_closed
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a negotiations agent determines that the
                    customer's account with a given provider is no longer active
                    and has been closed.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    bill.stopped
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a member of the BillFixers team determines
                    that work on a bill should be halted. This usually only
                    happens when we've received a complaint from the customer
                    about our negotiation process.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    customer.updated
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever an existing customer is updated.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    information_request.created
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a new information request is created.
                  </p>
                </div>
              </li>
              {/* <li className="py-5">
                <div className="relative focus-within:ring-2 focus-within:ring-indigo-500 grid grid-cols-2 gap-4">
                  <h3 className="text-sm font-semibold text-gray-800">
                    information_request.updated
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    Occurs whenever an existing information request is updated.
                    This includes whenever the customer responds.
                  </p>
                </div>
              </li> */}
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    offer.created
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a new offer is created. Offers start out in
                    a pending state. As you can see below, once a customer
                    accepts or rejects an offer, we send those webhooks as well.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    offer.accepted
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever an existing offer is accepted.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    offer.rejected
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever an existing offer is rejected.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation.started
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation has started and an agent is
                    actively attempting to cancel service for the customer.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation.succeeded
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation has been finalized and
                    service was successfully cancelled.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation.already_cancelled
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation has been finalized and
                    service was already cancelled.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation.unable_to_cancel
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation has been finalized and the
                    service was <span className="font-semibold">not</span> able
                    to be cancelled
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation.no_subscription_found
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation attempt was made and we
                    determined that the customer does not have an account with
                    the provider.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation_info_request.created
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a new cancellation info request has been
                    created.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation_offer.created
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a new cancellation offer has been created.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation_offer.accepted
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation offer has been accepted by a
                    customer.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    cancellation_offer.rejected
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a cancellation offer has been rejected,
                    either by a customer or by the automated 30 day cut off for
                    cancellation offers.
                  </p>
                </div>
              </li>
              <li className="py-5">
                <div className="relative grid grid-cols-2 gap-4 focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    provider.updated
                  </h3>
                  <p className="line-clamp-2 mt-1 text-sm text-gray-600">
                    Occurs whenever a a provider is updated. This includes
                    changes to the provider's cancellation fields.
                  </p>
                </div>
              </li>

              {/* <li className="py-5">
                <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                  <h3 className="text-sm font-semibold text-gray-800">
                    offer.updated
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                    Occurs whenever an existing offer is updated. This is also
                    triggered by accepting or rejecting the offer.
                  </p>
                </div>
              </li> */}
            </ul>
          </div>
        </Section>
      </Segment>

      <Divider />

      <Segment>
        <Section title="Setup and Testing" anchor="setup">
          <p className="line-clamp-2 mt-1 text-sm text-gray-600">
            You can configure your webhook URLs in the{" "}
            <Link className="text-blue-500" to={paths.settings}>
              settings
            </Link>{" "}
            section of the partner portal. Notice that there are URLs for both
            "live/production" as well as "test/development" environments.
          </p>
          <Space height={1} />
          <p className="line-clamp-2 mt-1 text-sm text-gray-600">
            We encourage you to test the various actions your application
            performs to see which webhooks we're likely to send you. You can
            easily see the webhooks in action by grabbing a test URL from{" "}
            <a
              href="https://webhook.site"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              webhook.site
            </a>{" "}
            and setting your test/development webhook url.
          </p>
          <Space height={1} />
          <p className="line-clamp-2 mt-1 text-sm text-gray-600">
            Once you have your webhook.site ready, trigger some events using the
            API tools we've set up for you in the various sections of the API
            Reference. Try creating a new customer or a new bill.
          </p>
        </Section>
      </Segment>
    </React.Fragment>
  );
};

const billNegotiationStartedWebhook = `{
  "event_type": "bill.negotiation_started",
  "id": "bill_12fasfa3187asd",
  "customer_id": "customer_12fasfa3187asd",
  "customer_external_id": "your_external_id",
  "data": {
    "negotiation_id": "negotiation_g6adio93m7jjki5"
  },
  "webhook_event_id": "webhook_event_128fasb03tp42fmndg9282kdkk44"
}
`;

export default Webhooks;

import orderBy from "lodash/orderBy";
import React from "react";
import { IBillFragment } from "src/components/graphql";

interface IProps {
  bill: IBillFragment;
  className?: string;
}

export default function DocumentSection({ bill, className }: IProps) {
  const sortedDocuments = orderBy(
    bill.documents,
    (doc) => doc.createdAt,
    "desc"
  );

  const documentLinks = sortedDocuments.map((document) => (
    <div className="text-sm text-gray-600">{document.filename}</div>
  ));

  return (
    <div className="my-4">
      {documentLinks}
      {bill.documents.length <= 0 && (
        <div className="text-center text-sm text-gray-600">
          This bill has no documents
        </div>
      )}
    </div>
  );
}

import React from "react";
import { TIcon } from "src/definitions/types/TIcon";

export function IconChevronDown({
  size = 24,
  className,
  style,
  onClick
}: TIcon) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={`${className}`}
      style={style}
      onClick={onClick}
      stroke="currentColor"
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import { haltAndCatchFire } from "src/utils/haltAndCatchFire";

export default function IdleCountdownLogout() {
  // The user has 30 seconds to do something before they are logged out
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else if (countdown <= 0) {
        haltAndCatchFire(true);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown]);

  return <span>{countdown}</span>;
}

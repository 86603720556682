import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/solid";
import queryString from "query-string";
import React from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import "./Paginator.css";

interface IPaginatorProps {
  totalCount: number;
  perPage: number;
}

export default function Paginator(props: IPaginatorProps) {
  const { totalCount, perPage } = props;
  const history = useHistory();

  const params: any = queryString.parse(window.location.search, {
    arrayFormat: "bracket"
  });

  const currentPage = parseInt(params.page || 1, 10);

  const handlePageClick = (pageNumber: any) => {
    const qs = queryString.stringify(
      { ...params, page: pageNumber },
      { arrayFormat: "bracket" }
    );
    history.push(`${window.location.pathname}?${qs}`);
    // dispatch(push(`${window.location.pathname}?${qs}`));
  };

  return (
    <Pagination
      totalItemsCount={totalCount}
      onChange={handlePageClick}
      activePage={currentPage}
      itemsCountPerPage={perPage}
      nextPageText={<ChevronRightIcon className="w-5 h-5 text-blue-500" />}
      prevPageText={<ChevronLeftIcon className="w-5 h-5 text-blue-500" />}
      firstPageText={
        <ChevronDoubleLeftIcon className="w-5 h-5 text-blue-500" />
      }
      lastPageText={
        <ChevronDoubleRightIcon className="w-5 h-5 text-blue-500" />
      }
      innerClass="Paginator-Container"
      activeClass="bg-blue-100 rounded-full"
      activeLinkClass="text-blue-500"
      itemClass="Paginator-Page"
      itemClassFirst="text-blue-500"
      itemClassPrev="text-blue-500"
      itemClassNext="text-blue-500"
      itemClassLast="Paginator-Last"
      disabledClass="Paginator-Disabled"
    />
  );
}

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFindCurrentPartnerQuery } from "src/components/graphql";
import { useTestMode } from "src/contexts/TestModeContext";
import useCurrentUser from "src/hooks/useCurrentUser";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import ApiKeys from "./Tabs/ApiKeys";
import PartnerUsers from "./Tabs/PartnerUsers";
import Profile from "./Tabs/Profile";
import Webhooks from "./Tabs/Webhooks/WebhooksTab";

const Settings: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { testMode } = useTestMode();
  const [activeTab, setActiveTab] = useState("profile");
  const { pathname } = useLocation();
  const history = useHistory();
  usePageTitle("Settings");

  useEffect(() => {
    if (pathname.includes("webhooks")) {
      setActiveTab("webhooks");
    } else if (pathname.includes("apikeys")) {
      setActiveTab("apikeys");
    } else if (pathname.includes("users")) {
      setActiveTab("users");
    } else {
      setActiveTab("profile");
    }
  }, [pathname]);

  const { loading, data } = useFindCurrentPartnerQuery();

  if (loading || !data) {
    return null;
  }

  const { currentPartner } = data;

  const activeTabSwitch = () => {
    switch (activeTab) {
      case "profile":
        return <Profile partner={currentPartner} />;
      case "users":
        return <PartnerUsers />;
      case "apikeys":
        return <ApiKeys partner={currentPartner} />;
      case "webhooks":
        return <Webhooks partner={currentPartner} />;
      default:
        break;
    }
  };

  return (
    <div className="relative mx-auto md:px-8 xl:px-0">
      <div>
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-6">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                value="webhooks"
                onChange={(evt) => {
                  setActiveTab(evt.target.value);
                }}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-bf-green-500 focus:outline-none focus:ring-bf-green-500 sm:text-sm"
              >
                <option value="webhooks">Webhooks</option>
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex">
                  {/* Current: "border-bf-green-500 text-bf-green-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <button
                    // onClick={() => setActiveTab("profile")}
                    onClick={() => history.push(paths.settings + "/profile")}
                    className={`${
                      activeTab === "profile"
                        ? "border-bf-green-500 text-bf-green-600"
                        : "text-gray-600"
                    } whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium focus:outline-none`}
                  >
                    Profile
                  </button>
                  {currentUser?.role === "manager" && (
                    <button
                      // onClick={() => setActiveTab("profile")}
                      onClick={() => history.push(paths.settings + "/users")}
                      className={`${
                        activeTab === "users"
                          ? "border-bf-green-500 text-bf-green-600"
                          : "text-gray-600"
                      } whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium focus:outline-none`}
                    >
                      Users
                    </button>
                  )}

                  <button
                    // onClick={() => setActiveTab("apikeys")}
                    onClick={() => history.push(paths.settings + "/apikeys")}
                    className={`${
                      activeTab === "apikeys"
                        ? "border-bf-green-500 text-bf-green-600"
                        : "text-gray-600"
                    } whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium focus:outline-none`}
                  >
                    API Keys
                  </button>
                  <button
                    // onClick={() => setActiveTab("webhooks")}
                    onClick={() => history.push(paths.settings + "/webhooks")}
                    className={`${
                      activeTab === "webhooks"
                        ? "border-bf-green-500 text-bf-green-600"
                        : "text-gray-600"
                    } whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium focus:outline-none`}
                  >
                    Webhooks
                  </button>
                </nav>
              </div>
            </div>
            {testMode && (
              <div className="my-6">
                <div className="rounded-md bg-red-100 p-2 text-red-900">
                  <p>To make changes to settings, please turn off test mode.</p>
                </div>
              </div>
            )}
            {activeTabSwitch()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import stopNegotiatingResponseJsonFile from "src/examples/responses/stop_negotiating.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function StopNegotiating({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("stop-negotiating");
      if (hash.includes("stop-negotiating")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#stop-negotiating">
        <h3 className="mb-4 text-xl font-medium">Stop negotiating</h3>
      </a>
      <p className="mb-8">
        If a bill is currently being negotiated, negotiations cannot be stopped.
        Negotiations can be stopped before negotiation begins or after they've
        finished, but not during the process.
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the bill.</div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An object containing a boolean success field indicating if
              negotiations were successfully stopped or not, an errors array
              containing errors (if any), and the bill object.
            </p>
            <p className="mt-2">
              Please note, there are several cases when the bill object will be
              null. If we have not yet attempted negotiation or the bill has
              been negotiated but no savings were found, calling{" "}
              <CodeSnippet code="stop_negotiating" bare inline /> will
              effectively delete the bill, and the returned bill object will be
              null.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(
                testApiKey!,
                stopNegotiatingBillExampleRubyBody
              ),
              js: javascriptExample(
                testApiKey!,
                stopNegotiatingBillExmapleJsBody
              ),
              curl: curlExample(testApiKey!, stopNegotiatingBillExmapleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              stopNegotiatingResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const stopNegotiatingBillExampleRubyBody = `
bill = client.stop_negotiating_bill(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0'
)
`;

const stopNegotiatingBillExmapleJsBody = `
const bill = await client.stopNegotiatingBill(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0'
)
`;

const stopNegotiatingBillExmapleCurlBody = `
$'{
  "query": "mutation StopNegotiating($id:ID!){StopNegotiating(input:{id:$id}){success errors bill{id autoRenegotiate cancelledAt}}}",
  "variables": {
    "id": "bill_d8fb9e40650351069573001a8184845c"
  }
`;

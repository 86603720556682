import React from "react";
import { Field, Form } from "react-final-form";
import NumberFormat from "react-number-format";
import AltLoader from "./AltLoader";
import Button from "./Button";
import {
  useCalculateSavingsEstimateLazyQuery,
  useListProvidersQuery
} from "./graphql";
import Segment from "./Segment";

export default function SavingsEstimator() {
  const { loading: providersLoading, data: providersData } =
    useListProvidersQuery();

  const [calculateEstimateQuery, { loading, data }] =
    useCalculateSavingsEstimateLazyQuery();

  const calculateEstimate = (
    providerId: string,
    currentMonthlyAmount: number
  ) => {
    calculateEstimateQuery({ variables: { providerId, currentMonthlyAmount } });
  };

  if (providersLoading && !providersData) {
    return null;
  }

  let providerOptions = providersData?.ListProviders.map((p) => {
    return {
      label: p.name,
      value: p.id
    };
  });

  providerOptions?.unshift({ label: "", value: "" });

  const handleFormSubmit = (values: any) => {
    const parsedValue = parseFloat(
      values.currentPayment.replace("$", "").replace(",", "")
    );

    if (!isNaN(parsedValue) && typeof parsedValue === "number") {
      calculateEstimate(values.provider, parsedValue);
    }
  };

  return (
    <Segment className="w-full">
      <div className="mb-6 flex justify-center">Estimate your savings</div>
      <Form
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit}>
            <label htmlFor="provider" className="mb-2 block w-full text-sm">
              Select a provider
            </label>
            <Field
              name="provider"
              render={({ input }) => (
                <select
                  id="provider"
                  className="mt-2 w-full rounded-sm bg-gray-100 p-2"
                  {...input}
                >
                  {providerOptions!.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
            />
            <div className="mt-4">
              <label
                htmlFor="current-payment"
                className="mb-2 block w-full text-sm"
              >
                Current monthly payment
              </label>
              <Field
                name="currentPayment"
                render={({ input }) => (
                  <NumberFormat
                    className="mb-6 w-full rounded-sm bg-gray-100 p-2"
                    displayType="input"
                    thousandSeparator
                    prefix={"$"}
                    decimalScale={2}
                    defaultValue={0}
                    value={input.value}
                    name={input.name}
                    onChange={input.onChange}
                    id="current-payment"
                  />
                )}
              />
            </div>
            <Button type="submit" fullWidth disabled={pristine || submitting}>
              Get an estimate
            </Button>
          </form>
        )}
      />

      {loading && <AltLoader className="mt-4" />}

      {data?.estimate && (
        <div className="mt-8">
          <div className="text-center text-sm font-semibold uppercase tracking-wider text-bf-green-600">
            We could save you
          </div>
          <div className="mt-4 flex items-center justify-between">
            <div className="rounded-full bg-bf-green-200 py-1 px-3 text-base font-medium text-bf-green-800">
              <NumberFormat
                value={data?.estimate?.estimatedAnnualSavings}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                displayType="text"
                prefix="$"
              />{" "}
              / year 🤑
            </div>
            <div className="rounded-full bg-bf-green-200 py-1 px-3 text-base font-medium text-bf-green-800">
              <NumberFormat
                value={data?.estimate?.estimatedMonthlySavings}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                displayType="text"
                prefix="$"
              />{" "}
              / month 🎊
            </div>
            <div className="rounded-full bg-bf-green-200 py-1 px-2 text-base font-medium text-bf-green-800">
              <NumberFormat
                value={data.estimate.percentageSavings}
                displayType="text"
                suffix="%"
              />{" "}
              🎉
            </div>
          </div>
        </div>
      )}
    </Segment>
  );
}

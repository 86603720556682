import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconChevronDown } from "src/components/NewIcons/IconChevronDown";
import paths from "src/paths";
import { getLinkClassNames } from "../Sidebar/Desktop";

export default function ApiDocsLinks() {
  const { pathname } = useLocation();
  const [apiDocsIsOpen, setApiDocsIsOpen] = useState(false);
  const [sandboxDocsIsOpen, setSandboxDocsIsOpen] = useState(false);

  const [isLibraryOpen, setIsLibraryOpen] = useState(
    pathname.includes("libraries")
  );

  return (
    <nav className="flex-1 space-y-1 bg-gray-800 px-2">
      <div className="space-y-1">
        <Link
          to={paths.dashboard}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          <h6>Dashboard</h6>
        </Link>
        <Link
          to={paths.apiDocs.home}
          // className={`${getLinkClassNames(paths.apiDocs.home, pathname)} pl-4`}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          Getting Started
        </Link>
        <Link
          to={paths.apiDocs.auth}
          // className={`${getLinkClassNames(paths.apiDocs.home, pathname)} pl-4`}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          Authentication
        </Link>
        {/* <Link
          to={paths.introduction}
          className="group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md text-gray-50 hover:text-gray-50 hover:bg-gray-700 focus:outline-none"
        >
          <h6>Introduction</h6>
        </Link> */}
        {/* <Link
                  to={paths.implementation.home}
                  className="group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md text-gray-50 hover:text-gray-50 hover:bg-gray-700 focus:outline-none"
                >
                  <h6>Implementation Guide</h6>
                </Link> */}
        <Link
          to={paths.rateLimits}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          <h6>Rate Limits</h6>
        </Link>
        <Link
          to={paths.webhooks}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          <h6>Webhooks</h6>
        </Link>
        <Link
          to={paths.apiDocs.implementation.home}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          <h6>Implementation Guide</h6>
        </Link>
        <button
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
          onClick={() => setApiDocsIsOpen(!apiDocsIsOpen)}
        >
          Core API Resources
          <IconChevronDown
            className={`ml-auto h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-300 ${
              apiDocsIsOpen ? "rotate-180 text-gray-400" : ""
            }`}
          />
        </button>

        {/* Expandable link section, show/hide based on state. */}
        <div
          className={`space-y-1 overflow-y-scroll ${
            apiDocsIsOpen ? "block" : "hidden"
          }`}
        >
          <Link
            to={paths.apiDocs.savingsEstimate}
            className={`${getLinkClassNames(
              paths.apiDocs.savingsEstimate,
              pathname
            )} pl-4`}
          >
            Savings Estimate
          </Link>
          <Link
            to={paths.apiDocs.savingsEstimate}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The savings estimate object
          </Link>
          <Link
            to={paths.apiDocs.savingsEstimate + "#calculate-savings-estimate"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Calculate an estimate
          </Link>
          <Link
            to={paths.apiDocs.savingsEvent}
            className={`${getLinkClassNames(
              paths.apiDocs.savingsEvent,
              pathname
            )} pl-4`}
          >
            Savings Event
          </Link>
          <Link
            to={paths.apiDocs.savingsEvent}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The savings event object
          </Link>
          <Link
            to={paths.apiDocs.savingsEvent + "#find-random-recent-savings"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find a random savings event
          </Link>
          <Link
            to={paths.apiDocs.savingsEvent + "#list-random-recent-savings"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List random savings events
          </Link>
          <Link
            to={paths.apiDocs.customers}
            className={`${getLinkClassNames(
              paths.apiDocs.customers,
              pathname
            )} pl-4`}
          >
            Customers
          </Link>
          <Link
            to={paths.apiDocs.customers}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The customer object
          </Link>
          <Link
            to={paths.apiDocs.customers + "#create-customer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Create a customer
          </Link>
          <Link
            to={paths.apiDocs.customers + "#list-customers"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List customers
          </Link>
          <Link
            to={paths.apiDocs.customers + "#find-customer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find a customer
          </Link>
          <Link
            to={paths.apiDocs.customers + "#set-eligible-for-negotiation"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Set eligible for negotiation
          </Link>
          <Link
            to={paths.apiDocs.customers + "#set-eligible-for-cancellation"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Set eligible for cancellation
          </Link>
          <Link
            to={paths.apiDocs.providers}
            className={`${getLinkClassNames(
              paths.apiDocs.providers,
              pathname
            )} pl-4`}
          >
            Providers
          </Link>
          <Link
            to={paths.apiDocs.providers}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The provider object
          </Link>
          <Link
            to={paths.apiDocs.providers + "#list-providers"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List providers
          </Link>
          <Link
            to={paths.apiDocs.providers + "#find-provider"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find provider
          </Link>
          <Link
            to={paths.apiDocs.bills}
            className={`${getLinkClassNames(
              paths.apiDocs.bills,
              pathname
            )} pl-4`}
          >
            Bills
          </Link>
          <Link
            to={paths.apiDocs.bills}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The bill object
          </Link>
          <Link
            to={paths.apiDocs.bills + "#create-bill"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Create a bill
          </Link>
          <Link
            to={paths.apiDocs.bills + "#create-document"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Create a document
          </Link>
          <Link
            to={paths.apiDocs.bills + "#set-bill-ready-to-negotiate"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Set ready to negotiate
          </Link>
          <Link
            to={paths.apiDocs.bills + "#provide-documentless-info"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Provide documentless info
          </Link>
          <Link
            to={paths.apiDocs.bills + "#update-bill"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Update a bill
          </Link>
          <Link
            to={paths.apiDocs.bills + "#list-bills"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List bills
          </Link>
          <Link
            to={paths.apiDocs.bills + "#find-bill"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find a bill
          </Link>
          <Link
            to={paths.apiDocs.bills + "#renegotiate-bill"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Renegotiate a bill
          </Link>
          <Link
            to={paths.apiDocs.bills + "#stop-negotiating"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Stop negotiating
          </Link>
          <Link
            to={paths.apiDocs.negotiations}
            className={`${getLinkClassNames(
              paths.apiDocs.negotiations,
              pathname
            )} pl-4`}
          >
            Negotiations
          </Link>
          <Link
            to={paths.apiDocs.negotiations}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The negotiation object
          </Link>
          <Link
            to={paths.apiDocs.negotiations + "#list-negotiations"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List negotiations
          </Link>
          <Link
            to={paths.apiDocs.items}
            className={`${getLinkClassNames(
              paths.apiDocs.items,
              pathname
            )} pl-4`}
          >
            Items
          </Link>
          <Link
            to={paths.apiDocs.items}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The item object
          </Link>
          <Link
            to={paths.apiDocs.offers}
            className={`${getLinkClassNames(
              paths.apiDocs.offers,
              pathname
            )} pl-4`}
          >
            Offers
          </Link>
          <Link
            to={paths.apiDocs.offers}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The offer object
          </Link>
          <Link
            to={paths.apiDocs.offers + "#list-offers"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List offers
          </Link>
          <Link
            to={paths.apiDocs.offers + "#find-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find an offer
          </Link>
          <Link
            to={paths.apiDocs.offers + "#accept-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Accept an offer
          </Link>
          <Link
            to={paths.apiDocs.offers + "#reject-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Reject an offer
          </Link>
          <Link
            to={paths.apiDocs.infoReqs}
            className={`${getLinkClassNames(
              paths.apiDocs.infoReqs,
              pathname
            )} pl-4`}
          >
            Information Requests
          </Link>
          <Link
            to={paths.apiDocs.infoReqs}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The information request object
          </Link>
          <Link
            to={paths.apiDocs.infoReqs + "#respond-to-info-request"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Respond to a request
          </Link>
          <Link
            to={paths.apiDocs.infoReqs + "#list-info-reqs"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List info requests
          </Link>
          <Link
            to={paths.apiDocs.infoReqs + "#find-info-req"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find an info request
          </Link>
          <Link
            to={paths.apiDocs.cancellations}
            className={`${getLinkClassNames(
              paths.apiDocs.cancellations,
              pathname
            )} pl-4`}
          >
            Cancellations
          </Link>
          <Link
            to={paths.apiDocs.cancellations}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The cancellation object
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#provider-cancellation-fields"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Provider cancellation fields
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#create-cancellation"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Create a cancellation
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#provide-cancellation-info"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Provide cancellation info
          </Link>
          <Link
            to={
              paths.apiDocs.cancellations + "#set-cancellation-ready-to-cancel"
            }
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Set ready to cancel
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#update-cancellation"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Update a cancellation
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#list-cancellations"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            List cancellations
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#find-cancellation"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find cancellation
          </Link>
          <Link
            to={paths.apiDocs.cancellations + "#stop-cancelling"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Stop cancelling
          </Link>
          <Link
            to={paths.apiDocs.cancellationInfoReqs}
            className={`${getLinkClassNames(
              paths.apiDocs.cancellationInfoReqs,
              pathname
            )} pl-4`}
          >
            Cancellation Info Requests
          </Link>
          <Link
            to={paths.apiDocs.cancellationInfoReqs}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The cancellation info request object
          </Link>
          <Link
            to={
              paths.apiDocs.cancellationInfoReqs +
              "#respond-to-cancellation-info-request"
            }
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Respond to a cancellation info request
          </Link>
          <Link
            to={
              paths.apiDocs.cancellationInfoReqs +
              "#find-cancellation-info-request"
            }
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find a cancellation info request
          </Link>
          <Link
            to={paths.apiDocs.cancellationOffers}
            className={`${getLinkClassNames(
              paths.apiDocs.cancellationOffers,
              pathname
            )} pl-4`}
          >
            Cancellation Offers
          </Link>
          <Link
            to={paths.apiDocs.cancellationOffers}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            The cancellation offer object
          </Link>
          <Link
            to={paths.apiDocs.cancellationOffers + "#accept-cancellation-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Accept a cancellation offer
          </Link>
          <Link
            to={paths.apiDocs.cancellationOffers + "#reject-cancellation-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Reject a cancellation offer
          </Link>
          <Link
            to={paths.apiDocs.cancellationOffers + "#find-cancellation-offer"}
            className={`group flex items-center rounded-md p-1 pl-8 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-gray-50`}
          >
            Find a cancellation offer
          </Link>
        </div>
      </div>

      <div className="space-y-1">
        <Link
          to={paths.apiDocs.sandboxResources}
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
        >
          <h6>Sandbox &amp; Testing Resources</h6>
        </Link>
      </div>

      <div className="space-y-1">
        <button
          className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-50 hover:bg-gray-700 hover:text-gray-50 focus:outline-none"
          onClick={() => setIsLibraryOpen(!isLibraryOpen)}
        >
          Client Libraries
          <IconChevronDown
            className={`ml-auto h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-300 ${
              isLibraryOpen ? "rotate-180 text-gray-400" : ""
            }`}
          />
        </button>

        {/* Expandable link section, show/hide based on state. */}
        <div className={`space-y-1 ${isLibraryOpen ? "block" : "hidden"}`}>
          <Link
            to={paths.libraries.javascript}
            className={`${getLinkClassNames(
              paths.libraries.javascript,
              pathname
            )} pl-4`}
          >
            Javascript
          </Link>
          <Link
            to={paths.libraries.ruby}
            className={`${getLinkClassNames(
              paths.libraries.ruby,
              pathname
            )} pl-4`}
          >
            Ruby
          </Link>
        </div>
      </div>
    </nav>
  );
}

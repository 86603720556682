const curlExample = (apiKey: string, body: string): string => {
  return `curl -X "POST" "https://sandbox.billfixers.com/partner/graphql" \
            -H 'X-Partner-ApiKey: ${apiKey}' \
            -H 'Content-Type: application/json; charset=utf-8' \
            -d ${body} \
}'
`;
};

export default curlExample;

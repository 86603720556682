import React, { ReactNode, ReactNodeArray } from "react";

interface IProps {
  children: ReactNode | ReactNodeArray;
  className?: string;
}

export default function Reference({ children, className }: IProps) {
  return (
    <div
      style={{ gridArea: "reference" }}
      className={`rounded-lg bg-gray-50 p-4 shadow-md ${className}`}
    >
      {children}
    </div>
  );
}

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";

interface IProps {
  password: string;
}

export default function PasswordComplexityValidator({ password }: IProps) {
  const [longEnough, setLongEnough] = useState(false);
  const [containsLowercase, setContainsLowercase] = useState(false);
  const [containsUppercase, setContainsUppercase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  // const [containsSymbol, setContainsSymbol] = useState(false);
  const [containsCommonPassword, setContainsCommonPassword] = useState(false);

  useEffect(() => {
    if (checkLength(password)) {
      setLongEnough(true);
    } else {
      setLongEnough(false);
    }

    if (checkLowercase(password)) {
      setContainsLowercase(true);
    } else {
      setContainsLowercase(false);
    }

    if (checkUppercase(password)) {
      setContainsUppercase(true);
    } else {
      setContainsUppercase(false);
    }

    if (checkNumber(password)) {
      setContainsNumber(true);
    } else {
      setContainsNumber(false);
    }

    // if (checkSymbol(password)) {
    //   setContainsSymbol(true);
    // } else {
    //   setContainsSymbol(false);
    // }

    if (checkCommonPassword(password)) {
      setContainsCommonPassword(true);
    } else {
      setContainsCommonPassword(false);
    }
  }, [password]);

  return (
    <div className="ml-1 mt-2">
      <p className="mb-4 text-sm text-gray-700">
        Your password must be at least 12 characters long, contain a lowercase
        letter, an uppercase letter, and a number.
      </p>
      <div className="flex flex-col space-y-2 text-sm text-gray-800">
        <div className="flex items-center space-x-1">
          {longEnough ? (
            <CheckCircleIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-600" />
          )}
          <span>At least 12 characters long</span>
        </div>
        <div className="flex items-center space-x-1">
          {containsLowercase ? (
            <CheckCircleIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-600" />
          )}
          <span>Contains a lowercase letter</span>
        </div>
        <div className="flex items-center space-x-1">
          {containsUppercase ? (
            <CheckCircleIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-600" />
          )}
          <span>Contains an uppercase letter</span>
        </div>
        <div className="flex items-center space-x-1">
          {containsNumber ? (
            <CheckCircleIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-600" />
          )}
          <span>Contains a number</span>
        </div>
        {/* <div className="flex items-center space-x-1">
          {containsSymbol ? (
            <CheckCircleIcon className="h-5 w-5 text-green-600" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-600" />
          )}
          <span>Contains a special character</span>
        </div> */}
        {containsCommonPassword && (
          <div className="flex items-start space-x-1">
            <XCircleIcon className="h-5 w-5 text-red-600" />
            <span className="flex-1">
              Come on now. You can't use the word "password" in your password.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function checkLength(password: string) {
  if (password === undefined) {
    return false;
  }
  return password.length >= 12;
}

function checkLowercase(password: string) {
  if (password === undefined) {
    return false;
  }
  return password.match(/.*[a-z]/g) != null;
}

function checkUppercase(password: string) {
  if (password === undefined) {
    return false;
  }
  return password.match(/.*[A-Z]/g) != null;
}

function checkNumber(password: string) {
  if (password === undefined) {
    return false;
  }
  return password.match(/.*[0-9]/g) != null;
}

// function checkSymbol(password: string) {
//   if (password === undefined) {
//     return false;
//   }
//   return password.match(/.*\W/g) != null;
// }

function checkCommonPassword(password: string) {
  if (password === undefined) {
    return false;
  }
  return password.match(/(password)/gi) != null;
}

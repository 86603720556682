import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const ScrollToTop: React.FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      const mainContent = document.getElementsByTagName("main")[0];
      if (mainContent) {
        mainContent.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);

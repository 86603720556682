import React from "react";
import { Link } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import Detail from "src/components/Detail";
import { useListBillsQuery } from "src/components/graphql";
import Money from "src/components/Money";
import paths from "src/paths";

interface IProps {
  customerId: string;
}

export default function CustomerBills({ customerId }: IProps) {
  const { loading, data } = useListBillsQuery({
    variables: {
      customerId
    }
  });

  if (loading && !data) {
    return <AltLoader />;
  }

  const bills = data?.ListBills.nodes.map((b) => b);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {!!bills?.length ? (
          <React.Fragment>
            {bills?.map((b) => (
              <li key={`${b.id}`}>
                <div className="flex items-start px-4 py-4">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <Link to={paths.bill(b.id)} className="cursor-pointer">
                        <p className="text-sm font-medium text-blue-600 truncate">
                          {b.title}
                        </p>
                        <p className="mt-1 flex items-center text-xs text-gray-500">
                          <span className="truncate">{b.id}</span>
                        </p>
                      </Link>
                      <div className="hidden md:block">
                        <div>
                          <Detail heading="Status" body={b.status} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Detail
                      heading="Active savings"
                      body={<Money amount={b.totalSavings} />}
                    />
                  </div>
                </div>
              </li>
            ))}
          </React.Fragment>
        ) : (
          <li>
            <div className="flex items-center justify-center px-4 py-4">
              <p className="text-sm text-gray-500">
                This customer doesn't have any bills.
              </p>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

import queryString from "query-string";
import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import AltLoader from "src/components/AltLoader";
import CodeSnippet from "src/components/CodeSnippet";
import DateAtTime from "src/components/DateAtTime";
import { useListCustomersQuery } from "src/components/graphql";
import Money from "src/components/Money";
import NewContainer from "src/components/NewContainer";
import Paginator from "src/components/Paginator";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";

const Customers: React.FC = () => {
  usePageTitle("Customers");
  const { page } = queryString.parse(window.location.search);
  const perPage = 25;
  const { loading, data } = useListCustomersQuery({
    variables: {
      offset: perPage * (parseInt(page as string) - 1 || 0),
      limit: perPage
    }
  });

  if (loading && !data) {
    return <AltLoader />;
  }

  if (!data) {
    <NewContainer>
      <div className="flex justify-center mt-8">
        No customers found. Use the API's{" "}
        <CodeSnippet bare inline code="createCustomer" /> mutation to create
        some.
      </div>
    </NewContainer>;
  }

  const customers = data?.ListCustomers.nodes;
  const totalCount = data?.ListCustomers.totalCount;

  return (
    <NewContainer>
      <h1 className="text-2xl font-medium text-gray-800">Customers</h1>
      <h5 className="text-xs text-gray-500">
        <NumberFormat value={totalCount} thousandSeparator displayType="text" />{" "}
        total customers
      </h5>
      <div className="flex flex-col mt-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name and ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Bill Count
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      All Time Savings
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((c, idx) => (
                    <tr
                      key={c.id}
                      className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <Link
                          to={paths.customer(c.id)}
                          className="cursor-pointer"
                        >
                          {c.name}
                          <br />
                          <span className="text-blue-500 text-xs">{c.id}</span>
                          <br />
                          {c.referralData !== "{}" && (
                            <span className="text-gray-600 text-xs">
                              {JSON.stringify(c.referralData)}
                            </span>
                          )}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <DateAtTime input={c.createdAt} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {c.billCount}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                        <Money amount={c.allTimeGrossSavings} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {totalCount && totalCount > perPage && (
        <div className="flex justify-center">
          <div className="bg-white rounded-lg mt-4 p-3 w-1/4 shadow-md">
            <Paginator totalCount={totalCount || 0} perPage={perPage} />
          </div>
        </div>
      )}
    </NewContainer>
    // <NewContainer>
    //   <div className="flex flex-col">
    //     <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    //       <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
    //         <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
    //           <table className="min-w-full divide-y divide-gray-200">
    //             <thead className="bg-gray-50">
    //               <tr>
    //                 <th
    //                   scope="col"
    //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    //                 >
    //                   Name
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    //                 >
    //                   Total Savings
    //                 </th>
    //               </tr>
    //             </thead>
    //             <tbody className="bg-white divide-y divide-gray-200">
    //               {customerList}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {totalCount > perPage && (
    //     <div className="flex justify-center">
    //       <div className="bg-gray-800 rounded-lg mt-4 p-3 w-1/3">
    //         <Paginator totalCount={totalCount || 0} perPage={perPage} />
    //       </div>
    //     </div>
    //   )}
    // </NewContainer>
  );
};

export default Customers;

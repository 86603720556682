import { Space, Stack } from "@cohubinc/blueprint";
import React from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import providerObjectJsonFile from "src/examples/provider.json";
import usePageTitle from "src/hooks/usePageTitle";
import ListProviders from "src/screens/ApiDocs/Providers/ListProviders";
import { Code, StyledApiDocs } from "../styles";
import FindProvider from "./FindProvider";

export default function ApiDocsProviders() {
  usePageTitle("Providers");

  return (
    <React.Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Typography.Large>
            A provider is the business or entity that is billing a customer.
          </Typography.Large>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="provider">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={"Alpha-numeric unique identifier for a provider."}
              />
              <FieldAttribute
                field={"name"}
                type={"String"}
                description={"Name of a provider."}
              />
              <FieldAttribute
                field={"ranking"}
                type={"Integer"}
                description={
                  "The provider’s ranking in a list of negotiable providers. Ranking conveys how often and how well we're able to negotiate with a provider."
                }
              />
              <FieldAttribute
                field={"cancellation_ranking"}
                type={"Integer"}
                description={
                  "The provider’s cancellation ranking in a list of cancellable providers. Cancellation ranking conveys how often and how well we're able to cancel services with a provider."
                }
              />
              <FieldAttribute
                field={"primary_brand_color"}
                type={"String"}
                description={
                  "The primary brand color of the provider, stored as a hex color code. This can be used to style the provider's logo or generally make things look nice."
                }
              />
              <FieldAttribute
                field={"secondary_brand_color"}
                type={"String"}
                description={
                  "The secondary brand color of the provider, stored as a hex color code. This can be used to style the provider's logo or generally make things look nice."
                }
              />
              <FieldAttribute
                field={"services"}
                type={"Array of strings"}
                description={
                  "List of services provided i.e. Television, Internet, Telephone."
                }
              />
              <FieldAttribute
                field={"bill_fields"}
                type={"Array of strings"}
                possibleValues={["last4ssn", "passcode"]}
                description={
                  "Bill level fields that are needed in order to negotiate with the provider. The values in this array are specific to each provider."
                }
              />
              <FieldAttribute
                field={"logo"}
                type={"Image"}
                possibleValues={[
                  "tinyUrl",
                  "iconUrl",
                  "thumbnailUrl",
                  "smallUrl",
                  "mediumUrl",
                  "largeUrl",
                  "grandeUrl"
                ]}
                description={
                  <div className="space-y-2">
                    <p>
                      An object containing keys that correspond to different
                      URLs representing the provider's logo in various sizes.
                    </p>
                    <p>
                      If you request the logo field, you should pass at least
                      one of the following into the logo field in your query:
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"square_logo"}
                type={"Image"}
                possibleValues={[
                  "tinyUrl",
                  "iconUrl",
                  "thumbnailUrl",
                  "smallUrl",
                  "mediumUrl",
                  "largeUrl",
                  "grandeUrl"
                ]}
                description={
                  <div className="space-y-2">
                    <p className="text-red-600">
                      Alias method for the above logo field.
                    </p>
                    <p>
                      An object containing keys that correspond to different
                      URLs representing the provider's logo in various sizes.
                    </p>
                    <p>
                      If you request the square_logo field, you should pass at
                      least one of the following into the logo field in your
                      query:
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"rectangle_logo"}
                type={"Image"}
                possibleValues={[
                  "tinyUrl",
                  "iconUrl",
                  "thumbnailUrl",
                  "smallUrl",
                  "mediumUrl",
                  "largeUrl",
                  "grandeUrl"
                ]}
                description={
                  <div className="space-y-2">
                    <p>
                      An object containing keys that correspond to different
                      URLs representing the provider's rectangular logo in
                      various sizes.
                    </p>
                    <p>
                      If you request the rectangle_logo field, you should pass
                      at least one of the following into the logo field in your
                      query:
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"provider_bill_fields"}
                type={"ProviderBillField"}
                description={
                  <div className="space-y-2">
                    <p>
                      An array of{" "}
                      <CodeSnippet bare inline code="ProviderBillField" />{" "}
                      objects that contain information about documentless
                      fields.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"documentless_info"}
                type={"ProviderBillField"}
                description={
                  <div className="space-y-2">
                    <p>
                      Alias field for{" "}
                      <CodeSnippet bare inline code="providerBillFields" />.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"provider_cancelltion_fields"}
                type={"ProviderCancellationField"}
                description={
                  <div className="space-y-2">
                    <p>
                      An array of{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="ProviderCancellationField"
                      />{" "}
                      objects that contain information needed for cancelling
                      service with a provider.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"cancellation_info"}
                type={"ProviderCancellationField"}
                description={
                  <div className="space-y-2">
                    <p>
                      Alias field for{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="providerCancellationFields"
                      />
                      .
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field={"download_statement_instructions"}
                type={"String"}
                description={
                  <div className="space-y-2">
                    <p>
                      Instructions for how to download a monthly statement for a
                      provider.
                    </p>
                  </div>
                }
              />

              <FieldAttribute
                field="diy_cancellation_enabled"
                type="Boolean"
                description={
                  <div className="space-y-2">
                    <p>
                      Whether or not this Provider object features instructions
                      which can be presented to the user if they want to cancel
                      a subscription themselves, found on the field{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="diyCancellationInstructions"
                      />
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="concierge_cancellation_enabled"
                type="Boolean"
                description={
                  <div className="space-y-2">
                    <p>
                      Whether or not this provider can be cancelled for the user
                      by Experian's conciege subscription cancellations service.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="available_on_external_marketplaces"
                type="Boolean"
                description={
                  <div className="space-y-2">
                    <p>
                      Whether or not this Provider object features instructions
                      which can be presented to the user if they want to cancel
                      a subscription themselves (if the subscription was
                      purchased on Apple pay or Google pay), found on the fields
                      <CodeSnippet
                        bare
                        inline
                        code="appleDiyInstructions"
                      />{" "}
                      and{" "}
                      <CodeSnippet bare inline code="googleDiyInstructions" />
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="hyperlink_text"
                type="Boolean"
                description={
                  <div className="space-y-2">
                    <p>
                      Contains the text that should be displayed as a hyperlink
                      in the instructions.
                      <CodeSnippet bare inline code="hyperlink_text" /> will
                      control the text that will be displayed, while the various
                      <CodeSnippet bare inline code="diyCancellationUrl" />{" "}
                      fields will control the URL that the text will link to.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="diy_cancellation_instructions"
                type="Array of Strings"
                description={
                  <div className="space-y-2">
                    <p>
                      An array of steps which can be presented to the user if
                      they wish to cancel a subscription themselves. These steps
                      may contain the strings{" "}
                      <CodeSnippet bare inline code="diyUrl" />, which should be
                      replaced eith a link to the url provided in the field{" "}
                      <CodeSnippet bare inline code="diyCancellationUrl" />.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="diy_cancellation_instructions_reminder"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      An optional reminder which can be presented to the user
                      when{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="diy_cancellation_instructions"
                      />{" "}
                      is presented.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="apple_diy_cancellation_instructions"
                type="Array of Strings"
                description={
                  <div className="space-y-2">
                    <p>
                      An array of steps which can be presented to the user if
                      they wish to cancel a subscription themselves, if that
                      subscription was purchased on Apple pay. they wish to
                      cancel a subscription themselves. These steps may contain
                      the string <CodeSnippet bare inline code="diyUrl" />,
                      which should be replaced eith a link to the url provided
                      in the field{" "}
                      <CodeSnippet bare inline code="appleDiyCancellationUrl" />
                      .
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="apple_diy_cancellation_instructions_reminder"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      An optional reminder which can be presented to the user
                      when{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="apple_diy_cancellation_instructions"
                      />{" "}
                      is presented.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="google_diy_cancellation_instructions"
                type="Array of Strings"
                description={
                  <div className="space-y-2">
                    <p>
                      An array of steps which can be presented to the user if
                      they wish to cancel a subscription themselves, if that
                      subscription was purchased on Google pay. cancel a
                      subscription themselves. These steps may contain the
                      string <CodeSnippet bare inline code="diyUrl" />, which
                      should be replaced eith a link to the url provided in the
                      field{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="googleDiyCancellationUrl"
                      />
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="google_diy_cancellation_instructions_reminder"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      An optional reminder which can be presented to the user
                      when{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="google_diy_cancellation_instructions"
                      />{" "}
                      is presented.
                    </p>
                  </div>
                }
              />

              <FieldAttribute
                field="diy_cancellation_url"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      A url which can be presented to the user as part of the
                      instructions for cancelling a subscription themselves.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="apple_diy_cancellation_url"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      A url which can be presented to the user as part of the
                      instructions for cancelling an Apple Pay subscription
                      themselves.
                    </p>
                  </div>
                }
              />
              <FieldAttribute
                field="google_diy_cancellation_url"
                type="String"
                description={
                  <div className="space-y-2">
                    <p>
                      A url which can be presented to the user as part of the
                      instructions for cancelling a Google Pay subscription
                      themselves.
                    </p>
                  </div>
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(providerObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <ListProviders id="list-providers" />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindProvider id="find-provider" />
    </React.Fragment>
  );
}

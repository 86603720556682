import { Space, Stack } from "@cohubinc/blueprint";
import { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Typography from "src/components/Typography";
import itemObjectJsonFile from "src/examples/item.json";
import usePageTitle from "src/hooks/usePageTitle";
import { StyledApiDocs } from "../styles";

export default function ApiDocsItems() {
  usePageTitle("Items");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          {/* <Typography.Subtitle block>Items</Typography.Subtitle>
          <Space height={1} /> */}
          <Typography.Large>
            An item is an individual charge (think line item) that appears on a
            bill. Items are what BillFixers negotiates.
          </Typography.Large>
          <Typography.Large>
            <span className="font-semibold underline">
              Items are only accessible via the API through their parent bill.
            </span>{" "}
            There are no methods to list, find, create, update, delete or
            otherwise interact with an item.
          </Typography.Large>
          <Typography.Large>
            Items will only appear on a bill after that bill has a successful
            negotiation.
          </Typography.Large>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="item">
              <FieldAttribute
                field={"name"}
                type={"string"}
                description={
                  "The name of the good or service that the provider is charging for."
                }
              />
              <FieldAttribute
                field={"type"}
                type={"string"}
                description={
                  <div>
                    <p className="mb-4">
                      The type of the item, either a 'Discount', an 'Otc' (which
                      is an abbreviation for 'One time credit'), or a
                      'PlanChange'.
                    </p>
                    <p className="mb-4">
                      A discount represents a monthly savings amount that recurs
                      each month on a bill. For example, we may negotiate a
                      reduction in a modem rental fee from $10.00 to $5.00
                      that's good for 12 months.
                    </p>
                    <p className="mb-4">
                      A one time credit or 'Otc' is a savings amount that
                      happens only once on a customers bill. For example, we may
                      negotiate a loyalty credit of $50 that applies to the
                      customer's next billing period.
                    </p>
                    <p>
                      A plan change represents a new billing plan/package that
                      resulted in savings for the customer. For example, with a
                      customer's consent we may change their TV plan that offers
                      500 channels to a plan that offers 200 channels to save
                      them $100 a month. Plan changes almost always happen in
                      conjunction with an Offer since we frequently require the
                      customer's consent to change their plan.
                    </p>
                  </div>
                }
                possibleValues={["Discount", "Otc", "PlanChange"]}
              />
              <FieldAttribute
                field={"prePrice"}
                type={"money"}
                description={
                  "The price the customer paid before BillFixers performed any negotiations."
                }
              />
              <FieldAttribute
                field={"postPrice"}
                type={"money"}
                description={
                  "The price the customer paid after BillFixers performed any negotiations."
                }
              />
              <FieldAttribute
                field={"savings"}
                type={"money"}
                description={
                  "The amount that BillFixers negotiated, calculated by subtracting the postPrice from the prePrice and multiplying by the duration the savings lasts for."
                }
              />
              <FieldAttribute
                field={"savingsStartOn"}
                type={"dateTime"}
                description={
                  "The date and time that negotiated savings begin. NOTE: Items may have different start on dates."
                }
              />
              <FieldAttribute
                field={"savingsEndOn"}
                type={"dateTime"}
                description={
                  "The date and time that negotiated savings expires. NOTE: Items may have different end on dates."
                }
              />
              <FieldAttribute
                field={"duration"}
                type={"integer"}
                description={
                  "The length of time in months that the savings is good for."
                }
              />
              <FieldAttribute
                field={"underContract"}
                type={"boolean"}
                description={
                  "Indicates if the item is part of a contract with the provider."
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <CodeSnippet
          className="w-full"
          code={JSON.stringify(itemObjectJsonFile, null, 2)}
          language="json"
        />
      </StyledApiDocs>
    </Fragment>
  );
}

import React, { ReactNode } from "react";

type TShadow =
  | "shadow-xs"
  | "shadow-sm"
  | "shadow"
  | "shadow-md"
  | "shadow-lg"
  | "shadow-xl"
  | "shadow-2xl";

interface IProps {
  children: ReactNode;
  shadow?: TShadow;
  className?: string;
}

export default function Segment({
  children,
  shadow = "shadow",
  className
}: IProps) {
  return (
    <div className={`rounded-lg bg-white p-4 ${shadow} ${className}`}>
      {children}
    </div>
  );
}

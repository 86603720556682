import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";
import logo from "src/assets/logo.png";
import { useTestMode } from "src/contexts/TestModeContext";
import useMeta from "src/hooks/useMeta";
import useSessionCheck from "src/hooks/useSessionCheck";
import IdleTimer from "../IdleTimer";
import TestModeToggle from "../TestModeToggle";
import Sidebar from "./Sidebar";

interface IProps {
  children: JSX.Element[] | JSX.Element | string | Element[];
}

const Layout: React.FC<IProps> = ({ children }) => {
  useSessionCheck();

  const { pageTitle } = useMeta();
  const { testMode } = useTestMode();
  const { pathname } = useLocation();

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <div className="flex">
            <button className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open sidebar</span>
              {/* Heroicon name: menu */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <img
              className="h-8 w-auto self-center"
              style={{ margin: "0px auto" }}
              src={logo}
              alt="BillFixers"
            />
          </div>
        </div>
        <main
          className="relative z-0 flex-1 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          {testMode && (
            <div
              className="sticky top-0 z-[1000] flex w-full items-center justify-between bg-purple-100 p-4 text-purple-800 shadow"
              style={{}}
            >
              <div className="">You are viewing test mode data.</div>
              <div className="flex items-center">
                <div className="mr-2">Turn off test mode</div>
                <TestModeToggle onChange={() => window.location.reload()} />
              </div>
            </div>
          )}
          <IdleTimer />
          <div className="py-6">
            {pathname.includes("/api-docs") && (
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-medium text-gray-800">
                  {pageTitle}
                </h1>
              </div>
            )}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default observer(Layout);

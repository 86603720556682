import React from "react";

type TBadgeVersion =
  | "primary"
  | "secondary"
  | "plain"
  | "danger"
  | "success"
  | "warning"
  | "purple";
interface IProps {
  text?: string;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
  version?: TBadgeVersion;
}

export default function Badge({
  text,
  className,
  size = "md",
  version
}: IProps) {
  const paddingSize = () => {
    switch (size) {
      case "xs":
        return " px-1 py-0.5 text-xs ";
      case "sm":
        return " px-2 py-0.5 text-xs ";
      case "md":
        return " px-3 py-0.5 text-sm leading-5 font-semibold ";
      case "lg":
        return " px-4 py-0.5 text-base leading-6 font-semibold ";
      default:
        return " px-3 py-0.5 text-sm leading-5 ";
    }
  };

  const versionClassNames = () => {
    switch (version) {
      case "primary":
        return " bg-bf-green-200 text-bf-green-800 ";
      case "secondary":
        return " bg-blue-500 text-blue-100 ";
      case "danger":
        return " bg-red-700 text-red-100 ";
      case "success":
        return " bg-green-500 text-green-100 ";
      case "warning":
        return " bg-orange-500 text-orange-100 ";
      case "plain":
        return " bg-gray-800 text-gray-200 ";
      case "purple":
        return " bg-purple-200 text-purple-700 ";
      default:
        return " ";
    }
  };

  return (
    <span
      className={`inline-flex items-center rounded ${className} ${paddingSize()} ${versionClassNames()}`}
    >
      {text}
    </span>
  );
}

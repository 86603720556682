const paths = {
  bill: (billId: string) => `/bills/${billId}`,
  bills: "/bills",
  webhookEvents: "/webhook-events",
  customer: (customerId: string) => `/customers/${customerId}`,
  customers: "/customers",
  dashboard: "/",
  infoRequest: (infoReqId: string) => `/info-reqs/${infoReqId}`,
  infoRequests: "/info-reqs",
  offerPath: (offerId: string) => `/offers/${offerId}`,
  offers: "/offers",
  profile: "/profile",
  reports: "/reports",
  resetPassword: "/reset-password",
  newPassword: "/new-password",
  settings: "/settings",
  rateLimits: "/api-docs/rate-limits",
  webhooks: "/api-docs/webhooks",
  apiDocs: {
    home: "/api-docs",
    auth: "/api-docs/authentication",
    bills: "/api-docs/bills",
    negotiations: "/api-docs/negotiations",
    items: "/api-docs/items",
    customers: "/api-docs/customers",
    providers: "/api-docs/providers",
    infoReqs: "/api-docs/info-reqs",
    offers: "/api-docs/offers",
    savingsEstimate: "/api-docs/savings-estimate",
    savingsEvent: "/api-docs/savings-event",
    sandboxResources: "/api-docs/sandbox-resources",
    cancellations: "/api-docs/cancellations",
    cancellationInfoReqs: "/api-docs/cancellation-info-requests",
    cancellationOffers: "/api-docs/cancellation-offers",
    implementation: {
      home: "/api-docs/implementation",
      creatingBills: "/api-docs/implementation/creating-bills",
      creatingCustomers: "/api-docs/implementation/creating-customers",
      respondingToInfoRequests:
        "/api-docs/implementation/responding-to-info-requests",
      respondingToOffers: "/api-docs/implementation/responding-to-offers",
      interpretingWebhooks: "/api-docs/implementation/interpreting-webhooks"
    }
  },
  libraries: {
    javascript: "/api-docs/libraries/javascript",
    ruby: "/api-docs/libraries/ruby"
  }
};

export default paths;

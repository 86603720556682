import React from "react";
import Response from "src/components/ApiDocs/Response";
import Breadcrumbs from "src/components/Breadcrumbs";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import findOfferResponseJsonFile from "src/examples/responses/find_offer.json";
import usePageTitle from "src/hooks/usePageTitle";

export default function RespondingToOffers() {
  usePageTitle("Responding to Offers");

  return (
    <NewContainer className="mt-8">
      <Breadcrumbs
        crumbs={[
          {
            path: "/api-docs/implementation",
            text: "Implementation Guide",
            active: false
          },
          {
            path: "/api-docs/implementation/responding-to-offers",
            text: "Responding to Offers",
            active: true
          }
        ]}
      />
      <div className="mt-8">
        <h3
          className="text-xl text-gray-bf-text-medium mb-2"
          id="creating-bills"
        >
          Responding to offers
        </h3>
      </div>
      <div className="mt-8">
        <div className="mb-8">
          <h3 className="text-xl text-gray-bf-text-medium mb-2">
            Listen for <CodeSnippet bare inline code="offer.created" /> webhooks
          </h3>
          <p className="mb-4">
            We can often save customers more money if they agree to certain
            terms set by the provider. When this happens, we create an offer
            that the customer must accept or reject within 30 days. If we
            haven't received a response in 30 days, we'll assume the customer
            does not want the offer and proceed with our negotiations.
          </p>
          <p className="mb-4">
            Below are some reasons we might create an offer:
          </p>
          <ul className="text-sm mb-8 ml-8 list-disc">
            <li className="mb-3">
              A contract with the provider would result in additional savings
              for the customer
            </li>
            <li className="mb-3">
              Removing unused services with a provider (like landline telephone
              service or monthly insurance on a cell phone) would result in
              additional savings for the customer
            </li>
            <li className="mb-3">
              A change in the current level of service (i.e. a faster internet
              package) would result in additional savings for the customer or
              would otherwise benefit the customer at no cost to them.
            </li>
          </ul>
          {/* <p>
            If we encounter any problems while negotiating, we'll create an{" "}
            <CodeSnippet bare inline code="informationRequest" /> and you'll
            receive a webhook.
          </p> */}
        </div>
        <div className="mb-8">
          <h3 className="text-xl text-gray-bf-text-medium mb-2">
            Find the offer
          </h3>
          <p className="mb-4">
            The webhook will contain the ID of the offer, which you should pass
            to the <CodeSnippet bare inline code="findOffer" /> query. The query
            will respond with the offer.
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-xl text-gray-bf-text-medium mb-2">
            Present the content to the customer and record their response
          </h3>
          <p className="mb-4">
            Show the customer the content field from the offer and give them a
            way to "Accept" or "Reject" the offer.
          </p>
          <p className="mb-4">
            For example, say a provider tells us that we can save a customer
            $100 more dollars a month if they agree to a 2 year contract. The{" "}
            <CodeSnippet bare inline code="findOffer" /> query would respond
            with an offer object like this:
          </p>
          <Response
            className="mt-6 mb-6"
            responseJson={JSON.stringify(findOfferResponseJsonFile, null, 2)}
          />
          <p className="mb-4">
            The two options to "Accept" or "Reject" an offer should be tied to
            the <CodeSnippet bare inline code="acceptOffer" /> mutation and the{" "}
            <CodeSnippet bare inline code="rejectOffer" /> mutation, both of
            which require the ID of the offer as the only argument.
          </p>
          <p className="mb-4">
            Once the offer has been accepted or rejected, you'll receive another
            webhook that corresponds with either action. If the offer did not
            receive a response from the customer within 30 days, you'll receive
            an <CodeSnippet bare inline code="offer.rejected" /> webhook after
            we automatically reject the offer due to no response from the
            customer.
          </p>
        </div>
      </div>
    </NewContainer>
  );
}

import { TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import {
  IPartnerFragment,
  useCreatePartnerWebhookHeaderMutation,
  useDeletePartnerWebhookHeaderMutation
} from "src/components/graphql";
import NewButton from "src/components/NewButton";
import SideSheet from "src/components/SideSheet";
import { useTestMode } from "src/contexts/TestModeContext";
import useCurrentUser from "src/hooks/useCurrentUser";
import WebhookHeaderForm from "./WebhookHeaderForm";

interface IProps {
  partner: IPartnerFragment;
}

export default function WebhookHeaders({ partner }: IProps) {
  const { currentUser } = useCurrentUser();
  const { testMode } = useTestMode();
  const [showHeaderForm, setShowHeaderForm] = useState(false);
  const [headerFormErrors, setHeaderFormErrors] = useState<
    string[] | undefined
  >();

  const [createWebhookHeaderMutation] = useCreatePartnerWebhookHeaderMutation();

  const [deleteWebhookHeaderMutation] = useDeletePartnerWebhookHeaderMutation();

  const createWebhookHeader = async (values: any) => {
    const result = await createWebhookHeaderMutation({
      variables: {
        webhookHeader: {
          headerName: values.headerName,
          headerValue: values.headerValue,
          headerType: values.headerType
        }
      },
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.CreatePartnerWebhookHeader?.success) {
      setShowHeaderForm(false);
    } else if (result.data?.CreatePartnerWebhookHeader?.errors) {
      setHeaderFormErrors(result.data?.CreatePartnerWebhookHeader?.errors);
    }
  };

  const deleteWebhookHeader = async (headerId: string) => {
    const result = await deleteWebhookHeaderMutation({
      variables: {
        webhookHeaderId: headerId
      },
      refetchQueries: ["FindCurrentPartner"]
    });

    if (result.data?.DeletePartnerWebhookHeader?.success) {
      console.log("something");
    }
  };

  const testHeaders = partner.webhookHeaders
    .filter((header) => header.headerType === "test_header")
    .map((h) => {
      return (
        <div className="flex items-center justify-between">
          <p>{h.headerName}</p>
          <p>{h.headerValue}</p>
          <TrashIcon
            onClick={() => deleteWebhookHeader(h.id)}
            className={`h-5 w-5 cursor-pointer text-red-500 ${
              testMode ? "pointer-events-none text-red-200" : ""
            }`}
          />
        </div>
      );
    });

  const liveHeaders = partner.webhookHeaders
    .filter((header) => header.headerType === "live_header")
    .map((h) => {
      return (
        <div className="flex items-center justify-between">
          <p>{h.headerName}</p>
          <p>{h.headerValue}</p>
          <TrashIcon
            onClick={() => deleteWebhookHeader(h.id)}
            className={`h-5 w-5 cursor-pointer text-red-500 ${
              testMode ? "pointer-events-none text-red-200" : ""
            }`}
          />
        </div>
      );
    });

  return (
    <React.Fragment>
      <div className="mb-6 mt-20">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Webhook Headers
            </h3>
            <p className="text-sm text-gray-500">
              Any headers you provide will be sent with every webhook.
            </p>
          </div>
          {currentUser?.role === "manager" && (
            <NewButton
              size="sm"
              onClick={() => setShowHeaderForm(true)}
              disabled={testMode}
            >
              Add Header
            </NewButton>
          )}
        </div>
        <div className="mt-8 grid grid-cols-2 items-start gap-8">
          <div className="rounded-lg bg-white p-4 shadow">
            <h5 className="mb-2 text-base text-gray-700">Test Headers</h5>
            {testHeaders}
          </div>
          <div className="rounded-lg bg-white p-4 shadow">
            <h5 className="mb-2 text-base text-gray-700">Live Headers</h5>
            {liveHeaders}
          </div>
        </div>
      </div>
      <SideSheet open={showHeaderForm} onClose={() => setShowHeaderForm(false)}>
        <WebhookHeaderForm
          initialValues={{ headerType: "test_header" }}
          errors={headerFormErrors}
          onSubmit={createWebhookHeader}
        />
      </SideSheet>
    </React.Fragment>
  );
}

import React from "react";
import Well from "src/components/Well";

interface IProps {
  body: string;
  className?: string;
}

export default function SuggestedApproach({ body, className }: IProps) {
  return (
    <div>
      <Well sunken className={`${className}`}>
        <h5 className="text-xs text-bf-green-600 mb-1">Suggested Approach</h5>
        <p className="font-semibold text-xs">{body}</p>
      </Well>
    </div>
  );
}

import React, { CSSProperties } from "react";

export type TMargin = 0.5 | 1 | 1.5 | 2 | 3 | 4 | 5 | 6;
export interface IDividerProps {
  /**
   * Margin, as rems, used on Y axis of element
   * @defaultValue 2
   */
  marginSize?: TMargin;
  /**
   * Margin as rems used on top of element
   * @defaultValue Same as `marginSize` default
   */
  marginTop?: TMargin;
  /**
   * Margin as rems used on bottom of element
   * @defaultValue Same as `marginSize` default
   */
  marginBottom?: TMargin;
  /**
   * Show or hide divider line
   * @defaultValue true
   */
  invisible?: boolean;
  className?: string;
  style?: CSSProperties;
  width?: number;
}

export default function Divider(props: IDividerProps) {
  const {
    marginSize = 1,
    marginTop = marginSize,
    marginBottom = marginSize,
    invisible = false,
    width = 100,
    className,
    style
  } = props;

  return (
    <div
      className={`${!invisible && "bg-gray-300"} ${className}`}
      style={{
        height: "1px",
        marginTop: `${marginTop || 0}rem`,
        marginBottom: `${marginBottom || 0}rem`,
        width: `${width}%`,
        ...style
      }}
    />
  );
}

import React, { ReactNode, ReactNodeArray } from "react";

interface IProps {
  children?: ReactNode | ReactNodeArray;
  className?: string;
}

export default function NewContainer({ children, className }: IProps) {
  return (
    <div
      className={`w-full md:w-7/8 md:max-w-screen-xl px-2 md:mx-auto ${className}`}
    >
      {children}
    </div>
  );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import listProvidersResponseJsonFile from "src/examples/responses/list_providers.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListProviders({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("list-providers");
      if (hash.includes("list-providers")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/providers#list-providers">
        <h3 className="mb-8 text-xl font-medium">List providers</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <Parameter
            field={"rankedOnly"}
            type={"boolean"}
            description={
              <React.Fragment>
                <div>
                  If true, the query will only return providers with a non-null
                  ranking value.
                </div>
              </React.Fragment>
            }
          />
          <Parameter
            field={"negotiable"}
            type={"boolean"}
            description={
              <React.Fragment>
                <div>
                  If true, the query will only return providers that we can
                  negotiate with.
                </div>
              </React.Fragment>
            }
          />
          <Parameter
            field={"cancellable"}
            type={"boolean"}
            description={
              <React.Fragment>
                <div>
                  If true, the query will only return providers that we can
                  attempt to cancel service with.
                </div>
              </React.Fragment>
            }
          />
          <Parameter
            field={"cancellationRankedOnly"}
            type={"boolean"}
            description={
              <React.Fragment>
                <div>
                  If true, the query will only return providers with a non-null
                  cancellation_ranking value.
                </div>
              </React.Fragment>
            }
          />
          <Parameter
            field={"anyRanked"}
            type={"boolean"}
            description={
              <React.Fragment>
                <div>
                  If true, the query will only any provider that has a ranking
                  or a cancellation_ranking value.
                </div>
              </React.Fragment>
            }
          />
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An array of the providers that BillFixers currently negotiates
              with.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, listProvidersExampleRubyBody),
              js: javascriptExample(testApiKey!, listProvidersExampleJsBody),
              curl: curlExample(testApiKey!, listProvidersExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              listProvidersResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const listProvidersExampleRubyBody = `
providers = client.list_providers
`;

const listProvidersExampleJsBody = `
const { providers } = await client.listProviders();
`;

const listProvidersExampleCurlBody = `
$'{
	"query": "query ListProviders($rankedOnly:Boolean,$negotiable:Boolean,$cancellable:Boolean,$cancellationRankedOnly:Boolean,$anyRanked:Boolean){providers:ListProviders(rankedOnly:$rankedOnly,negotiable:$negotiable,cancellable:$cancellable,cancellationRankedOnly:$cancellationRankedOnly){id name services createdAt updatedAt logo{tinyUrl smallUrl mediumUrl} providerBillFields{id providerId label name placeholder dataType required requiredResponseLength helpText createdAt updatedAt}}}",
	"variables": {
    "rankedOnly": true,
    "negotiable": true
  }
`;

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents a date in ISO 8601 format */
  Date: string;
  /** Represents date/time in iso8601 format */
  DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  /** Represents money value */
  Money: number;
};

/** Autogenerated input type of AcceptCancellationOffer */
export type IAcceptCancellationOfferInput = {
  cancellationOfferId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of AcceptCancellationOffer. */
export type IAcceptCancellationOfferPayload = {
  __typename: "AcceptCancellationOfferPayload";
  cancellationOffer?: Maybe<ICancellationOffer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of AcceptOffer */
export type IAcceptOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  offerId: Scalars["ID"];
};

/** Autogenerated return type of AcceptOffer. */
export type IAcceptOfferPayload = {
  __typename: "AcceptOfferPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  offer?: Maybe<IOffer>;
  success: Scalars["Boolean"];
};

export type IBill = {
  __typename: "Bill";
  allowsContract: Scalars["Boolean"];
  autoRenegotiate: Scalars["Boolean"];
  badDocument: Scalars["Boolean"];
  /** The date the bill negotiations were cancelled, if present */
  cancelledAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  /** The date the record was created */
  createdAt: Scalars["DateTime"];
  customer: ICustomer;
  customerEligibileForNegotiation: Scalars["Boolean"];
  /** Billfixer's alpha-numeric Customer ID */
  customerId: Scalars["ID"];
  documentCount: Scalars["Int"];
  documentless: Scalars["Boolean"];
  /** Allows documentless submission of a bill */
  documentlessAllowed: Scalars["Boolean"];
  /** A JSON object containing fields needed for documentless negotiation related to this bill's provider. */
  documentlessInfo?: Maybe<Scalars["JSON"]>;
  documents: Array<IDocument>;
  /** The lifetime value gained from negotiations */
  grossSavings: Scalars["Money"];
  /** Alpha-numeric unique identifier */
  id: Scalars["String"];
  informationRequests: Array<IInformationRequest>;
  /** The items on the bill */
  items: Array<IItem>;
  missingDocumentInfo: Scalars["Boolean"];
  negotiationResultsNote?: Maybe<Scalars["String"]>;
  /** The negotiations on the bill */
  negotiations: Array<INegotiation>;
  nextRenegotiateOn?: Maybe<Scalars["Date"]>;
  offers: Array<IOffer>;
  provider: IProvider;
  /** Billfixer's alpha-numeric Provider ID */
  providerId: Scalars["ID"];
  readyToNegotiate: Scalars["Boolean"];
  /** End date of savings */
  savingsEndOn: Scalars["DateTime"];
  /** End date of savings */
  savingsExpireOn: Scalars["DateTime"];
  /** Start date of savings */
  savingsStartOn: Scalars["DateTime"];
  scheduledForNegotiationAt?: Maybe<Scalars["DateTime"]>;
  /** Status of the bill */
  status: Scalars["String"];
  /** Title of the bill */
  title: Scalars["String"];
  /** The current value gained from negotiations */
  totalSavings: Scalars["Money"];
  typeOfSavings?: Maybe<Scalars["String"]>;
  /** The last time the record was updated */
  updatedAt: Scalars["DateTime"];
};

/** Attributes for creating or updating a bill */
export type IBillAttributes = {
  /** Indicates if the customer is willing to accept a contract to save money */
  allowsContract?: InputMaybe<Scalars["Boolean"]>;
  /** Inidicates if the customer is wants the bill to be renegotiated after savings/discounts expire */
  autoRenegotiate?: InputMaybe<Scalars["Boolean"]>;
  /** Array of document URLs to be attached to the bill */
  documentUrls?: InputMaybe<Array<Scalars["String"]>>;
  /** The last 4 digits of the customer's social security number */
  last4ssn?: InputMaybe<Scalars["String"]>;
  /** The passcode used to authorize changes to the account */
  passcode?: InputMaybe<Scalars["String"]>;
};

/** The connection type for Bill. */
export type IBillConnection = {
  __typename: "BillConnection";
  /** A list of edges. */
  edges: Array<IBillEdge>;
  /** A list of nodes. */
  nodes: Array<IBill>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
  totalSavings: Scalars["Money"];
};

/** An edge in a connection. */
export type IBillEdge = {
  __typename: "BillEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IBill>;
};

export type ICancellation = {
  __typename: "Cancellation";
  alreadyCancelledAt?: Maybe<Scalars["DateTime"]>;
  awaitedConsentAt?: Maybe<Scalars["DateTime"]>;
  awaitedInformationAt?: Maybe<Scalars["DateTime"]>;
  /** A JSON object containing fields needed for cancellation this cancellation's provider. */
  cancellationInfo?: Maybe<Scalars["JSON"]>;
  cancellationInfoRequests: Array<ICancellationInfoRequest>;
  cancellationOffers: Array<ICancellationOffer>;
  createdAt: Scalars["DateTime"];
  customer: ICustomer;
  customerCancellationInstructions?: Maybe<Scalars["String"]>;
  customerId: Scalars["ID"];
  dateOfLastTransaction?: Maybe<Scalars["DateTime"]>;
  expectedSavings?: Maybe<Scalars["Money"]>;
  /** Alpha-numeric unique identifier */
  id: Scalars["String"];
  identifiedSubscription: Scalars["Boolean"];
  infoRequests: Array<ICancellationInfoRequest>;
  last4OfPaymentMethod?: Maybe<Scalars["String"]>;
  noSubscriptionFoundAt?: Maybe<Scalars["DateTime"]>;
  paymentAmount: Scalars["Money"];
  provider: IProvider;
  providerCancellationFieldResponses: Array<IProviderCancellationFieldResponse>;
  providerCancellationFields: Array<IProviderCancellationField>;
  providerId: Scalars["ID"];
  /** A JSON object containing fields needed for cancellation this cancellation's provider. */
  providerSpecificFieldsJson?: Maybe<Scalars["JSON"]>;
  rawTransactionName?: Maybe<Scalars["String"]>;
  readyToCancel: Scalars["Boolean"];
  readyToCancelAt?: Maybe<Scalars["DateTime"]>;
  refundAmount: Scalars["Money"];
  state: Scalars["String"];
  succeededAt?: Maybe<Scalars["DateTime"]>;
  unableToCancelAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

/** Attributes for creating or updating a cancellation */
export type ICancellationAttributes = {
  /** Id of the customer */
  customerId?: InputMaybe<Scalars["ID"]>;
  dateOfLastTransaction?: InputMaybe<Scalars["DateTime"]>;
  last4OfPaymentMethod?: InputMaybe<Scalars["String"]>;
  paymentAmount?: InputMaybe<Scalars["Money"]>;
  /** Id for the provider */
  providerId?: InputMaybe<Scalars["ID"]>;
  providerSpecificFields?: InputMaybe<Scalars["JSON"]>;
  rawTransactionName?: InputMaybe<Scalars["String"]>;
};

/** The connection type for Cancellation. */
export type ICancellationConnection = {
  __typename: "CancellationConnection";
  /** A list of edges. */
  edges: Array<ICancellationEdge>;
  /** A list of nodes. */
  nodes: Array<ICancellation>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ICancellationEdge = {
  __typename: "CancellationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ICancellation>;
};

export type ICancellationInfoRequest = {
  __typename: "CancellationInfoRequest";
  cancellationId: Scalars["ID"];
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  fields: Array<ICancellationInfoRequestField>;
  headerText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  respondedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

/** Attributes for updating an information request */
export type ICancellationInfoRequestAttributes = {
  fields: Array<ICancellationInfoRequestFieldAttributes>;
};

export type ICancellationInfoRequestField = {
  __typename: "CancellationInfoRequestField";
  cancellationInfoRequestId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  id: Scalars["ID"];
  label: Scalars["String"];
  placeholder: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  value?: Maybe<Scalars["String"]>;
};

/** Attributes for updating cancellation info request fields */
export type ICancellationInfoRequestFieldAttributes = {
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type ICancellationOffer = {
  __typename: "CancellationOffer";
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  cancellation: IBill;
  /** Alpha-numeric unique identifier */
  cancellationId: Scalars["String"];
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: ICustomer;
  /** Alpha-numeric unique identifier */
  customerId: Scalars["String"];
  daysUntilExpiration: Scalars["Int"];
  /** Alpha-numeric unique identifier */
  id: Scalars["String"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** Autogenerated input type of CreateBill */
export type ICreateBillInput = {
  bill: IBillAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the customer */
  customerId: Scalars["ID"];
  /** The ID of the provider */
  providerId: Scalars["ID"];
};

/** Autogenerated return type of CreateBill. */
export type ICreateBillPayload = {
  __typename: "CreateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateCancellation */
export type ICreateCancellationInput = {
  cancellation?: InputMaybe<ICancellationAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the customer */
  customerId: Scalars["ID"];
  /** The ID of the provider */
  providerId: Scalars["ID"];
};

/** Autogenerated return type of CreateCancellation. */
export type ICreateCancellationPayload = {
  __typename: "CreateCancellationPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateCustomer */
export type ICreateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  customer: ICustomerAttributes;
};

/** Autogenerated return type of CreateCustomer. */
export type ICreateCustomerPayload = {
  __typename: "CreateCustomerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateDocument */
export type ICreateDocumentInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  document: IDocumentAttributes;
};

/** Autogenerated return type of CreateDocument. */
export type ICreateDocumentPayload = {
  __typename: "CreateDocumentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  document?: Maybe<IDocument>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreatePartnerUser */
export type ICreatePartnerUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  partnerUser: IPartnerUserAttributes;
};

/** Autogenerated return type of CreatePartnerUser. */
export type ICreatePartnerUserPayload = {
  __typename: "CreatePartnerUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  partnerUser?: Maybe<IPartnerUser>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreatePartnerWebhookHeader */
export type ICreatePartnerWebhookHeaderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhookHeader: IPartnerWebhookHeaderAttributes;
};

/** Autogenerated return type of CreatePartnerWebhookHeader. */
export type ICreatePartnerWebhookHeaderPayload = {
  __typename: "CreatePartnerWebhookHeaderPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  webhookHeader?: Maybe<IPartnerWebhookHeader>;
};

/** Autogenerated input type of CreatePartnerWebhook */
export type ICreatePartnerWebhookInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhook: IPartnerWebhookAttributes;
};

/** Autogenerated return type of CreatePartnerWebhook. */
export type ICreatePartnerWebhookPayload = {
  __typename: "CreatePartnerWebhookPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  webhook?: Maybe<IPartnerWebhook>;
};

/** The person being billed by a provider */
export type ICustomer = {
  __typename: "Customer";
  allTimeGrossSavings: Scalars["Money"];
  b2b: Scalars["Boolean"];
  billCount: Scalars["Int"];
  bills: IBillConnection;
  corvetteId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  eligibleForCancellation: Scalars["Boolean"];
  eligibleForNegotiation: Scalars["Boolean"];
  email?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  last4ssn?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  membershipId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  readyBillCount: Scalars["Int"];
  referralData?: Maybe<Scalars["JSON"]>;
  type: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** The person being billed by a provider */
export type ICustomerbillsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Attributes for creating or updating a customer */
export type ICustomerAttributes = {
  corvetteId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  last4ssn?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  membershipId?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  vip?: InputMaybe<Scalars["Boolean"]>;
};

/** The connection type for Customer. */
export type ICustomerConnection = {
  __typename: "CustomerConnection";
  /** A list of edges. */
  edges: Array<ICustomerEdge>;
  /** A list of nodes. */
  nodes: Array<ICustomer>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ICustomerEdge = {
  __typename: "CustomerEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ICustomer>;
};

/** Autogenerated input type of DeletePartnerWebhookHeader */
export type IDeletePartnerWebhookHeaderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhookHeaderId: Scalars["ID"];
};

/** Autogenerated return type of DeletePartnerWebhookHeader. */
export type IDeletePartnerWebhookHeaderPayload = {
  __typename: "DeletePartnerWebhookHeaderPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DeletePartnerWebhook */
export type IDeletePartnerWebhookInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhookId: Scalars["ID"];
};

/** Autogenerated return type of DeletePartnerWebhook. */
export type IDeletePartnerWebhookPayload = {
  __typename: "DeletePartnerWebhookPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type IDocument = {
  __typename: "Document";
  billId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  documentableId: Scalars["ID"];
  documentableType: Scalars["String"];
  filename: Scalars["String"];
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

/** Attributes for creating or updating a document */
export type IDocumentAttributes = {
  base64String?: InputMaybe<Scalars["String"]>;
  tempUrl?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type IImage = {
  __typename: "Image";
  grandeUrl: Scalars["String"];
  iconUrl: Scalars["String"];
  id?: Maybe<Scalars["ID"]>;
  imageProcessed: Scalars["Boolean"];
  largeUrl: Scalars["String"];
  mediumUrl: Scalars["String"];
  originalUrl: Scalars["String"];
  smallUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
  tinyUrl: Scalars["String"];
};

export type IInformationRequest = {
  __typename: "InformationRequest";
  bill: IBill;
  /** Alpha-numeric unique identifier */
  billId: Scalars["String"];
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: ICustomer;
  /** Alpha-numeric unique identifier */
  customerId: Scalars["String"];
  fields: Array<IInformationRequestField>;
  headerText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  respondedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

/** Attributes for updating an information request */
export type IInformationRequestAttributes = {
  fields: Array<IInformationRequestFieldAttributes>;
};

/** The connection type for InformationRequest. */
export type IInformationRequestConnection = {
  __typename: "InformationRequestConnection";
  /** A list of edges. */
  edges: Array<IInformationRequestEdge>;
  /** A list of nodes. */
  nodes: Array<IInformationRequest>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IInformationRequestEdge = {
  __typename: "InformationRequestEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IInformationRequest>;
};

export type IInformationRequestField = {
  __typename: "InformationRequestField";
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  id: Scalars["ID"];
  label: Scalars["String"];
  placeholder: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  value?: Maybe<Scalars["String"]>;
};

/** Attributes for updating information request fields */
export type IInformationRequestFieldAttributes = {
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type IItem = {
  __typename: "Item";
  /** The date the record was created */
  createdAt: Scalars["DateTime"];
  /** The length of time in months that an item's savings is good for */
  duration: Scalars["Int"];
  /** Whether or not the savings for this item have expired */
  expired: Scalars["Boolean"];
  /** The savings divided by the duration */
  monthlySavings: Scalars["Money"];
  /** Name of the good or service */
  name: Scalars["String"];
  /** The post-negotiation price */
  postPrice: Scalars["Money"];
  /** The pre-negotiation price */
  prePrice: Scalars["Money"];
  /** The difference between the prePrice and the postPrice */
  savings: Scalars["Money"];
  /** The date the discount/savings end */
  savingsEndOn: Scalars["DateTime"];
  /** The date the discount/savings start */
  savingsStartOn: Scalars["DateTime"];
  /** The type of item */
  type: Scalars["String"];
  /** Indicates the item is under contract */
  underContract: Scalars["Boolean"];
  /** The last time the record was updated */
  updatedAt: Scalars["DateTime"];
};

export type IMutation = {
  __typename: "Mutation";
  /** Accept a cancellation offer */
  AcceptCancellationOffer?: Maybe<IAcceptCancellationOfferPayload>;
  /** Accept an offer */
  AcceptOffer?: Maybe<IAcceptOfferPayload>;
  CreateBill?: Maybe<ICreateBillPayload>;
  CreateCancellation?: Maybe<ICreateCancellationPayload>;
  CreateCustomer?: Maybe<ICreateCustomerPayload>;
  CreateDocument?: Maybe<ICreateDocumentPayload>;
  CreatePartnerUser?: Maybe<ICreatePartnerUserPayload>;
  CreatePartnerWebhook?: Maybe<ICreatePartnerWebhookPayload>;
  CreatePartnerWebhookHeader?: Maybe<ICreatePartnerWebhookHeaderPayload>;
  DeletePartnerWebhook?: Maybe<IDeletePartnerWebhookPayload>;
  DeletePartnerWebhookHeader?: Maybe<IDeletePartnerWebhookHeaderPayload>;
  ProvideCancellationInfo?: Maybe<IProvideCancellationInfoPayload>;
  ProvideDocumentlessInfo?: Maybe<IProvideDocumentlessInfoPayload>;
  /** Reject a cancellation offer */
  RejectCancellationOffer?: Maybe<IRejectCancellationOfferPayload>;
  /** Reject an offer */
  RejectOffer?: Maybe<IRejectOfferPayload>;
  RenegotiateBill?: Maybe<IRenegotiateBillPayload>;
  RespondToCancellationInfoRequest?: Maybe<IRespondToCancellationInfoRequestPayload>;
  RespondToInformationRequest?: Maybe<IRespondToInformationRequestPayload>;
  RotateApiKeys?: Maybe<IRotateApiKeysPayload>;
  SandboxGenerateNegotiation?: Maybe<ISandboxGenerateNegotiationPayload>;
  SandboxResetBill?: Maybe<ISandboxResetBillPayload>;
  SandboxTransitionBill?: Maybe<ISandboxTransitionBillPayload>;
  SandboxTransitionCancellation?: Maybe<ISandboxTransitionCancellationPayload>;
  SandboxTransitionNegotiation?: Maybe<ISandboxTransitionNegotiationPayload>;
  SandboxTriggerProviderUpdated?: Maybe<ISandboxTriggerProviderUpdatedPayload>;
  SetBillReadyToNegotiate?: Maybe<ISetBillReadyToNegotiatePayload>;
  SetCancellationReadyToCancel?: Maybe<ISetCancellationReadyToCancelPayload>;
  SetCustomerEligibleForCancellation?: Maybe<ISetCustomerEligibleForCancellationPayload>;
  SetCustomerEligibleForNegotiation?: Maybe<ISetCustomerEligibleForNegotiationPayload>;
  StopCancelling?: Maybe<IStopCancellingPayload>;
  StopNegotiating?: Maybe<IStopNegotiatingPayload>;
  UpdateBill?: Maybe<IUpdateBillPayload>;
  UpdateCancellation?: Maybe<IUpdateCancellationPayload>;
  UpdateCustomer?: Maybe<IUpdateCustomerPayload>;
  UpdatePartnerUser?: Maybe<IUpdatePartnerUserPayload>;
  UpdatePartnerWebhook?: Maybe<IUpdatePartnerWebhookPayload>;
  UpdatePartnerWebhookHeader?: Maybe<IUpdatePartnerWebhookHeaderPayload>;
  UpdateWebhookUrls?: Maybe<IUpdateWebhookUrlsPayload>;
};

export type IMutationAcceptCancellationOfferArgs = {
  input: IAcceptCancellationOfferInput;
};

export type IMutationAcceptOfferArgs = {
  input: IAcceptOfferInput;
};

export type IMutationCreateBillArgs = {
  input: ICreateBillInput;
};

export type IMutationCreateCancellationArgs = {
  input: ICreateCancellationInput;
};

export type IMutationCreateCustomerArgs = {
  input: ICreateCustomerInput;
};

export type IMutationCreateDocumentArgs = {
  input: ICreateDocumentInput;
};

export type IMutationCreatePartnerUserArgs = {
  input: ICreatePartnerUserInput;
};

export type IMutationCreatePartnerWebhookArgs = {
  input: ICreatePartnerWebhookInput;
};

export type IMutationCreatePartnerWebhookHeaderArgs = {
  input: ICreatePartnerWebhookHeaderInput;
};

export type IMutationDeletePartnerWebhookArgs = {
  input: IDeletePartnerWebhookInput;
};

export type IMutationDeletePartnerWebhookHeaderArgs = {
  input: IDeletePartnerWebhookHeaderInput;
};

export type IMutationProvideCancellationInfoArgs = {
  input: IProvideCancellationInfoInput;
};

export type IMutationProvideDocumentlessInfoArgs = {
  input: IProvideDocumentlessInfoInput;
};

export type IMutationRejectCancellationOfferArgs = {
  input: IRejectCancellationOfferInput;
};

export type IMutationRejectOfferArgs = {
  input: IRejectOfferInput;
};

export type IMutationRenegotiateBillArgs = {
  input: IRenegotiateBillInput;
};

export type IMutationRespondToCancellationInfoRequestArgs = {
  input: IRespondToCancellationInfoRequestInput;
};

export type IMutationRespondToInformationRequestArgs = {
  input: IRespondToInformationRequestInput;
};

export type IMutationRotateApiKeysArgs = {
  input: IRotateApiKeysInput;
};

export type IMutationSandboxGenerateNegotiationArgs = {
  input: ISandboxGenerateNegotiationInput;
};

export type IMutationSandboxResetBillArgs = {
  input: ISandboxResetBillInput;
};

export type IMutationSandboxTransitionBillArgs = {
  input: ISandboxTransitionBillInput;
};

export type IMutationSandboxTransitionCancellationArgs = {
  input: ISandboxTransitionCancellationInput;
};

export type IMutationSandboxTransitionNegotiationArgs = {
  input: ISandboxTransitionNegotiationInput;
};

export type IMutationSandboxTriggerProviderUpdatedArgs = {
  input: ISandboxTriggerProviderUpdatedInput;
};

export type IMutationSetBillReadyToNegotiateArgs = {
  input: ISetBillReadyToNegotiateInput;
};

export type IMutationSetCancellationReadyToCancelArgs = {
  input: ISetCancellationReadyToCancelInput;
};

export type IMutationSetCustomerEligibleForCancellationArgs = {
  input: ISetCustomerEligibleForCancellationInput;
};

export type IMutationSetCustomerEligibleForNegotiationArgs = {
  input: ISetCustomerEligibleForNegotiationInput;
};

export type IMutationStopCancellingArgs = {
  input: IStopCancellingInput;
};

export type IMutationStopNegotiatingArgs = {
  input: IStopNegotiatingInput;
};

export type IMutationUpdateBillArgs = {
  input: IUpdateBillInput;
};

export type IMutationUpdateCancellationArgs = {
  input: IUpdateCancellationInput;
};

export type IMutationUpdateCustomerArgs = {
  input: IUpdateCustomerInput;
};

export type IMutationUpdatePartnerUserArgs = {
  input: IUpdatePartnerUserInput;
};

export type IMutationUpdatePartnerWebhookArgs = {
  input: IUpdatePartnerWebhookInput;
};

export type IMutationUpdatePartnerWebhookHeaderArgs = {
  input: IUpdatePartnerWebhookHeaderInput;
};

export type IMutationUpdateWebhookUrlsArgs = {
  input: IUpdateWebhookUrlsInput;
};

export type INegotiation = {
  __typename: "Negotiation";
  autoCreated?: Maybe<Scalars["Boolean"]>;
  billId: Scalars["ID"];
  failedAt?: Maybe<Scalars["DateTime"]>;
  fixedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  items: Array<IItem>;
  negotiationNumber: Scalars["Int"];
  resultsNote?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  state: Scalars["String"];
  totalSavings: Scalars["Money"];
  typeOfSavings?: Maybe<Scalars["String"]>;
};

/** The connection type for Negotiation. */
export type INegotiationConnection = {
  __typename: "NegotiationConnection";
  /** A list of edges. */
  edges: Array<INegotiationEdge>;
  /** A list of nodes. */
  nodes: Array<INegotiation>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type INegotiationEdge = {
  __typename: "NegotiationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: INegotiation;
};

export type IOffer = {
  __typename: "Offer";
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  bill: IBill;
  /** Alpha-numeric unique identifier */
  billId: Scalars["String"];
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: ICustomer;
  /** Alpha-numeric unique identifier */
  customerId: Scalars["String"];
  daysUntilExpiration: Scalars["Int"];
  /** Alpha-numeric unique identifier */
  id: Scalars["String"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** The connection type for Offer. */
export type IOfferConnection = {
  __typename: "OfferConnection";
  /** A list of edges. */
  edges: Array<IOfferEdge>;
  /** A list of nodes. */
  nodes: Array<IOffer>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IOfferEdge = {
  __typename: "OfferEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IOffer>;
};

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type IPartner = {
  __typename: "Partner";
  /** Live API key */
  apiKey: Scalars["String"];
  averageSavingsPerBill: Scalars["Money"];
  averageSavingsPerCustomer: Scalars["Money"];
  averageSavingsPerReadyBill: Scalars["Money"];
  billCount: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  customerCount: Scalars["Int"];
  customersWithReadyBillsCount: Scalars["Int"];
  customersWithoutReadyBillsCount: Scalars["Int"];
  /** Alpha-numeric unique identifier */
  id: Scalars["String"];
  lastNinetyDaysSignups: Scalars["Int"];
  lastSevenDaysSignups: Scalars["Int"];
  lastThirtyDaysSignups: Scalars["Int"];
  /** Webhook URL for live/production requests */
  liveWebhookUrl?: Maybe<Scalars["String"]>;
  /** Name of the partner */
  name: Scalars["String"];
  readyBillCount: Scalars["Int"];
  /** The partner's referral code */
  referralCode?: Maybe<Scalars["String"]>;
  /** The partner's referral link */
  referralLink: Scalars["String"];
  /** Test API key */
  testApiKey: Scalars["String"];
  /** Webhook URL for test/development requests */
  testWebhookUrl?: Maybe<Scalars["String"]>;
  totalSavings: Scalars["Money"];
  updatedAt: Scalars["DateTime"];
  webhookHeaders: Array<IPartnerWebhookHeader>;
  webhooks: Array<IPartnerWebhook>;
};

export type IPartnerUser = {
  __typename: "PartnerUser";
  authenticationToken: Scalars["String"];
  email: Scalars["String"];
  id: Scalars["ID"];
  lastSignInAt?: Maybe<Scalars["DateTime"]>;
  partnerId: Scalars["ID"];
  role: Scalars["String"];
};

/** Attributes for creating or updating a partner user */
export type IPartnerUserAttributes = {
  email: Scalars["String"];
  partnerId?: InputMaybe<Scalars["ID"]>;
  password?: InputMaybe<Scalars["String"]>;
  passwordConfirmation?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
};

/** The connection type for PartnerUser. */
export type IPartnerUserConnection = {
  __typename: "PartnerUserConnection";
  /** A list of edges. */
  edges: Array<IPartnerUserEdge>;
  /** A list of nodes. */
  nodes: Array<IPartnerUser>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IPartnerUserEdge = {
  __typename: "PartnerUserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IPartnerUser;
};

export type IPartnerWebhook = {
  __typename: "PartnerWebhook";
  clientId?: Maybe<Scalars["String"]>;
  clientSecret?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  /** The type, either test or live, of the webhook */
  events: Array<Scalars["String"]>;
  /** Alpha-numeric unique identifier */
  id: Scalars["ID"];
  oauthToken?: Maybe<Scalars["String"]>;
  oauthUrl?: Maybe<Scalars["String"]>;
  partner: IPartner;
  /** Alpha-numeric unique identifier for the partner */
  partnerId: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
  webhookEnvironment?: Maybe<Scalars["String"]>;
  webhookType: Scalars["String"];
};

/** Attributes for creating or updating a partner webhook */
export type IPartnerWebhookAttributes = {
  clientId?: InputMaybe<Scalars["String"]>;
  clientSecret?: InputMaybe<Scalars["String"]>;
  events: Array<Scalars["String"]>;
  oauthUrl?: InputMaybe<Scalars["String"]>;
  url: Scalars["String"];
  webhookEnvironment?: InputMaybe<Scalars["String"]>;
  webhookType: Scalars["String"];
};

export type IPartnerWebhookHeader = {
  __typename: "PartnerWebhookHeader";
  createdAt: Scalars["DateTime"];
  /** The header name */
  headerName: Scalars["String"];
  /** The type, either test or live, of the header */
  headerType: Scalars["String"];
  /** The header value */
  headerValue: Scalars["String"];
  /** Alpha-numeric unique identifier */
  id: Scalars["ID"];
  partner: IPartner;
  /** Alpha-numeric unique identifier for the partner */
  partnerId: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

/** Attributes for creating or updating a partner webhook header */
export type IPartnerWebhookHeaderAttributes = {
  headerName: Scalars["String"];
  headerType: Scalars["String"];
  headerValue: Scalars["String"];
};

/** Autogenerated input type of ProvideCancellationInfo */
export type IProvideCancellationInfoInput = {
  cancellationId: Scalars["ID"];
  cancellationInfo: Scalars["JSON"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of ProvideCancellationInfo. */
export type IProvideCancellationInfoPayload = {
  __typename: "ProvideCancellationInfoPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of ProvideDocumentlessInfo */
export type IProvideDocumentlessInfoInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  documentlessInfo: Scalars["JSON"];
};

/** Autogenerated return type of ProvideDocumentlessInfo. */
export type IProvideDocumentlessInfoPayload = {
  __typename: "ProvideDocumentlessInfoPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type IProvider = {
  __typename: "Provider";
  billFields: Array<Scalars["String"]>;
  cancellable: Scalars["Boolean"];
  cancellationInfo: Array<IProviderCancellationField>;
  /** The cancellation ranking of the provider. Numbers closer to zero indicate a better ranking. */
  cancellationRanking?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  /** DIY instructions for cancelling service with the provider */
  customerCancellationInstructions?: Maybe<Scalars["String"]>;
  /** Allows documentless submission of a bill */
  documentlessAllowed: Scalars["Boolean"];
  documentlessInfo: Array<IProviderBillField>;
  /** Instructions for how to download a statement from the provider */
  downloadStatementInstructions?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  logo?: Maybe<IImage>;
  name: Scalars["String"];
  negotiable: Scalars["Boolean"];
  primaryBrandColor?: Maybe<Scalars["String"]>;
  providerBillFields: Array<IProviderBillField>;
  providerCancellationFields: Array<IProviderCancellationField>;
  /** The ranking of the provider. Numbers closer to zero indicate a better ranking. */
  ranking?: Maybe<Scalars["Int"]>;
  rectangleLogo?: Maybe<IImage>;
  secondaryBrandColor?: Maybe<Scalars["String"]>;
  services: Array<Scalars["String"]>;
  squareLogo?: Maybe<IImage>;
  updatedAt: Scalars["DateTime"];
};

export type IProviderBillField = {
  __typename: "ProviderBillField";
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  formatHashString?: Maybe<Scalars["String"]>;
  helpText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  label: Scalars["String"];
  name: Scalars["String"];
  placeholder?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  required: Scalars["Boolean"];
  requiredResponseLength?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type IProviderCancellationField = {
  __typename: "ProviderCancellationField";
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  detailedDataType?: Maybe<Scalars["String"]>;
  helpText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  label: Scalars["String"];
  name: Scalars["String"];
  placeholder?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  required: Scalars["Boolean"];
  requiredResponseLength?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type IProviderCancellationFieldResponse = {
  __typename: "ProviderCancellationFieldResponse";
  cancellationId: Scalars["ID"];
  id: Scalars["ID"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type IQuery = {
  __typename: "Query";
  CalculateSavingsEstimate: ISavingsEstimate;
  FindBill?: Maybe<IBill>;
  FindCancellation?: Maybe<ICancellation>;
  FindCancellationInfoRequest?: Maybe<ICancellationInfoRequest>;
  FindCancellationOffer?: Maybe<ICancellationOffer>;
  FindCurrentPartner: IPartner;
  FindCurrentPartnerUser: IPartnerUser;
  FindCustomer?: Maybe<ICustomer>;
  FindInformationRequest?: Maybe<IInformationRequest>;
  FindOffer?: Maybe<IOffer>;
  FindProvider?: Maybe<IProvider>;
  FindRandomRecentSavings?: Maybe<ISavingsEvent>;
  ListBills: IBillConnection;
  ListCancellations: ICancellationConnection;
  ListCustomers: ICustomerConnection;
  ListInformationRequests: IInformationRequestConnection;
  ListNegotiations: INegotiationConnection;
  ListOffers: IOfferConnection;
  ListPartnerUsers: IPartnerUserConnection;
  ListProviders: Array<IProvider>;
  ListRandomRecentSavings: Array<ISavingsEvent>;
  ListWebhookEvents: IWebhookEventConnection;
  PingUptime: Scalars["String"];
  SandboxListNegotiationsForBill: INegotiationConnection;
};

export type IQueryFindBillArgs = {
  id: Scalars["ID"];
};

export type IQueryFindCancellationArgs = {
  id: Scalars["ID"];
};

export type IQueryFindCancellationInfoRequestArgs = {
  cancellationInfoRequestId: Scalars["ID"];
};

export type IQueryFindCancellationOfferArgs = {
  cancellationOfferId: Scalars["ID"];
};

export type IQueryFindCustomerArgs = {
  id: Scalars["ID"];
};

export type IQueryFindInformationRequestArgs = {
  informationRequestId: Scalars["ID"];
};

export type IQueryFindOfferArgs = {
  offerId: Scalars["ID"];
};

export type IQueryFindProviderArgs = {
  id: Scalars["ID"];
};

export type IQueryFindRandomRecentSavingsArgs = {
  savingsSource?: InputMaybe<Scalars["String"]>;
};

export type IQueryListBillsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  apiId?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  missingDocumentInfo?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type IQueryListCancellationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  apiId?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type IQueryListCustomersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
};

export type IQueryListInformationRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
};

export type IQueryListNegotiationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type IQueryListOffersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billId?: InputMaybe<Scalars["ID"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type IQueryListPartnerUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type IQueryListProvidersArgs = {
  anyRanked?: InputMaybe<Scalars["Boolean"]>;
  cancellable?: InputMaybe<Scalars["Boolean"]>;
  cancellationRankedOnly?: InputMaybe<Scalars["Boolean"]>;
  negotiable?: InputMaybe<Scalars["Boolean"]>;
  rankedOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type IQueryListRandomRecentSavingsArgs = {
  arraySize?: InputMaybe<Scalars["Int"]>;
  savingsSource?: InputMaybe<Scalars["String"]>;
};

export type IQueryListWebhookEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  delivered?: InputMaybe<Scalars["Boolean"]>;
  eventType?: InputMaybe<Array<Scalars["String"]>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  notDelivered?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recordApiId?: InputMaybe<Scalars["ID"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
  subjectType?: InputMaybe<Scalars["String"]>;
};

export type IQueryPingUptimeArgs = {
  partnerId: Scalars["ID"];
};

export type IQuerySandboxListNegotiationsForBillArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated input type of RejectCancellationOffer */
export type IRejectCancellationOfferInput = {
  cancellationOfferId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of RejectCancellationOffer. */
export type IRejectCancellationOfferPayload = {
  __typename: "RejectCancellationOfferPayload";
  cancellationOffer?: Maybe<ICancellationOffer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RejectOffer */
export type IRejectOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  offerId: Scalars["ID"];
};

/** Autogenerated return type of RejectOffer. */
export type IRejectOfferPayload = {
  __typename: "RejectOfferPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  offer?: Maybe<IOffer>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RenegotiateBill */
export type IRenegotiateBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the bill that should be renegotiated */
  id: Scalars["ID"];
};

/** Autogenerated return type of RenegotiateBill. */
export type IRenegotiateBillPayload = {
  __typename: "RenegotiateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RespondToCancellationInfoRequest */
export type IRespondToCancellationInfoRequestInput = {
  cancellationInfoRequest: ICancellationInfoRequestAttributes;
  /** Id of the cancellation info request */
  cancellationInfoRequestId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of RespondToCancellationInfoRequest. */
export type IRespondToCancellationInfoRequestPayload = {
  __typename: "RespondToCancellationInfoRequestPayload";
  cancellation?: Maybe<ICancellation>;
  cancellationInfoRequest?: Maybe<IInformationRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RespondToInformationRequest */
export type IRespondToInformationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the information request */
  id: Scalars["ID"];
  informationRequest: IInformationRequestAttributes;
};

/** Autogenerated return type of RespondToInformationRequest. */
export type IRespondToInformationRequestPayload = {
  __typename: "RespondToInformationRequestPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  informationRequest?: Maybe<IInformationRequest>;
  negotiation?: Maybe<INegotiation>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RotateApiKeys */
export type IRotateApiKeysInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of RotateApiKeys. */
export type IRotateApiKeysPayload = {
  __typename: "RotateApiKeysPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  partner?: Maybe<IPartner>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxGenerateNegotiation */
export type ISandboxGenerateNegotiationInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of SandboxGenerateNegotiation. */
export type ISandboxGenerateNegotiationPayload = {
  __typename: "SandboxGenerateNegotiationPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxResetBill */
export type ISandboxResetBillInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of SandboxResetBill. */
export type ISandboxResetBillPayload = {
  __typename: "SandboxResetBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxTransitionBill */
export type ISandboxTransitionBillInput = {
  /** Id of the bill that will transition */
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transition: Scalars["String"];
};

/** Autogenerated return type of SandboxTransitionBill. */
export type ISandboxTransitionBillPayload = {
  __typename: "SandboxTransitionBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxTransitionCancellation */
export type ISandboxTransitionCancellationInput = {
  /** Id of the cancellation that will transition */
  cancellationId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transition: Scalars["String"];
};

/** Autogenerated return type of SandboxTransitionCancellation. */
export type ISandboxTransitionCancellationPayload = {
  __typename: "SandboxTransitionCancellationPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxTransitionNegotiation */
export type ISandboxTransitionNegotiationInput = {
  /** Id of the bill that will transition its current negotiation */
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transition: Scalars["String"];
};

/** Autogenerated return type of SandboxTransitionNegotiation. */
export type ISandboxTransitionNegotiationPayload = {
  __typename: "SandboxTransitionNegotiationPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  negotiation?: Maybe<INegotiation>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SandboxTriggerProviderUpdated */
export type ISandboxTriggerProviderUpdatedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the bill that will transition */
  providerId: Scalars["ID"];
};

/** Autogenerated return type of SandboxTriggerProviderUpdated. */
export type ISandboxTriggerProviderUpdatedPayload = {
  __typename: "SandboxTriggerProviderUpdatedPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type ISavingsEstimate = {
  __typename: "SavingsEstimate";
  estimatedAnnualSavings: Scalars["Money"];
  estimatedMonthlySavings: Scalars["Money"];
  percentageSavings: Scalars["Float"];
  premiumSubscriptionMonthlyCost: Scalars["Float"];
};

export type ISavingsEstimateestimatedAnnualSavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEstimateestimatedMonthlySavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEstimatepercentageSavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEstimatepremiumSubscriptionMonthlyCostArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEvent = {
  __typename: "SavingsEvent";
  customer: ICustomer;
  customerFirstName: Scalars["String"];
  customerInitials: Scalars["String"];
  provider: IProvider;
  providerLogo?: Maybe<IImage>;
  providerName: Scalars["String"];
  savings: Scalars["Money"];
  savingsDate: Scalars["DateTime"];
  savingsSourceType: Scalars["String"];
};

/** Autogenerated input type of SetBillReadyToNegotiate */
export type ISetBillReadyToNegotiateInput = {
  /** Id of the bill */
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of SetBillReadyToNegotiate. */
export type ISetBillReadyToNegotiatePayload = {
  __typename: "SetBillReadyToNegotiatePayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCancellationReadyToCancel */
export type ISetCancellationReadyToCancelInput = {
  /** Id of the cancellation */
  cancellationId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of SetCancellationReadyToCancel. */
export type ISetCancellationReadyToCancelPayload = {
  __typename: "SetCancellationReadyToCancelPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerEligibleForCancellation */
export type ISetCustomerEligibleForCancellationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the customer */
  customerId: Scalars["ID"];
  eligibleForCancellation: Scalars["Boolean"];
};

/** Autogenerated return type of SetCustomerEligibleForCancellation. */
export type ISetCustomerEligibleForCancellationPayload = {
  __typename: "SetCustomerEligibleForCancellationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerEligibleForNegotiation */
export type ISetCustomerEligibleForNegotiationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the customer */
  customerId: Scalars["ID"];
  eligibleForNegotiation: Scalars["Boolean"];
};

/** Autogenerated return type of SetCustomerEligibleForNegotiation. */
export type ISetCustomerEligibleForNegotiationPayload = {
  __typename: "SetCustomerEligibleForNegotiationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of StopCancelling */
export type IStopCancellingInput = {
  cancellationId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of StopCancelling. */
export type IStopCancellingPayload = {
  __typename: "StopCancellingPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of StopNegotiating */
export type IStopNegotiatingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of StopNegotiating. */
export type IStopNegotiatingPayload = {
  __typename: "StopNegotiatingPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateBill */
export type IUpdateBillInput = {
  bill: IBillAttributes;
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateBill. */
export type IUpdateBillPayload = {
  __typename: "UpdateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateCancellation */
export type IUpdateCancellationInput = {
  cancellation: ICancellationAttributes;
  /** Id of the cancellation to be updated */
  cancellationId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateCancellation. */
export type IUpdateCancellationPayload = {
  __typename: "UpdateCancellationPayload";
  cancellation?: Maybe<ICancellation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateCustomer */
export type IUpdateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  customer: ICustomerAttributes;
  id: Scalars["ID"];
};

/** Autogenerated return type of UpdateCustomer. */
export type IUpdateCustomerPayload = {
  __typename: "UpdateCustomerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdatePartnerUser */
export type IUpdatePartnerUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Id of the partner user to be updated */
  id: Scalars["ID"];
  partnerUser: IPartnerUserAttributes;
};

/** Autogenerated return type of UpdatePartnerUser. */
export type IUpdatePartnerUserPayload = {
  __typename: "UpdatePartnerUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  partnerUser?: Maybe<IPartnerUser>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdatePartnerWebhookHeader */
export type IUpdatePartnerWebhookHeaderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhookHeader: IPartnerWebhookHeaderAttributes;
  webhookHeaderId: Scalars["ID"];
};

/** Autogenerated return type of UpdatePartnerWebhookHeader. */
export type IUpdatePartnerWebhookHeaderPayload = {
  __typename: "UpdatePartnerWebhookHeaderPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  webhookHeader?: Maybe<IPartnerWebhookHeader>;
};

/** Autogenerated input type of UpdatePartnerWebhook */
export type IUpdatePartnerWebhookInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  webhook: IPartnerWebhookAttributes;
  webhookId: Scalars["ID"];
};

/** Autogenerated return type of UpdatePartnerWebhook. */
export type IUpdatePartnerWebhookPayload = {
  __typename: "UpdatePartnerWebhookPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  webhook?: Maybe<IPartnerWebhook>;
};

/** Autogenerated input type of UpdateWebhookUrls */
export type IUpdateWebhookUrlsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  liveWebhookUrl?: InputMaybe<Scalars["String"]>;
  testWebhookUrl?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateWebhookUrls. */
export type IUpdateWebhookUrlsPayload = {
  __typename: "UpdateWebhookUrlsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  partner: IPartner;
  success: Scalars["Boolean"];
};

export type IWebhookEvent = {
  __typename: "WebhookEvent";
  additionalData?: Maybe<Scalars["JSON"]>;
  apiId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  delivered: Scalars["Boolean"];
  deliveredAt?: Maybe<Scalars["DateTime"]>;
  deliveredAtUtc?: Maybe<Scalars["DateTime"]>;
  eventType: Scalars["String"];
  id: Scalars["ID"];
  partnerId: Scalars["ID"];
  partnerWebhook?: Maybe<IPartnerWebhook>;
  partnerWebhookId?: Maybe<Scalars["ID"]>;
  recordApiId: Scalars["ID"];
  replayed: Scalars["Boolean"];
  retryCount: Scalars["Int"];
  subjectId: Scalars["ID"];
  subjectType: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  url?: Maybe<Scalars["String"]>;
};

/** The connection type for WebhookEvent. */
export type IWebhookEventConnection = {
  __typename: "WebhookEventConnection";
  /** A list of edges. */
  edges: Array<IWebhookEventEdge>;
  /** A list of nodes. */
  nodes: Array<IWebhookEvent>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IWebhookEventEdge = {
  __typename: "WebhookEventEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IWebhookEvent>;
};

export type IBillFragment = {
  __typename: "Bill";
  id: string;
  status: string;
  title: string;
  providerId: string;
  totalSavings: number;
  grossSavings: number;
  autoRenegotiate: boolean;
  allowsContract: boolean;
  createdAt: string;
  updatedAt: string;
  cancelledAt?: string | null;
  documentlessInfo?: any | null;
  missingDocumentInfo: boolean;
  documentless: boolean;
  documentCount: number;
  nextRenegotiateOn?: string | null;
  items: Array<{
    __typename: "Item";
    name: string;
    type: string;
    prePrice: number;
    postPrice: number;
    duration: number;
    expired: boolean;
    savings: number;
    savingsStartOn: string;
    savingsEndOn: string;
    underContract: boolean;
    createdAt: string;
    updatedAt: string;
  }>;
  provider: {
    __typename: "Provider";
    id: string;
    name: string;
    ranking?: number | null;
    services: Array<string>;
  };
  customer: {
    __typename: "Customer";
    id: string;
    externalId?: string | null;
    email?: string | null;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    createdAt: string;
    updatedAt: string;
    allTimeGrossSavings: number;
    billCount: number;
    readyBillCount: number;
    referralData?: any | null;
    eligibleForNegotiation: boolean;
  };
  offers: Array<{
    __typename: "Offer";
    id: string;
    billId: string;
    status: string;
    content: string;
    contentHtml: string;
    daysUntilExpiration: number;
    createdAt: string;
    updatedAt: string;
    acceptedAt?: string | null;
    rejectedAt?: string | null;
  }>;
  informationRequests: Array<{
    __typename: "InformationRequest";
    id: string;
    billId: string;
    customerId: string;
    content: string;
    contentHtml: string;
    headerText?: string | null;
    createdAt: string;
    updatedAt: string;
    respondedAt?: string | null;
    fields: Array<{
      __typename: "InformationRequestField";
      id: string;
      label: string;
      placeholder: string;
      dataType: string;
      value?: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
  }>;
  documents: Array<{
    __typename: "Document";
    id: string;
    filename: string;
    url: string;
    documentableId: string;
    createdAt: string;
  }>;
};

export type ICustomerFragment = {
  __typename: "Customer";
  id: string;
  externalId?: string | null;
  email?: string | null;
  name: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  createdAt: string;
  updatedAt: string;
  allTimeGrossSavings: number;
  billCount: number;
  readyBillCount: number;
  referralData?: any | null;
  eligibleForNegotiation: boolean;
};

export type IDocumentFragment = {
  __typename: "Document";
  id: string;
  filename: string;
  url: string;
  documentableId: string;
  createdAt: string;
};

export type IInformationRequestFragment = {
  __typename: "InformationRequest";
  id: string;
  billId: string;
  customerId: string;
  content: string;
  contentHtml: string;
  headerText?: string | null;
  createdAt: string;
  updatedAt: string;
  respondedAt?: string | null;
  fields: Array<{
    __typename: "InformationRequestField";
    id: string;
    label: string;
    placeholder: string;
    dataType: string;
    value?: string | null;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type IInformationRequestFieldFragment = {
  __typename: "InformationRequestField";
  id: string;
  label: string;
  placeholder: string;
  dataType: string;
  value?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type IItemFragment = {
  __typename: "Item";
  name: string;
  type: string;
  prePrice: number;
  postPrice: number;
  duration: number;
  expired: boolean;
  savings: number;
  savingsStartOn: string;
  savingsEndOn: string;
  underContract: boolean;
  createdAt: string;
  updatedAt: string;
};

export type INegotiationFragment = {
  __typename: "Negotiation";
  id: string;
  billId: string;
  negotiationNumber: number;
  state: string;
  totalSavings: number;
  resultsNote?: string | null;
  typeOfSavings?: string | null;
  startedAt?: string | null;
  fixedAt?: string | null;
  failedAt?: string | null;
  items: Array<{
    __typename: "Item";
    name: string;
    type: string;
    prePrice: number;
    postPrice: number;
    duration: number;
    expired: boolean;
    savings: number;
    savingsStartOn: string;
    savingsEndOn: string;
    underContract: boolean;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type IOfferFragment = {
  __typename: "Offer";
  id: string;
  billId: string;
  status: string;
  content: string;
  contentHtml: string;
  daysUntilExpiration: number;
  createdAt: string;
  updatedAt: string;
  acceptedAt?: string | null;
  rejectedAt?: string | null;
};

export type IPartnerFragment = {
  __typename: "Partner";
  id: string;
  name: string;
  referralCode?: string | null;
  referralLink: string;
  liveWebhookUrl?: string | null;
  testWebhookUrl?: string | null;
  apiKey: string;
  testApiKey: string;
  totalSavings: number;
  customerCount: number;
  billCount: number;
  readyBillCount: number;
  averageSavingsPerBill: number;
  averageSavingsPerReadyBill: number;
  averageSavingsPerCustomer: number;
  customersWithReadyBillsCount: number;
  customersWithoutReadyBillsCount: number;
  lastSevenDaysSignups: number;
  lastThirtyDaysSignups: number;
  lastNinetyDaysSignups: number;
  webhooks: Array<{
    __typename: "PartnerWebhook";
    id: string;
    partnerId: string;
    webhookEnvironment?: string | null;
    url: string;
    oauthUrl?: string | null;
    webhookType: string;
    events: Array<string>;
    clientId?: string | null;
    clientSecret?: string | null;
    createdAt: string;
    updatedAt: string;
  }>;
  webhookHeaders: Array<{
    __typename: "PartnerWebhookHeader";
    id: string;
    partnerId: string;
    headerName: string;
    headerValue: string;
    headerType: string;
    createdAt: string;
    updatedAt: string;
  }>;
};

export type IPartnerUserFragment = {
  __typename: "PartnerUser";
  id: string;
  partnerId: string;
  email: string;
  authenticationToken: string;
  role: string;
  lastSignInAt?: string | null;
};

export type IPartnerWebhookFragment = {
  __typename: "PartnerWebhook";
  id: string;
  partnerId: string;
  webhookEnvironment?: string | null;
  url: string;
  oauthUrl?: string | null;
  webhookType: string;
  events: Array<string>;
  clientId?: string | null;
  clientSecret?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type IPartnerWebhookHeaderFragment = {
  __typename: "PartnerWebhookHeader";
  id: string;
  partnerId: string;
  headerName: string;
  headerValue: string;
  headerType: string;
  createdAt: string;
  updatedAt: string;
};

export type IProviderFragment = {
  __typename: "Provider";
  id: string;
  name: string;
  ranking?: number | null;
  services: Array<string>;
};

export type IWebhookEventFragment = {
  __typename: "WebhookEvent";
  id: string;
  additionalData?: any | null;
  apiId: string;
  createdAt: string;
  delivered: boolean;
  deliveredAt?: string | null;
  deliveredAtUtc?: string | null;
  eventType: string;
  partnerId: string;
  partnerWebhookId?: string | null;
  recordApiId: string;
  retryCount: number;
  subjectId: string;
  subjectType: string;
  updatedAt: string;
  url?: string | null;
  partnerWebhook?: {
    __typename: "PartnerWebhook";
    id: string;
    url: string;
    webhookEnvironment?: string | null;
  } | null;
};

export type ICreatePartnerUserMutationVariables = Exact<{
  params: IPartnerUserAttributes;
}>;

export type ICreatePartnerUserMutation = {
  __typename: "Mutation";
  CreatePartnerUser?: {
    __typename: "CreatePartnerUserPayload";
    success: boolean;
    errors: Array<string>;
    partnerUser?: {
      __typename: "PartnerUser";
      id: string;
      partnerId: string;
      email: string;
      authenticationToken: string;
      role: string;
      lastSignInAt?: string | null;
    } | null;
  } | null;
};

export type ICreatePartnerWebhookMutationVariables = Exact<{
  webhook: IPartnerWebhookAttributes;
}>;

export type ICreatePartnerWebhookMutation = {
  __typename: "Mutation";
  CreatePartnerWebhook?: {
    __typename: "CreatePartnerWebhookPayload";
    success: boolean;
    errors: Array<string>;
    webhook?: {
      __typename: "PartnerWebhook";
      id: string;
      partnerId: string;
      webhookEnvironment?: string | null;
      url: string;
      oauthUrl?: string | null;
      webhookType: string;
      events: Array<string>;
      clientId?: string | null;
      clientSecret?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type ICreatePartnerWebhookHeaderMutationVariables = Exact<{
  webhookHeader: IPartnerWebhookHeaderAttributes;
}>;

export type ICreatePartnerWebhookHeaderMutation = {
  __typename: "Mutation";
  CreatePartnerWebhookHeader?: {
    __typename: "CreatePartnerWebhookHeaderPayload";
    success: boolean;
    errors: Array<string>;
    webhookHeader?: {
      __typename: "PartnerWebhookHeader";
      id: string;
      headerName: string;
      headerValue: string;
      headerType: string;
    } | null;
  } | null;
};

export type IDeletePartnerWebhookMutationVariables = Exact<{
  webhookId: Scalars["ID"];
}>;

export type IDeletePartnerWebhookMutation = {
  __typename: "Mutation";
  DeletePartnerWebhook?: {
    __typename: "DeletePartnerWebhookPayload";
    success: boolean;
    errors: Array<string>;
  } | null;
};

export type IDeletePartnerWebhookHeaderMutationVariables = Exact<{
  webhookHeaderId: Scalars["ID"];
}>;

export type IDeletePartnerWebhookHeaderMutation = {
  __typename: "Mutation";
  DeletePartnerWebhookHeader?: {
    __typename: "DeletePartnerWebhookHeaderPayload";
    success: boolean;
    errors: Array<string>;
  } | null;
};

export type IRotateApiKeysMutationVariables = Exact<{ [key: string]: never }>;

export type IRotateApiKeysMutation = {
  __typename: "Mutation";
  RotateApiKeys?: {
    __typename: "RotateApiKeysPayload";
    success: boolean;
    errors: Array<string>;
    partner?: {
      __typename: "Partner";
      id: string;
      name: string;
      referralCode?: string | null;
      referralLink: string;
      liveWebhookUrl?: string | null;
      testWebhookUrl?: string | null;
      apiKey: string;
      testApiKey: string;
      totalSavings: number;
      customerCount: number;
      billCount: number;
      readyBillCount: number;
      averageSavingsPerBill: number;
      averageSavingsPerReadyBill: number;
      averageSavingsPerCustomer: number;
      customersWithReadyBillsCount: number;
      customersWithoutReadyBillsCount: number;
      lastSevenDaysSignups: number;
      lastThirtyDaysSignups: number;
      lastNinetyDaysSignups: number;
      webhooks: Array<{
        __typename: "PartnerWebhook";
        id: string;
        partnerId: string;
        webhookEnvironment?: string | null;
        url: string;
        oauthUrl?: string | null;
        webhookType: string;
        events: Array<string>;
        clientId?: string | null;
        clientSecret?: string | null;
        createdAt: string;
        updatedAt: string;
      }>;
      webhookHeaders: Array<{
        __typename: "PartnerWebhookHeader";
        id: string;
        partnerId: string;
        headerName: string;
        headerValue: string;
        headerType: string;
        createdAt: string;
        updatedAt: string;
      }>;
    } | null;
  } | null;
};

export type ISandboxGenerateNegotiationMutationVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type ISandboxGenerateNegotiationMutation = {
  __typename: "Mutation";
  SandboxGenerateNegotiation?: {
    __typename: "SandboxGenerateNegotiationPayload";
    success: boolean;
    errors: Array<string>;
    bill?: {
      __typename: "Bill";
      id: string;
      status: string;
      title: string;
      providerId: string;
      totalSavings: number;
      grossSavings: number;
      autoRenegotiate: boolean;
      allowsContract: boolean;
      createdAt: string;
      updatedAt: string;
      cancelledAt?: string | null;
      documentlessInfo?: any | null;
      missingDocumentInfo: boolean;
      documentless: boolean;
      documentCount: number;
      nextRenegotiateOn?: string | null;
      items: Array<{
        __typename: "Item";
        name: string;
        type: string;
        prePrice: number;
        postPrice: number;
        duration: number;
        expired: boolean;
        savings: number;
        savingsStartOn: string;
        savingsEndOn: string;
        underContract: boolean;
        createdAt: string;
        updatedAt: string;
      }>;
      provider: {
        __typename: "Provider";
        id: string;
        name: string;
        ranking?: number | null;
        services: Array<string>;
      };
      customer: {
        __typename: "Customer";
        id: string;
        externalId?: string | null;
        email?: string | null;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        createdAt: string;
        updatedAt: string;
        allTimeGrossSavings: number;
        billCount: number;
        readyBillCount: number;
        referralData?: any | null;
        eligibleForNegotiation: boolean;
      };
      offers: Array<{
        __typename: "Offer";
        id: string;
        billId: string;
        status: string;
        content: string;
        contentHtml: string;
        daysUntilExpiration: number;
        createdAt: string;
        updatedAt: string;
        acceptedAt?: string | null;
        rejectedAt?: string | null;
      }>;
      informationRequests: Array<{
        __typename: "InformationRequest";
        id: string;
        billId: string;
        customerId: string;
        content: string;
        contentHtml: string;
        headerText?: string | null;
        createdAt: string;
        updatedAt: string;
        respondedAt?: string | null;
        fields: Array<{
          __typename: "InformationRequestField";
          id: string;
          label: string;
          placeholder: string;
          dataType: string;
          value?: string | null;
          createdAt: string;
          updatedAt: string;
        }>;
      }>;
      documents: Array<{
        __typename: "Document";
        id: string;
        filename: string;
        url: string;
        documentableId: string;
        createdAt: string;
      }>;
    } | null;
  } | null;
};

export type ISandboxResetBillMutationVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type ISandboxResetBillMutation = {
  __typename: "Mutation";
  SandboxResetBill?: {
    __typename: "SandboxResetBillPayload";
    success: boolean;
    errors: Array<string>;
    bill?: {
      __typename: "Bill";
      id: string;
      status: string;
      title: string;
      providerId: string;
      totalSavings: number;
      grossSavings: number;
      autoRenegotiate: boolean;
      allowsContract: boolean;
      createdAt: string;
      updatedAt: string;
      cancelledAt?: string | null;
      documentlessInfo?: any | null;
      missingDocumentInfo: boolean;
      documentless: boolean;
      documentCount: number;
      nextRenegotiateOn?: string | null;
      items: Array<{
        __typename: "Item";
        name: string;
        type: string;
        prePrice: number;
        postPrice: number;
        duration: number;
        expired: boolean;
        savings: number;
        savingsStartOn: string;
        savingsEndOn: string;
        underContract: boolean;
        createdAt: string;
        updatedAt: string;
      }>;
      provider: {
        __typename: "Provider";
        id: string;
        name: string;
        ranking?: number | null;
        services: Array<string>;
      };
      customer: {
        __typename: "Customer";
        id: string;
        externalId?: string | null;
        email?: string | null;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        createdAt: string;
        updatedAt: string;
        allTimeGrossSavings: number;
        billCount: number;
        readyBillCount: number;
        referralData?: any | null;
        eligibleForNegotiation: boolean;
      };
      offers: Array<{
        __typename: "Offer";
        id: string;
        billId: string;
        status: string;
        content: string;
        contentHtml: string;
        daysUntilExpiration: number;
        createdAt: string;
        updatedAt: string;
        acceptedAt?: string | null;
        rejectedAt?: string | null;
      }>;
      informationRequests: Array<{
        __typename: "InformationRequest";
        id: string;
        billId: string;
        customerId: string;
        content: string;
        contentHtml: string;
        headerText?: string | null;
        createdAt: string;
        updatedAt: string;
        respondedAt?: string | null;
        fields: Array<{
          __typename: "InformationRequestField";
          id: string;
          label: string;
          placeholder: string;
          dataType: string;
          value?: string | null;
          createdAt: string;
          updatedAt: string;
        }>;
      }>;
      documents: Array<{
        __typename: "Document";
        id: string;
        filename: string;
        url: string;
        documentableId: string;
        createdAt: string;
      }>;
    } | null;
  } | null;
};

export type ISandboxTransitionNegotiationMutationVariables = Exact<{
  billId: Scalars["ID"];
  transition: Scalars["String"];
}>;

export type ISandboxTransitionNegotiationMutation = {
  __typename: "Mutation";
  SandboxTransitionNegotiation?: {
    __typename: "SandboxTransitionNegotiationPayload";
    success: boolean;
    errors: Array<string>;
    bill?: {
      __typename: "Bill";
      id: string;
      status: string;
      title: string;
      providerId: string;
      totalSavings: number;
      grossSavings: number;
      autoRenegotiate: boolean;
      allowsContract: boolean;
      createdAt: string;
      updatedAt: string;
      cancelledAt?: string | null;
      documentlessInfo?: any | null;
      missingDocumentInfo: boolean;
      documentless: boolean;
      documentCount: number;
      nextRenegotiateOn?: string | null;
      items: Array<{
        __typename: "Item";
        name: string;
        type: string;
        prePrice: number;
        postPrice: number;
        duration: number;
        expired: boolean;
        savings: number;
        savingsStartOn: string;
        savingsEndOn: string;
        underContract: boolean;
        createdAt: string;
        updatedAt: string;
      }>;
      provider: {
        __typename: "Provider";
        id: string;
        name: string;
        ranking?: number | null;
        services: Array<string>;
      };
      customer: {
        __typename: "Customer";
        id: string;
        externalId?: string | null;
        email?: string | null;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        createdAt: string;
        updatedAt: string;
        allTimeGrossSavings: number;
        billCount: number;
        readyBillCount: number;
        referralData?: any | null;
        eligibleForNegotiation: boolean;
      };
      offers: Array<{
        __typename: "Offer";
        id: string;
        billId: string;
        status: string;
        content: string;
        contentHtml: string;
        daysUntilExpiration: number;
        createdAt: string;
        updatedAt: string;
        acceptedAt?: string | null;
        rejectedAt?: string | null;
      }>;
      informationRequests: Array<{
        __typename: "InformationRequest";
        id: string;
        billId: string;
        customerId: string;
        content: string;
        contentHtml: string;
        headerText?: string | null;
        createdAt: string;
        updatedAt: string;
        respondedAt?: string | null;
        fields: Array<{
          __typename: "InformationRequestField";
          id: string;
          label: string;
          placeholder: string;
          dataType: string;
          value?: string | null;
          createdAt: string;
          updatedAt: string;
        }>;
      }>;
      documents: Array<{
        __typename: "Document";
        id: string;
        filename: string;
        url: string;
        documentableId: string;
        createdAt: string;
      }>;
    } | null;
    negotiation?: {
      __typename: "Negotiation";
      id: string;
      billId: string;
      negotiationNumber: number;
      state: string;
      totalSavings: number;
      resultsNote?: string | null;
      typeOfSavings?: string | null;
      startedAt?: string | null;
      fixedAt?: string | null;
      failedAt?: string | null;
      items: Array<{
        __typename: "Item";
        name: string;
        type: string;
        prePrice: number;
        postPrice: number;
        duration: number;
        expired: boolean;
        savings: number;
        savingsStartOn: string;
        savingsEndOn: string;
        underContract: boolean;
        createdAt: string;
        updatedAt: string;
      }>;
    } | null;
  } | null;
};

export type IUpdatePartnerUserMutationVariables = Exact<{
  id: Scalars["ID"];
  params: IPartnerUserAttributes;
}>;

export type IUpdatePartnerUserMutation = {
  __typename: "Mutation";
  UpdatePartnerUser?: {
    __typename: "UpdatePartnerUserPayload";
    success: boolean;
    errors: Array<string>;
    partnerUser?: {
      __typename: "PartnerUser";
      id: string;
      partnerId: string;
      email: string;
      authenticationToken: string;
      role: string;
      lastSignInAt?: string | null;
    } | null;
  } | null;
};

export type IUpdatePartnerWebhookMutationVariables = Exact<{
  webhookId: Scalars["ID"];
  webhook: IPartnerWebhookAttributes;
}>;

export type IUpdatePartnerWebhookMutation = {
  __typename: "Mutation";
  UpdatePartnerWebhook?: {
    __typename: "UpdatePartnerWebhookPayload";
    success: boolean;
    errors: Array<string>;
    webhook?: {
      __typename: "PartnerWebhook";
      id: string;
      partnerId: string;
      webhookEnvironment?: string | null;
      url: string;
      oauthUrl?: string | null;
      webhookType: string;
      events: Array<string>;
      clientId?: string | null;
      clientSecret?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type IUpdatePartnerWebhookHeaderMutationVariables = Exact<{
  webhookHeaderId: Scalars["ID"];
  webhookHeader: IPartnerWebhookHeaderAttributes;
}>;

export type IUpdatePartnerWebhookHeaderMutation = {
  __typename: "Mutation";
  UpdatePartnerWebhookHeader?: {
    __typename: "UpdatePartnerWebhookHeaderPayload";
    success: boolean;
    errors: Array<string>;
    webhookHeader?: {
      __typename: "PartnerWebhookHeader";
      id: string;
      headerName: string;
      headerValue: string;
      headerType: string;
    } | null;
  } | null;
};

export type IUpdateWebhookUrlsMutationVariables = Exact<{
  liveWebhookUrl?: InputMaybe<Scalars["String"]>;
  testWebhookUrl?: InputMaybe<Scalars["String"]>;
}>;

export type IUpdateWebhookUrlsMutation = {
  __typename: "Mutation";
  UpdateWebhookUrls?: {
    __typename: "UpdateWebhookUrlsPayload";
    success: boolean;
    errors: Array<string>;
    partner: {
      __typename: "Partner";
      id: string;
      name: string;
      referralCode?: string | null;
      referralLink: string;
      liveWebhookUrl?: string | null;
      testWebhookUrl?: string | null;
      apiKey: string;
      testApiKey: string;
      totalSavings: number;
      customerCount: number;
      billCount: number;
      readyBillCount: number;
      averageSavingsPerBill: number;
      averageSavingsPerReadyBill: number;
      averageSavingsPerCustomer: number;
      customersWithReadyBillsCount: number;
      customersWithoutReadyBillsCount: number;
      lastSevenDaysSignups: number;
      lastThirtyDaysSignups: number;
      lastNinetyDaysSignups: number;
      webhooks: Array<{
        __typename: "PartnerWebhook";
        id: string;
        partnerId: string;
        webhookEnvironment?: string | null;
        url: string;
        oauthUrl?: string | null;
        webhookType: string;
        events: Array<string>;
        clientId?: string | null;
        clientSecret?: string | null;
        createdAt: string;
        updatedAt: string;
      }>;
      webhookHeaders: Array<{
        __typename: "PartnerWebhookHeader";
        id: string;
        partnerId: string;
        headerName: string;
        headerValue: string;
        headerType: string;
        createdAt: string;
        updatedAt: string;
      }>;
    };
  } | null;
};

export type ICalculateSavingsEstimateQueryVariables = Exact<{
  providerId: Scalars["ID"];
  currentMonthlyAmount: Scalars["Float"];
}>;

export type ICalculateSavingsEstimateQuery = {
  __typename: "Query";
  estimate: {
    __typename: "SavingsEstimate";
    estimatedAnnualSavings: number;
    estimatedMonthlySavings: number;
    percentageSavings: number;
  };
};

export type IFindBillQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type IFindBillQuery = {
  __typename: "Query";
  bill?: {
    __typename: "Bill";
    id: string;
    status: string;
    title: string;
    providerId: string;
    totalSavings: number;
    grossSavings: number;
    autoRenegotiate: boolean;
    allowsContract: boolean;
    createdAt: string;
    updatedAt: string;
    cancelledAt?: string | null;
    documentlessInfo?: any | null;
    missingDocumentInfo: boolean;
    documentless: boolean;
    documentCount: number;
    nextRenegotiateOn?: string | null;
    items: Array<{
      __typename: "Item";
      name: string;
      type: string;
      prePrice: number;
      postPrice: number;
      duration: number;
      expired: boolean;
      savings: number;
      savingsStartOn: string;
      savingsEndOn: string;
      underContract: boolean;
      createdAt: string;
      updatedAt: string;
    }>;
    provider: {
      __typename: "Provider";
      id: string;
      name: string;
      ranking?: number | null;
      services: Array<string>;
    };
    customer: {
      __typename: "Customer";
      id: string;
      externalId?: string | null;
      email?: string | null;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      createdAt: string;
      updatedAt: string;
      allTimeGrossSavings: number;
      billCount: number;
      readyBillCount: number;
      referralData?: any | null;
      eligibleForNegotiation: boolean;
    };
    offers: Array<{
      __typename: "Offer";
      id: string;
      billId: string;
      status: string;
      content: string;
      contentHtml: string;
      daysUntilExpiration: number;
      createdAt: string;
      updatedAt: string;
      acceptedAt?: string | null;
      rejectedAt?: string | null;
    }>;
    informationRequests: Array<{
      __typename: "InformationRequest";
      id: string;
      billId: string;
      customerId: string;
      content: string;
      contentHtml: string;
      headerText?: string | null;
      createdAt: string;
      updatedAt: string;
      respondedAt?: string | null;
      fields: Array<{
        __typename: "InformationRequestField";
        id: string;
        label: string;
        placeholder: string;
        dataType: string;
        value?: string | null;
        createdAt: string;
        updatedAt: string;
      }>;
    }>;
    documents: Array<{
      __typename: "Document";
      id: string;
      filename: string;
      url: string;
      documentableId: string;
      createdAt: string;
    }>;
  } | null;
};

export type IFindCurrentPartnerQueryVariables = Exact<{ [key: string]: never }>;

export type IFindCurrentPartnerQuery = {
  __typename: "Query";
  currentPartner: {
    __typename: "Partner";
    id: string;
    name: string;
    referralCode?: string | null;
    referralLink: string;
    liveWebhookUrl?: string | null;
    testWebhookUrl?: string | null;
    apiKey: string;
    testApiKey: string;
    totalSavings: number;
    customerCount: number;
    billCount: number;
    readyBillCount: number;
    averageSavingsPerBill: number;
    averageSavingsPerReadyBill: number;
    averageSavingsPerCustomer: number;
    customersWithReadyBillsCount: number;
    customersWithoutReadyBillsCount: number;
    lastSevenDaysSignups: number;
    lastThirtyDaysSignups: number;
    lastNinetyDaysSignups: number;
    webhooks: Array<{
      __typename: "PartnerWebhook";
      id: string;
      partnerId: string;
      webhookEnvironment?: string | null;
      url: string;
      oauthUrl?: string | null;
      webhookType: string;
      events: Array<string>;
      clientId?: string | null;
      clientSecret?: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
    webhookHeaders: Array<{
      __typename: "PartnerWebhookHeader";
      id: string;
      partnerId: string;
      headerName: string;
      headerValue: string;
      headerType: string;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type IFindCurrentPartnerUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IFindCurrentPartnerUserQuery = {
  __typename: "Query";
  currentUser: {
    __typename: "PartnerUser";
    id: string;
    partnerId: string;
    email: string;
    authenticationToken: string;
    role: string;
    lastSignInAt?: string | null;
  };
};

export type IFindCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"];
}>;

export type IFindCustomerQuery = {
  __typename: "Query";
  customer?: {
    __typename: "Customer";
    id: string;
    externalId?: string | null;
    email?: string | null;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    createdAt: string;
    updatedAt: string;
    allTimeGrossSavings: number;
    billCount: number;
    readyBillCount: number;
    referralData?: any | null;
    eligibleForNegotiation: boolean;
  } | null;
};

export type IListBillsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  missingDocumentInfo?: InputMaybe<Scalars["Boolean"]>;
  apiId?: InputMaybe<Scalars["ID"]>;
}>;

export type IListBillsQuery = {
  __typename: "Query";
  ListBills: {
    __typename: "BillConnection";
    totalCount: number;
    totalSavings: number;
    nodes: Array<{
      __typename: "Bill";
      id: string;
      status: string;
      title: string;
      providerId: string;
      totalSavings: number;
      grossSavings: number;
      autoRenegotiate: boolean;
      allowsContract: boolean;
      createdAt: string;
      updatedAt: string;
      cancelledAt?: string | null;
      documentlessInfo?: any | null;
      missingDocumentInfo: boolean;
      documentless: boolean;
      documentCount: number;
      nextRenegotiateOn?: string | null;
      customer: { __typename: "Customer"; id: string; name: string };
      provider: { __typename: "Provider"; id: string; name: string };
    }>;
  };
};

export type IListCustomersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type IListCustomersQuery = {
  __typename: "Query";
  ListCustomers: {
    __typename: "CustomerConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Customer";
      id: string;
      externalId?: string | null;
      email?: string | null;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      createdAt: string;
      updatedAt: string;
      allTimeGrossSavings: number;
      billCount: number;
      readyBillCount: number;
      referralData?: any | null;
      eligibleForNegotiation: boolean;
    }>;
  };
};

export type IListPartnerUsersQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
}>;

export type IListPartnerUsersQuery = {
  __typename: "Query";
  ListPartnerUsers: {
    __typename: "PartnerUserConnection";
    nodes: Array<{
      __typename: "PartnerUser";
      id: string;
      partnerId: string;
      email: string;
      authenticationToken: string;
      role: string;
      lastSignInAt?: string | null;
    }>;
  };
};

export type IListProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type IListProvidersQuery = {
  __typename: "Query";
  ListProviders: Array<{
    __typename: "Provider";
    id: string;
    name: string;
    ranking?: number | null;
    services: Array<string>;
  }>;
};

export type IListWebhookEventsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  subjectType?: InputMaybe<Scalars["String"]>;
  delivered?: InputMaybe<Scalars["Boolean"]>;
  notDelivered?: InputMaybe<Scalars["Boolean"]>;
  eventType?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  recordApiId?: InputMaybe<Scalars["ID"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type IListWebhookEventsQuery = {
  __typename: "Query";
  events: {
    __typename: "WebhookEventConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      endCursor?: string | null;
      hasNextPage: boolean;
    };
    nodes: Array<{
      __typename: "WebhookEvent";
      id: string;
      additionalData?: any | null;
      apiId: string;
      createdAt: string;
      delivered: boolean;
      deliveredAt?: string | null;
      deliveredAtUtc?: string | null;
      eventType: string;
      partnerId: string;
      partnerWebhookId?: string | null;
      recordApiId: string;
      retryCount: number;
      subjectId: string;
      subjectType: string;
      updatedAt: string;
      url?: string | null;
      partnerWebhook?: {
        __typename: "PartnerWebhook";
        id: string;
        url: string;
        webhookEnvironment?: string | null;
      } | null;
    }>;
  };
};

export type ISandboxListNegotiationsForBillQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type ISandboxListNegotiationsForBillQuery = {
  __typename: "Query";
  negotiations: {
    __typename: "NegotiationConnection";
    nodes: Array<{
      __typename: "Negotiation";
      id: string;
      billId: string;
      negotiationNumber: number;
      state: string;
      totalSavings: number;
      resultsNote?: string | null;
      typeOfSavings?: string | null;
      startedAt?: string | null;
      fixedAt?: string | null;
      failedAt?: string | null;
      items: Array<{
        __typename: "Item";
        name: string;
        type: string;
        prePrice: number;
        postPrice: number;
        duration: number;
        expired: boolean;
        savings: number;
        savingsStartOn: string;
        savingsEndOn: string;
        underContract: boolean;
        createdAt: string;
        updatedAt: string;
      }>;
    }>;
  };
};

export const ItemFragmentDoc = gql`
  fragment ItemFragment on Item {
    name
    type
    prePrice
    postPrice
    duration
    expired
    savings
    savingsStartOn
    savingsEndOn
    expired
    underContract
    createdAt
    updatedAt
  }
`;
export const ProviderFragmentDoc = gql`
  fragment ProviderFragment on Provider {
    id
    name
    ranking
    services
  }
`;
export const CustomerFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    id
    externalId
    email
    name
    firstName
    lastName
    phoneNumber
    createdAt
    updatedAt
    allTimeGrossSavings
    billCount
    readyBillCount
    referralData
    eligibleForNegotiation
  }
`;
export const OfferFragmentDoc = gql`
  fragment OfferFragment on Offer {
    id
    billId
    status
    content
    contentHtml
    daysUntilExpiration
    createdAt
    updatedAt
    acceptedAt
    rejectedAt
  }
`;
export const InformationRequestFieldFragmentDoc = gql`
  fragment InformationRequestFieldFragment on InformationRequestField {
    id
    label
    placeholder
    dataType
    value
    createdAt
    updatedAt
  }
`;
export const InformationRequestFragmentDoc = gql`
  fragment InformationRequestFragment on InformationRequest {
    id
    billId
    customerId
    content
    contentHtml
    headerText
    createdAt
    updatedAt
    respondedAt
    fields {
      ...InformationRequestFieldFragment
    }
  }
  ${InformationRequestFieldFragmentDoc}
`;
export const DocumentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    id
    filename
    url
    documentableId
    createdAt
  }
`;
export const BillFragmentDoc = gql`
  fragment BillFragment on Bill {
    id
    status
    title
    providerId
    totalSavings
    grossSavings
    autoRenegotiate
    allowsContract
    createdAt
    updatedAt
    cancelledAt
    documentlessInfo
    missingDocumentInfo
    documentless
    documentCount
    nextRenegotiateOn
    items {
      ...ItemFragment
    }
    provider {
      ...ProviderFragment
    }
    customer {
      ...CustomerFragment
    }
    offers {
      ...OfferFragment
    }
    informationRequests {
      ...InformationRequestFragment
    }
    documents {
      ...DocumentFragment
    }
  }
  ${ItemFragmentDoc}
  ${ProviderFragmentDoc}
  ${CustomerFragmentDoc}
  ${OfferFragmentDoc}
  ${InformationRequestFragmentDoc}
  ${DocumentFragmentDoc}
`;
export const NegotiationFragmentDoc = gql`
  fragment NegotiationFragment on Negotiation {
    id
    billId
    negotiationNumber
    state
    totalSavings
    resultsNote
    typeOfSavings
    startedAt
    fixedAt
    failedAt
    items {
      ...ItemFragment
    }
  }
  ${ItemFragmentDoc}
`;
export const PartnerWebhookFragmentDoc = gql`
  fragment PartnerWebhookFragment on PartnerWebhook {
    id
    partnerId
    webhookEnvironment
    url
    oauthUrl
    webhookType
    events
    clientId
    clientSecret
    createdAt
    updatedAt
  }
`;
export const PartnerWebhookHeaderFragmentDoc = gql`
  fragment PartnerWebhookHeaderFragment on PartnerWebhookHeader {
    id
    partnerId
    headerName
    headerValue
    headerType
    createdAt
    updatedAt
  }
`;
export const PartnerFragmentDoc = gql`
  fragment PartnerFragment on Partner {
    id
    name
    referralCode
    referralLink
    liveWebhookUrl
    testWebhookUrl
    apiKey
    testApiKey
    totalSavings
    customerCount
    billCount
    readyBillCount
    averageSavingsPerBill
    averageSavingsPerReadyBill
    averageSavingsPerCustomer
    customersWithReadyBillsCount
    customersWithoutReadyBillsCount
    lastSevenDaysSignups
    lastThirtyDaysSignups
    lastNinetyDaysSignups
    webhooks {
      ...PartnerWebhookFragment
    }
    webhookHeaders {
      ...PartnerWebhookHeaderFragment
    }
  }
  ${PartnerWebhookFragmentDoc}
  ${PartnerWebhookHeaderFragmentDoc}
`;
export const PartnerUserFragmentDoc = gql`
  fragment PartnerUserFragment on PartnerUser {
    id
    partnerId
    email
    authenticationToken
    role
    lastSignInAt
  }
`;
export const WebhookEventFragmentDoc = gql`
  fragment WebhookEventFragment on WebhookEvent {
    id
    additionalData
    apiId
    createdAt
    delivered
    deliveredAt
    deliveredAtUtc
    eventType
    partnerId
    partnerWebhookId
    recordApiId
    retryCount
    subjectId
    subjectType
    updatedAt
    url
    partnerWebhook {
      id
      url
      webhookEnvironment
    }
  }
`;
export const CreatePartnerUserDocument = gql`
  mutation CreatePartnerUser($params: PartnerUserAttributes!) {
    CreatePartnerUser(input: { partnerUser: $params }) {
      success
      errors
      partnerUser {
        ...PartnerUserFragment
      }
    }
  }
  ${PartnerUserFragmentDoc}
`;
export type ICreatePartnerUserMutationFn = ApolloReactCommon.MutationFunction<
  ICreatePartnerUserMutation,
  ICreatePartnerUserMutationVariables
>;

/**
 * __useCreatePartnerUserMutation__
 *
 * To run a mutation, you first call `useCreatePartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerUserMutation, { data, loading, error }] = useCreatePartnerUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreatePartnerUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreatePartnerUserMutation,
    ICreatePartnerUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ICreatePartnerUserMutation,
    ICreatePartnerUserMutationVariables
  >(CreatePartnerUserDocument, options);
}
export type CreatePartnerUserMutationHookResult = ReturnType<
  typeof useCreatePartnerUserMutation
>;
export type CreatePartnerUserMutationResult =
  ApolloReactCommon.MutationResult<ICreatePartnerUserMutation>;
export type CreatePartnerUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreatePartnerUserMutation,
    ICreatePartnerUserMutationVariables
  >;
export const CreatePartnerWebhookDocument = gql`
  mutation CreatePartnerWebhook($webhook: PartnerWebhookAttributes!) {
    CreatePartnerWebhook(input: { webhook: $webhook }) {
      success
      errors
      webhook {
        ...PartnerWebhookFragment
      }
    }
  }
  ${PartnerWebhookFragmentDoc}
`;
export type ICreatePartnerWebhookMutationFn =
  ApolloReactCommon.MutationFunction<
    ICreatePartnerWebhookMutation,
    ICreatePartnerWebhookMutationVariables
  >;

/**
 * __useCreatePartnerWebhookMutation__
 *
 * To run a mutation, you first call `useCreatePartnerWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerWebhookMutation, { data, loading, error }] = useCreatePartnerWebhookMutation({
 *   variables: {
 *      webhook: // value for 'webhook'
 *   },
 * });
 */
export function useCreatePartnerWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreatePartnerWebhookMutation,
    ICreatePartnerWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ICreatePartnerWebhookMutation,
    ICreatePartnerWebhookMutationVariables
  >(CreatePartnerWebhookDocument, options);
}
export type CreatePartnerWebhookMutationHookResult = ReturnType<
  typeof useCreatePartnerWebhookMutation
>;
export type CreatePartnerWebhookMutationResult =
  ApolloReactCommon.MutationResult<ICreatePartnerWebhookMutation>;
export type CreatePartnerWebhookMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreatePartnerWebhookMutation,
    ICreatePartnerWebhookMutationVariables
  >;
export const CreatePartnerWebhookHeaderDocument = gql`
  mutation CreatePartnerWebhookHeader(
    $webhookHeader: PartnerWebhookHeaderAttributes!
  ) {
    CreatePartnerWebhookHeader(input: { webhookHeader: $webhookHeader }) {
      success
      errors
      webhookHeader {
        id
        headerName
        headerValue
        headerType
      }
    }
  }
`;
export type ICreatePartnerWebhookHeaderMutationFn =
  ApolloReactCommon.MutationFunction<
    ICreatePartnerWebhookHeaderMutation,
    ICreatePartnerWebhookHeaderMutationVariables
  >;

/**
 * __useCreatePartnerWebhookHeaderMutation__
 *
 * To run a mutation, you first call `useCreatePartnerWebhookHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerWebhookHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerWebhookHeaderMutation, { data, loading, error }] = useCreatePartnerWebhookHeaderMutation({
 *   variables: {
 *      webhookHeader: // value for 'webhookHeader'
 *   },
 * });
 */
export function useCreatePartnerWebhookHeaderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreatePartnerWebhookHeaderMutation,
    ICreatePartnerWebhookHeaderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ICreatePartnerWebhookHeaderMutation,
    ICreatePartnerWebhookHeaderMutationVariables
  >(CreatePartnerWebhookHeaderDocument, options);
}
export type CreatePartnerWebhookHeaderMutationHookResult = ReturnType<
  typeof useCreatePartnerWebhookHeaderMutation
>;
export type CreatePartnerWebhookHeaderMutationResult =
  ApolloReactCommon.MutationResult<ICreatePartnerWebhookHeaderMutation>;
export type CreatePartnerWebhookHeaderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreatePartnerWebhookHeaderMutation,
    ICreatePartnerWebhookHeaderMutationVariables
  >;
export const DeletePartnerWebhookDocument = gql`
  mutation DeletePartnerWebhook($webhookId: ID!) {
    DeletePartnerWebhook(input: { webhookId: $webhookId }) {
      success
      errors
    }
  }
`;
export type IDeletePartnerWebhookMutationFn =
  ApolloReactCommon.MutationFunction<
    IDeletePartnerWebhookMutation,
    IDeletePartnerWebhookMutationVariables
  >;

/**
 * __useDeletePartnerWebhookMutation__
 *
 * To run a mutation, you first call `useDeletePartnerWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerWebhookMutation, { data, loading, error }] = useDeletePartnerWebhookMutation({
 *   variables: {
 *      webhookId: // value for 'webhookId'
 *   },
 * });
 */
export function useDeletePartnerWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IDeletePartnerWebhookMutation,
    IDeletePartnerWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IDeletePartnerWebhookMutation,
    IDeletePartnerWebhookMutationVariables
  >(DeletePartnerWebhookDocument, options);
}
export type DeletePartnerWebhookMutationHookResult = ReturnType<
  typeof useDeletePartnerWebhookMutation
>;
export type DeletePartnerWebhookMutationResult =
  ApolloReactCommon.MutationResult<IDeletePartnerWebhookMutation>;
export type DeletePartnerWebhookMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IDeletePartnerWebhookMutation,
    IDeletePartnerWebhookMutationVariables
  >;
export const DeletePartnerWebhookHeaderDocument = gql`
  mutation DeletePartnerWebhookHeader($webhookHeaderId: ID!) {
    DeletePartnerWebhookHeader(input: { webhookHeaderId: $webhookHeaderId }) {
      success
      errors
    }
  }
`;
export type IDeletePartnerWebhookHeaderMutationFn =
  ApolloReactCommon.MutationFunction<
    IDeletePartnerWebhookHeaderMutation,
    IDeletePartnerWebhookHeaderMutationVariables
  >;

/**
 * __useDeletePartnerWebhookHeaderMutation__
 *
 * To run a mutation, you first call `useDeletePartnerWebhookHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerWebhookHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerWebhookHeaderMutation, { data, loading, error }] = useDeletePartnerWebhookHeaderMutation({
 *   variables: {
 *      webhookHeaderId: // value for 'webhookHeaderId'
 *   },
 * });
 */
export function useDeletePartnerWebhookHeaderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IDeletePartnerWebhookHeaderMutation,
    IDeletePartnerWebhookHeaderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IDeletePartnerWebhookHeaderMutation,
    IDeletePartnerWebhookHeaderMutationVariables
  >(DeletePartnerWebhookHeaderDocument, options);
}
export type DeletePartnerWebhookHeaderMutationHookResult = ReturnType<
  typeof useDeletePartnerWebhookHeaderMutation
>;
export type DeletePartnerWebhookHeaderMutationResult =
  ApolloReactCommon.MutationResult<IDeletePartnerWebhookHeaderMutation>;
export type DeletePartnerWebhookHeaderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IDeletePartnerWebhookHeaderMutation,
    IDeletePartnerWebhookHeaderMutationVariables
  >;
export const RotateApiKeysDocument = gql`
  mutation RotateApiKeys {
    RotateApiKeys(input: {}) {
      success
      errors
      partner {
        ...PartnerFragment
      }
    }
  }
  ${PartnerFragmentDoc}
`;
export type IRotateApiKeysMutationFn = ApolloReactCommon.MutationFunction<
  IRotateApiKeysMutation,
  IRotateApiKeysMutationVariables
>;

/**
 * __useRotateApiKeysMutation__
 *
 * To run a mutation, you first call `useRotateApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateApiKeysMutation, { data, loading, error }] = useRotateApiKeysMutation({
 *   variables: {
 *   },
 * });
 */
export function useRotateApiKeysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IRotateApiKeysMutation,
    IRotateApiKeysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IRotateApiKeysMutation,
    IRotateApiKeysMutationVariables
  >(RotateApiKeysDocument, options);
}
export type RotateApiKeysMutationHookResult = ReturnType<
  typeof useRotateApiKeysMutation
>;
export type RotateApiKeysMutationResult =
  ApolloReactCommon.MutationResult<IRotateApiKeysMutation>;
export type RotateApiKeysMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IRotateApiKeysMutation,
    IRotateApiKeysMutationVariables
  >;
export const SandboxGenerateNegotiationDocument = gql`
  mutation SandboxGenerateNegotiation($billId: ID!) {
    SandboxGenerateNegotiation(input: { billId: $billId }) {
      success
      errors
      bill {
        ...BillFragment
      }
    }
  }
  ${BillFragmentDoc}
`;
export type ISandboxGenerateNegotiationMutationFn =
  ApolloReactCommon.MutationFunction<
    ISandboxGenerateNegotiationMutation,
    ISandboxGenerateNegotiationMutationVariables
  >;

/**
 * __useSandboxGenerateNegotiationMutation__
 *
 * To run a mutation, you first call `useSandboxGenerateNegotiationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSandboxGenerateNegotiationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sandboxGenerateNegotiationMutation, { data, loading, error }] = useSandboxGenerateNegotiationMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useSandboxGenerateNegotiationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISandboxGenerateNegotiationMutation,
    ISandboxGenerateNegotiationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ISandboxGenerateNegotiationMutation,
    ISandboxGenerateNegotiationMutationVariables
  >(SandboxGenerateNegotiationDocument, options);
}
export type SandboxGenerateNegotiationMutationHookResult = ReturnType<
  typeof useSandboxGenerateNegotiationMutation
>;
export type SandboxGenerateNegotiationMutationResult =
  ApolloReactCommon.MutationResult<ISandboxGenerateNegotiationMutation>;
export type SandboxGenerateNegotiationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISandboxGenerateNegotiationMutation,
    ISandboxGenerateNegotiationMutationVariables
  >;
export const SandboxResetBillDocument = gql`
  mutation SandboxResetBill($billId: ID!) {
    SandboxResetBill(input: { billId: $billId }) {
      success
      errors
      bill {
        ...BillFragment
      }
    }
  }
  ${BillFragmentDoc}
`;
export type ISandboxResetBillMutationFn = ApolloReactCommon.MutationFunction<
  ISandboxResetBillMutation,
  ISandboxResetBillMutationVariables
>;

/**
 * __useSandboxResetBillMutation__
 *
 * To run a mutation, you first call `useSandboxResetBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSandboxResetBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sandboxResetBillMutation, { data, loading, error }] = useSandboxResetBillMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useSandboxResetBillMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISandboxResetBillMutation,
    ISandboxResetBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ISandboxResetBillMutation,
    ISandboxResetBillMutationVariables
  >(SandboxResetBillDocument, options);
}
export type SandboxResetBillMutationHookResult = ReturnType<
  typeof useSandboxResetBillMutation
>;
export type SandboxResetBillMutationResult =
  ApolloReactCommon.MutationResult<ISandboxResetBillMutation>;
export type SandboxResetBillMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISandboxResetBillMutation,
    ISandboxResetBillMutationVariables
  >;
export const SandboxTransitionNegotiationDocument = gql`
  mutation SandboxTransitionNegotiation($billId: ID!, $transition: String!) {
    SandboxTransitionNegotiation(
      input: { billId: $billId, transition: $transition }
    ) {
      success
      errors
      bill {
        ...BillFragment
      }
      negotiation {
        ...NegotiationFragment
      }
    }
  }
  ${BillFragmentDoc}
  ${NegotiationFragmentDoc}
`;
export type ISandboxTransitionNegotiationMutationFn =
  ApolloReactCommon.MutationFunction<
    ISandboxTransitionNegotiationMutation,
    ISandboxTransitionNegotiationMutationVariables
  >;

/**
 * __useSandboxTransitionNegotiationMutation__
 *
 * To run a mutation, you first call `useSandboxTransitionNegotiationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSandboxTransitionNegotiationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sandboxTransitionNegotiationMutation, { data, loading, error }] = useSandboxTransitionNegotiationMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      transition: // value for 'transition'
 *   },
 * });
 */
export function useSandboxTransitionNegotiationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISandboxTransitionNegotiationMutation,
    ISandboxTransitionNegotiationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ISandboxTransitionNegotiationMutation,
    ISandboxTransitionNegotiationMutationVariables
  >(SandboxTransitionNegotiationDocument, options);
}
export type SandboxTransitionNegotiationMutationHookResult = ReturnType<
  typeof useSandboxTransitionNegotiationMutation
>;
export type SandboxTransitionNegotiationMutationResult =
  ApolloReactCommon.MutationResult<ISandboxTransitionNegotiationMutation>;
export type SandboxTransitionNegotiationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISandboxTransitionNegotiationMutation,
    ISandboxTransitionNegotiationMutationVariables
  >;
export const UpdatePartnerUserDocument = gql`
  mutation UpdatePartnerUser($id: ID!, $params: PartnerUserAttributes!) {
    UpdatePartnerUser(input: { id: $id, partnerUser: $params }) {
      success
      errors
      partnerUser {
        ...PartnerUserFragment
      }
    }
  }
  ${PartnerUserFragmentDoc}
`;
export type IUpdatePartnerUserMutationFn = ApolloReactCommon.MutationFunction<
  IUpdatePartnerUserMutation,
  IUpdatePartnerUserMutationVariables
>;

/**
 * __useUpdatePartnerUserMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerUserMutation, { data, loading, error }] = useUpdatePartnerUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdatePartnerUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdatePartnerUserMutation,
    IUpdatePartnerUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IUpdatePartnerUserMutation,
    IUpdatePartnerUserMutationVariables
  >(UpdatePartnerUserDocument, options);
}
export type UpdatePartnerUserMutationHookResult = ReturnType<
  typeof useUpdatePartnerUserMutation
>;
export type UpdatePartnerUserMutationResult =
  ApolloReactCommon.MutationResult<IUpdatePartnerUserMutation>;
export type UpdatePartnerUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdatePartnerUserMutation,
    IUpdatePartnerUserMutationVariables
  >;
export const UpdatePartnerWebhookDocument = gql`
  mutation UpdatePartnerWebhook(
    $webhookId: ID!
    $webhook: PartnerWebhookAttributes!
  ) {
    UpdatePartnerWebhook(input: { webhookId: $webhookId, webhook: $webhook }) {
      success
      errors
      webhook {
        ...PartnerWebhookFragment
      }
    }
  }
  ${PartnerWebhookFragmentDoc}
`;
export type IUpdatePartnerWebhookMutationFn =
  ApolloReactCommon.MutationFunction<
    IUpdatePartnerWebhookMutation,
    IUpdatePartnerWebhookMutationVariables
  >;

/**
 * __useUpdatePartnerWebhookMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerWebhookMutation, { data, loading, error }] = useUpdatePartnerWebhookMutation({
 *   variables: {
 *      webhookId: // value for 'webhookId'
 *      webhook: // value for 'webhook'
 *   },
 * });
 */
export function useUpdatePartnerWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdatePartnerWebhookMutation,
    IUpdatePartnerWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IUpdatePartnerWebhookMutation,
    IUpdatePartnerWebhookMutationVariables
  >(UpdatePartnerWebhookDocument, options);
}
export type UpdatePartnerWebhookMutationHookResult = ReturnType<
  typeof useUpdatePartnerWebhookMutation
>;
export type UpdatePartnerWebhookMutationResult =
  ApolloReactCommon.MutationResult<IUpdatePartnerWebhookMutation>;
export type UpdatePartnerWebhookMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdatePartnerWebhookMutation,
    IUpdatePartnerWebhookMutationVariables
  >;
export const UpdatePartnerWebhookHeaderDocument = gql`
  mutation UpdatePartnerWebhookHeader(
    $webhookHeaderId: ID!
    $webhookHeader: PartnerWebhookHeaderAttributes!
  ) {
    UpdatePartnerWebhookHeader(
      input: {
        webhookHeaderId: $webhookHeaderId
        webhookHeader: $webhookHeader
      }
    ) {
      success
      errors
      webhookHeader {
        id
        headerName
        headerValue
        headerType
      }
    }
  }
`;
export type IUpdatePartnerWebhookHeaderMutationFn =
  ApolloReactCommon.MutationFunction<
    IUpdatePartnerWebhookHeaderMutation,
    IUpdatePartnerWebhookHeaderMutationVariables
  >;

/**
 * __useUpdatePartnerWebhookHeaderMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerWebhookHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerWebhookHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerWebhookHeaderMutation, { data, loading, error }] = useUpdatePartnerWebhookHeaderMutation({
 *   variables: {
 *      webhookHeaderId: // value for 'webhookHeaderId'
 *      webhookHeader: // value for 'webhookHeader'
 *   },
 * });
 */
export function useUpdatePartnerWebhookHeaderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdatePartnerWebhookHeaderMutation,
    IUpdatePartnerWebhookHeaderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IUpdatePartnerWebhookHeaderMutation,
    IUpdatePartnerWebhookHeaderMutationVariables
  >(UpdatePartnerWebhookHeaderDocument, options);
}
export type UpdatePartnerWebhookHeaderMutationHookResult = ReturnType<
  typeof useUpdatePartnerWebhookHeaderMutation
>;
export type UpdatePartnerWebhookHeaderMutationResult =
  ApolloReactCommon.MutationResult<IUpdatePartnerWebhookHeaderMutation>;
export type UpdatePartnerWebhookHeaderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdatePartnerWebhookHeaderMutation,
    IUpdatePartnerWebhookHeaderMutationVariables
  >;
export const UpdateWebhookUrlsDocument = gql`
  mutation UpdateWebhookUrls($liveWebhookUrl: String, $testWebhookUrl: String) {
    UpdateWebhookUrls(
      input: {
        liveWebhookUrl: $liveWebhookUrl
        testWebhookUrl: $testWebhookUrl
      }
    ) {
      success
      errors
      partner {
        ...PartnerFragment
      }
    }
  }
  ${PartnerFragmentDoc}
`;
export type IUpdateWebhookUrlsMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateWebhookUrlsMutation,
  IUpdateWebhookUrlsMutationVariables
>;

/**
 * __useUpdateWebhookUrlsMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookUrlsMutation, { data, loading, error }] = useUpdateWebhookUrlsMutation({
 *   variables: {
 *      liveWebhookUrl: // value for 'liveWebhookUrl'
 *      testWebhookUrl: // value for 'testWebhookUrl'
 *   },
 * });
 */
export function useUpdateWebhookUrlsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateWebhookUrlsMutation,
    IUpdateWebhookUrlsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    IUpdateWebhookUrlsMutation,
    IUpdateWebhookUrlsMutationVariables
  >(UpdateWebhookUrlsDocument, options);
}
export type UpdateWebhookUrlsMutationHookResult = ReturnType<
  typeof useUpdateWebhookUrlsMutation
>;
export type UpdateWebhookUrlsMutationResult =
  ApolloReactCommon.MutationResult<IUpdateWebhookUrlsMutation>;
export type UpdateWebhookUrlsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdateWebhookUrlsMutation,
    IUpdateWebhookUrlsMutationVariables
  >;
export const CalculateSavingsEstimateDocument = gql`
  query CalculateSavingsEstimate(
    $providerId: ID!
    $currentMonthlyAmount: Float!
  ) {
    estimate: CalculateSavingsEstimate {
      estimatedAnnualSavings(
        providerId: $providerId
        currentMonthlyAmount: $currentMonthlyAmount
      )
      estimatedMonthlySavings(
        providerId: $providerId
        currentMonthlyAmount: $currentMonthlyAmount
      )
      percentageSavings(
        providerId: $providerId
        currentMonthlyAmount: $currentMonthlyAmount
      )
    }
  }
`;

/**
 * __useCalculateSavingsEstimateQuery__
 *
 * To run a query within a React component, call `useCalculateSavingsEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateSavingsEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateSavingsEstimateQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      currentMonthlyAmount: // value for 'currentMonthlyAmount'
 *   },
 * });
 */
export function useCalculateSavingsEstimateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ICalculateSavingsEstimateQuery,
    ICalculateSavingsEstimateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ICalculateSavingsEstimateQuery,
    ICalculateSavingsEstimateQueryVariables
  >(CalculateSavingsEstimateDocument, options);
}
export function useCalculateSavingsEstimateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ICalculateSavingsEstimateQuery,
    ICalculateSavingsEstimateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ICalculateSavingsEstimateQuery,
    ICalculateSavingsEstimateQueryVariables
  >(CalculateSavingsEstimateDocument, options);
}
export type CalculateSavingsEstimateQueryHookResult = ReturnType<
  typeof useCalculateSavingsEstimateQuery
>;
export type CalculateSavingsEstimateLazyQueryHookResult = ReturnType<
  typeof useCalculateSavingsEstimateLazyQuery
>;
export type CalculateSavingsEstimateQueryResult = ApolloReactCommon.QueryResult<
  ICalculateSavingsEstimateQuery,
  ICalculateSavingsEstimateQueryVariables
>;
export const FindBillDocument = gql`
  query FindBill($billId: ID!) {
    bill: FindBill(id: $billId) {
      ...BillFragment
    }
  }
  ${BillFragmentDoc}
`;

/**
 * __useFindBillQuery__
 *
 * To run a query within a React component, call `useFindBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBillQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useFindBillQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindBillQuery,
    IFindBillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFindBillQuery, IFindBillQueryVariables>(
    FindBillDocument,
    options
  );
}
export function useFindBillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindBillQuery,
    IFindBillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFindBillQuery, IFindBillQueryVariables>(
    FindBillDocument,
    options
  );
}
export type FindBillQueryHookResult = ReturnType<typeof useFindBillQuery>;
export type FindBillLazyQueryHookResult = ReturnType<
  typeof useFindBillLazyQuery
>;
export type FindBillQueryResult = ApolloReactCommon.QueryResult<
  IFindBillQuery,
  IFindBillQueryVariables
>;
export const FindCurrentPartnerDocument = gql`
  query FindCurrentPartner {
    currentPartner: FindCurrentPartner {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentDoc}
`;

/**
 * __useFindCurrentPartnerQuery__
 *
 * To run a query within a React component, call `useFindCurrentPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentPartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCurrentPartnerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFindCurrentPartnerQuery,
    IFindCurrentPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFindCurrentPartnerQuery,
    IFindCurrentPartnerQueryVariables
  >(FindCurrentPartnerDocument, options);
}
export function useFindCurrentPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindCurrentPartnerQuery,
    IFindCurrentPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFindCurrentPartnerQuery,
    IFindCurrentPartnerQueryVariables
  >(FindCurrentPartnerDocument, options);
}
export type FindCurrentPartnerQueryHookResult = ReturnType<
  typeof useFindCurrentPartnerQuery
>;
export type FindCurrentPartnerLazyQueryHookResult = ReturnType<
  typeof useFindCurrentPartnerLazyQuery
>;
export type FindCurrentPartnerQueryResult = ApolloReactCommon.QueryResult<
  IFindCurrentPartnerQuery,
  IFindCurrentPartnerQueryVariables
>;
export const FindCurrentPartnerUserDocument = gql`
  query FindCurrentPartnerUser {
    currentUser: FindCurrentPartnerUser {
      ...PartnerUserFragment
    }
  }
  ${PartnerUserFragmentDoc}
`;

/**
 * __useFindCurrentPartnerUserQuery__
 *
 * To run a query within a React component, call `useFindCurrentPartnerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentPartnerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentPartnerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCurrentPartnerUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFindCurrentPartnerUserQuery,
    IFindCurrentPartnerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFindCurrentPartnerUserQuery,
    IFindCurrentPartnerUserQueryVariables
  >(FindCurrentPartnerUserDocument, options);
}
export function useFindCurrentPartnerUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindCurrentPartnerUserQuery,
    IFindCurrentPartnerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFindCurrentPartnerUserQuery,
    IFindCurrentPartnerUserQueryVariables
  >(FindCurrentPartnerUserDocument, options);
}
export type FindCurrentPartnerUserQueryHookResult = ReturnType<
  typeof useFindCurrentPartnerUserQuery
>;
export type FindCurrentPartnerUserLazyQueryHookResult = ReturnType<
  typeof useFindCurrentPartnerUserLazyQuery
>;
export type FindCurrentPartnerUserQueryResult = ApolloReactCommon.QueryResult<
  IFindCurrentPartnerUserQuery,
  IFindCurrentPartnerUserQueryVariables
>;
export const FindCustomerDocument = gql`
  query FindCustomer($customerId: ID!) {
    customer: FindCustomer(id: $customerId) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useFindCustomerQuery__
 *
 * To run a query within a React component, call `useFindCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useFindCustomerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindCustomerQuery,
    IFindCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFindCustomerQuery,
    IFindCustomerQueryVariables
  >(FindCustomerDocument, options);
}
export function useFindCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindCustomerQuery,
    IFindCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFindCustomerQuery,
    IFindCustomerQueryVariables
  >(FindCustomerDocument, options);
}
export type FindCustomerQueryHookResult = ReturnType<
  typeof useFindCustomerQuery
>;
export type FindCustomerLazyQueryHookResult = ReturnType<
  typeof useFindCustomerLazyQuery
>;
export type FindCustomerQueryResult = ApolloReactCommon.QueryResult<
  IFindCustomerQuery,
  IFindCustomerQueryVariables
>;
export const ListBillsDocument = gql`
  query ListBills(
    $limit: Int
    $offset: Int
    $customerId: ID
    $status: String
    $missingDocumentInfo: Boolean
    $apiId: ID
  ) {
    ListBills(
      limit: $limit
      offset: $offset
      customerId: $customerId
      status: $status
      missingDocumentInfo: $missingDocumentInfo
      apiId: $apiId
    ) {
      totalCount
      totalSavings
      nodes {
        id
        status
        title
        providerId
        totalSavings
        grossSavings
        autoRenegotiate
        allowsContract
        createdAt
        updatedAt
        cancelledAt
        documentlessInfo
        missingDocumentInfo
        documentless
        documentCount
        nextRenegotiateOn
        customer {
          id
          name
        }
        provider {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useListBillsQuery__
 *
 * To run a query within a React component, call `useListBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBillsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customerId: // value for 'customerId'
 *      status: // value for 'status'
 *      missingDocumentInfo: // value for 'missingDocumentInfo'
 *      apiId: // value for 'apiId'
 *   },
 * });
 */
export function useListBillsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListBillsQuery,
    IListBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IListBillsQuery, IListBillsQueryVariables>(
    ListBillsDocument,
    options
  );
}
export function useListBillsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListBillsQuery,
    IListBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IListBillsQuery,
    IListBillsQueryVariables
  >(ListBillsDocument, options);
}
export type ListBillsQueryHookResult = ReturnType<typeof useListBillsQuery>;
export type ListBillsLazyQueryHookResult = ReturnType<
  typeof useListBillsLazyQuery
>;
export type ListBillsQueryResult = ApolloReactCommon.QueryResult<
  IListBillsQuery,
  IListBillsQueryVariables
>;
export const ListCustomersDocument = gql`
  query ListCustomers($limit: Int, $offset: Int) {
    ListCustomers(limit: $limit, offset: $offset) {
      totalCount
      nodes {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useListCustomersQuery__
 *
 * To run a query within a React component, call `useListCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListCustomersQuery,
    IListCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IListCustomersQuery,
    IListCustomersQueryVariables
  >(ListCustomersDocument, options);
}
export function useListCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListCustomersQuery,
    IListCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IListCustomersQuery,
    IListCustomersQueryVariables
  >(ListCustomersDocument, options);
}
export type ListCustomersQueryHookResult = ReturnType<
  typeof useListCustomersQuery
>;
export type ListCustomersLazyQueryHookResult = ReturnType<
  typeof useListCustomersLazyQuery
>;
export type ListCustomersQueryResult = ApolloReactCommon.QueryResult<
  IListCustomersQuery,
  IListCustomersQueryVariables
>;
export const ListPartnerUsersDocument = gql`
  query ListPartnerUsers($skip: Int, $q: String) {
    ListPartnerUsers(skip: $skip, q: $q) {
      nodes {
        ...PartnerUserFragment
      }
    }
  }
  ${PartnerUserFragmentDoc}
`;

/**
 * __useListPartnerUsersQuery__
 *
 * To run a query within a React component, call `useListPartnerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartnerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartnerUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useListPartnerUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListPartnerUsersQuery,
    IListPartnerUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IListPartnerUsersQuery,
    IListPartnerUsersQueryVariables
  >(ListPartnerUsersDocument, options);
}
export function useListPartnerUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListPartnerUsersQuery,
    IListPartnerUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IListPartnerUsersQuery,
    IListPartnerUsersQueryVariables
  >(ListPartnerUsersDocument, options);
}
export type ListPartnerUsersQueryHookResult = ReturnType<
  typeof useListPartnerUsersQuery
>;
export type ListPartnerUsersLazyQueryHookResult = ReturnType<
  typeof useListPartnerUsersLazyQuery
>;
export type ListPartnerUsersQueryResult = ApolloReactCommon.QueryResult<
  IListPartnerUsersQuery,
  IListPartnerUsersQueryVariables
>;
export const ListProvidersDocument = gql`
  query ListProviders {
    ListProviders {
      ...ProviderFragment
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useListProvidersQuery__
 *
 * To run a query within a React component, call `useListProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >(ListProvidersDocument, options);
}
export function useListProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >(ListProvidersDocument, options);
}
export type ListProvidersQueryHookResult = ReturnType<
  typeof useListProvidersQuery
>;
export type ListProvidersLazyQueryHookResult = ReturnType<
  typeof useListProvidersLazyQuery
>;
export type ListProvidersQueryResult = ApolloReactCommon.QueryResult<
  IListProvidersQuery,
  IListProvidersQueryVariables
>;
export const ListWebhookEventsDocument = gql`
  query ListWebhookEvents(
    $limit: Int
    $subjectType: String
    $delivered: Boolean
    $notDelivered: Boolean
    $eventType: [String!]
    $recordApiId: ID
    $sortBy: String
    $sortDirection: String
    $offset: Int
  ) {
    events: ListWebhookEvents(
      limit: $limit
      subjectType: $subjectType
      delivered: $delivered
      notDelivered: $notDelivered
      eventType: $eventType
      recordApiId: $recordApiId
      sortBy: $sortBy
      sortDirection: $sortDirection
      offset: $offset
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      nodes {
        ...WebhookEventFragment
      }
    }
  }
  ${WebhookEventFragmentDoc}
`;

/**
 * __useListWebhookEventsQuery__
 *
 * To run a query within a React component, call `useListWebhookEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhookEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhookEventsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      subjectType: // value for 'subjectType'
 *      delivered: // value for 'delivered'
 *      notDelivered: // value for 'notDelivered'
 *      eventType: // value for 'eventType'
 *      recordApiId: // value for 'recordApiId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListWebhookEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListWebhookEventsQuery,
    IListWebhookEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IListWebhookEventsQuery,
    IListWebhookEventsQueryVariables
  >(ListWebhookEventsDocument, options);
}
export function useListWebhookEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListWebhookEventsQuery,
    IListWebhookEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IListWebhookEventsQuery,
    IListWebhookEventsQueryVariables
  >(ListWebhookEventsDocument, options);
}
export type ListWebhookEventsQueryHookResult = ReturnType<
  typeof useListWebhookEventsQuery
>;
export type ListWebhookEventsLazyQueryHookResult = ReturnType<
  typeof useListWebhookEventsLazyQuery
>;
export type ListWebhookEventsQueryResult = ApolloReactCommon.QueryResult<
  IListWebhookEventsQuery,
  IListWebhookEventsQueryVariables
>;
export const SandboxListNegotiationsForBillDocument = gql`
  query SandboxListNegotiationsForBill($billId: ID!) {
    negotiations: SandboxListNegotiationsForBill(billId: $billId) {
      nodes {
        ...NegotiationFragment
      }
    }
  }
  ${NegotiationFragmentDoc}
`;

/**
 * __useSandboxListNegotiationsForBillQuery__
 *
 * To run a query within a React component, call `useSandboxListNegotiationsForBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useSandboxListNegotiationsForBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSandboxListNegotiationsForBillQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useSandboxListNegotiationsForBillQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ISandboxListNegotiationsForBillQuery,
    ISandboxListNegotiationsForBillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ISandboxListNegotiationsForBillQuery,
    ISandboxListNegotiationsForBillQueryVariables
  >(SandboxListNegotiationsForBillDocument, options);
}
export function useSandboxListNegotiationsForBillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ISandboxListNegotiationsForBillQuery,
    ISandboxListNegotiationsForBillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ISandboxListNegotiationsForBillQuery,
    ISandboxListNegotiationsForBillQueryVariables
  >(SandboxListNegotiationsForBillDocument, options);
}
export type SandboxListNegotiationsForBillQueryHookResult = ReturnType<
  typeof useSandboxListNegotiationsForBillQuery
>;
export type SandboxListNegotiationsForBillLazyQueryHookResult = ReturnType<
  typeof useSandboxListNegotiationsForBillLazyQuery
>;
export type SandboxListNegotiationsForBillQueryResult =
  ApolloReactCommon.QueryResult<
    ISandboxListNegotiationsForBillQuery,
    ISandboxListNegotiationsForBillQueryVariables
  >;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
};
export default result;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import listOffersResponseJsonFile from "src/examples/responses/list_offers.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListOffers({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("list-offers");
      if (hash.includes("list-offers")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/offers#list-offers">
        <h3 className="mb-8 text-xl font-medium">List offers</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              field={"limit"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>
                    The number of offers you'd like returned by this request.
                  </div>
                </React.Fragment>
              }
              defaultValue={"25"}
            />
            <Parameter
              field={"offset"}
              type={"integer"}
              description={
                <React.Fragment>
                  <div>The number of offers to skip.</div>
                </React.Fragment>
              }
              defaultValue={"0"}
            />
            <Parameter
              field={"customer_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include offers
                    associated with the given customer ID.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include offers
                    associated with the given bill ID.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"status"}
              type={"string"}
              possibleValues={["pending", "accepted", "rejected"]}
              description={
                <React.Fragment>
                  <div>
                    If provided, the response will only include offers with the
                    given status.
                  </div>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The total number of offers that matched the given parameters and
              an array of those offers.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, listOffersExampleRubyBody),
              js: javascriptExample(testApiKey!, listOffersExampleJsBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(listOffersResponseJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const listOffersExampleRubyBody = `
offers = client.list_offers(
  limit: 25,
  offset: 0,
  customer_id: "customer_12fasfa3187asd",
  status: "pending"
)
`;
const listOffersExampleJsBody = `
const { totalCount, customers } = await client.listOffers(
  {
    limit: 25,
    offset: 0,
    customerId: "customer_12fasfa3187asd",
    status: "pending"
  }
);
`;

import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import DateAtTime from "src/components/DateAtTime";
import Detail from "src/components/Detail";
import {
  IInformationRequestFieldFragment,
  IInformationRequestFragment
} from "src/components/graphql";

interface IInfoReqsProps {
  infoReqs: IInformationRequestFragment[];
}

const InfoReqs: React.FC<IInfoReqsProps> = ({ infoReqs }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {!!infoReqs.length ? (
          <React.Fragment>
            {infoReqs.map((inf) => (
              <li key={`${inf.id}`}>
                <div className="flex items-start px-4 py-4">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium truncate">
                          {/* {startCase(inf.)} */}
                          Info Request
                        </p>
                        <p className="mt-1 flex items-center text-xs text-gray-500">
                          {/* <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                          <span className="truncate">{inf.id}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <Detail
                            heading="Requested on"
                            body={
                              <React.Fragment>
                                <DateAtTime onlyDate input={inf.createdAt} />
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Detail
                      heading="Responded on"
                      body={
                        inf.respondedAt ? (
                          <DateAtTime onlyDate input={inf.createdAt} />
                        ) : (
                          "No response"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="px-4 py-4">
                  <InfoReqFields infoReq={inf} fields={inf.fields} />
                </div>
              </li>
            ))}
          </React.Fragment>
        ) : (
          <li>
            <div className="flex items-center justify-center px-4 py-4">
              <p className="text-sm text-gray-500">
                This bill doesn't have any info requests.
              </p>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

interface InfoReqFieldsProps {
  infoReq: IInformationRequestFragment;
  fields: IInformationRequestFieldFragment[];
}

const InfoReqFields: React.FC<InfoReqFieldsProps> = ({ infoReq, fields }) => {
  // const [open, setOpen] = useState(false);

  const requestContent = () => {
    return { __html: infoReq.contentHtml };
  };

  return (
    <Disclosure>
      {({ open }) => (
        <React.Fragment>
          <div className="flex items-center justify-center">
            <Disclosure.Button className="text-xs text-gray-500 font-semibold tracking-wider cursor-pointer uppercase mb-3">
              {open ? "Hide request details" : "Show request details"}
            </Disclosure.Button>
          </div>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              <div>
                <div
                  className="text-sm text-gray-700 mb-4"
                  dangerouslySetInnerHTML={requestContent()}
                />
                {/* <div className="text-sm font-semibold text-gray-700">
                  Field inputs
                </div> */}
                {fields.map((f) => (
                  <div key={f.id} className="my-2">
                    <label
                      htmlFor={`${f.label}_${f.id}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {f.label}
                    </label>
                    <div className="mt-1">
                      {f.value ? (
                        <input
                          type="text"
                          name={f.label}
                          id={`${f.label}_${f.id}`}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100 p-2"
                          placeholder={f.placeholder}
                          value={f.value}
                          readOnly
                        />
                      ) : (
                        <input
                          type="text"
                          name={f.label}
                          id={`${f.label}_${f.id}`}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100 p-2"
                          placeholder={f.placeholder}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </Transition>
        </React.Fragment>
      )}
    </Disclosure>
  );
};

export default InfoReqs;

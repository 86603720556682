import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import updateCancellationObjectJsonFile from "src/examples/responses/update_cancellation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function UpdateCancellation({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("update-cancellation");
      if (hash.includes("update-cancellation")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/cancellations#update-cancellation">
        <h3 className="mb-8 text-xl font-medium">Update a cancellation</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              required
              field={"cancellation_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    Alpha-numeric unique identifier for the cancellation.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"payment_amount"}
              type={"Money"}
              defaultValue={"$0.00"}
              description={
                "The amount of money that the customer typically pays for their service."
              }
            />
            <Parameter
              field={"last4_of_payment_method"}
              defaultValue="null"
              type={"String"}
              description={
                "The last 4 digits of the payment method that the customer uses to pay for their service."
              }
            />
            <Parameter
              defaultValue="null"
              field={"date_of_last_transaction"}
              type={"String"}
              description={
                "The date of the last transaction that the customer made for their service."
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The cancellation object that was updated if successful.</p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(testApiKey!, updateCancellationExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              updateCancellationObjectJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const updateCancellationExampleCurlBody = `
$'{
  "query": "mutation UpdateCancellation($cancellationId:ID!,$cancellation:CancellationAttributes){UpdateCancellation(input:{cancellationId:$cancellationId,cancellation:$cancellation}){success errors cancellation{id}}}"
  "variables": {
		"cancellationId": "cancellation_8e993f69bbcaaaea69f7cdc1a7eb8222",
		"cancellation": {
      "paymentAmount": "19.99",
    }
	}
`;

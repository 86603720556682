import { Stack } from "@cohubinc/blueprint";
import React, { Fragment, ReactNode } from "react";
import Divider from "../Divider";
// import styled from "styled-components";
import Typography from "../Typography";

// const StyledAvailableFields = styled.div`
//   padding: 1rem;
//   background-color: hsla(212, 25%, 20%, 1);
//   border-radius: 10px;
//   width: 100%;
// `;

interface IAvailableFieldsProps {
  children: ReactNode[];
  objectName?: string;
}

export default function AvailableFields({
  children,
  objectName
}: IAvailableFieldsProps) {
  const dividedChildren = children.map((c, idx) => {
    if (children.length === idx + 1) {
      return <Fragment key={idx}>{c}</Fragment>;
    } else {
      return (
        <Fragment key={idx}>
          {c}
          <Divider marginSize={0.5} />
        </Fragment>
      );
    }
  });

  return (
    <Fragment>
      {objectName && (
        <Typography.HeadingSmall>
          The {objectName} object
        </Typography.HeadingSmall>
      )}
      <Divider marginSize={1} />
      <Stack>{dividedChildren}</Stack>
    </Fragment>
  );
}

import { useEffect } from "react";
import AltLoader from "src/components/AltLoader";
import { useFindCurrentPartnerQuery } from "src/components/graphql";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import Statistic from "src/components/Statistic";
// import Stats from "./Stats";
import useMeta from "src/hooks/useMeta";

export default function Dashboard() {
  const { setPageTitle } = useMeta();
  // const { loading, data } = useListBillsQuery();
  const { loading, data } = useFindCurrentPartnerQuery();

  useEffect(() => {
    setPageTitle("");
  }, [setPageTitle]);

  if (loading && !data) {
    return <AltLoader />;
  }

  const currentPartner = data?.currentPartner;

  return (
    <NewContainer>
      <div className="grid grid-cols-4 gap-4">
        <Segment className="p-2">
          <Statistic
            value={currentPartner?.billCount}
            metric="Total bills"
            meta=""
          />
        </Segment>
        <Segment className="p-2">
          <Statistic
            value={currentPartner?.readyBillCount}
            metric="Negotiable bills"
            // meta="Bills that contain all necessary information, like a document or documentless fields, to be negotiating."
          />
        </Segment>
        <Segment className="p-2">
          <Statistic
            value={currentPartner?.averageSavingsPerCustomer}
            metric="Savings per customer"
            valueType="money"
            // meta="Bills that contain all necessary information, like a document or documentless fields, to be negotiating."
          />
        </Segment>
        <Segment className="p-2">
          <Statistic
            value={currentPartner?.totalSavings}
            metric="All time savings"
            valueType="money"
            // meta="Bills that contain all necessary information, like a document or documentless fields, to be negotiating."
          />
        </Segment>
      </div>
    </NewContainer>
  );
}

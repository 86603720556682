import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import provideCancellationInfoResponseJson from "src/examples/responses/provide_cancellation_info.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ProvideCancellationInfo({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "provide-cancellation-info"
      );
      if (hash.includes("provide-cancellation-info")) {
        console.log("here");

        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/cancellations#provide-cancellation-info">
        <h3 className="mb-4 text-xl font-medium">Provide cancellation info</h3>
      </a>
      <p className="mb-8">
        In order to cancel service with a provider, we need certain information
        that varies per provider.
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>

          <AvailableFields>
            <Parameter
              required
              field={"cancellation_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    Alpha-numeric unique identifier for the cancellation.
                  </div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"cancellation_info"}
              type={"JSON"}
              required
              description={
                <React.Fragment>
                  <p>
                    A JSON object containing the keys and values for the fields
                    the cancellation needs based on its provider.
                  </p>
                  <p className="mt-4">
                    These fields are most often things like PIN, Service
                    Address, Account Holder Name, or Phone Number, but they vary
                    by provider.
                  </p>
                  <p className="mt-4">
                    In order to know what fields to send, you can either use the{" "}
                    <CodeSnippet inline bare code="findProvider" /> query and
                    check the{" "}
                    <CodeSnippet
                      bare
                      inline
                      code="provider_cancellation_fields"
                    />
                    , or use the{" "}
                    <CodeSnippet bare inline code="findCancellation" /> query
                    and check its{" "}
                    <CodeSnippet bare inline code="cancellation_info" />.
                  </p>
                  <p className="mt-4">
                    The keys you send in your request should correspond with the{" "}
                    <CodeSnippet bare inline code="name" /> of a field in the{" "}
                    <CodeSnippet bare inline code="cancellation_info" /> JSON
                    array.
                  </p>
                  <p className="mt-4">
                    Some keys are required, and if they're not sent in the
                    request, an error will be returned explaining which keys
                    must be sent in the request.
                  </p>
                  <p className="mt-4">
                    If a response has already been recorded for a particular
                    field, that field's{" "}
                    <CodeSnippet bare inline code="recorded_response" /> key
                    will contain the response. It will otherwise be null.
                  </p>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An object containing a boolean success field indicating if the
              documentless info was successfully recorded, an errors array
              containing errors (if any), and the cancellation object.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(
                testApiKey!,
                provideCancellationInfoExampleCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              provideCancellationInfoResponseJson,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const provideCancellationInfoExampleCurlBody = `
$'{
  "query": "mutation ProvideCancellationInfo($cancellationId:ID!,$cancellationInfo:JSON!){ProvideCancellationInfo(input:{cancellationId:$cancellationId,cancellationInfo:$cancellationInfo}){success errors cancellation{id cancellationInfo}}}",
  "variables": {
  "cancellationId": "cancellation_d8fb9e40650351069573001a8184845c",
  "cancellationInfo": {
    "subscriberEmail": "email@example.com"
}
`;

// import useInterval from "@use-it/interval";
import { useCallback, useEffect } from "react";
import { protocol, host } from "src/config";
import useSession from "./useSession";
export default function useSessionCheck() {
  const { logOut } = useSession();

  const checkToken = useCallback(async () => {
    // If test mode is turned on, just return null
    if (localStorage.getItem("testMode") === "true") {
      console.log("testMode on");

      return null;
    }

    const url = `${protocol}://${host}/api/partner_user_sessions/check_partner_token`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Vary: "Origin,Access-Control-Request-Headers,Access-Control-Request-Method"
      },
      body: JSON.stringify({
        email: localStorage.getItem("email"),
        browser_token: localStorage.getItem("token")
      })
    });

    const result = await response.json();

    if (!result.valid) {
      logOut();
    }
  }, [logOut]);

  useEffect(() => {
    const tokenCheckInterval = setInterval(checkToken, 30_000);
    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [checkToken]);
}

import { ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";

export type TCrumb = {
  path: string;
  text: string;
  active?: boolean;
};

interface IProps {
  crumbs: TCrumb[];
}

export default function Breadcrumbs({ crumbs }: IProps) {
  return (
    <div className="flex items-center my-4 space-x-2">
      {crumbs.map((crumb, idx) => (
        <React.Fragment>
          <Breadcrumb
            path={crumb.path}
            text={crumb.text}
            active={crumb.active}
          />
          {idx !== crumbs.length - 1 && (
            <ChevronRightIcon className="w-4 h-4 text-gray-400" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function Breadcrumb({ path, text, active = false }: TCrumb) {
  return (
    <div className="text-sm">
      <Link
        to={path}
        className={active ? "text-bf-green-600" : "text-gray-500"}
      >
        {text}
      </Link>
    </div>
  );
}

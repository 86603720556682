import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "src/assets/bf-logo-white.png";
import Heroicon from "src/components/Heroicon";
import useSession from "src/hooks/useSession";
import paths from "src/paths";

function getLinkClassNames(path: string, currentPath: string) {
  const inactiveLinkClassNames =
    "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md";

  const activeLinkClassNames =
    "bg-gray-900 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md";

  if (path === "/" && currentPath === "/") {
    return activeLinkClassNames;
  }

  const parts = currentPath.split("/");
  if (parts[parts.length - 1].includes("_")) {
    parts.pop();
  }

  if (path === parts.join("/")) {
    return activeLinkClassNames;
  }

  return inactiveLinkClassNames;
}

const MobileSidebar: React.FC = () => {
  const { logOut, email } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const { pathname } = useLocation();
  const [apiDocsIsOpen, setApiDocsIsOpen] = useState(
    pathname.includes("api-docs")
  );
  return (
    <div className="md:hidden">
      <div className={`fixed inset-0 flex flex-1 ${isOpen ? "z-40" : ""}`}>
        {/*
            Off-canvas menu overlay, show/hide based on off-canvas menu state.

            Entering: "transition-opacity ease-linear duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "transition-opacity ease-linear duration-300"
              From: "opacity-100"
              To: "opacity-0"
          */}
        <Transition
          show={isOpen}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" onClick={() => setIsOpen(false)}>
            <div className="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
        </Transition>
        {/*
            Off-canvas menu, show/hide based on off-canvas menu state.

            Entering: "transition ease-in-out duration-300 transform"
              From: "-translate-x-full"
              To: "translate-x-0"
            Leaving: "transition ease-in-out duration-300 transform"
              From: "translate-x-0"
              To: "-translate-x-full"
          */}
        <Transition
          show={isOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex h-full w-full max-w-xs flex-1 flex-col bg-gray-800">
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setIsOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                {/* Heroicon name: x */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Billfixers" />
              </div>
              <nav className="mt-5 space-y-1 px-2">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                {/* <Link
                  to={paths.dashboard}
                  className={getLinkClassNames(paths.dashboard, pathname)}
                >
                  <Heroicon.Home className="text-gray-300 mr-4 h-6 w-6" />
                  Dashboard
                </Link> */}

                <div className="space-y-1">
                  <button
                    className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setApiDocsIsOpen(!apiDocsIsOpen)}
                  >
                    {/* Heroicon name: chip */}
                    <svg
                      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      viewBox="0 0 256 288"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMidYMid"
                    >
                      <path
                        d="M152.576 32.963l59.146 34.15a25.819 25.819 0 0 1 5.818-4.604c12.266-7.052 27.912-2.865 35.037 9.402 7.052 12.267 2.865 27.912-9.402 35.037a25.698 25.698 0 0 1-6.831 2.72v68.325a25.7 25.7 0 0 1 6.758 2.702c12.34 7.125 16.527 22.771 9.402 35.038-7.052 12.266-22.771 16.453-35.038 9.402a25.464 25.464 0 0 1-6.34-5.147l-58.786 33.94a25.671 25.671 0 0 1 1.295 8.08c0 14.103-11.458 25.636-25.635 25.636-14.177 0-25.635-11.46-25.635-25.636 0-2.52.362-4.954 1.037-7.253l-59.13-34.14a25.824 25.824 0 0 1-5.738 4.52c-12.34 7.051-27.986 2.864-35.038-9.402-7.051-12.267-2.864-27.913 9.402-35.038a25.71 25.71 0 0 1 6.758-2.703v-68.324a25.698 25.698 0 0 1-6.831-2.72C.558 99.897-3.629 84.178 3.423 71.911c7.052-12.267 22.77-16.454 35.037-9.402a25.82 25.82 0 0 1 5.79 4.575l59.163-34.159a25.707 25.707 0 0 1-1.048-7.29C102.365 11.46 113.823 0 128 0c14.177 0 25.635 11.459 25.635 25.635 0 2.548-.37 5.007-1.059 7.328zm-6.162 10.522l59.287 34.23a25.599 25.599 0 0 0 2.437 19.831c3.609 6.278 9.488 10.44 16.013 12.062v68.41c-.333.081-.664.17-.993.264L145.725 44.17c.234-.224.464-.452.689-.684zm-36.123.7l-77.432 134.11a25.824 25.824 0 0 0-1.01-.27v-68.417c6.525-1.622 12.404-5.784 16.013-12.062a25.6 25.6 0 0 0 2.427-19.869l59.27-34.22c.239.247.483.49.732.727zm24.872 6.075l77.414 134.08a25.492 25.492 0 0 0-4.513 5.757 25.7 25.7 0 0 0-2.702 6.758H50.64a25.71 25.71 0 0 0-2.704-6.758 25.825 25.825 0 0 0-4.506-5.724l77.429-134.107A25.715 25.715 0 0 0 128 51.27c2.487 0 4.89-.352 7.163-1.01zm11.795 194.478l58.902-34.008a25.865 25.865 0 0 1-.473-1.682H50.607c-.082.333-.171.663-.266.992l59.19 34.175A25.558 25.558 0 0 1 128 236.373a25.564 25.564 0 0 1 18.958 8.365z"
                        fill="rgba(159, 166, 178, var(--tw-text-opacity))"
                        fillRule="evenodd"
                      />
                    </svg>
                    API Reference
                    {/* Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" */}
                    <svg
                      className={`ml-auto h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-300 ${
                        apiDocsIsOpen ? "rotate-90 text-gray-400" : ""
                      }`}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </button>

                  {/* Expandable link section, show/hide based on state. */}
                  <div
                    className={`space-y-1 ${
                      apiDocsIsOpen ? "block" : "hidden"
                    }`}
                  >
                    <Link
                      to={paths.apiDocs.home}
                      className={`${getLinkClassNames(
                        paths.apiDocs.home,
                        pathname
                      )} pl-11`}
                    >
                      Getting Started
                    </Link>
                    <Link
                      to={paths.apiDocs.bills}
                      className={`${getLinkClassNames(
                        paths.apiDocs.bills,
                        pathname
                      )} pl-11`}
                    >
                      Bills
                    </Link>
                    {/* <Link
                      to={paths.apiDocs.billItems}
                      className={`${getLinkClassNames(
                        paths.apiDocs.billItems,
                        pathname
                      )} pl-11`}
                    >
                      Bill Items
                    </Link> */}
                    <Link
                      to={paths.apiDocs.customers}
                      className={`${getLinkClassNames(
                        paths.apiDocs.customers,
                        pathname
                      )} pl-11`}
                    >
                      Customers
                    </Link>
                    <Link
                      to={paths.apiDocs.providers}
                      className={`${getLinkClassNames(
                        paths.apiDocs.providers,
                        pathname
                      )} pl-11`}
                    >
                      Providers
                    </Link>
                    <Link
                      to={paths.apiDocs.offers}
                      className={`${getLinkClassNames(
                        paths.apiDocs.offers,
                        pathname
                      )} pl-11`}
                    >
                      Offers
                    </Link>
                    <Link
                      to={paths.apiDocs.infoReqs}
                      className={`${getLinkClassNames(
                        paths.apiDocs.infoReqs,
                        pathname
                      )} pl-11`}
                    >
                      Information Requests
                    </Link>
                  </div>
                </div>

                <div className="space-y-1">
                  <button
                    className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setIsLibraryOpen(!isLibraryOpen)}
                  >
                    <Heroicon.Code className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300" />
                    Client Libraries
                    {/* Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" */}
                    <svg
                      className={`ml-auto h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-300 ${
                        isLibraryOpen ? "rotate-90 text-gray-400" : ""
                      }`}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </button>

                  {/* Expandable link section, show/hide based on state. */}
                  <div
                    className={`space-y-1 ${
                      isLibraryOpen ? "block" : "hidden"
                    }`}
                  >
                    <Link
                      to={paths.libraries.javascript}
                      className={`${getLinkClassNames(
                        paths.libraries.javascript,
                        pathname
                      )} pl-11`}
                    >
                      Javascript
                    </Link>
                    <Link
                      to={paths.libraries.ruby}
                      className={`${getLinkClassNames(
                        paths.libraries.ruby,
                        pathname
                      )} pl-11`}
                    >
                      Ruby
                    </Link>
                  </div>
                </div>

                <Link
                  to={paths.webhooks}
                  className={getLinkClassNames(paths.webhooks, pathname)}
                >
                  <svg
                    className="mr-3 h-6 w-6 text-gray-300"
                    viewBox="0 0 256 239"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      d="M119.54 100.503c-10.61 17.836-20.775 35.108-31.152 52.25-2.665 4.401-3.984 7.986-1.855 13.58 5.878 15.454-2.414 30.493-17.998 34.575-14.697 3.851-29.016-5.808-31.932-21.543-2.584-13.927 8.224-27.58 23.58-29.757 1.286-.184 2.6-.205 4.762-.367l23.358-39.168C73.612 95.465 64.868 78.39 66.803 57.23c1.368-14.957 7.25-27.883 18-38.477 20.59-20.288 52.002-23.573 76.246-8.001 23.284 14.958 33.948 44.094 24.858 69.031-6.854-1.858-13.756-3.732-21.343-5.79 2.854-13.865.743-26.315-8.608-36.981-6.178-7.042-14.106-10.733-23.12-12.093-18.072-2.73-35.815 8.88-41.08 26.618-5.976 20.13 3.069 36.575 27.784 48.967z"
                      fill="hsla(164, 74%, 52%, 1)"
                    />
                    <path
                      d="M149.841 79.41c7.475 13.187 15.065 26.573 22.587 39.836 38.02-11.763 66.686 9.284 76.97 31.817 12.422 27.219 3.93 59.457-20.465 76.25-25.04 17.238-56.707 14.293-78.892-7.851 5.654-4.733 11.336-9.487 17.407-14.566 21.912 14.192 41.077 13.524 55.305-3.282 12.133-14.337 11.87-35.714-.615-49.75-14.408-16.197-33.707-16.691-57.035-1.143-9.677-17.168-19.522-34.199-28.893-51.491-3.16-5.828-6.648-9.21-13.77-10.443-11.893-2.062-19.571-12.275-20.032-23.717-.453-11.316 6.214-21.545 16.634-25.53 10.322-3.949 22.435-.762 29.378 8.014 5.674 7.17 7.477 15.24 4.491 24.083-.83 2.466-1.905 4.852-3.07 7.774z"
                      fill="rgba(159, 166, 178, var(--tw-text-opacity))"
                    />
                    <path
                      d="M167.707 187.21h-45.77c-4.387 18.044-13.863 32.612-30.19 41.876-12.693 7.2-26.373 9.641-40.933 7.29-26.808-4.323-48.728-28.456-50.658-55.63-2.184-30.784 18.975-58.147 47.178-64.293 1.947 7.071 3.915 14.21 5.862 21.264-25.876 13.202-34.832 29.836-27.59 50.636 6.375 18.304 24.484 28.337 44.147 24.457 20.08-3.962 30.204-20.65 28.968-47.432 19.036 0 38.088-.197 57.126.097 7.434.117 13.173-.654 18.773-7.208 9.22-10.784 26.191-9.811 36.121.374 10.148 10.409 9.662 27.157-1.077 37.127-10.361 9.62-26.73 9.106-36.424-1.26-1.992-2.136-3.562-4.673-5.533-7.298z"
                      fill="rgba(159, 166, 178, var(--tw-text-opacity))"
                    />
                  </svg>
                  Webhooks
                </Link>
              </nav>
            </div>
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <div className="flex items-center">
                <div className="ml-3">
                  <p className="text-base font-medium text-white">
                    <Link
                      to={paths.profile}
                      className="group block flex-shrink-0"
                    >
                      {email}
                    </Link>
                  </p>
                  <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                    <button
                      className="rounded-full focus:outline-none"
                      onClick={logOut}
                    >
                      Log out
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div
          className="w-14 flex-shrink-0 cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;

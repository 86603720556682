import React, { CSSProperties, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";
import copyToClipboard from "src/utils/copyToClipboard";
import styled from "styled-components";
import Button from "../Button";

const BareSnippet = styled.span<Partial<ICodeSnippetProps>>`
  border-radius: 4px;
  padding: ${(props) => (props.inline ? "0.15rem" : "1rem")};
  /* background-color: #f0f5f9; */
  display: ${(props) => (props.inline ? "inline-block" : "block")};
`;

type TInsetCopyProps = {
  translate?: number;
  showBackground?: boolean;
};

interface ICodeSnippetProps {
  code: string;
  bare?: boolean;
  inline?: boolean;
  language?: string;
  style?: CSSProperties;
  customStyle?: CSSProperties;
  className?: string;
  copy?: boolean;
  showInsetCopy?: boolean;
  insetCopy?: TInsetCopyProps;
}

export default function CodeSnippet({
  code,
  bare = false,
  inline = false,
  language,
  style,
  customStyle,
  className,
  copy,
  showInsetCopy = false,
  insetCopy = { showBackground: true, translate: 98 }
}: ICodeSnippetProps) {
  const [copiedSnippet, setCopiedSnippet] = useState(false);

  useEffect(() => {
    if (copiedSnippet) {
      setTimeout(() => {
        setCopiedSnippet(false);
      }, 3_000);
    }
  }, [copiedSnippet]);

  function handleCopyToClipBoard() {
    setCopiedSnippet(true);
    copyToClipboard(code);
  }

  const copyButton = (
    <Button
      type="button"
      version="plain"
      size="sm"
      onClick={() => handleCopyToClipBoard()}
      className="mt-2"
    >
      {copiedSnippet ? "Copied!" : "Copy"}
    </Button>
  );

  const insetCopyButton = (
    <button
      type="button"
      className="rounded-md px-3 py-2 text-xs uppercase leading-4 tracking-widest text-white focus:outline-none"
      onClick={() => handleCopyToClipBoard()}
    >
      {copiedSnippet ? "Copied!" : "Copy"}
    </button>
  );

  if (bare) {
    return (
      <BareSnippet
        inline={inline}
        style={style}
        className={`mt-1 bg-gray-200 text-sm ${
          inline ? "" : "w-full"
        } ${className}`}
      >
        <code className="text-gray-800">{code}</code>
      </BareSnippet>
    );
  } else {
    return (
      <div className={`relative w-full text-sm ${className}`}>
        <SyntaxHighlighter
          language={language || "js"}
          style={xonokai}
          codeTagProps={{
            style: {
              fontSize: "12px"
            }
          }}
          customStyle={
            customStyle || {
              width: "inherit",
              border: "none",
              borderRadius: "0.375rem",
              background: "hsla(212, 25%, 20%, 1)",
              fontSize: "12px !important"
            }
          }
        >
          {code}
        </SyntaxHighlighter>
        {showInsetCopy && (
          <div
            className={`absolute flex justify-end text-white ${
              insetCopy.showBackground ? "bg-gray-900" : ""
            } mr-2 rounded-md`}
            style={{
              top: `${insetCopy.translate}%`,
              right: "0",
              transform: `translate(0%, -${insetCopy.translate}%)`
            }}
          >
            {insetCopyButton}
          </div>
        )}
        {copy && <div className="flex justify-end">{copyButton}</div>}
      </div>
    );
  }
}

import { Field, Form } from "react-final-form";
import Divider from "src/components/Divider";
import Errors from "src/components/Errors";
import NewButton from "src/components/NewButton";

interface IEditPartnerUserFormProps {
  onSubmit: (values: any) => void;
  initialValues: any;
  errors: string[];
}

const roleOptions = [
  { value: "viewer", label: "Viewer" },
  { value: "manager", label: "Manager" }
];

export default function EditPartnerUserForm({
  onSubmit,
  initialValues,
  errors
}: IEditPartnerUserFormProps) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit} className="space-y-4">
            {errors.length > 0 && <Errors errors={errors} className="mb-8" />}
            <Field
              name="email"
              render={({ input }) => (
                <div className="text-left">
                  <label htmlFor="email">Email</label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      {...input}
                      id="email"
                      required
                      placeholder="jane.doe@email.com"
                      autoComplete="off"
                      className="block w-full min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Changing an email will invalidate any active session a user
                    has and automatically log them out.
                  </p>
                </div>
              )}
            />
            <Field
              name="role"
              render={({ input }) => (
                <div className="wrap text-left">
                  <label htmlFor="role">Role</label>
                  <select
                    id={"role"}
                    {...input}
                    autoComplete="off"
                    className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    {roleOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <p className="mt-2 text-sm text-gray-500">
                    Managers can add, update, and delete webhooks and manage
                    other users. Viewers cannot manage or view other users and
                    cannot make changes to webhooks.
                  </p>
                </div>
              )}
            />
            {/* <Typography.Small block muted className="mb-8">
              Leave these empty unless you want to set the password
            </Typography.Small>
            <PasswordInputField name="password" label="Password" />
            <PasswordInputField
              name="passwordConfirmation"
              label="Password Confirmation"
            /> */}
            <Divider marginSize={1} />
            <NewButton
              type="submit"
              size="sm"
              fullWidth
              loading={submitting}
              disabled={pristine || submitting}
            >
              Save
            </NewButton>
          </form>
        );
      }}
    />
  );
}

import React from "react";
import Breadcrumbs from "src/components/Breadcrumbs";
import NewContainer from "src/components/NewContainer";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";

export default function InterpretingWebhooks() {
  usePageTitle("How to Interpret Webhooks");

  return (
    <NewContainer className="mt-8">
      <Breadcrumbs
        crumbs={[
          {
            path: "/api-docs/implementation",
            text: "Implementation Guide",
            active: false
          },
          {
            path: paths.apiDocs.implementation.interpretingWebhooks,
            text: "How to Interpret Webhooks",
            active: true
          }
        ]}
      />
      <div className="mt-8">
        <div className="mb-8">
          {/* <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="creating-bills"
          >
            How to interpret webhooks
          </h3> */}
          <p className="mb-4">
            Getting a webhook from us means that there is new data or
            information available about a bill or the negotiation process, and
            you should update your own data and application UI accordingly. You
            may also want to notify your customer of the updated information.
          </p>
          <p className="mb-4">
            A few of our webhooks will require direct action on the part of the
            customer in order to continue negotiating a bill. We'll break the
            webhooks into two groups: Informational and Action Required
          </p>
        </div>
        <div className="mb-8">
          <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="the-customer-is-always-first"
          >
            Informational webhooks
          </h3>
          <p className="mb-4">
            Many of our webhooks simply alert you that a change has happened
            regarding a bill or customer, like a negotiation has been completed
            or a customer has been updated. These webhooks don't require you to
            ask the customer for more information or get a direct response from
            them, but they are useful (and often critical) to convey what's
            going on with a negotiation.
          </p>
          <p className="mb-4">
            Here's a list of informational webhooks and what you could do with
            them:
          </p>
          <WebhookTable hooks={infoHooks} />
        </div>
        <div className="mb-8">
          <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="the-customer-is-always-first"
          >
            Action required webhooks
          </h3>
          <p className="mb-4">
            Our other webhooks require some kind of action on your behalf or the
            customer's. Generally, these webhooks indicate that information
            about a bill or a customer is missing and we need it to continue the
            negotiation process.
          </p>
          <p className="mb-4">
            Here's a list of action required webhooks and what you should do
            with them:
          </p>
          <WebhookTable hooks={actionHooks} />
        </div>
      </div>
    </NewContainer>
  );
}

type THook = {
  event: string;
  action: string;
};

const infoHooks = [
  {
    event: "bill.negotiation_started",
    action: "Let the customer know that a negotiation has begun."
  },
  {
    event: "bill.negotiation_completed",
    action:
      "Let the customer know that a negotiation has been completed and their savings have been locked in."
  },
  {
    event: "bill.negotiation_failed",
    action:
      "Let the customer know that a negotiation has failed and we were not able to achieve any savings for them."
  },
  {
    event: "bill.savings_updated",
    action:
      "Let the customer know that a change to their original savings has happened."
  },
  {
    event: "bill.autofix_upcoming",
    action:
      "Let the customer know that their bill is due to be renegotiated soon and that they should expect new savings."
  },
  {
    event: "bill.outside_of_scope",
    action:
      "Let the customer know that the bill they submitted isn't eligible for negotiation."
  },
  {
    event: "customer.updated",
    action: "Honestly, probably nothing."
  },
  {
    event: "offer.accepted",
    action: ""
  },
  {
    event: "offer.rejected",
    action: ""
  }
];

const actionHooks = [
  {
    event: "bill.bad_document",
    action:
      "Let the customer know that the document they provided is missing important information that we need for our negotiation."
  },
  {
    event: "information_request.created",
    action:
      "Look up the info request using the ID from the webhook and let the customer know what information they need to provide in order to continue the negotiation."
  },
  {
    event: "offer.created",
    action:
      "Look up the offer using the ID from the webhook and let the customer know that there's a new offer awaiting their response."
  }
];

interface IWebhookTableProps {
  hooks: THook[];
}

function WebhookTable({ hooks }: IWebhookTableProps) {
  return (
    /* This example requires Tailwind CSS v2.0+ */
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Webhook/Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {hooks.map((hook, idx) => (
                  <tr className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {hook.event}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {hook.action}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { createContext, ReactNode, useEffect, useReducer } from "react";

type TLanguage = "bash" | "ruby" | "javascript" | "python";

export interface IPreferredLanguageState {
  preferredLanuage: TLanguage;
  setPreferredLanguage: (language: TLanguage) => void;
}

interface IPreferredLanguageContext {
  preferredLanguage: TLanguage;
  dispatch: React.Dispatch<any>;
}

interface IProps {
  children: ReactNode;
}

const preferredLanguageReducer = (_: any, preferredLanguage: TLanguage) => {
  switch (preferredLanguage) {
    case "bash":
      return "bash";
    case "ruby":
      return "ruby";
    case "javascript":
      return "javascript";
    case "python":
      return "python";
    default:
      return "ruby";
  }
};

const PreferredLanguageContext: React.Context<IPreferredLanguageContext> = createContext(
  {} as IPreferredLanguageContext
);

const initialState =
  (localStorage.getItem("preferredLanguage") as TLanguage) || "ruby";

function PreferredLanguageProvider({ children }: IProps) {
  const [preferredLanguage, dispatch] = useReducer(
    preferredLanguageReducer,
    initialState
  );

  useEffect(() => {
    localStorage.setItem("preferredLanguage", preferredLanguage);
  }, [preferredLanguage]);

  return (
    <PreferredLanguageContext.Provider value={{ preferredLanguage, dispatch }}>
      {children}
    </PreferredLanguageContext.Provider>
  );
}

export function usePreferredLanguage() {
  return React.useContext(PreferredLanguageContext);
}

export { PreferredLanguageProvider, PreferredLanguageContext };

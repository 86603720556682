import { Space, Stack } from "@cohubinc/blueprint";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Typography from "src/components/Typography";
import providerCancellationFieldObjectJsonFile from "src/examples/provider_cancellation_field.json";
import { Code, StyledApiDocs } from "../styles";

interface IProps {
  className?: string;
  id?: string;
}

export default function ProviderCancellationFields({ className, id }: IProps) {
  const { hash } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "provider-cancellation-fields"
      );
      if (hash.includes("provider-cancellation-fields")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/cancellations#provider-cancellation-fields">
        <h3 className="mb-8 text-xl font-medium">
          Provider cancellation fields
        </h3>
      </a>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              A provider cancellation field represents a single point of
              information that we need in order to cancel services with a
              provider. They are strucutred to be easy to create HTML form
              elements out of and include fields that can and should be used to
              render form elements.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="provider cancellation field">
              {/* <FieldAttribute
                field={"id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a cancellation."
                }
              /> */}
              <FieldAttribute
                field={"provider_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for the provider that the cancellation field belongs to."
                }
              />
              <FieldAttribute
                field={"label"}
                type={"string"}
                description={
                  "The label that should be used to describe the field in a form."
                }
              />
              <FieldAttribute
                field={"name"}
                type={"string"}
                description={
                  "The name that should be used in the name attribute of a form input element."
                }
              />
              <FieldAttribute
                field={"placeholder"}
                type={"string"}
                description={
                  "The placeholder that can be used in the name attribute of a form input element."
                }
              />
              <FieldAttribute
                field={"data_type"}
                type={"string"}
                possibleValues={["string", "number", "boolean"]}
                description={
                  "The type of data expected. This can be used to help determine what type of form input element to use."
                }
              />
              <FieldAttribute
                field={"detailed_data_type"}
                type={"string"}
                possibleValues={[
                  "account_holder_name",
                  "full_address",
                  "zip_code",
                  "email",
                  "phone_number",
                  "last_4_payment_method_digits"
                ]}
                description={
                  "A more detailed description of the type of expected data for the field, like 'full_address', 'phone_number', etc. This can be used to pre-fill fields for a customer."
                }
              />
              <FieldAttribute
                field={"required"}
                type={"Boolean"}
                defaultValue={"false"}
                description={
                  "Whether or not the field is required to be filled out in order to cancel services with the provider."
                }
              />
              <FieldAttribute
                field={"required_response_length"}
                type={"number"}
                defaultValue={"null"}
                description={
                  "The minimum length that the provided value must be if the field is required."
                }
              />
              <FieldAttribute
                field={"help_text"}
                type={"string"}
                defaultValue={"null"}
                description={
                  "Informational text that can be used to provide more information about the field."
                }
              />
              <FieldAttribute
                field={"last4_of_payment_method"}
                defaultValue="null"
                type={"String"}
                description={
                  "The last 4 digits of the payment method that the customer uses to pay for their service."
                }
              />
              <FieldAttribute
                defaultValue="null"
                field={"date_of_last_transaction"}
                type={"String"}
                description={
                  "The date of the last transaction that the customer made for their service."
                }
              />
              <FieldAttribute
                field={"cancellation_info"}
                type={"Money"}
                description={
                  <React.Fragment>
                    <p>An array of ProviderCancellationField objects.</p>
                    <p className="mt-4">
                      These fields are most often things like PIN, Service
                      Address, Account Holder Name, or Phone Number, but they
                      vary by provider.
                    </p>
                    <p className="mt-4">
                      In order to know what fields to send, you can either use
                      the <CodeSnippet inline bare code="findProvider" /> query
                      and check the{" "}
                      <CodeSnippet
                        bare
                        inline
                        code="provider_cancellation_fields"
                      />
                      , or use the{" "}
                      <CodeSnippet bare inline code="findCancellation" /> query
                      and check its{" "}
                      <CodeSnippet bare inline code="cancellation_info" />.
                    </p>
                    <p className="mt-4">
                      When using the{" "}
                      <CodeSnippet bare inline code="provideCancellationInfo" />{" "}
                      mutation, the keys you send in your request should
                      correspond with the{" "}
                      <CodeSnippet bare inline code="name" /> of a field in the{" "}
                      <CodeSnippet bare inline code="cancellation_info" /> JSON
                      array.
                    </p>
                    <p className="mt-4">
                      Some keys are required, and if they're not sent in the
                      request, an error will be returned explaining which keys
                      must be sent in the request.
                    </p>
                    <p className="mt-4">
                      If a response has already been recorded for a particular
                      field, that field's{" "}
                      <CodeSnippet bare inline code="recorded_response" /> key
                      will contain the response. It will otherwise be null.
                    </p>
                  </React.Fragment>
                }
              />
              <FieldAttribute
                field={"provider"}
                type={"Provider"}
                description={
                  "The provider that the customer would like to cancel service with."
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(
              providerCancellationFieldObjectJsonFile,
              null,
              2
            )}
            language="json"
          />
        </Code>
      </StyledApiDocs>
    </div>
  );
}

import { LockClosedIcon } from "@heroicons/react/solid";
import React from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import Feedback from "src/components/Feedback";
import Logo from "src/components/Logo";
import paths from "src/paths";

interface IFormValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IFormValues) => void;
  errors?: string[];
}

const LoginForm: React.FC<IProps> = ({ onSubmit: submit, errors }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex justify-center">
          <Logo />
        </div>
        {!!errors?.length && <Feedback message={errors[0]} type="error" />}
        <Form
          onSubmit={submit}
          initialValues={{ email: "", password: "" }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <Field
                      name="email"
                      type="email"
                      render={({ input }) => (
                        <input
                          {...input}
                          autoComplete="email"
                          required
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                        />
                      )}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <Field
                      name="password"
                      type="password"
                      render={({ input }) => (
                        <input
                          {...input}
                          autoComplete="current-password"
                          required
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Password"
                        />
                      )}
                      required
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {/* <div className="flex items-center">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember_me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Remember me
                    </label>
                  </div> */}
                  <div className="text-sm">
                    <Link
                      to={paths.resetPassword}
                      className="font-medium text-bf-green-600 hover:text-bf-green-500"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-bf-green-600 hover:bg-bf-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bf-green-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-white" />
                    </span>
                    Sign in
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default LoginForm;

import { host, protocol } from "src/config";

export async function rotateToken(email: string) {
  const storedEmail = localStorage.getItem("email");
  const url = `${protocol}://${host}/api/partner_user_sessions/rotate_partner_token`;
  let sandboxUrl;
  if (localStorage.getItem("testMode") === "true") {
    sandboxUrl = `${protocol}://${host}/api/partner_user_sessions/rotate_partner_token`;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email: storedEmail })
  });

  if (response.status === 200) {
    console.log("200");
  }
}

import React, { Component, CSSProperties } from "react";
import Color from "src/definitions/enums/Color";
import Font from "src/definitions/enums/Font";

interface ITypographyProps {
  /** React Children nodes are required */
  children: React.ReactNode;
  /** Optionally pass className */
  className?: string;
  /** Optionally pass style object */
  style?: CSSProperties;
  /** Text can be the muted color */
  muted?: boolean;

  /** Text can be display: block */
  block?: boolean;

  weight?: 300 | 400 | 500 | 600 | 700;

  /** Text can be a <p /> tag instead of a <span /> */
  p?: boolean;

  /** Text can use the secondary font */
  secondary?: boolean;

  /** Easily make the text the default light color */
  light?: boolean;

  /** Use error styles */
  error?: boolean;

  color?: Color | string;

  uppercase?: boolean;

  alignment?: string;

  kerning?: number;
  mono?: boolean;
}

export const SuperTitle = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 48, lineHeight: "normal", display: "block" }
  });

export const Title = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 36, lineHeight: "normal", display: "block" }
  });

export const Subtitle = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 30, display: "block" }
  });

export const HeadingLarge = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 24 }
  });

export const HeadingSmall = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 20, fontWeight: 500 }
  });

export const HeadingTiny = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: 18 }
  });

export const Large = (props: ITypographyProps) =>
  buildType({ ...props, typeStyle: { fontSize: 16 } });

export const Regular = (props: ITypographyProps) =>
  buildType({
    ...props,
    typeStyle: { fontSize: Font.defaultFontSize }
  });

export const Small = (props: ITypographyProps) =>
  buildType({ ...props, typeStyle: { fontSize: 12 } });

export const Tiny = (props: ITypographyProps) =>
  buildType({ ...props, typeStyle: { fontSize: 10.2 } });

export default class Typography extends Component<ITypographyProps> {
  static SuperTitle = SuperTitle;
  static Title = Title;
  static Subtitle = Subtitle;
  static HeadingLarge = HeadingLarge;
  static HeadingSmall = HeadingSmall;
  static HeadingTiny = HeadingTiny;
  static Large = Large;
  static Small = Small;
  static Tiny = Tiny;

  render() {
    return <Regular {...this.props} />;
  }
}

type TBuildTypeArgs = ITypographyProps & { typeStyle: CSSProperties };
export const buildType = ({
  children,
  p,
  muted,
  light,
  block,
  error,
  className = "",
  typeStyle = {},
  style = {},
  color = Color.text,
  uppercase,
  alignment,
  weight,
  kerning,
  mono
}: TBuildTypeArgs) => {
  if (children === undefined || React.Children.count(children) === 0)
    return null;

  const defaultStyles = {
    fontFamily: mono ? "Roboto Mono" : "Inter",
    color: color as any,
    textTransform: (uppercase ? "uppercase" : "initial") as any,
    textAlign: (alignment ? alignment : "initial") as any
  };
  const fontWeightStyle = weight ? { fontWeight: weight } : { fontWeight: 400 };
  const kerningStyle = kerning ? { letterSpacing: `${kerning}rem` } : {};
  const mutedStyle = muted ? { color: Color.grey400 as any } : {};
  const blockStyle = block ? { display: "block" } : {};
  const lightStyle = light ? { color: Color.trueWhite as any } : {};
  const errorStyle = error ? { color: Color.primaryRed as any } : {};

  const styleProp: CSSProperties = {
    ...defaultStyles,
    ...fontWeightStyle,
    ...kerningStyle,
    ...typeStyle,
    ...mutedStyle,
    ...blockStyle,
    ...lightStyle,
    ...errorStyle,
    ...style
  };

  const props = {
    children,
    className,
    style: styleProp
  };

  return p ? <p {...props} /> : <span {...props} />;
};

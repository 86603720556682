import styled from "styled-components";

export const StyledApiDocs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "reference code";
  gap: 1rem;
  place-items: start;
`;

export const Reference = styled.div`
  grid-area: "reference";
`;

export const Code = styled.div`
  grid-area: "code";
  position: sticky;
  top: 10px;
`;

export const FieldName = styled.span.attrs((props) => ({
  className: "text-bf-green-600 hover:text-bf-green-800"
}))``;

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import apolloClient from "./apollo";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import { PreferredLanguageProvider } from "./contexts/PreferredLanguageContext";
import { TestModeProvider } from "./contexts/TestModeContext";
import Routes from "./Routes";
import "./styles/variables.css";

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <TestModeProvider>
        <PreferredLanguageProvider>
          <Router>
            <ScrollToTop />
            <Routes />
          </Router>
        </PreferredLanguageProvider>
      </TestModeProvider>
    </ApolloProvider>
  );
}

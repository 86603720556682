import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import SideSheet from "src/components/SideSheet";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import updateBillObjectJsonFile from "src/examples/responses/update_bill.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function UpdateBill({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();
  const [showAutoFixDetails, setShowAutoFixDetails] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("update-bill");
      if (hash.includes("update-bill")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#update-bill">
        <h3 className="mb-8 text-xl font-medium">Update a bill</h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>Alpha-numeric unique identifier for the bill.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"document_urls"}
              type={"array of strings"}
              description={
                <React.Fragment>
                  <p>
                    The urls that are associated with this bills
                    documents/statements.
                  </p>
                  <p className="mt-4">
                    We need these statements so that we can see what the
                    customer is currently paying for and to figure out how best
                    to save them money.
                  </p>
                  {/* <p className="mt-4 text-xs text-gray-600">
                    NOTE: At least one URL must be present in the array.
                  </p> */}
                </React.Fragment>
              }
            />
            <Parameter
              field={"last4ssn"}
              type={"string"}
              description={
                <React.Fragment>
                  <p>
                    The last 4 digits of the social security number associated
                    with the bill.
                  </p>
                  <p className="mt-4">
                    We need this information to be able to make changes to the
                    customer's account and negotiate on the customer's behalf.
                  </p>
                  <p className="mt-4">
                    Check the provider's{" "}
                    <CodeSnippet
                      bare
                      inline
                      code="billFields"
                      className="text-xs"
                    />{" "}
                    to know if this field is relevant for the bill.
                  </p>
                  <p className="mt-4 text-xs text-gray-600">
                    NOTE: This field is encrypted once we've received it and
                    cannot be fetched in any future API requests.
                  </p>
                </React.Fragment>
              }
            />
            <Parameter
              field={"passcode"}
              type={"string"}
              description={
                <React.Fragment>
                  <p>The passcode that is associated with the bill.</p>
                  <p className="mt-4">
                    This is often some kind of answer to a security question. We
                    need this information to be able to make changes to the
                    customer's account and negotiate on the customer's behalf
                  </p>
                  <p className="mt-4">
                    Check the provider's{" "}
                    <CodeSnippet
                      bare
                      inline
                      code="billFields"
                      className="text-xs"
                    />{" "}
                    to know if this field is relevant for the bill.
                  </p>
                  <p className="mt-4 text-xs text-gray-600">
                    NOTE: This field is encrypted once we've received it and
                    cannot be fetched in any future API requests.
                  </p>
                </React.Fragment>
              }
            />
            <Parameter
              field={"allows_contract"}
              type={"boolean"}
              description={
                "Whether or not the customer allows us to enter them into a contract with their provider. We'll only do this if it allows us to get more savings for the customer."
              }
            />
            <Parameter
              field={"auto_renegotiate"}
              type={"boolean"}
              defaultValue="true"
              description={
                <React.Fragment>
                  <div>
                    <p>
                      Whether or not the customer would like this bill to be
                      automatically negotiated whenever its savings expire in
                      the future.
                    </p>
                    <p className="mt-4">
                      For more about how auto_renegotiate works,{" "}
                      <span
                        className="cursor-pointer text-blue-500"
                        onClick={() => setShowAutoFixDetails(true)}
                      >
                        click here.
                      </span>
                    </p>
                  </div>
                  <SideSheet
                    open={showAutoFixDetails}
                    onClose={() => setShowAutoFixDetails(false)}
                    title={"Auto Renegotiate (aka AutoFix)"}
                  >
                    <div className="space-y-4">
                      <p>
                        Whenever we perform a negotiation, we take note of when
                        our negotiated savings is expected to end and record
                        that date on the bill.
                      </p>
                      <p>
                        That date becomes the next_renengotiate_on date, which
                        is what we use to determine when to automatically
                        renegotiate a bill.
                      </p>
                      <p>
                        The next_renegotiate_on date may be updated if we
                        determine that negotiated savings are expiring sooner or
                        later than we initially recorded. The date will also
                        update when a new negotiation occurs.
                      </p>
                      <p>
                        We have a daily task that determines which bills are
                        expiring soon and it proactively creates new
                        negotiations so that the customer does not experience a
                        sudden increase in their bills price.
                      </p>
                      <p>
                        When a new negotiation is created, a webhook is
                        delivered called{" "}
                        <CodeSnippet inline bare code="bill.autofix_upcoming" />{" "}
                        that alerts the partner about the upcoming negotiation.
                      </p>
                    </div>
                  </SideSheet>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The bill object that was updated if successful.</p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(testApiKey!, updateBillExampleRubyBody),
              js: javascriptExample(testApiKey!, updateBillExampleJsBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(updateBillObjectJsonFile, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const updateBillExampleRubyBody = `
bill = client.update_bill("bill_12fasfa3187asd", {
  last4ssn: "9999",
  passcode: "4321",
  allows_contract: true,
  auto_renegotiate: false,
  document_urls: [
    "https://example.com/bill.pdf",
    "https://example.com/bill2.pdf"
  ]
})
`;

const updateBillExampleJsBody = `
const bill = await client.updateBill("bill_12fasfa3187asd", {
  last4ssn: "9999",
  passcode: "4321",
  allowsContract: true,
  autoRenegotiate: false,
  documentUrls: [
    "https://example.com/bill.pdf",
    "https://example.com/bill2.pdf"
  ]
});
`;

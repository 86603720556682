import React from "react";
import Badge from "../Badge";
import Expander from "../Expander";
import { TFieldAttributes } from "./FieldAttribute";
import PossibleFieldValues from "./PossibleFieldValues";

interface IChildAttributesProps {
  attributes: TFieldAttributes[];
  canExpand?: boolean;
}

export default function ChildAttributes({
  attributes,
  canExpand = true
}: IChildAttributesProps) {
  let attributesElements;

  attributesElements = attributes.map((ev: TFieldAttributes, idx: number) => (
    <div className="my-4 ml-6" key={idx}>
      <div className="flex flex-col">
        <div className="mb-2 flex items-center">
          <div className="mr-2 font-mono text-sm font-semibold">{ev.field}</div>
          <Badge
            text={ev.type}
            size="sm"
            className="bg-gray-200 text-gray-800"
          />
          {/* <span
            className={`font-mono ml-2 text-xs rounded px-2 py-0.5 ${
              required && "text-red-600 bg-red-100"
            }`}
          >
            {required ? "required" : "optional"}
          </span> */}
        </div>
        {ev.possibleValues && (
          <div className="mt-2 flex items-center text-sm">
            <span className="mr-2 text-xs">Possible values:</span>
            <PossibleFieldValues values={ev.possibleValues} />
          </div>
        )}
        <span className="mt-2 text-sm">{ev.description}</span>
      </div>
    </div>
  ));

  if (canExpand) {
    return (
      <Expander
        expandElement={<div>Show child fields</div>}
        collapseElement={<div>Hide child fields</div>}
      >
        {attributesElements}
      </Expander>
    );
  } else {
    return <React.Fragment>{attributesElements}</React.Fragment>;
  }
}

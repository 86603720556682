import React from "react";
import DateAtTime from "src/components/DateAtTime";
import Detail from "src/components/Detail";
import { IItemFragment } from "src/components/graphql";
import Money from "src/components/Money";

interface IBillItemsProps {
  items: IItemFragment[];
}

const BillItems: React.FC<IBillItemsProps> = ({ items }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {!!items.length ? (
          <React.Fragment>
            {items.map((it, idx) => (
              <li key={`${it.name}_${idx}`}>
                <div className="flex items-start px-4 py-4">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium truncate">
                          {it.name}
                        </p>
                        <p className="mt-1 flex items-center text-xs text-gray-500">
                          <span className="truncate">
                            {it.type === "Otc" ? "One time credit" : it.type}
                          </span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <Detail
                            heading="Duration"
                            body={
                              <React.Fragment>
                                <DateAtTime
                                  onlyDate
                                  input={it.savingsStartOn}
                                />{" "}
                                to{" "}
                                <DateAtTime onlyDate input={it.savingsEndOn} />
                              </React.Fragment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Detail
                      heading="Savings"
                      body={<Money amount={it.savings} />}
                    />
                  </div>
                </div>
              </li>
            ))}
          </React.Fragment>
        ) : (
          <li>
            <div className="flex items-center justify-center px-4 py-4">
              <p className="text-sm text-gray-500">
                This bill doesn't have any items.
              </p>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default BillItems;

import { DocumentTextIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

interface IDocLinkProps {
  to: string;
}

const DocLink: React.FC<IDocLinkProps> = ({ to }) => {
  return (
    <Link
      to={to}
      target="_blank"
      rel="noopener noreferrer"
      className="group flex items-center transition-all duration-1000 ease-in-out"
    >
      <DocumentTextIcon className="w-5 h-5 ml-1 text-gray-400 group-hover:text-blue-500" />
      <span className="bg-blue-800 text-xs text-gray-50 hidden group-hover:inline px-2 rounded-md">
        Go to the docs
      </span>
    </Link>
  );
};

export default DocLink;

import { Space, Stack } from "@cohubinc/blueprint";
import React from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import customerObjectJsonFile from "src/examples/customer.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import CreateCustomer from "./CreateCustomer";
import FindCustomer from "./FindCustomer";
import ListCustomers from "./ListCustomers";
import SetCustomerEligibleForCancellation from "./SetCustomerEligibleForCancellation";
import SetCustomerEligibleForNegotiation from "./SetCustomerEligibleForNegotiation";

export default function ApiDocsCustomers() {
  usePageTitle("Customers");

  return (
    <React.Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Typography.Large>
            A customer is a person or entity who receives a bill from a
            provider. A customer must be created before a bill is created, since
            all bills are associated with customers.
          </Typography.Large>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference className="w-full">
          <AvailableFields objectName="customer">
            <FieldAttribute
              field={"id"}
              type={"ID"}
              description={"Alpha-numeric unique identifier for a customer."}
            />
            <FieldAttribute
              field={"email"}
              type={"String"}
              description={"Customer's email address."}
            />
            <FieldAttribute
              field={"name"}
              type={"String"}
              description={"Customer's full name."}
            />
            <FieldAttribute
              field={"first_name"}
              type={"String"}
              description={"Customer's first name."}
            />
            <FieldAttribute
              field={"last_name"}
              type={"String"}
              description={"Customer's last name."}
            />
            <FieldAttribute
              field={"phone_number"}
              type={"String"}
              description={"Customer's phone number."}
            />
            <FieldAttribute
              field={"last4ssn"}
              overridePreferredLanguage
              type={"String"}
              description={
                "The last 4 digits of the customer's social security number"
              }
            />
            <FieldAttribute
              field={"eligible_for_negotiation"}
              type={"Boolean"}
              description={
                "Indicates if the customer is eligible to have their bills negotiated."
              }
            />
            <FieldAttribute
              field={"eligible_for_cancellation"}
              type={"Boolean"}
              description={
                "Indicates if the customer is eligible to have their chosen provider services cancelled."
              }
            />
            <FieldAttribute
              field={"external_id"}
              type={"String"}
              description={
                "Alpha-numeric unique identifier for a customer, typically set during the creation of a customer."
              }
            />
            <FieldAttribute
              field={"corvette_id"}
              type={"String"}
              description={
                "Alpha-numeric unique identifier for a customer, typically set during the creation of a customer."
              }
            />
            <FieldAttribute
              field={"membership_id"}
              type={"String"}
              description={
                "Unique identifier for a customer, typically set during the creation of a customer."
              }
            />
            <FieldAttribute
              field={"created_at"}
              type={"DateTime"}
              description={"Date and time a customer was created."}
            />
            <FieldAttribute
              field={"updated_at"}
              type={"DateTime"}
              description={"Date and time a customer was updated."}
            />
            <FieldAttribute
              field={"bills"}
              type={"Array of bill objects"}
              description={"List of the customer's bills."}
            />
          </AvailableFields>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(customerObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <CreateCustomer id="create-customer" />
      <Divider marginSize={6} className="bg-gray-300" />
      <ListCustomers id="list-customers" />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindCustomer id="find-customer" />
      <Divider marginSize={6} className="bg-gray-300" />
      <SetCustomerEligibleForNegotiation id="set-eligible-for-negotiation" />
      <Divider marginSize={6} className="bg-gray-300" />
      <SetCustomerEligibleForCancellation id="set-eligible-for-cancellation" />
    </React.Fragment>
  );
}

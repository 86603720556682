import React from "react";
import Response from "src/components/ApiDocs/Response";
import Breadcrumbs from "src/components/Breadcrumbs";
import CodeSnippet from "src/components/CodeSnippet";
import NewContainer from "src/components/NewContainer";
import findInfoReqResponseJsonFile from "src/examples/responses/find_info_req.json";
import usePageTitle from "src/hooks/usePageTitle";

export default function RespondingToInfoRequests() {
  usePageTitle("Responding to Info Requests");

  return (
    <NewContainer className="mt-8">
      <Breadcrumbs
        crumbs={[
          {
            path: "/api-docs/implementation",
            text: "Implementation Guide",
            active: false
          },
          {
            path: "/api-docs/implementation/responding-to-info-requests",
            text: "Responding to Info Requests",
            active: true
          }
        ]}
      />
      <div className="mt-8">
        <h3
          className="text-xl text-gray-bf-text-medium mb-2"
          id="creating-bills"
        >
          Responding to info requests
        </h3>
      </div>
      <div className="mt-8">
        <div className="mb-8">
          <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="the-customer-is-always-first"
          >
            Listen for{" "}
            <CodeSnippet bare inline code="information_request.created" />{" "}
            webhooks
          </h3>
          <p className="mb-4">
            There are many reasons that we may need additional information to
            complete negotiations for a bill. Some of the most common reasons
            are:
          </p>
          <ul className="text-sm mb-8 ml-8 list-disc">
            <li className="mb-3">
              The document that was provided may be blurry or not include all
              necessary information
            </li>
            <li className="mb-3">The PIN/passcode provided is incorrect</li>
            <li className="mb-3">
              The customer has a past due balance with their provider
            </li>
            <li className="mb-3">
              The account with the provider has been closed or is inaccesible
            </li>
          </ul>
          <p>
            If we encounter any problems while negotiating, we'll create an{" "}
            <CodeSnippet bare inline code="informationRequest" /> and you'll
            receive a webhook.
          </p>
        </div>
        <div className="mb-8">
          <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="the-customer-is-always-first"
          >
            Find the information request
          </h3>
          <p className="mb-4">
            The webhook will contain the ID of the information request, which
            you should pass to the{" "}
            <CodeSnippet bare inline code="findInformationRequest" /> query. The
            query will respond with the information request and all of its
            fields.
          </p>
        </div>
        <div className="mb-8">
          <h3
            className="text-xl text-gray-bf-text-medium mb-2"
            id="the-customer-is-always-first"
          >
            Present the content to the customer and record their answers
          </h3>
          <p className="mb-4">
            Show the customer the content field from the information request and
            give them a form to respond with.
          </p>
          <p className="mb-4">
            The form should have inputs for each of the information request
            fields that came back in the{" "}
            <CodeSnippet bare inline code="findInformationRequest" /> query.
            Those fields will contain subfields with relevant data to use when
            creating your form inputs.
          </p>
          <p className="mb-4">
            For example, say we need a customer's address to continue
            negotiating their bill. You'll receive the created webhook, find the
            info request using the ID, and receive a response that looks like
            this:
          </p>
          <Response
            className="mt-6 mb-6"
            responseJson={JSON.stringify(findInfoReqResponseJsonFile, null, 2)}
          />
          <p className="mb-4">
            You can use the fields array to build your form inputs. When the
            form is submitted, it should use the{" "}
            <CodeSnippet bare inline code="respondToInformationRequest" />{" "}
            mutation, passing the ID of the information request and an object
            containing an array called fields.
          </p>
        </div>
      </div>
    </NewContainer>
  );
}

import { Space, Stack } from "@cohubinc/blueprint";
import React from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Typography from "src/components/Typography";
import cancellationOfferObjectJsonFile from "src/examples/cancellation_offer.json";
import usePageTitle from "src/hooks/usePageTitle";
import { Code, StyledApiDocs } from "../styles";
import AcceptCancellationOffer from "./AcceptCancellationOffer";
import FindCancellationOffer from "./FindCancellationOffer";
import RejectCancellationOffer from "./RejectCancellationOffer";

export default function ApiDocsCancellationOffers() {
  usePageTitle("Cancellation Offers");

  return (
    <React.Fragment>
      <Space height={3} />
      <StyledApiDocs>
        <Stack>
          <Stack>
            <Typography.Large>
              During the course of a cancellation, we may need explicit consent
              from a customer to cancel their service with a provider.
            </Typography.Large>
          </Stack>
          <Space height={2} />
        </Stack>
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <Stack>
            <AvailableFields objectName="cancellationOffer">
              <FieldAttribute
                field={"id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for a cancellation offer."
                }
              />
              <FieldAttribute
                field={"cancellation_id"}
                type={"ID"}
                description={
                  "Alpha-numeric unique identifier for the cancellation that a cancellation offer belongs to."
                }
              />
              <FieldAttribute
                field={"content"}
                type={"String"}
                description={
                  "A plain-text string describing the question or reason the cancelling agent needs customer consent."
                }
              />
              <FieldAttribute
                field={"content_html"}
                type={"String"}
                description={"HTML formatted version of the content"}
              />
              <FieldAttribute
                field={"created_at"}
                type={"DateTime"}
                description={
                  "Date and time when the cancellation offer was created"
                }
              />
              <FieldAttribute
                field={"updated_at"}
                type={"DateTime"}
                description={
                  "Date and time when the cancellation offer was last updated"
                }
              />
              <FieldAttribute
                field={"rejected_at"}
                type={"DateTime"}
                description={
                  "Date and time when the customer rejected the cancellation offer"
                }
              />
              <FieldAttribute
                field={"accepted_at"}
                type={"DateTime"}
                description={
                  "Date and time when the customer accepted the cancellation offer"
                }
              />
            </AvailableFields>
          </Stack>
        </Reference>
        <Code className="w-full">
          <CodeSnippet
            code={JSON.stringify(cancellationOfferObjectJsonFile, null, 2)}
            language="json"
          />
        </Code>
      </StyledApiDocs>
      <Divider marginSize={6} className="bg-gray-300" />
      <AcceptCancellationOffer />
      <Divider marginSize={6} className="bg-gray-300" />
      <RejectCancellationOffer />
      <Divider marginSize={6} className="bg-gray-300" />
      <FindCancellationOffer />
      {/* <ProviderCancellationFields id="provider-cancellation-fields" /> */}
    </React.Fragment>
  );
}

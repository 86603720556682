import React from "react";
import logo from "src/assets/logo.png";

const CheckEmail: React.FC = () => {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="BillFixers" />
          <h2 className="mt-6 text-center text-lg font-semibold text-gray-800">
            Check your email for instructions on resetting your password.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;

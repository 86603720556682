import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "src/assets/bf-logo-white.png";
import { useFindCurrentPartnerQuery } from "src/components/graphql";
import TestModeToggle from "src/components/TestModeToggle";
import useSession from "src/hooks/useSession";
import paths from "src/paths";
import ApiDocsLinks from "./ApiDocsLinks";
import Navigation from "./Navigation";

export function getLinkClassNames(path: string, currentPath: string) {
  const inactiveLinkClassNames =
    "text-gray-200 hover:bg-gray-700 hover:text-gray-50 group flex items-center p-1 text-sm font-medium rounded-md";
  const activeLinkClassNames =
    "bg-gray-900 text-gray-50 group flex items-center p-1 text-sm font-medium rounded-md";

  if (path === "/" && currentPath === "/") {
    return activeLinkClassNames;
  }

  const parts = currentPath.split("/");
  if (parts[parts.length - 1].includes("_")) {
    parts.pop();
  }

  if (path === parts.join("/")) {
    return activeLinkClassNames;
  }

  return inactiveLinkClassNames;
}

const DesktopSidebar: React.FC = () => {
  const { pathname } = useLocation();
  const [apiDocsIsOpen, setApiDocsIsOpen] = useState(
    pathname.includes("api-docs")
  );

  useEffect(() => {
    if (pathname.includes("api-docs")) {
      setApiDocsIsOpen(true);
    }
    return () => {
      setApiDocsIsOpen(false);
    };
  }, [pathname]);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex w-64 flex-col">
        <div className="flex h-0 flex-1 flex-col bg-gray-800">
          <div
            className="z-10 flex flex-shrink-0 items-center px-4 pt-5 pb-4"
            // style={{ boxShadow: "0 20px 2px -2px rgba(31,41,56, 0.5)" }}
          >
            <Link to={paths.dashboard} className="cursor-pointer">
              <img className="h-10 w-auto" src={logo} alt="Billfixers" />
            </Link>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto pb-4">
            {apiDocsIsOpen ? <ApiDocsLinks /> : <Navigation />}
          </div>

          <div className="flex flex-shrink-0 px-4 pt-4">
            <div className="flex items-center">
              <TestModeToggle
                className="mr-2"
                onChange={() => window.location.reload()}
              />
              <span className="text-sm font-medium text-gray-50">
                View test data
              </span>
            </div>
          </div>
          <ProfileArea />
        </div>
      </div>
    </div>
  );
};

export default DesktopSidebar;

const ProfileArea: React.FC = () => {
  const { logOut, email } = useSession();

  const { loading, data } = useFindCurrentPartnerQuery();

  if (loading && !data) {
    return null;
  }

  const currentPartner = data?.currentPartner;

  return (
    <div className="flex flex-shrink-0 p-4">
      <div className="flex items-center">
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-50">
            <Link
              to={paths.settings}
              className="group block w-full flex-shrink-0"
            >
              {email}
            </Link>
          </p>
          <p className="text-xs text-gray-200">{currentPartner?.name}</p>
          <p className="mt-3 text-xs font-medium text-gray-300 group-hover:text-gray-200">
            <button
              className="rounded-full focus:outline-none"
              onClick={logOut}
            >
              Log out
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import javascriptExample from "src/examples/graphql/queries/javascript/javascriptExample";
import rubyExample from "src/examples/graphql/queries/ruby/rubyExample";
import provideDocumentlessInfoResponseJson from "src/examples/responses/provide_documentless_info.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ProvideDocumentlessInfo({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "provide-documentless-info"
      );
      if (hash.includes("provide-documentless-info")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#provide-documentless-info">
        <h3 className="mb-4 text-xl font-medium">Provide documentless info</h3>
      </a>
      <p className="mb-8">
        Many of the most common providers can be negotiated without a document
        as long as we're given certain information about the bill and the
        account holder.
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>

          <AvailableFields>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>Alpha-numeric unique identifier for the bill.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"documentless_info"}
              type={"JSON"}
              required
              description={
                <React.Fragment>
                  <p>
                    A JSON object containing the keys and values for the fields
                    the bill needs based on its provider.
                  </p>
                  <p className="mt-4">
                    These fields are most often things like PIN, Service
                    Address, Account Holder Name, or Phone Number, but they vary
                    by provider.
                  </p>
                  <p className="mt-4">
                    In order to know what fields to send, you can either use the{" "}
                    <CodeSnippet inline bare code="findProvider" /> query and
                    check the{" "}
                    <CodeSnippet bare inline code="provider_bill_fields" />, or
                    use the <CodeSnippet bare inline code="findBill" /> query
                    and check its{" "}
                    <CodeSnippet bare inline code="documentless_info" />.
                  </p>
                  <p className="mt-4">
                    The keys you send in your request should correspond with the{" "}
                    <CodeSnippet bare inline code="name" /> of a field in the{" "}
                    <CodeSnippet bare inline code="documentless_info" /> JSON
                    array.
                  </p>
                  <p className="mt-4">
                    Some keys are required, and if they're not sent in the
                    request, an error will be returned explaining which keys
                    must be sent in the request.
                  </p>
                  <p className="mt-4">
                    If a response has already been recorded for a particular
                    field, that field's{" "}
                    <CodeSnippet bare inline code="recorded_response" /> key
                    will contain the response. It will otherwise be null.
                  </p>
                </React.Fragment>
              }
            />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An object containing a boolean success field indicating if the
              documentless info was successfully recorded, an errors array
              containing errors (if any), and the bill object.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              ruby: rubyExample(
                testApiKey!,
                provideDocumentlessInfoExampleRubyBody
              ),
              js: javascriptExample(
                testApiKey!,
                provideDocumentlessInfoExampleJsBody
              ),
              curl: curlExample(
                testApiKey!,
                provideDocumentlessInfoExampleCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              provideDocumentlessInfoResponseJson,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const provideDocumentlessInfoExampleRubyBody = `
bill = client.provide_documentless_info(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0', {
    service_address: '1234 Main St. Anytown, CA 90210',
    account_holder_name: 'Luke Skywalker'
  }
)
`;

const provideDocumentlessInfoExampleJsBody = `
bill = async client.provideDocumentlessInfo(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0', {
    service_address: '1234 Main St. Anytown, CA 90210',
    account_holder_name: 'Luke Skywalker'
  }
)
`;

const provideDocumentlessInfoExampleCurlBody = `
$'{
  "query": "mutation ProvideDocumentlessInfo($billId:ID!,$documentlessInfo:JSON!){ProvideDocumentlessInfo(input:{billId:$billId,documentlessInfo:$documentlessInfo}){success errors bill{id status providerId customerId totalSavings createdAt updatedAt documentlessInfo customer{id email name firstName lastName phoneNumber createdAt updatedAt}provider{id name services createdAt updatedAt}}}}",
  "variables": {
  "billId": "bill_d8fb9e40650351069573001a8184845c",
  "documentlessInfo": {
    "serviceAddress": "1234 Main St."
}
`;

import React from "react";
import { Field, Form } from "react-final-form";
import logo from "src/assets/logo.png";
import Feedback from "src/components/Feedback";
import PasswordComplexityValidator from "src/components/PasswordComplexityValidator";

interface IFormValues {
  password: string;
  password_confirmation: string;
}

interface IProps {
  onSubmit: (values: IFormValues) => void;
  errors?: string[];
}

const NewPasswordForm: React.FC<IProps> = ({ onSubmit: submit, errors }) => {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="BillFixers" />
          <h2 className="mt-6 text-center text-lg font-semibold text-gray-700">
            Set your new password
          </h2>
        </div>
        {!!errors?.length && <Feedback message={errors[0]} type="error" />}
        <Form
          onSubmit={submit}
          initialValues={{ password: "", password_confirmation: "" }}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px">
                  <div>
                    <label htmlFor="password" className="sr-only">
                      New Password
                    </label>
                    <Field
                      name="password"
                      type="password"
                      render={({ input }) => (
                        <input
                          {...input}
                          autoComplete="none"
                          required
                          className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Enter a new password"
                        />
                      )}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Confirm New Password
                    </label>
                    <Field
                      name="password_confirmation"
                      type="password"
                      render={({ input }) => (
                        <input
                          {...input}
                          autoComplete="none"
                          required
                          className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Enter it again"
                        />
                      )}
                      required
                    />
                  </div>
                </div>
                <PasswordComplexityValidator password={values.password} />
                <div>
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-bf-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-bf-green-500 focus:outline-none focus:ring-2 focus:ring-bf-green-500 focus:ring-offset-2 disabled:opacity-50"
                    disabled={submitting}
                  >
                    {submitting ? "Setting new password" : "Set password"}
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default NewPasswordForm;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import sandboxTransitionCancellationJsonFile from "src/examples/responses/sandbox_transition_cancellation.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SandboxTransitionCancellation({
  className,
  id
}: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "sandbox-transition-cancellation"
      );
      if (hash.includes("sandbox-transition-cancellation")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "sandbox-transition-cancellation"}>
      <a href="/api-docs/sandbox-resources#sandbox-transition-cancellation">
        <h3 className="mb-8 text-xl font-medium">
          Sandbox transition cancellation
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"cancellation_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the cancellation that is transitioning.</div>
                </React.Fragment>
              }
            />
            <Parameter
              required
              field={"transition"}
              type={"String"}
              description={
                <React.Fragment>
                  <div>
                    The verb representing the transition for the cancellation.
                    Must be one of:
                  </div>
                  <ul className="mt-2 space-y-3">
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"start"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        The start transition must happen before any other
                        transitions can take place.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"succeed"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Completes the cancellation successfully.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"mark_unable_to_cancel"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Completes the cancellation unsuccessfully.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"mark_already_cancelled"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Complete the cancellation because the service was
                        already cancelled.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"mark_no_subscription_found"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Complete the cancellation because no customer account
                        could be found with the provider.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"request_information"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Generates a cancellation info request for the
                        cancellation.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"request_consent"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Generates a cancellation offer for the cancellation.
                      </div>
                    </li>
                    <li>
                      <CodeSnippet
                        bare
                        inline
                        code={"reset"}
                        className="mb-1 text-xs"
                      />
                      <div className="text-xs">
                        Resets the cancellation to its initial pending state.
                        This destroys any cancellation info requests or
                        cancellation offers that have been generated and sets
                        the cancellation refund amount to $0.00.
                      </div>
                    </li>
                  </ul>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The cancellation that has transitioned, if the mutation was
              successful.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(
                testApiKey!,
                sandboxTransitionCancellationExampleCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              sandboxTransitionCancellationJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const sandboxTransitionCancellationExampleCurlBody = `
$'{
  "query": "mutation SandboxTransitionCancellation($cancellationId:ID!,$transition:String!){SandboxTransitionCancellation(input:{cancellationId:$cancellationId,transition:$transition}){success errors cancellation{id}}}",
	"variables": {
		"cancellationId": "cancellation_5484b35341280f05b76e085a6e822e47",
		"transition": "succeed"
	}
}`;

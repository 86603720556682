import React from "react";
import { useTestMode } from "src/contexts/TestModeContext";
import Toggle from "./Toggle";

interface IProps {
  className?: string;
  onChange?: () => void;
}

export default function TestModeToggle({ className, onChange }: IProps) {
  const { testMode, dispatch } = useTestMode();

  return (
    <Toggle
      className={`${className}`}
      onChange={() => {
        dispatch(!testMode);
        if (onChange) {
          onChange();
        }
      }}
      isEnabled={testMode}
    />
  );
}

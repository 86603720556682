import React from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import Feedback from "src/components/Feedback";
import Logo from "src/components/Logo";

interface IFormValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IFormValues) => void;
  errors?: string[];
}

const ResetPasswordForm: React.FC<IProps> = ({ onSubmit: submit, errors }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex justify-center">
          <Logo />
        </div>
        <h2 className="mt-6 text-center text-xl font-bold text-gray-600">
          Reset your password
        </h2>
        {!!errors?.length && <Feedback message={errors[0]} type="error" />}
        <Form
          onSubmit={submit}
          initialValues={{ email: "", password: "" }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <Field
                      name="email"
                      type="email"
                      render={({ input }) => (
                        <input
                          {...input}
                          autoComplete="email"
                          required
                          className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-bf-green-500 focus:border-bf-green-500 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                        />
                      )}
                      required
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-bf-green-600 hover:bg-bf-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bf-green-500 disabled:opacity-50"
                    disabled={submitting}
                  >
                    {submitting ? "Sending" : "Reset Password"}
                  </button>
                </div>
              </form>
            );
          }}
        />
        <div className="flex justify-center mt-6">
          <Link className="text-blue-500" to={"/"}>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import findCancellationInfoReqResponseJsonFile from "src/examples/responses/find_cancellation_info_request.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function FindCancellationInfoRequest({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "find-cancellation-info-req"
      );
      if (hash.includes("find-cancellation-info-req")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "find-cancellation-info-req"}>
      <a href="/api-docs/bills#find-cancellation-info-req">
        <h3 className="mb-8 text-xl font-medium">
          Find a cancellation info request
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"cancellation_info_request_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the cancellation info request.</div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>The cancellation info request object, if found.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={
              {
                // ruby: rubyExample(testApiKey!, findInfoRequestExampleRubyBody),
                // js: javascriptExample(testApiKey!, findInfoRequestExampleJsBody)
              }
            }
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              findCancellationInfoReqResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

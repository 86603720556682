import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import createDocumentResponseJson from "src/examples/responses/create_document.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function CreateDocument({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById("create-document");
      if (hash.includes("create-document")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href="/api-docs/bills#create-document">
        <h3 className="mb-4 text-xl font-medium">Create a document</h3>
      </a>
      <p className="mb-8">
        As an alternative to providing{" "}
        <CodeSnippet inline bare code={"documentUrls"} /> in the create or
        update bill mutations, you can create a document directly with the{" "}
        <CodeSnippet inline bare code={"createDocument"} /> mutation.
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>

          <AvailableFields>
            <Parameter
              required
              field={"bill_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>Alpha-numeric unique identifier for the bill.</div>
                </React.Fragment>
              }
            />
            <Parameter
              field={"document"}
              type="DocumentAttributes"
              required
              description={""}
              childAttributes={[
                {
                  field: "title",
                  description: "The title or filename of the document.",
                  type: "string"
                },
                {
                  field: "temp_url",
                  description:
                    "The URL where the document can be found. We use this URL to fetch the document and save it to our own servers.",
                  type: "string"
                },
                {
                  field: "base64_string",
                  description:
                    "A base 64 encoded string of a PDF or image file representing the document. If this paramater is passed in the mutation, we'll construct a file and upload it to our servers.",
                  type: "string"
                }
              ]}
            />
            {/* <Parameter
              field={"title"}
              type={"string"}
              description={
                <React.Fragment>
                  <p>The title or filename of the document.</p>
                </React.Fragment>
              }
            />
            <Parameter
              field={"temp_url"}
              type={"string"}
              description={
                <React.Fragment>
                  <p>
                    The URL where the document can be found. We use this URL to
                    fetch the document and save it to our own servers.
                  </p>
                </React.Fragment>
              }
            />
            <Parameter
              field={"base64_string"}
              type={"string"}
              description={
                <React.Fragment>
                  <p>
                    A base 64 encoded string of a PDF or image file representing
                    the document. If this paramater is passed in the mutation,
                    we'll construct a file and upload it to our servers.
                  </p>
                </React.Fragment>
              }
            /> */}
          </AvailableFields>
          <div className="rounded-md bg-red-100 p-2 text-sm text-red-600 ">
            * You must pass either temp_url or base64_string.
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An object containing a boolean success field indicating if the
              document was successfully created, an errors array containing
              errors (if any), and the document object.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(
              //   testApiKey!,
              //   createDocumentExampleRubyBody
              // ),
              // js: javascriptExample(
              //   testApiKey!,
              //   createDocumentExampleJsBody
              // ),
              curl: curlExample(testApiKey!, createDocumentExampleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(createDocumentResponseJson, null, 2)}
          />
        </div>
      </div>
    </div>
  );
}

const createDocumentExampleRubyBody = `
bill = client.create_document(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0', {
    service_address: '1234 Main St. Anytown, CA 90210',
    account_holder_name: 'Luke Skywalker'
  }
)
`;

const createDocumentExampleJsBody = `
bill = async client.provideDocumentlessInfo(
  'bill_c00ed07a7d39ec06bcd38e6aaa0e73d0', {
    service_address: '1234 Main St. Anytown, CA 90210',
    account_holder_name: 'Luke Skywalker'
  }
)
`;

const createDocumentExampleCurlBody = `
$'{
  "query": "mutation CreateDocument($billId:ID!,$document:DocumentAttributes!){CreateDocument(input:{billId:$billId,document:$document}){success errors document{id title filename billId createdAt}}}",
  "variables": {
  "billId": "bill_d8fb9e40650351069573001a8184845c",
  "document": {
    "title": "testfile.pdf",
    "tempUrl": "https://example.com/testfile.pdf",
    "base64String": "data:application/pdf;base64,JVBERi0xLjMKMSAwIG9iago8PAovVHlwZSAvUGFnZXMKL0NvdW50IDMKL0tpZHMg..."
}
`;

import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import AltLoader from "src/components/AltLoader";
import Button from "src/components/Button";
import CodeSnippet from "src/components/CodeSnippet";
import Detail from "src/components/Detail";
import Divider from "src/components/Divider";
import {
  INegotiationFragment,
  useSandboxGenerateNegotiationMutation,
  useSandboxListNegotiationsForBillQuery,
  useSandboxTransitionNegotiationMutation
} from "src/components/graphql";

interface IProps {
  billId: string;
}

export default function Negotiations({ billId }: IProps) {
  const [negotiations, setNegotiations] = useState<INegotiationFragment[]>([]);
  const { loading, data } = useSandboxListNegotiationsForBillQuery({
    variables: { billId },
    // This is some hacky bullshit that I do not like. But Apollo is not triggering a rerender unless
    // this fetchPolicy is here and also "FindBill" is in the refetchQueries below and the fetch-policy
    // for the Bill/index.tsx query FindBill is also "cache-and-network".
    fetchPolicy: "cache-and-network"
  });

  const [sandboxGenerateNegotiationMutation] =
    useSandboxGenerateNegotiationMutation({
      variables: {
        billId
      },
      refetchQueries: ["FindBill", "SandboxListNegotiationsForBill"]
    });

  useEffect(() => {
    if (data) {
      setNegotiations(data.negotiations.nodes.map((n) => n));
    }
  }, [data]);

  if (loading || !data || !data.negotiations) {
    return <AltLoader />;
  }

  const generateNeg = async () => {
    const result = await sandboxGenerateNegotiationMutation();

    if (result.data?.SandboxGenerateNegotiation?.success) {
      // console.log("hoorayy");
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {!!negotiations.length ? (
          <React.Fragment>
            {negotiations.map((neg, idx) => (
              <React.Fragment key={`${neg.id}_${idx}`}>
                <Negotiation negotiation={neg} billId={billId} />
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          <li className="px-4 py-4" key="one">
            <div className="flex items-center justify-center">
              <p className="text-sm text-gray-500">
                This bill doesn't have any negotiations.
              </p>
            </div>
            <div className="mt-4 flex items-center justify-center">
              <Button size="xs" version="plain" onClick={generateNeg}>
                Generate negotiation
              </Button>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

interface INegotiationProps {
  negotiation: INegotiationFragment;
  billId: string;
}

const Negotiation: React.FC<INegotiationProps> = ({ negotiation, billId }) => {
  const [transitionNegotiationMutation] =
    useSandboxTransitionNegotiationMutation();

  const transitionNegotiation = async (transitionVerb: string) => {
    const result = await transitionNegotiationMutation({
      variables: {
        billId: billId,
        transition: transitionVerb
      },
      refetchQueries: ["FindBill"]
    });

    if (result.data?.SandboxTransitionNegotiation?.success) {
      // console.log("transition successful");
    }
  };

  return (
    <li className="px-4 py-4">
      <div className="flex items-start">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="truncate text-sm font-medium">
                {/* {it.name} */}
                Negotiation {negotiation.negotiationNumber}
              </p>
              <p className="mt-1 flex items-center text-xs text-gray-500">
                something
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <Detail heading="Status" body={startCase(negotiation.state)} />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <Detail heading="Status" body={startCase(negotiation.state)} /> */}
          <Button
            size="xs"
            version="plain"
            // className="text-purple-700"
            onClick={() => transitionNegotiation("reset")}
          >
            Reset Negotiation
          </Button>
        </div>
      </div>
      <Divider />
      <div className="mt-6">
        {/* <h4 className="mb-1 text-gray-600">Test actions</h4> */}
        <p className="mb-6 text-sm text-gray-600">
          When we negotiate, there are various lifecycle stages that a
          negotiation and its parent bill go through. The buttons below
          represent the lifecycle transitions that this negotiation may go
          through in its current state of{" "}
          <CodeSnippet
            bare
            inline
            code={negotiation.state}
            className="text-xs"
          />
          . If there are no buttons, the negotiation has reached an end state
          and you can reset it.
        </p>
        <div className="grid grid-cols-2 gap-4">
          {negotiation.state === "pending" && (
            <Button
              size="sm"
              version="plain"
              onClick={() => transitionNegotiation("start")}
            >
              Start
            </Button>
          )}
          {negotiation.state === "started" && (
            <React.Fragment>
              <Button
                size="sm"
                version="plain"
                onClick={() => transitionNegotiation("fix")}
                className="mr-2"
              >
                Fix
              </Button>
              <Button
                size="sm"
                version="plain"
                onClick={() => transitionNegotiation("fail")}
                className="mr-2"
              >
                Fail
              </Button>
              <Button
                size="sm"
                version="plain"
                onClick={() => transitionNegotiation("request_information")}
                className="mr-2"
              >
                Request Info
              </Button>
              <Button
                size="sm"
                version="plain"
                onClick={() => transitionNegotiation("request_consent")}
                className="mr-2"
              >
                Make Offer
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </li>
  );
};

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import setCancellationReadyToCancelJson from "src/examples/responses/set_cancellation_ready_to_cancel.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function SetCancellationReadyToCancel({
  className,
  id
}: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  const hashRoute = "set-cancellation-ready-to-cancel";

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(hashRoute);
      if (hash.includes(hashRoute)) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href={`/api-docs/cancellations#${hashRoute}`}>
        <h3 className="mb-8 text-xl font-medium">
          Set cancellation ready to cancel
        </h3>
      </a>
      <p className="mb-8">
        This mutation sets a cancellations{" "}
        <CodeSnippet inline bare code={"ready_to_cancel"} /> column to true, and
        should be used whenever the cancellation has all the requisite parts to
        begin the cancellation process with an agent (all cancellation fields
        have been filled out and the customer has been made aware of what a
        cancellation entails).
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment>
          <div className="text-base">Parameters</div>
          <AvailableFields>
            <Parameter
              required
              field={"cancellation_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>
                    Alpha-numeric unique identifier for the cancellation that
                    will be ready to cancel.
                  </div>
                </React.Fragment>
              }
            />
            <React.Fragment />
          </AvailableFields>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              The cancellation object that is now ready to cancel, if
              successful.
            </p>
            <p className="mt-4">
              If the request failed, an array of errors will be returned and the
              success field will be <CodeSnippet bare inline code="false" />
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(
                testApiKey!,
                setCancellationReadyToCancelExampleCurlBody
              )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              setCancellationReadyToCancelJson,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const setCancellationReadyToCancelExampleCurlBody = `
$'{
  "query": "mutation SetCancellationReadyToCancel($cancellationId:ID!){SetCancellationReadyToCancel(input:{cancellationId:$cancellationId}){success errors cancellation{id readyToCancel}}}",
  "variables": {
		"cancellationId": "cancellation_8e993f69bbcaaaea69f7cdc1a7eb8222"
	}
`;

import { useContext } from "react";
import { MetaStoreContext } from "src/stores/MetaStore";

export default function useMeta() {
  const { pageTitle, setPageTitle } = useContext(MetaStoreContext);

  return {
    pageTitle,
    setPageTitle
  };
}

import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "src/components/Breadcrumbs";
import Divider from "src/components/Divider";
import NewContainer from "src/components/NewContainer";
import usePageTitle from "src/hooks/usePageTitle";
import paths from "src/paths";
import CreatingBills from "./CreatingBills";
import CreatingCustomers from "./CreatingCustomers";
import InterpretingWebhooks from "./InterpretingWebhooks";
import RespondingToInfoRequests from "./RespondingToInfoRequests";
import RespondingToOffers from "./RespondingToOffers";

export default function ImplementationGuide() {
  usePageTitle("Implementation Guide");

  return (
    <React.Fragment>
      <NewContainer className="mt-8">
        <Breadcrumbs
          crumbs={[
            {
              path: "/api-docs/implementation",
              text: "Implementation Guide",
              active: true
            }
          ]}
        />
        <p className="mb-8">
          While there are no hard and fast rules about how you should implement
          our API in your own application, there are certain user experience
          flows that make the process easier for customers to understand. Below
          are our suggestions for how you might build and architect the user
          experience to match the expectations of our API.
        </p>
        <p className="">
          Use the guides below to see our suggested methods for implementing the
          API into your own product.
        </p>
        <ul className="ml-12 mt-8 list-disc">
          <li className="mb-3">
            <Link
              to={paths.apiDocs.implementation.creatingCustomers}
              className="text-blue-500 cursor-pointer"
            >
              Creating customers
            </Link>
          </li>
          <li className="mb-3">
            <Link
              to={paths.apiDocs.implementation.creatingBills}
              className="text-blue-500 cursor-pointer"
            >
              Creating bills
            </Link>
          </li>
          <li className="mb-3">
            <Link
              to={paths.apiDocs.implementation.respondingToInfoRequests}
              className="text-blue-500 cursor-pointer"
            >
              Responding to info requests
            </Link>
          </li>
          <li className="mb-3">
            <Link
              to={paths.apiDocs.implementation.respondingToOffers}
              className="text-blue-500 cursor-pointer"
            >
              Responding to offers
            </Link>
          </li>
          <li className="mb-3">
            <Link
              to={paths.apiDocs.implementation.interpretingWebhooks}
              className="text-blue-500 cursor-pointer"
            >
              How to interpret webhooks
            </Link>
          </li>
        </ul>
        <Divider marginSize={4} />
        <CreatingCustomers />
        <Divider marginSize={4} />
        <CreatingBills />
        <Divider marginSize={4} />
        <RespondingToInfoRequests />
        <Divider marginSize={4} />
        <RespondingToOffers />
        <Divider marginSize={4} />
        <InterpretingWebhooks />
        {/* <Divider marginSize={4} /> */}
      </NewContainer>
    </React.Fragment>
  );
}

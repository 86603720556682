import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import stopCancellingResponseJsonFile from "src/examples/responses/stop_cancelling.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function StopCancelling({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  const hashRoute = "stop-cancelling";

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(hashRoute);
      if (hash.includes(hashRoute)) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id}>
      <a href={`/api-docs/cancellations#${hashRoute}`}>
        <h3 className="mb-4 text-xl font-medium">Stop cancelling</h3>
      </a>
      <p className="mb-8">
        If a cancellation is currently being cancelled by an agent, a
        cancellation cannot be stopped. Cancellations can be stopped before the
        cancellation process with an agent begins or after they've finished, but
        not during the process.
      </p>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              required
              field={"cancellation_id"}
              type={"ID"}
              description={
                <React.Fragment>
                  <div>The ID of the cancellation.</div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>
              An object containing a boolean success field indicating if the
              cancellation was successfully stopped or not, an errors array
              containing errors (if any), and the cancellation object.
            </p>
            <p className="mt-2">
              Please note, there are several cases when the cancellation object
              will be null. If we have not yet attempted to cancel service,
              calling <CodeSnippet code="stop_cancelling" bare inline /> will
              effectively delete the cancellation, and the returned cancellation
              object will be null.
            </p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              curl: curlExample(testApiKey!, stopCancellingExmapleCurlBody)
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              stopCancellingResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const stopCancellingExmapleCurlBody = `
$'{
  "query": "mutation StopCancelling($cancellationId:ID!){StopCancelling(input:{cancellationId:$cancellationId}){success errors cancellation{id state cancelledAt}}}",
  "variables": {
    "cancellationId": "canellation_d8fb9e40650351069573001a8184845c"
  }
`;

import React, { createContext, ReactNode, useEffect, useReducer } from "react";

export interface ITestModeState {
  testModeOn: boolean;
  setTestModeOn: (testMode: boolean) => void;
}

interface ITestModeContext {
  testMode: boolean;
  dispatch: React.Dispatch<any>;
}

interface IProps {
  children: ReactNode;
}

const testModeReducer = (_: any, isTestMode: boolean) =>
  isTestMode ? true : false;

const TestModeContext: React.Context<ITestModeContext> = createContext(
  {} as ITestModeContext
);

const initialState = localStorage.getItem("testMode") === "true" || false;

function TestModeProvider({ children }: IProps) {
  const [testMode, dispatch] = useReducer(testModeReducer, initialState);
  // const [testMode, setTestMode] = useState(
  //   localStorage.getItem("testModeOn") === "true" || false
  // );

  useEffect(() => {
    localStorage.setItem("testMode", testMode.toString());
    // if (localStorage.getItem("testModeOn") === "true") {
    //   setTestMode(true);
    // }
  }, [testMode]);

  return (
    <TestModeContext.Provider value={{ testMode, dispatch }}>
      {children}
    </TestModeContext.Provider>
  );
}

export function useTestMode() {
  return React.useContext(TestModeContext);
}

export { TestModeProvider, TestModeContext };

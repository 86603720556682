import React from "react";
import moment from "moment";

interface IProps {
  input: any;
  onlyTime?: boolean;
  onlyDate?: boolean;
  timeFormat?: string;
  dateFormat?: string;
}

export default function DateAtTime({
  input,
  onlyTime = false,
  onlyDate = false,
  dateFormat = "l",
  timeFormat = "h:mm a"
}: IProps) {
  const datePart = moment(input).format(dateFormat);
  const timePart = moment(input).format(timeFormat);

  const parsedDateTime = () => {
    if (onlyTime) {
      return timePart;
    } else if (onlyDate) {
      return datePart;
    } else {
      return `${datePart} at ${timePart}`;
    }
  };

  return <span>{parsedDateTime()}</span>;
}

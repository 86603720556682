import Adjustments from "./icons/Adjustments";
import Home from "./icons/Home";
import Chip from "./icons/Chip";
import Code from "./icons/Code";
import Cog from "./icons/Cog";
import Speakerphone from "./icons/Speakerphone";

const Heroicon = {
  Adjustments,
  Chip,
  Code,
  Cog,
  Home,
  Speakerphone
};

export default Heroicon;

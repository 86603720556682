import { usePreferredLanguage } from "src/contexts/PreferredLanguageContext";
import CodeSnippet from "../CodeSnippet";

type TLanguageExample = {
  ruby?: string | (() => void);
  js?: string | (() => void);
  curl?: string | (() => void);
  python?: string | (() => void);
};

interface IProps {
  route: string;
  examples: TLanguageExample;
  className?: string;
}

export default function Request({ route, examples, className }: IProps) {
  const { preferredLanguage, dispatch } = usePreferredLanguage();

  const languageExampleSwitch = () => {
    switch (preferredLanguage) {
      case "ruby":
        if (typeof examples.ruby === "function") {
          return `${examples.ruby()}`;
        } else {
          return examples.ruby;
        }
      case "javascript":
        if (typeof examples.js === "function") {
          return `${examples.js()}`;
        } else {
          return examples.js;
        }
      case "bash":
        if (typeof examples.curl === "function") {
          return `${examples.curl()}`;
        } else {
          return examples.curl;
        }
      case "python":
        if (typeof examples.python === "function") {
          return `${examples.python()}`;
        } else {
          return examples.python;
        }
      default:
        break;
    }
  };

  return (
    <div className={`${className}`}>
      <div className="rounded-t-md bg-gray-700 p-2">
        <div className="flex items-center justify-between">
          <div className="font-mono text-sm text-gray-50">POST {route}</div>
          <div className="flex items-center justify-evenly space-x-2 text-xs text-gray-50">
            <div
              onClick={() => dispatch("ruby")}
              className={`cursor-pointer p-1 ${
                preferredLanguage === "ruby" ? "rounded bg-gray-800" : ""
              }`}
            >
              ruby
            </div>
            <div
              onClick={() => dispatch("javascript")}
              className={`cursor-pointer p-1 ${
                preferredLanguage === "javascript" ? "rounded bg-gray-800" : ""
              }`}
            >
              javascript
            </div>
            <div
              onClick={() => dispatch("bash")}
              className={`cursor-pointer p-1 ${
                preferredLanguage === "bash" ? "rounded bg-gray-800" : ""
              }`}
            >
              cURL
            </div>
            {examples.python && (
              <div
                onClick={() => dispatch("python")}
                className={`cursor-pointer p-1 ${
                  preferredLanguage === "python" ? "rounded bg-gray-800" : ""
                }`}
              >
                python
              </div>
            )}
          </div>
        </div>
      </div>
      <CodeSnippet
        language={preferredLanguage || "js"}
        code={languageExampleSwitch() || "Coming soon"}
        customStyle={{
          width: "inherit",
          border: "none",
          borderTopRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0.375rem",
          borderBottomRightRadius: "0.375rem",
          background: "hsla(212, 25%, 20%, 1)"
        }}
        showInsetCopy
      />
    </div>
  );
}

import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { ReactNode } from "react";
import Badge from "src/components/Badge";
import CodeSnippet from "src/components/CodeSnippet";
import { usePreferredLanguage } from "src/contexts/PreferredLanguageContext";
import ChildAttributes from "../ChildAttributes";
import PossibleFieldValues from "../PossibleFieldValues";

export type TFieldAttributes = {
  field: string;
  type: string;
  description: string | ReactNode[] | ReactNode;
  possibleValues?: string[];
  required?: boolean;
  defaultValue?: string;
  overridePreferredLanguage?: boolean; // use this to make the field not change case automatically and look exactly like what is provided to the field prop
};

interface IFieldAttributeProps extends TFieldAttributes {
  childAttributes?: TFieldAttributes[];
}

export default function Parameter({
  field,
  type,
  description,
  possibleValues,
  childAttributes,
  required = false,
  defaultValue,
  overridePreferredLanguage = false
}: IFieldAttributeProps) {
  const { preferredLanguage } = usePreferredLanguage();

  let casedField = field;

  if (!overridePreferredLanguage) {
    switch (preferredLanguage) {
      case "ruby":
        casedField = snakeCase(casedField);
        break;
      case "javascript":
        casedField = camelCase(casedField);
        break;
      case "bash":
        casedField = camelCase(casedField);
        break;
      case "python":
        casedField = snakeCase(casedField);
        break;
      default:
        casedField = camelCase(casedField);
    }
  }

  return (
    <div className="my-4">
      <div className="flex flex-col">
        <div className="mb-2 flex items-center">
          <div className="mr-2 font-mono text-sm font-semibold">
            {casedField}
          </div>
          <Badge text={type} className="bg-gray-200 text-gray-800" />
          <span
            className={`ml-2 rounded px-2 py-0.5 font-mono text-xs ${
              required && "bg-red-100 text-red-600"
            }`}
          >
            {required ? "required" : "optional"}
          </span>
        </div>
        {possibleValues && (
          <div className="mt-2 flex items-center text-sm">
            <span className="mr-2">Accepted values:</span>
            <PossibleFieldValues values={possibleValues} />
          </div>
        )}
        {defaultValue && (
          <div className="mb-2 text-sm">
            Default: <CodeSnippet inline bare code={defaultValue} />
          </div>
        )}
        <span className="mt-2 text-sm">{description}</span>
        {childAttributes && (
          <ChildAttributes attributes={childAttributes} canExpand={false} />
        )}
      </div>
    </div>
  );
}

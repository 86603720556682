import { Space, Stack } from "@cohubinc/blueprint";
import React, { Fragment } from "react";
import AvailableFields from "src/components/ApiDocs/AvailableFields";
import FieldAttribute from "src/components/ApiDocs/FieldAttribute";
import Reference from "src/components/ApiDocs/Reference";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import SavingsEstimator from "src/components/SavingsEstimator";
import Typography from "src/components/Typography";
import exampleSavingsEstimate from "src/examples/savings_estimate.json";
import usePageTitle from "src/hooks/usePageTitle";
import { StyledApiDocs } from "../styles";
import CalculateSavingsEstimate from "./CalculateSavingsEstimate";

const SavingsEstimate: React.FC = () => {
  usePageTitle("Savings Estimate");

  return (
    <Fragment>
      <Space height={3} />
      <StyledApiDocs className="mb-16">
        <Stack>
          <Typography.Large>
            BillFixers can estimate how much money a customer can save on a
            bill. We use a linear regression to estimate how much we'll be able
            to save based on a given provider and a given current monthly
            payment amount.
          </Typography.Large>
          <Typography.Large>
            On the right you'll see an example implementation of what you can do
            with a savings estimate. Try it out for yourself!
          </Typography.Large>
          <Space height={2} />
        </Stack>
        <SavingsEstimator />
      </StyledApiDocs>
      <StyledApiDocs>
        <Reference>
          <AvailableFields objectName="savingsEstimate">
            <FieldAttribute
              field={"estimatedAnnualSavings"}
              type={"Money"}
              description={
                "Amount that BillFixers estimates could be saved annually"
              }
            />
            <FieldAttribute
              field={"estimatedMonthlySavings"}
              type={"Money"}
              description={
                "Amount that BillFixers estimates could be saved monthly"
              }
            />
            <FieldAttribute
              field={"percentageSavings"}
              type={"Float"}
              description={
                "Percentage of the current payment that BillFixers estimates could be saved"
              }
            />
          </AvailableFields>
        </Reference>
        <CodeSnippet
          code={JSON.stringify(exampleSavingsEstimate, null, 2)}
          language="json"
        />
      </StyledApiDocs>
      <Divider marginSize={6} />
      <CalculateSavingsEstimate />
    </Fragment>
  );
};

export default SavingsEstimate;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Parameter from "src/components/ApiDocs/Mutations/Parameter";
import Request from "src/components/ApiDocs/Request";
import Response from "src/components/ApiDocs/Response";
import CodeSnippet from "src/components/CodeSnippet";
import Divider from "src/components/Divider";
import Segment from "src/components/Segment";
import curlExample from "src/examples/graphql/queries/cURL/curlExample";
import listRandomRecentSavingsResponseJsonFile from "src/examples/responses/list_random_recent_savings.json";
import useSession from "src/hooks/useSession";

interface IProps {
  className?: string;
  id?: string;
}

export default function ListRandomRecentSavings({ className, id }: IProps) {
  const { hash } = useLocation();
  const { testApiKey } = useSession();

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById(
        "list-random-recent-savings"
      );
      if (hash.includes("list-random-recent-savings")) {
        element?.scrollIntoView({
          behavior: "auto"
        });
      }
    }, 250);
  }, [hash]);

  return (
    <div className={className} id={id || "list-random-recent-savings"}>
      <a href="/api-docs/savings-estimate#list-random-recent-savings">
        <h3 className="mb-8 text-xl font-medium">
          List random recent savings events
        </h3>
      </a>
      <div className="grid grid-cols-2 place-items-start gap-4">
        <Segment className="w-full">
          <div className="text-base">Parameters</div>
          <Divider marginSize={1} />
          <div>
            <Parameter
              field={"savingsSource"}
              type={"String"}
              defaultValue="null"
              description={
                <React.Fragment>
                  <div>
                    The method/source that resulted in savings. Passing{" "}
                    <CodeSnippet bare inline code="null" /> to this argument
                    will return an array of savings events that contains both
                    negotiations and cancellations.
                  </div>
                </React.Fragment>
              }
              possibleValues={["negotiation", "cancellation"]}
            />
            <Parameter
              field={"arraySize"}
              type={"Integer"}
              defaultValue="10"
              description={
                <React.Fragment>
                  <div>
                    The number of savings events to return. The maximum value is
                    100
                  </div>
                </React.Fragment>
              }
            />
          </div>
          <div className="mt-12 text-base">Returns</div>
          <Divider marginSize={1} />
          <div className="text-sm">
            <p>An array of savings event objects.</p>
          </div>
        </Segment>
        <div className="sticky top-10 w-full">
          <Request
            route="/partners/graphql"
            examples={{
              // ruby: rubyExample(testApiKey!, findRandomRecentSavingsExampleRubyBody),
              // js: javascriptExample(
              //   testApiKey!,
              //   findRandomRecentSavingsExampleJsBody
              // ),
              curl: curlExample(
                testApiKey!,
                ListRandomRecentSavingsExampleCurlBody
              )
              // python: pythonExample(
              //   testApiKey!,
              //   findRandomRecentSavingsExamplePythonBody
              // )
            }}
          />
          <Response
            className="mt-6"
            responseJson={JSON.stringify(
              listRandomRecentSavingsResponseJsonFile,
              null,
              2
            )}
          />
        </div>
      </div>
    </div>
  );
}

const ListRandomRecentSavingsExampleCurlBody = `
$'{
	"query": "query ListRandomRecentSavings($savingsSource:String,$arraySize:Int){ListRandomRecentSavings(savingsSource:$savingsSource,arraySize:$arraySize){savings savingsSourceType customerInitials customerFirstName providerName providerLogo{tinyUrl smallUrl mediumUrl}savingsDate}}",
	"variables": {
		"arraySize": 3
	}
`;

import { host, protocol } from "src/config";

export interface IAuthenticatedPartnerUser {
  email: string;
  authentication_token: string;
  api_key: string;
  test_api_key: string;
}

export interface IAuthenticateResponse {
  partner_user?: IAuthenticatedPartnerUser;
  errors?: string[];
}

export async function authenticate(email: string, password: string) {
  const endpoint = [protocol, "://", host, "/api/partner_user_sessions"].join(
    ""
  );

  const response: IAuthenticateResponse = await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: {
      "Content-type": "application/json"
    }
  }).then((r) => r.json());

  return response;
}
